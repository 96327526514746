import { cnMixFlex } from '@consta/uikit/MixFlex';

import { KnowledgeDto } from '../../../../generateAxios';
import outlookLogo from '../../../assets/images/img_portfolio_outlookLogo.png';
import { InfoCard } from '../../../components/InfoCard/InfoCard.tsx';

import { KnowledgeCopyLinkButton } from './Buttons/KnowledgeCopyLinkButton.tsx';
import { KnowledgeShareButton } from './Buttons/KnowledgeShareButton.tsx';

export const KnowledgeShareInfoCard = ({ knowledge }: { knowledge: KnowledgeDto }) => {
  return (
    <InfoCard
      header={<img src={outlookLogo} height={'48px'} alt={'img'} />}
      content={'Знаете, кому будет интересен этот материал? Отправьте ссылку на почту в один клик'}
      footer={
        <div className={cnMixFlex({ gap: 'm', justify: 'space-between' })}>
          <KnowledgeShareButton knowledge={knowledge} />
          <KnowledgeCopyLinkButton />
        </div>
      }
    />
  );
};
