import { memo } from 'react';

import { useFormContext } from 'react-hook-form';

import { FullTeamItemDto, type HelpdeskForShowDto } from '../../../../../../../generateAxios';
import { DnDSortList } from '../../../../../../components/DnDSortList/DnDSortList.tsx';

import { ListTitle } from '../ListTitle/ListTitle.tsx';
import { ResourceListItem } from '../ResourceListItem/ResourceListItem.tsx';

export const DraggableResources = memo(
  ({
    onDelete,
    onEdit,
    selectedResource,
    onAdd,
  }: {
    onEdit: (item: HelpdeskForShowDto) => void;
    onDelete: (item: HelpdeskForShowDto) => void;
    onAdd: () => void;
    selectedResource: HelpdeskForShowDto | null;
  }) => {
    const { watch, setValue } = useFormContext<FullTeamItemDto>();

    const resources = watch('resources');

    const handlerAfterMove = (items: HelpdeskForShowDto[]) => {
      setValue('resources', items);
    };

    return (
      <>
        <ListTitle title={'Список ресурсов'} buttonLabel={'Добавить ресурс'} onClick={onAdd} />
        <DnDSortList<HelpdeskForShowDto>
          type={'resource'}
          renderItem={(item) => {
            return (
              <ResourceListItem
                item={item}
                onClickEdit={onEdit}
                onClickDelete={onDelete}
                isSelected={selectedResource?._uuid === item._uuid}
              />
            );
          }}
          originalItems={resources ?? []}
          uniqueKey={'_uuid'}
          afterMove={handlerAfterMove}
        />
      </>
    );
  }
);
