/* eslint-disable @typescript-eslint/no-unused-vars */
import { FaqDto, FaqRestService } from '../../generateAxios';

import { createAppSlice } from '../createAppSlice';
import { AppDispatch, RootState } from '../store.ts';

import { generalActions } from './generalSlice.ts';

type FaqPageState = {
  faqItems: FaqDto[] | null;
  pageLoader: boolean;
  faqEditModal: boolean;
  faqDeleteModal: FaqDto | null;
  currentFaq: FaqDto | null;
  newFaq: FaqDto | null;
};

const initialState: FaqPageState = {
  faqItems: null,
  pageLoader: true,
  faqEditModal: false,
  faqDeleteModal: null,
  currentFaq: null,
  newFaq: null,
};

const faqPageSlice = createAppSlice({
  name: 'faqPage',
  initialState,
  reducers: (creators) => ({
    setFaqEditModal: creators.reducer<boolean>((state, action) => {
      state.faqEditModal = action.payload;
    }),
    setFaqDeleteModal: creators.reducer<FaqDto | null>((state, action) => {
      state.faqDeleteModal = action.payload;
    }),
    setCurrentFaq: creators.reducer<FaqDto | null>((state, action) => {
      state.currentFaq = action.payload;
    }),
    setNewFaq: creators.reducer<FaqDto | null>((state, action) => {
      state.newFaq = action.payload;
    }),
    clearState: creators.reducer(() => {
      return initialState;
    }),
    getFaqs: creators.asyncThunk(
      async (_: undefined) => {
        const res = await FaqRestService.getFaq();
        return res;
      },
      {
        pending: (state) => {
          state.pageLoader = true;
        },
        fulfilled: (state, action) => {
          state.faqItems = action.payload;
        },
        rejected: (_) => {},
        settled: (state) => {
          state.pageLoader = false;
        },
      }
    ),
    addToFavorite: creators.asyncThunk(
      async (faq: FaqDto) => {
        const res = await FaqRestService.addFaqToMain(!faq.show_on_main_page, faq._uuid);
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.faqItems =
            state.faqItems?.map((item) =>
              item._uuid === action.payload._uuid ? action.payload : item
            ) ?? null;
        },
        rejected: (_) => {},
      }
    ),
    createFaq: creators.asyncThunk(
      async (faq: FaqDto, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const res = await FaqRestService.addFaq(faq);
        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: 'Вопрос успешно добавлен',
          })
        );
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.faqEditModal = false;
          state.faqItems = action.payload;
        },
        rejected: (_) => {},
      }
    ),
    updateFaq: creators.asyncThunk(
      async (faq: FaqDto, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const res = await FaqRestService.updateFaq(faq);
        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: 'Вопрос успешно изменен',
          })
        );
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.faqEditModal = false;
          state.faqItems = action.payload;
        },
        rejected: (_) => {},
      }
    ),
    deleteFaq: creators.asyncThunk(
      async (_: undefined, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const currentFaq = state.faqPage.currentFaq;
        if (currentFaq?._uuid) {
          const res = await FaqRestService.deleteFaq(currentFaq?._uuid);
          dispatch(
            generalActions.addNotification({
              status: 'success',
              message: 'Вопрос успешно удален',
            })
          );
          return res;
        } else {
          return thunkAPI.rejectWithValue('нет id для удаления faq');
        }
      },
      {
        fulfilled: (state, action) => {
          state.faqDeleteModal = null;
          state.currentFaq = null;
          state.faqItems = action.payload;
        },
        rejected: (_, action) => {
          console.log(action.payload);
        },
      }
    ),
    sortFaqs: creators.asyncThunk(
      async (newFaqs: FaqDto[], thunkAPI) => {
        // const state = thunkAPI.getState() as RootState;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        // const currentFaq = state.faqPage.currentFaq;
        // расстановка ord по убыванию для элементов
        const itemsWithNewOrd = newFaqs.map((item, index, array) => ({
          ...item,
          ord: array.length - 1 - index,
        }));
        const res = await FaqRestService.updateFaqsOrder(itemsWithNewOrd);
        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: 'Порядок элементов изменен',
          })
        );
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.faqItems = action.payload;
        },
        rejected: (state) => {
          state.faqItems = [...(state.faqItems ?? [])];
        },
      }
    ),
  }),

  selectors: {
    pageLoader: (state) => state.pageLoader,
    faqItems: (state) => state.faqItems,
    faqCountInMainPage: (state) =>
      state.faqItems?.filter((item) => item.show_on_main_page).length || 0,
    faqEditModal: (state) => state.faqEditModal,
    faqDeleteModal: (state) => state.faqDeleteModal,
    currentFaq: (state) => state.currentFaq,
    newFaq: (state) => state.newFaq,
  },
});

export const faqPageActions = faqPageSlice.actions;
export const faqPageSelectors = faqPageSlice.getSelectors(faqPageSlice.selectSlice);

export default faqPageSlice.reducer;
