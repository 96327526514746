import { Dispatch, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

import { type FeedbackDto, PortfolioDto } from '../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks.ts';
import {
  portfolioItemPageActions,
  portfolioItemPageSelectors,
} from '../../../../store/reducers/portfolioItemPageSlice.ts';
import { isAdminModeSelector } from '../../../../store/reducers/selectors.ts';

import { AppModal } from '../../AppModal/AppModal.tsx';
import { Divider } from '../../Divider/Divider.tsx';
import { PortfolioFormValues } from '../../PortfolioEditor/PortfolioEditor.tsx';
import { PortfolioPreview } from '../../PortfolioPreview/PortfolioPreview.tsx';

import classes from './PortfolioPreviewModal.module.css';

export const PortfolioPreviewModal = ({
  portfolioPreviewModal: modal,
  setPortfolioPreviewModal,
  goBack,
  saveButtonName,
}: {
  portfolioPreviewModal: { data: PortfolioFormValues | null; open: boolean };
  setPortfolioPreviewModal: Dispatch<{
    data: PortfolioFormValues | null;
    open: boolean;
  }>;
  goBack: () => void;
  saveButtonName: string;
}) => {
  const dispatch = useAppDispatch();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const portfolio = useAppSelector(portfolioItemPageSelectors.portfolio);
  const submit = async (data: PortfolioFormValues) => {
    setLoading(true);
    const result = await dispatch(
      portfolioItemPageActions.savePortfolio({
        data,
        isAdminMode,
        alreadyPublished: portfolio?.status === 'PUBLISHED',
      })
    );
    setLoading(false);
    if (result.type === 'portfolioItemPage/savePortfolio/fulfilled') {
      setPortfolioPreviewModal({ open: false, data: modal.data });
      goBack();
    }
  };

  // const dispatch = useAppDispatch();
  // const modal = useAppSelector(portfolioItemPageSelectors.portfolioPreviewModal);
  const addedFeedback: FeedbackDto[] =
    modal.data?.feedbacks?.map((el) => ({
      user: {
        ...el.user,
        _uuid: '_uuid' in el.user ? el.user._uuid : '',
      },
      body: el.body,
      guid: '00000000-0000-0000-0000-000000000000',
    })) ?? [];
  const newFeedback: FeedbackDto[] =
    modal.data && 'newFeedback' in modal.data && modal.data.newFeedback
      ? [
          {
            user: {
              // display_name: modal.data.newFeedback.user.display_name,
              // sso_id: modal.data.newFeedback.user.sso_id,
              // _uuid: '_uuid' in modal.data.newFeedback.user ? modal.data.newFeedback.user._uuid : '',
              ...modal.data.newFeedback.user,
              _uuid: modal.data.newFeedback.user.sso_id,
            },
            body: modal.data.newFeedback.body,
            guid: modal.data.newFeedback.guid,
          },
        ]
      : [];

  const data: PortfolioDto | null = modal.data
    ? {
        ...modal.data,
        domains: modal.data.domains ? [...modal.data.domains] : undefined,
        complexity: { _uuid: '' },
        _uuid: '',
        status: 'MODERATION',
        user: {
          ...modal.data.user,
          _uuid: '',
          sso_id: modal.data.user?.sso_id || '',
          display_name: modal.data.user?.display_name || '',
        },
        feedbacks: [...addedFeedback, ...newFeedback],
      }
    : null;

  return (
    <AppModal
      isOpen={modal.open}
      className={classes.modal}
      close={() => setPortfolioPreviewModal({ open: false, data: modal.data })}
      afterClose={() => setPortfolioPreviewModal({ open: false, data: null })}
    >
      <Text size={'xl'} weight={'medium'} className={classes.title}>
        Предпросмотр
      </Text>
      <Divider />
      <div className={classes.content}>
        {data && <PortfolioPreview portfolio={data} withTool />}
      </div>
      <Divider />
      <div className={classes.buttonGroup}>
        <Button
          label={'Отмена'}
          view={'ghost'}
          onClick={() => setPortfolioPreviewModal({ open: false, data: modal.data })}
          loading={loading}
        />
        <Button
          label={saveButtonName}
          view={'primary'}
          onClick={() => modal.data && submit(modal.data)}
          loading={loading}
          disabled={portfolio?.read_only}
        />
      </div>
    </AppModal>
  );
};
