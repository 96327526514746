import { Attachment } from '@consta/uikit/Attach';
import { Button } from '@consta/uikit/Button';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconTrash } from '@consta/uikit/IconTrash';

import { FileForShowDto } from '../../../../../../../../generateAxios';

import classes from './KnowledgeEditorFileItem.module.css';

export const KnowledgeEditorFileItem = ({
  fileItem,
  deleteFile,
  index,
}: {
  fileItem: (FileForShowDto & { data?: File }) | FileForShowDto;
  index: number;
  deleteFile: (index: number) => void;
}) => {
  const fileTitle = fileItem.origin_file_name ?? 'нет имени файла';
  const fileSizeMb = fileItem.origin_file_size ? fileItem.origin_file_size / (1024 * 1024) : 0;
  const fileInfo = `${fileSizeMb.toFixed(2)} Мб`;

  return (
    <div className={classes.container}>
      <IconDraggable size={'s'} className={classes.draggable} />

      <Attachment
        fileName={fileTitle}
        // title={fileInfo}
        fileExtension={
          'data' in fileItem && fileItem.data instanceof File
            ? fileItem.data.name.split('.').pop()
            : fileItem.origin_file_extension?.extension
        }
        fileDescription={fileInfo}
        // className={classes.attachment}
        size={'m'}
      />
      <Button
        view={'clear'}
        onlyIcon
        iconRight={IconTrash}
        size={'s'}
        onClick={() => deleteFile(index)}
      />
    </div>
  );
};
