import { useEffect, useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconForward } from '@consta/uikit/IconForward';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { Text } from '@consta/uikit/Text';

import Slider from 'react-slick';

import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import { useAppDispatch } from '../../../../../hooks/hooks.ts';
import { generalActions } from '../../../../../store/reducers/generalSlice.ts';
import { AppContentWrapper } from '../../../AppContentWrapper/AppContentWrapper.tsx';
import { FileForShowDtoWithData } from '../../../KnowledgeEditor/KnowledgeEditor.tsx';
import { VideoApp } from '../../../VideoApp/VideoApp.tsx';

import { KnowledgeDtoWithFileData } from '../../KnowledgePreview.tsx';

import classes from './KnowledgeContentVideo.module.css';

export const KnowledgeContentVideo = ({ knowledge }: { knowledge: KnowledgeDtoWithFileData }) => {
  const sliderRef = useRef<Slider>(null);
  const [currentVideo, setCurrentVideo] = useState<number>(0);

  if (!knowledge.video_files) return null;

  return (
    <AppContentWrapper
      width={'wide'}
      className={cnMixFlex({ direction: 'column', gap: 'xl', flex: 'flex' })}
    >
      <div className={classes.videoContainer}>
        <Slider
          initialSlide={0}
          slidesToShow={1}
          slidesToScroll={1}
          draggable={false}
          beforeChange={(_, nextSlide) => {
            setCurrentVideo(nextSlide);
          }}
          ref={sliderRef}
          infinite={false}
          // adaptiveHeight
          swipe={false}
          arrows={false}
        >
          {knowledge.video_files?.map((video) => <VideoItem video={video} key={video._uuid} />)}
        </Slider>
        {/*todo будет редизайн описания видео */}
        <Text className={classes.videoDescription}>
          {knowledge.video_files?.[currentVideo]?.description}
        </Text>
        {knowledge.video_files.length > 1 && (
          <div className={cnMixFlex({ gap: 'xl', align: 'center' })}>
            <Button
              view={'ghost'}
              iconRight={IconBackward}
              disabled={currentVideo === 0}
              form={'round'}
              onClick={() => {
                knowledge.video_files?.[currentVideo]?._uuid &&
                  (
                    document.getElementById(
                      knowledge.video_files[currentVideo]._uuid
                    ) as HTMLVideoElement
                  ).pause();
                sliderRef.current?.slickPrev();
                // setCurrentVideo((prev) => prev - 1);
              }}
            />
            <Text>
              {currentVideo + 1} из {knowledge.video_files.length}
            </Text>
            <Button
              view={'ghost'}
              iconRight={IconForward}
              form={'round'}
              disabled={currentVideo === knowledge.video_files.length - 1}
              onClick={() => {
                knowledge.video_files?.[currentVideo]?._uuid &&
                  (
                    document.getElementById(
                      knowledge.video_files[currentVideo]._uuid
                    ) as HTMLVideoElement
                  ).pause();
                sliderRef.current?.slickNext();
                // setCurrentVideo((prev) => prev + 1);
              }}
            />
          </div>
        )}
      </div>
    </AppContentWrapper>
  );
};

const VideoItem = ({ video }: { video: FileForShowDtoWithData }) => {
  const dispatch = useAppDispatch();
  const videoDataSrc =
    'data' in video && video.data instanceof File ? URL.createObjectURL(video.data) : undefined;
  const videoLinkSrc = `${OpenAPI.BASE}/files/video/${video._uuid}`;

  useEffect(() => {
    return () => {
      if (videoDataSrc) {
        // при очистке URL ломается слайдер
        // URL.revokeObjectURL(videoDataSrc);
      }
    };
  });

  // const handleContextMenu = (event: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
  //   event.preventDefault();
  // };

  return (
    <div key={video._uuid} className={classes.slide}>
      <VideoApp width={694} height={400} id={video._uuid}>
        <source
          src={'data' in video && video.data instanceof File ? videoDataSrc : videoLinkSrc}
          type="video/mp4"
          onError={() => {
            dispatch(
              generalActions.addNotification({
                status: 'warning',
                message: 'ошибка при загрузке видео',
              })
            );
          }}
        />
      </VideoApp>
    </div>
  );
};
