import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFormContext } from 'react-hook-form';

import type { FullTeamItemDto, HelpdeskForShowDto } from '../../../../../../../generateAxios';

import classes from './EditEmail.module.css';

export const EditEmail = ({ email }: { email: HelpdeskForShowDto }) => {
  const { control, getValues } = useFormContext<FullTeamItemDto>();

  let emailIndex = (getValues('emails') ?? [])?.findIndex((r) => r._uuid === email._uuid);
  if (emailIndex === -1) return null;
  return (
    <div className={classes.container}>
      <Text size={'l'} weight={'medium'} className={cnMixSpace({ mB: '2xs' })}>
        {`${email.is_new ? 'Добавление' : 'Редактирование'} адреса почты`}
      </Text>

      <Controller
        name={`emails`}
        control={control}
        render={({ field: { ref, ...field } }) => {
          return (
            <TextField
              {...field}
              width={'full'}
              value={field.value?.[emailIndex]?.name ?? ''}
              label={'Название почты'}
              placeholder={'Введите название почты'}
              onChange={({ value }) => {
                const newValue = field.value ?? [];
                newValue[emailIndex].name = value ?? '';
                field.onChange(newValue);
              }}
              inputRef={ref}
            />
          );
        }}
      />
      <Controller
        name={`emails`}
        control={control}
        rules={{
          validate: {
            validateEmail: (value) => {
              if (
                value?.[emailIndex]?.link?.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
              ) {
                return true;
              } else {
                return 'Введите адрес электронной почты';
              }
            },
          },
        }}
        render={({ field: { ref, ...field }, fieldState: { error } }) => {
          return (
            <TextField
              {...field}
              required
              width={'full'}
              value={field.value?.[emailIndex]?.link ?? ''}
              label={'Адрес электронной почты'}
              placeholder={'Введите адрес электронной почты'}
              onChange={({ value }) => {
                const newValue = [...(field.value ?? [])];
                newValue[emailIndex] = { ...newValue[emailIndex], link: value ?? '' };
                field.onChange(newValue);
              }}
              inputRef={ref}
              status={!!error?.message ? 'alert' : undefined}
              caption={error?.message}
            />
          );
        }}
      />
    </div>
  );
};
