import { Text } from '@consta/uikit/Text';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import chartIcon from '../../../../assets/icons/svg/icn_main_hero_charts.svg';
import playIcon from '../../../../assets/icons/svg/icn_main_hero_play.svg';
import scheduleIcon from '../../../../assets/icons/svg/icn_main_hero_schedules.svg';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { divideNumIntoCategories } from '../../../../utils/divideNumIntoCategories.ts';

import classes from './HeroBlock.module.scss';

const heroForWho = [
  {
    text: 'Пользователям аналитических приложений и ИТ-специалистам',
    svg: chartIcon,
  },
  {
    text: 'Тем, кто формирует отчетность и презентации для руководства',
    svg: playIcon,
  },
  {
    text: 'Сотрудникам, которые анализируют данные в файлах Excel',
    svg: scheduleIcon,
  },
];

export const HeroBlock = () => {
  const { STORE_USER_COUNT } = useAppSelector(mainPageSelectors.metricsObject);
  return (
    <div className={classes.heroContainer}>
      <div className={classes.greyBg}></div>

      <AppContentWrapper>
        <div className={classes.heroContent}>
          <div className={classes.heroTitle}>
            <Text size={'4xl'} weight={'semibold'}>
              Лаборатория данных
            </Text>
            <Text view={'primary'} size={'xl'} weight={'medium'}>
              Экосистема для самостоятельного поиска, обработки, анализа и визуализации данных
            </Text>
          </div>

          <div className={classes.heroUserCount}>
            <Text size={'3xl'} weight={'semibold'}>
              {STORE_USER_COUNT?.value && divideNumIntoCategories(STORE_USER_COUNT?.value)}
            </Text>
            <Text view={'primary'} size={'m'} weight={'semibold'}>
              {STORE_USER_COUNT?.presentation_name}
            </Text>
          </div>
        </div>
      </AppContentWrapper>
      <AppContentWrapper>
        <div className={classes.forWhoCardContainer}>
          {heroForWho.map((el) => (
            <div className={classes.forWhoCard} key={el.text}>
              <img src={el.svg} alt={''} width={32} height={32} />
              <Text>{el.text}</Text>
            </div>
          ))}
        </div>
      </AppContentWrapper>
    </div>
  );
};
