import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

import { useSearchParams } from 'react-router-dom';

import { FullToolDto } from '../../../../../generateAxios';
import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { toolsPageSelectors } from '../../../../../store/reducers/toolsPageSlice.ts';
import imgErrorSettings from '../../../../assets/icons/svg/icn_settings_cross.svg';
import img from '../../../../assets/images/case-publish-bg.png';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import { toolQueryKey } from '../../ToolsPage.tsx';

import classes from './ToolsSwitcher.module.css';

export const ToolsSwitcher = () => {
  const tools = useAppSelector(toolsPageSelectors.tools);
  const [currentToolId] = useAppSelector(selectParamByKey(toolQueryKey));
  const otherTools = currentToolId
    ? tools?.filter((tool) => tool.tool._uuid !== currentToolId) ?? []
    : tools?.slice(1) ?? [];

  const [, setSearchParams] = useSearchParams();

  const handlerClick = (item: FullToolDto) => {
    setSearchParams((prev) => {
      prev.set(toolQueryKey, item.tool._uuid);
      return prev;
    });
    scrollTo({ behavior: 'smooth', top: 240 });
  };
  return (
    otherTools.length > 0 && (
      <AppContentWrapper className={classes.container}>
        <Text size={'3xl'} weight={'semibold'}>
          Другие инструменты
        </Text>
        <div className={classes.buttonGroup}>
          {otherTools.map((tool) => (
            <Button
              key={tool.tool._uuid}
              view={'clear'}
              iconLeft={() => (
                <img
                  src={
                    tool?.tool.uuid_image
                      ? `${OpenAPI.BASE}/image/${tool?.tool.uuid_image}`
                      : imgErrorSettings
                  }
                  onError={(event) => {
                    event.currentTarget.src = imgErrorSettings;
                  }}
                  alt={''}
                  height={'32px'}
                />
              )}
              onClick={() => handlerClick(tool)}
              className={classes.button}
            />
          ))}
        </div>
      </AppContentWrapper>
    )
  );
};
