import { useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconClose } from '@consta/uikit/IconClose';
import { IconForward } from '@consta/uikit/IconForward';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

import Slider from 'react-slick';

import { OpenAPI } from '../../../../generateAxios/core/OpenAPI.ts';
import imgErrorSettings from '../../../assets/icons/svg/icn_settings_cross.svg';

import classes from './ImageSlidermodal.module.css';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  initialSlide?: number;
  images?: {
    uuid_image?: string;
    // ord?: number;
    // is_main?: boolean;
    description?: string;
    // data_hash?: string;
    // origin_file_name?: string;
    // origin_file_type?: string;
    // origin_file_size?: number;
    // image_type?: 'TOOL' | 'CONNECTION_TYPE' | 'USER_AVATAR' | 'DATA' | 'OTHER';
    image?: string;
  }[];
};

export const ImageSlidermodal = ({ showModal, images, closeModal, initialSlide = 0 }: Props) => {
  const sliderRef = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const imagesLength = images?.length || 0;

  return (
    <Modal
      isOpen={showModal}
      onEsc={closeModal}
      position={'top'}
      onOpen={() => {
        setCurrentSlide(initialSlide);
        document.body.style.overflow = 'hidden'; // отключаем прокрутку при открытии модального окна
      }}
      afterClose={() => {
        setCurrentSlide(0);
        document.body.style.overflow = ''; // возвращаем прокрутку при закрытии модального окна
      }}
      className={classes.modal}
    >
      <div className={classes.content}>
        <Slider
          initialSlide={initialSlide}
          slidesToShow={1}
          slidesToScroll={1}
          draggable={false}
          beforeChange={(_, nextSlide) => {
            setCurrentSlide(nextSlide);
          }}
          ref={sliderRef}
          infinite={false}
          adaptiveHeight
          swipe={false}
          arrows={false}
        >
          {images?.map((image) => {
            const imgSrc =
              'data' in image && image.data instanceof File
                ? URL.createObjectURL(image.data)
                : image.uuid_image
                  ? `${OpenAPI.BASE}/image/${image.uuid_image}`
                  : imgErrorSettings;
            return (
              <div key={image.uuid_image} className={classes.slide}>
                <img
                  key={image.uuid_image}
                  id={'image'}
                  src={imgSrc}
                  onError={(event) => {
                    event.currentTarget.src = imgErrorSettings;
                  }}
                  alt={''}
                  className={classes.img}
                />
              </div>
            );
          })}
        </Slider>
        {images?.[currentSlide].description && (
          <Text className={classes.text}>{images?.[currentSlide].description}</Text>
        )}
        <div className={classes.carouselButtons}>
          <Button
            onlyIcon
            iconLeft={IconBackward}
            view="ghost"
            form="round"
            size="m"
            onClick={() => sliderRef.current?.slickPrev()}
            disabled={currentSlide === 0}
          />
          <Text onClick={() => {}}>
            {currentSlide + 1} из {imagesLength}
          </Text>

          <Button
            onlyIcon
            iconLeft={IconForward}
            view="ghost"
            form="round"
            size="m"
            onClick={() => sliderRef.current?.slickNext()}
            disabled={currentSlide + 1 === imagesLength}
          />
        </div>
      </div>
      <Button
        className={classes.closeButton}
        iconLeft={() => <IconClose style={{ color: '#FFF' }} />}
        view={'clear'}
        onClick={closeModal}
      />
    </Modal>
  );
};
