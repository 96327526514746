import { useMemo } from 'react';

import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { IconForward } from '@consta/uikit/IconForward';
import { cnMixCard } from '@consta/uikit/MixCard';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import educationIcon from '../../../../assets/icons/svg/icn_main_about_product_education.svg';
import experienceIcon from '../../../../assets/icons/svg/icn_main_about_product_experience.svg';
import fastIcon from '../../../../assets/icons/svg/icn_main_about_product_fast.svg';
import guideIcon from '../../../../assets/icons/svg/icn_main_about_product_guide.svg';
import toolsIcon from '../../../../assets/icons/svg/icn_main_about_product_tools.svg';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { knowledgeConfig } from '../../../../configs/knowledgeConfig.ts';
import { tagHashKeysConfig } from '../../../../configs/linksConfig.ts';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';

import classes from './AboutProduct.module.scss';

const aboutProductContent = [
  {
    title: 'Быстрый старт',
    description: 'Решайте задачи без инициации проекта и \nпривлечения разработчика',
    svg: fastIcon,
  },
  {
    title: 'Непрерывное обучение',
    description: 'Совершенствуйте свои компетенции \nпо анализу и визуализации данных',
    svg: educationIcon,
  },
  {
    title: 'Удобные инструменты',
    description: 'Используйте современные ИТ-решения \nи актуальные данные',
    svg: toolsIcon,
  },
  {
    title: 'Обмен опытом',
    description:
      'Расскажите об успешном решении задачи \nи познакомьтесь с достижениями ваших коллег',
    svg: experienceIcon,
  },
];

export const AboutProduct = () => {
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const tags = useAppSelector(mainPageSelectors.knowledgeTagUuids);

  // формирование ссылки для Гайда о процессе решения аналитических задач
  const guideLink: string | null = useMemo(() => {
    const tagUuid = tags[tagHashKeysConfig.tag_process];
    if (!tagUuid) return null;
    return `${PageRoutes.DataLab_KnowledgesPage}?${knowledgeConfig.filterTagsQueryKey}=${tagUuid}${isAdminMode ? '&mode=admin' : ''}`;
  }, [tags, isAdminMode]);

  return (
    <AppContentWrapper className={classes.aboutProductContainer}>
      <Text view={'brand'} size={'l'} weight={'bold'}>
        О ПРОДУКТЕ
      </Text>
      <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xs', mB: 'm' })}>
        Возможности
      </Text>
      <Text size={'xl'} weight={'regular'} className={cnMixCard({})}>
        {`Лаборатория данных обеспечивает всем необходимым для
        эффективного решения задач по анализу данных`}
      </Text>

      <Grid className={cnMixSpace({ mT: '4xl' })} cols={2} colGap={'l'} rowGap={'3xl'}>
        {aboutProductContent.map((el) => (
          <div className={classes.gridContainer} key={el.title}>
            <img src={el.svg} alt={''} width={'48px'} height={'48px'} />
            <Text size={'2xl'} weight={'bold'}>
              {el.title}
            </Text>
            <GridItem colStart={2}>
              <Text size={'l'} weight={'medium'}>
                {el.description}
              </Text>
            </GridItem>
          </div>
        ))}
      </Grid>

      <div className={classes.guide}>
        <div className={cnMixFlex({ gap: 'xl' })}>
          <img src={guideIcon} alt="guide" width={'48px'} height={'48px'} />
          <Text>
            {`Гайд о процессе решения аналитических задач расскажет, как использовать наши
              инструменты для достижения лучших результатов`}
          </Text>
        </div>
        {guideLink ? (
          <Button
            as={'a'}
            href={relativeToAbsoluteLink(guideLink)}
            target={'_blank'}
            view={'clear'}
            form={'round'}
            onClick={(event) => {
              event.preventDefault();
              navigate(guideLink);
            }}
            iconRight={IconForward}
            label={'Подробнее'}
          />
        ) : (
          <Button
            disabled
            view={'clear'}
            form={'round'}
            iconRight={IconForward}
            label={'Подробнее'}
          />
        )}
      </div>
    </AppContentWrapper>
  );
};
