import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { generalActions, generalSelectors } from '../../../store/reducers/generalSlice.ts';

import { AppModal } from '../AppModal/AppModal.tsx';

import classes from './LoginModal.module.css';

export const LoginModal = () => {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const showLoginModal = useAppSelector(generalSelectors.showLoginModal);

  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handlerConfirm = async () => {
    // const callback = () => {
    //   navigate(0);
    // };
    dispatch(
      generalActions.authUser({
        authParams: {
          username: login,
          password: password,
        },
        callback: undefined,
      })
    );
  };

  return (
    <AppModal
      isOpen={showLoginModal}
      close={() => {
        dispatch(generalActions.setShowLoginModal(false));
      }}
      hideCloseIcon
      disableCloseOnClickOutside
      afterClose={() => {
        setLogin('');
        setPassword('');
      }}
    >
      <div className={classes.loginForm}>
        <Text size={'l'} weight={'bold'} lineHeight={'l'}>
          Введите имя пользователя и пароль
        </Text>
        <TextField
          key={'login'}
          id={'login'}
          label="Имя пользователя"
          labelPosition="top"
          onChange={({ value }) => {
            setLogin(value ?? '');
          }}
          value={login}
          type={'text'}
          placeholder="Имя пользователя"
          width={'full'}
        />
        <TextField
          key={'password'}
          id={'password'}
          label="Пароль"
          labelPosition="top"
          onChange={({ value }) => {
            setPassword(value ?? '');
          }}
          value={password}
          type="password"
          placeholder="Пароль"
          width={'full'}
        />
        <Button label={'Войти'} onClick={handlerConfirm} />
      </div>
    </AppModal>
  );
};
