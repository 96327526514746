import { IconComponent } from '@consta/uikit/Icon';
import { ListItem } from '@consta/uikit/ListCanary';
import { Select } from '@consta/uikit/Select';
import { Text } from '@consta/uikit/Text';
import { DefaultItem } from '@consta/uikit/__internal__/src/components/Select/helpers';

import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../store/reducers/selectors.ts';

import classes from './SortSelect.module.css';

export type CustomSortItem = DefaultItem & {
  // value: NonNullable<FiltersPortfolioDto['order']>;
  value: string;
  icon: IconComponent;
};

type Props = {
  sortQueryKey: string;
  items: CustomSortItem[];
};

export const SortSelect = ({ items, sortQueryKey }: Props) => {
  const [value] = useAppSelector(selectParamByKey(sortQueryKey));
  const [, setSearchParams] = useSearchParams();
  const onChange = ({ value }: { value: CustomSortItem | null; e: React.SyntheticEvent }) => {
    setSearchParams((prev) => {
      value?.value ? prev.set(sortQueryKey, value.value) : prev.delete(sortQueryKey);
      return prev;
    });
  };
  return (
    <Select
      items={items}
      value={items.find((el) => el.value === value) ?? items[0]}
      onChange={onChange}
      size={'m'}
      className={classes.select}
      dropdownClassName={classes.dropdown}
      renderValue={({ item }) => (
        <div className={classes.itemValue}>
          <item.icon view={'link'} />
          <Text>{item.label}</Text>
        </div>
      )}
      renderItem={({ item, onClick, active }) => {
        return (
          <ListItem
            label={item.label}
            size={'m'}
            leftSide={<item.icon />}
            role="option"
            aria-selected={active}
            onClick={onClick}
            checked={active}
          />
        );
      }}
    />
  );
};
