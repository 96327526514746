import { useEffect, useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconClose } from '@consta/uikit/IconClose';
import { IconForward } from '@consta/uikit/IconForward';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { withTooltip } from '@consta/uikit/withTooltip';

import Slider from 'react-slick';

import { OpenAPI } from '../../../../generateAxios/core/OpenAPI.ts';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks.ts';
import { generalActions } from '../../../../store/reducers/generalSlice.ts';
import {
  knowledgePageActions,
  knowledgePageSelectors,
} from '../../../../store/reducers/knowledgePageSlice.ts';

import { VideoApp } from '../../VideoApp/VideoApp.tsx';

import classes from './VideoSlidermodal.module.css';

const TextWithTooltip = withTooltip()(Text);

export const VideoSliderModal = () => {
  const dispatch = useAppDispatch();
  const videoModal = useAppSelector(knowledgePageSelectors.videoModal);
  const sliderRef = useRef<Slider>(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const filesLength = videoModal.files.length;

  const closeModal = () => {
    dispatch(knowledgePageActions.setVideoModal({ isOpen: false, files: [] }));
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, [videoModal]);

  const handlerChangeSlide = (direction: 'prev' | 'next') => {
    videoModal.files?.[currentSlide]?._uuid &&
      (document.getElementById(videoModal.files[currentSlide]._uuid) as HTMLVideoElement).pause();
    direction === 'prev' ? sliderRef.current?.slickPrev() : sliderRef.current?.slickNext();
  };

  return (
    <Modal
      isOpen={videoModal.isOpen}
      onEsc={closeModal}
      position={'top'}
      onOpen={() => {
        document.body.style.overflow = 'hidden'; // отключаем прокрутку при открытии модального окна
      }}
      afterClose={() => {
        document.body.style.overflow = ''; // возвращаем прокрутку при закрытии модального окна
      }}
      className={classes.modal}
    >
      <div className={classes.content}>
        <Slider
          initialSlide={0}
          slidesToShow={1}
          slidesToScroll={1}
          draggable={false}
          beforeChange={(_, nextSlide) => {
            setCurrentSlide(nextSlide);
          }}
          ref={sliderRef}
          infinite={false}
          swipe={false}
          arrows={false}
        >
          {videoModal.files?.map((video) => {
            return (
              <div key={video._uuid} className={classes.slide}>
                <VideoApp id={video._uuid}>
                  <source
                    src={`${OpenAPI.BASE}/files/video/${video._uuid}`}
                    type="video/mp4"
                    onError={() => {
                      dispatch(
                        generalActions.addNotification({
                          status: 'warning',
                          message: 'ошибка при загрузке видео',
                        })
                      );
                    }}
                  />
                </VideoApp>
              </div>
            );
          })}
        </Slider>
        {videoModal.files?.[currentSlide]?.description && (
          <VideoDescription description={videoModal.files?.[currentSlide].description} />
        )}
        <div className={classes.carouselButtons}>
          <Button
            onlyIcon
            iconLeft={IconBackward}
            view="ghost"
            form="round"
            size="m"
            onClick={() => handlerChangeSlide('prev')}
            disabled={currentSlide === 0}
          />
          <Text onClick={() => {}}>
            {currentSlide + 1} из {filesLength}
          </Text>

          <Button
            onlyIcon
            iconLeft={IconForward}
            view="ghost"
            form="round"
            size="m"
            onClick={() => handlerChangeSlide('next')}
            disabled={currentSlide + 1 === filesLength}
          />
        </div>
      </div>
      <Button
        className={classes.closeButton}
        iconLeft={() => <IconClose style={{ color: '#FFF' }} />}
        view={'clear'}
        onClick={closeModal}
      />
    </Modal>
  );
};

const VideoDescription = ({ description }: { description: string }) => {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const descriptionElement = (
    <Text className={classes.text} ref={descriptionRef}>
      {description}
    </Text>
  );

  useEffect(() => {
    setShowTooltip(
      !!descriptionRef.current &&
        descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight
    );
  }, [description]);

  return showTooltip ? (
    <TextWithTooltip
      tooltipProps={{
        content: description,
        size: 'l',
      }}
      className={classes.text}
    >
      {description}
    </TextWithTooltip>
  ) : (
    descriptionElement
  );
};
