import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconSortUp } from '@consta/uikit/IconSortUp';

import { FiltersPortfolioDto } from '../../../../../generateAxios';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { SearchInput } from '../../../../components/SearchInput/SearchInput.tsx';
import { CustomSortItem, SortSelect } from '../../../../components/SortSelect/SortSelect.tsx';
import { portfolioConfig } from '../../../../configs/portfolioConfig.ts';

import classes from './PortfolioFilters.module.css';
import { ButtonAdd } from './components/ButtonAdd/ButtonAdd.tsx';
import { ButtonFilters } from './components/ButtonFilters/ButtonFilters.tsx';
import { PortfolioFilterBar } from './components/PortfolioFilterBar/PortfolioFilterBar.tsx';
import { SwitcherForPublication } from './components/SwitcherForPublication/SwitcherForPublication.tsx';

type PortfolioSortItem = CustomSortItem & {
  value: NonNullable<FiltersPortfolioDto['order']>;
};

const items: PortfolioSortItem[] = [
  { id: 1, label: 'Сначала новые', icon: IconSortDown, value: 'PORTFOLIO_DATE_DESC' },
  { id: 2, label: 'Сначала старые', icon: IconSortUp, value: 'PORTFOLIO_DATE_ASC' },
  { id: 3, label: 'А - Я', icon: IconSortDown, value: 'PORTFOLIO_NAME_ASC' },
  { id: 4, label: 'Я - А', icon: IconSortUp, value: 'PORTFOLIO_NAME_DESC' },
  { id: 5, label: 'Понравившиеся', icon: IconSortDown, value: 'PORTFOLIO_LIKE_DESC' },
];

export const PortfolioFilters = () => {
  return (
    <AppContentWrapper className={classes.container}>
      <div className={classes.filters}>
        <ButtonAdd />
        <SearchInput
          searchQueryKey={portfolioConfig.searchQueryKey}
          placeholder={'Поиск по наименованию и описанию'}
          showSearchButton
          size={'m'}
          wrapperClassName={classes.searchInput}
        />
        <SortSelect sortQueryKey={portfolioConfig.sortQueryKey} items={items} />
        <ButtonFilters />
      </div>
      <div className={classes.filterTags}>
        <SwitcherForPublication />
        <PortfolioFilterBar />
      </div>
    </AppContentWrapper>
  );
};
