import { memo } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { Text } from '@consta/uikit/Text';

import classes from '../../TeamEditModal.module.css';

export const ListTitle = memo(
  ({
    title,
    buttonLabel,
    onClick,
  }: {
    title: string;
    buttonLabel: string;
    onClick: () => void;
  }) => {
    return (
      <div className={classes.listTitle}>
        <Text size={'l'} weight={'medium'}>
          {title}
        </Text>
        <Button
          label={buttonLabel}
          iconRight={IconAdd}
          size={'xs'}
          view={'secondary'}
          onClick={onClick}
        />
      </div>
    );
  }
);
