import { Text } from '@consta/uikit/Text';

export const EmptyContent = () => {
  return (
    <div>
      <Text size={'l'} weight={'medium'}>
        Выберите контакт, ресурс или адрес почты
      </Text>
    </div>
  );
};
