import { Text } from '@consta/uikit/Text';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { toolsPageSelectors } from '../../../../../store/reducers/toolsPageSlice.ts';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import { toolQueryKey } from '../../ToolsPage.tsx';

import classes from './ToolsPluses.module.css';

export const ToolsPluses = () => {
  const tools = useAppSelector(toolsPageSelectors.tools);
  const [currentToolId] = useAppSelector(selectParamByKey(toolQueryKey));
  const currentTool = tools?.find((tool) => tool.tool._uuid === currentToolId) ?? tools?.[0];

  return (
    currentTool?.pluses && (
      <AppContentWrapper className={classes.container}>
        <Text size={'3xl'} weight={'semibold'}>
          Преимущества {currentTool.tool.name}
        </Text>
        <div className={classes.pluses}>
          {currentTool.pluses.map((plus, index) => (
            <div key={index} className={classes.plus}>
              <PlusIndex index={index} />
              <Text size={'2xl'} weight={'bold'}>
                {plus.capture}
              </Text>
              <Text size={'l'} weight={'medium'} className={classes.plusText}>
                {plus.text}
              </Text>
            </div>
          ))}
        </div>
      </AppContentWrapper>
    )
  );
};

const PlusIndex = ({ index }: { index: number }) => {
  return (
    <div className={classes.plusIndex}>
      <Text size={'3xl'} weight={'bold'} view={'link'}>
        {index + 1}
      </Text>
    </div>
  );
};
