import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import appIcon from '../../../../assets/icons/svg/icn_main_about_process_app.svg';
import caseIcon from '../../../../assets/icons/svg/icn_main_about_process_case.svg';
import dataIcon from '../../../../assets/icons/svg/icn_main_about_process_data.svg';
import studyIcon from '../../../../assets/icons/svg/icn_main_about_process_study.svg';
import taskIcon from '../../../../assets/icons/svg/icn_main_about_process_task.svg';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import classes from './AboutProcess.module.scss';
import { ProcessStepCard } from './components/ProcessStepCard/ProcessStepCard.tsx';

type Props = {
  scrollTo: (refNumber: number) => void;
};

export const AboutProcess = ({ scrollTo }: Props) => {
  return (
    <>
      <AppContentWrapper className={classes.margin144}>
        <Text view={'brand'} size={'l'} weight={'bold'}>
          О ПРОЦЕССЕ
        </Text>
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xs', mB: 'm' })}>
          Как решить задачу
        </Text>
        <Text size={'xl'} weight={'regular'}>
          {`Мы предлагаем универсальный процесс и инструменты
           для выполнения каждого его шага`}
        </Text>
      </AppContentWrapper>
      <AppContentWrapper className={cnMixSpace({ mT: '4xl' })}>
        <div className={classes.stepsCards}>
          <div className={classes.stepsCardsSmall}>
            <ProcessStepCard
              step={'1'}
              imgSrc={taskIcon}
              title={'Опишите задачу'}
              bottomArrow
              rightArrow
              onClick={() => scrollTo(1)}
            />
            <ProcessStepCard
              step={'2'}
              imgSrc={dataIcon}
              title={'Определите \nисточники данных'}
              bottomArrow
              rightArrow
              onClick={() => scrollTo(2)}
            />
            <ProcessStepCard
              step={'3'}
              imgSrc={appIcon}
              title={`Создайте \nприложение`}
              bottomArrow
              rightArrow
              onClick={() => scrollTo(3)}
              additionalClass={classes.bigCard}
            />
            <ProcessStepCard
              step={'4'}
              imgSrc={caseIcon}
              title={'Опубликуйте кейс'}
              bottomArrow
              onClick={() => scrollTo(4)}
            />
          </div>
          <ProcessStepCard
            step={'∞'}
            imgSrc={studyIcon}
            title={'Повышайте компетенции на каждом этапе'}
            onClick={() => scrollTo(5)}
            additionalClass={classes.fullWidth}
          />
        </div>
      </AppContentWrapper>
    </>
  );
};
