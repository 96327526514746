import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Tabs } from '@consta/uikit/Tabs';
import { Text } from '@consta/uikit/Text';

import { useSearchParams } from 'react-router-dom';

import { FullToolDto } from '../../../../../generateAxios';
import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { toolsPageSelectors } from '../../../../../store/reducers/toolsPageSlice.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import imgErrorSettings from '../../../../assets/icons/svg/icn_settings_cross.svg';
import { AppBreadcrumbs } from '../../../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import { toolQueryKey } from '../../ToolsPage.tsx';

import classes from './ToolsHero.module.scss';

export const ToolsHero = () => {
  const tools = useAppSelector(toolsPageSelectors.tools);
  const [, setSearchParams] = useSearchParams();
  const [currentToolId] = useAppSelector(selectParamByKey(toolQueryKey));

  return (
    <div className={classes.container}>
      <div className={classes.greyBg}></div>
      <AppContentWrapper className={classes.content}>
        <AppBreadcrumbs
          crumbs={[
            { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
            { label: 'Инструменты', rout: PageRoutes.DataLab_ToolsPage },
          ]}
          classname={classes.breadcrumbs}
        />
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xl' })}>
          Инструменты
        </Text>
        <Text size={'xl'} className={classes.contentDescription}>
          {`Определитесь с инструментом анализа данных, исходя
           из нюансов задачи и возможностей инструмента`}
        </Text>
        <Tabs
          className={classes.tabs}
          view={'clear'}
          value={tools?.find((item) => item.tool._uuid === currentToolId) ?? tools?.[0]}
          onChange={({ value }) =>
            setSearchParams((prev) => {
              prev.set(toolQueryKey, value.tool._uuid);
              return prev;
            })
          }
          fitMode={'scroll'}
          getItemLeftSide={(item) => <LeftIcon item={item} />}
          items={tools ?? []}
          getItemLabel={(item) => item.tool.name}
        />
      </AppContentWrapper>
    </div>
  );
};

const LeftIcon = ({ item }: { item: FullToolDto }) => {
  return (
    <div className={classes.leftIcon}>
      <img
        width={'24px'}
        height={'24px'}
        src={
          item.tool.uuid_icon ? `${OpenAPI.BASE}/image/${item.tool.uuid_icon}` : imgErrorSettings
        }
        onError={(event) => {
          event.currentTarget.src = imgErrorSettings;
        }}
        alt={item.tool.name}
      />
    </div>
  );
};
