import { ReactNode, useEffect } from 'react';

import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';

import { IconClose } from '@consta/icons/IconClose';

import classes from './AppModal.module.css';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  close: () => void;
  className?: string;
  disableCloseOnClickOutside?: boolean;
  disableCloseOnEsc?: boolean;
  hideCloseIcon?: boolean;
  afterClose?: () => void;
};

export const AppModal = ({
  children,
  isOpen,
  close,
  className,
  disableCloseOnClickOutside,
  disableCloseOnEsc,
  hideCloseIcon,
  afterClose,
}: Props) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onClickOutside={disableCloseOnClickOutside === true ? undefined : close}
      onEsc={disableCloseOnEsc === true ? undefined : close}
      className={`${classes.modal} ${className}`}
      onOpen={() => {
        document.body.style.overflow = 'hidden'; // отключаем прокрутку при открытии модального окна
      }}
      onClose={() => {}}
      afterClose={() => {
        document.body.style.overflow = ''; // возвращаем прокрутку при закрытии модального окна
        afterClose?.();
      }}
    >
      {!hideCloseIcon && (
        <Button
          size={'s'}
          view={'clear'}
          className={classes.closeIcon}
          onClick={close}
          onlyIcon={true}
          iconRight={IconClose}
        />
      )}
      {children}
    </Modal>
  );
};
