import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';

import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { metricsPageSelectors } from '../../../../../store/reducers/metricsPageSlice.ts';

export const metricsBlockQueryKey = 'block';

export const MetricsBlockSwitcher = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const metrics = useAppSelector(metricsPageSelectors.metrics);
  const currentBlockUuid = searchParams.get(metricsBlockQueryKey);

  if (!metrics || metrics.length === 1) {
    return null;
  }

  const currentBlock = metrics.find((item) => item.block?._uuid === currentBlockUuid) ?? metrics[0];

  return (
    <ChoiceGroup
      items={metrics ?? []}
      getItemLabel={(item) => item.block?.short_name ?? ''}
      name={'metricBlocks'}
      value={currentBlock}
      onChange={({ value }) => {
        setSearchParams((prev) => {
          prev.set(metricsBlockQueryKey, value.block._uuid);
          return prev;
        });
      }}
    />
  );
};
