/// <reference types="vite/client" />
/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiRequestOptions } from './ApiRequestOptions';


type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

export type OpenAPIConfig = {
  BASE: string;
  VERSION: string;
  WITH_CREDENTIALS: boolean;
  CREDENTIALS: 'include' | 'omit' | 'same-origin';
  TOKEN?: string | Resolver<string> | undefined;
  USERNAME?: string | Resolver<string> | undefined;
  PASSWORD?: string | Resolver<string> | undefined;
  HEADERS?: Headers | Resolver<Headers> | undefined;
  ENCODE_PATH?: ((path: string) => string) | undefined;
};

const baseUrlDEV =
  import.meta.env.VITE_BASE_URL && import.meta.env.VITE_BASE_URL !== '/'
    ? import.meta.env.VITE_BASE_URL
    : '/api';

const baseUrlPROD = import.meta.env.BASE_URL !== '/'
  ? `${import.meta.env.BASE_URL}/api`
  : '/api';

export const BaseUrl = (import.meta.env.DEV ? baseUrlDEV : baseUrlPROD).replace(/\/{2,}/g, '/');

// значение  BASE перезаписывается из файла config.json
//   "API_BASE_PATH": "https://sss.ss/api", - новый baseUrl для запросов
//   "CHANGE_BASE_PATH": false, - следует ли его заменять
export const OpenAPI: OpenAPIConfig = {
  BASE: BaseUrl,
  VERSION: '0',
  WITH_CREDENTIALS: true, // true для xsrf
  CREDENTIALS: 'include',
  TOKEN: undefined,
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: undefined,
  ENCODE_PATH: undefined,
};
