/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockBlockToolDto } from '../models/BlockBlockToolDto';
import type { BlockToolDto } from '../models/BlockToolDto';
import type { ComplexityDto } from '../models/ComplexityDto';
import type { EventLogDto } from '../models/EventLogDto';
import type { EventLogSendDto } from '../models/EventLogSendDto';
import type { FileExtensionDto } from '../models/FileExtensionDto';
import type { FullToolDto } from '../models/FullToolDto';
import type { HelpdeskDto } from '../models/HelpdeskDto';
import type { TagDto } from '../models/TagDto';
import type { ToolBlockToolDto } from '../models/ToolBlockToolDto';
import type { ToolDto } from '../models/ToolDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NsiRestService {
  /**
   * @returns ToolDto OK
   * @throws ApiError
   */
  public static getTool(): CancelablePromise<Array<ToolDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/tool',
    });
  }
  /**
   * @param requestBody
   * @returns ToolDto OK
   * @throws ApiError
   */
  public static updateTool(
    requestBody: ToolDto,
  ): CancelablePromise<ToolDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/nsi/tool',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns ToolDto OK
   * @throws ApiError
   */
  public static addTool(
    requestBody: ToolDto,
  ): CancelablePromise<ToolDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/tool',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns TagDto OK
   * @throws ApiError
   */
  public static getTag(): CancelablePromise<Array<TagDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/tag',
    });
  }
  /**
   * @param requestBody
   * @returns TagDto OK
   * @throws ApiError
   */
  public static updateTag(
    requestBody: TagDto,
  ): CancelablePromise<TagDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/nsi/tag',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns TagDto OK
   * @throws ApiError
   */
  public static addTag(
    requestBody: TagDto,
  ): CancelablePromise<TagDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/tag',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns BlockToolDto OK
   * @throws ApiError
   */
  public static getBlockTool(): CancelablePromise<Array<BlockToolDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/instance',
    });
  }
  /**
   * @param requestBody
   * @returns BlockToolDto OK
   * @throws ApiError
   */
  public static updateBlockTool(
    requestBody: BlockToolDto,
  ): CancelablePromise<BlockToolDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/nsi/instance',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns BlockToolDto OK
   * @throws ApiError
   */
  public static addBlockTool(
    requestBody: BlockToolDto,
  ): CancelablePromise<BlockToolDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/instance',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param type
   * @returns HelpdeskDto OK
   * @throws ApiError
   */
  public static getHelpdesk(
    type?: 'HELPDESK' | 'EMAIL' | 'RESOURCE' | 'LEARNING',
  ): CancelablePromise<Array<HelpdeskDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/helpdesk',
      query: {
        'type': type,
      },
    });
  }
  /**
   * @param requestBody
   * @returns HelpdeskDto OK
   * @throws ApiError
   */
  public static updateHelpdesk(
    requestBody: HelpdeskDto,
  ): CancelablePromise<HelpdeskDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/nsi/helpdesk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns HelpdeskDto OK
   * @throws ApiError
   */
  public static addHelpdesk(
    requestBody: HelpdeskDto,
  ): CancelablePromise<HelpdeskDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/helpdesk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns FileExtensionDto OK
   * @throws ApiError
   */
  public static getFileExtension(): CancelablePromise<Array<FileExtensionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/fileextension',
    });
  }
  /**
   * @param requestBody
   * @returns FileExtensionDto OK
   * @throws ApiError
   */
  public static updateFileExtension(
    requestBody: FileExtensionDto,
  ): CancelablePromise<FileExtensionDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/nsi/fileextension',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns FileExtensionDto OK
   * @throws ApiError
   */
  public static addFileExtension(
    requestBody: FileExtensionDto,
  ): CancelablePromise<FileExtensionDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/fileextension',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ComplexityDto OK
   * @throws ApiError
   */
  public static getComplexity(): CancelablePromise<Array<ComplexityDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/complexity',
    });
  }
  /**
   * @param requestBody
   * @returns ComplexityDto OK
   * @throws ApiError
   */
  public static updateComplexity(
    requestBody: ComplexityDto,
  ): CancelablePromise<ComplexityDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/nsi/complexity',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns ComplexityDto OK
   * @throws ApiError
   */
  public static addComplexity(
    requestBody: ComplexityDto,
  ): CancelablePromise<ComplexityDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/complexity',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Инструменты для модалки
   * @returns BlockBlockToolDto OK
   * @throws ApiError
   */
  public static getBlockWithInstances(): CancelablePromise<Array<BlockBlockToolDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/tool/modal',
    });
  }
  /**
   * Инструменты для модалки
   * @param requestBody
   * @returns ToolBlockToolDto OK
   * @throws ApiError
   */
  public static saveBlockWithInstances(
    requestBody: Array<BlockBlockToolDto>,
  ): CancelablePromise<Array<ToolBlockToolDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/tool/modal',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Инструменты для их страницы
   * @returns FullToolDto OK
   * @throws ApiError
   */
  public static getFullTool(): CancelablePromise<Array<FullToolDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/tool/full',
    });
  }
  /**
   * Добавление свойств для инструмента для их страницы со свойствами
   * @param requestBody
   * @returns FullToolDto OK
   * @throws ApiError
   */
  public static addFullTool(
    requestBody: FullToolDto,
  ): CancelablePromise<Array<FullToolDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/tool/full',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns HelpdeskDto OK
   * @throws ApiError
   */
  public static saveHelpdesk(
    requestBody: Array<HelpdeskDto>,
  ): CancelablePromise<Array<HelpdeskDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/helpdesk/all',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * метод передачи события с frontend
   * @param requestBody
   * @returns EventLogDto OK
   * @throws ApiError
   */
  public static eventLog(
    requestBody: EventLogSendDto,
  ): CancelablePromise<EventLogDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/nsi/event-log',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Инструменты для главной страницы
   * @returns ToolBlockToolDto OK
   * @throws ApiError
   */
  public static getToolWithInstances(): CancelablePromise<Array<ToolBlockToolDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/nsi/tool/main',
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteTool(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/nsi/tool/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteTag(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/nsi/tag/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteBlockTool(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/nsi/instance/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteHelpdesk(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/nsi/helpdesk/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteFileExtension(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/nsi/fileextension/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteComplexity(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/nsi/complexity/{id}',
      path: {
        'id': id,
      },
    });
  }
}
