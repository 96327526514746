import { useRef, useState } from 'react';

import { IconBag } from '@consta/uikit/IconBag';
import { Text } from '@consta/uikit/Text';

import { PortfolioDto } from '../../../../../generateAxios';

import classes from './PortfolioOrganisation.module.css';

export const PortfolioOrganisation = ({ portfolio }: { portfolio: PortfolioDto }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div className={classes.container}>
      <IconBag size={'m'} view={'brand'} as={'div'} className={classes.icon} />
      <Text
        view={'secondary'}
        truncate={!showMore}
        ref={ref}
        onClick={() => {
          if (ref.current && ref.current?.scrollWidth > ref.current?.clientWidth) {
            setShowMore(true);
          }
        }}
        onMouseEnter={(e) => {
          e.currentTarget.setAttribute(
            'style',
            ref.current && ref.current?.scrollWidth > ref.current?.clientWidth
              ? 'cursor: pointer;'
              : ''
          );
        }}
      >
        {portfolio.organizations?.map((el) => el.name).join(' ,')}
      </Text>
    </div>
  );
};
