import classNames from 'classnames';
import parse from 'html-react-parser';

import { KnowledgeDto } from '../../../../../generateAxios';

import classes from './KnowledgeContentInternal.module.css';

export const KnowledgeContentInternal = ({ knowledge }: { knowledge: KnowledgeDto }) => {
  if (!knowledge.body) return null;

  return (
    <div
      style={{ color: 'var(--color-typo-primary)' }}
      className={classNames({
        [classes.container]: true,
        [classes.wideMode]: knowledge.wide_body,
      })}
    >
      {parse(knowledge.body.replace('color: #000000;', 'color: var(--color-typo-primary)') || '')}
    </div>
  );
};
