import { Fragment } from 'react';

import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import classNames from 'classnames';

import { FeedbackDto } from '../../../../../generateAxios';
import { AppContentWrapper } from '../../../AppContentWrapper/AppContentWrapper.tsx';
import { Divider } from '../../../Divider/Divider.tsx';
import { PortfolioComment } from '../../../PortfolioComment/PortfolioComment.tsx';

export const PortfolioFeedbacks = ({ feedbacks }: { feedbacks: FeedbackDto[] }) => {
  return (
    <AppContentWrapper
      width={'wide'}
      className={classNames(
        cnMixSpace({ mT: '5xl' }),
        cnMixFlex({ direction: 'column', gap: 'l' })
      )}
    >
      {feedbacks.map((feedback, index, array) => {
        return (
          <Fragment key={index}>
            <PortfolioComment feedback={feedback} />
            {index !== array.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </AppContentWrapper>
  );
};
