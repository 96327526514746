import { Button } from '@consta/uikit/Button';
import { IconSettings } from '@consta/uikit/IconSettings';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks.ts';
import { generalActions } from '../../../../../../store/reducers/generalSlice.ts';
import { mainPageSelectors } from '../../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../../store/reducers/selectors.ts';
import consultationImg from '../../../../../assets/images/main-cons.png';
import { divideNumIntoCategories } from '../../../../../utils/divideNumIntoCategories.ts';

import classes from './ConsultationCard.module.scss';

export const ConsultationCard = () => {
  const dispatch = useAppDispatch();
  const { HELPDESK_CALL_COUNT } = useAppSelector(mainPageSelectors.metricsObject);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  return (
    <div className={classes.container}>
      {isAdminMode && (
        <Button
          iconLeft={IconSettings}
          view={'ghost'}
          className={classes.settingsButton}
          onClick={() => {
            dispatch(generalActions.setHelpdeskEditModal('HELPDESK'));
          }}
        />
      )}
      <img src={consultationImg} alt="" width={'380px'} height={'290px'} />
      <div className={classes.content}>
        <Text size={'3xl'} weight={'semibold'}>
          Получите консультацию
        </Text>
        <Text size={'xl'} className={cnMixSpace({ mT: 'm' })}>
          Сотрудники лаборатории расскажут с чего начать или как пройти определённый этап процесса —
          готовы помочь с любым вопросом
        </Text>
        <div
          className={classNames(
            cnMixFlex({ gap: '6xl', align: 'flex-end' }),
            cnMixSpace({ mT: '4xl' })
          )}
        >
          <div className={cnMixFlex({ direction: 'column', gap: 'xs' })}>
            <Text size={'2xl'} view={'secondary'} weight={'medium'}>
              {`${divideNumIntoCategories(HELPDESK_CALL_COUNT?.value)}+ ${HELPDESK_CALL_COUNT?.presentation_name}`}
            </Text>
            <Text view={'secondary'}>
              Все решённые: о процессах, визуализации, расчетах, доступах и многом другом
            </Text>
          </div>
          <Button
            label="Оставить заявку"
            form={'round'}
            onClick={() => {
              dispatch(generalActions.setSupportModal('short'));
            }}
          />
        </div>
      </div>
    </div>
  );
};
