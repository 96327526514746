import { Button } from '@consta/uikit/Button';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { useAppDispatch } from '../../../../../../../hooks/hooks.ts';
import { portfolioPageActions } from '../../../../../../../store/reducers/portfolioPageSlice.ts';

export const ButtonFilters = () => {
  const dispatch = useAppDispatch();
  return (
    <Button
      label={'Фильтры'}
      iconLeft={IconFunnel}
      view={'ghost'}
      className={cnMixSpace({ pR: 'l' })}
      onClick={() => {
        dispatch(portfolioPageActions.setShowSideBar(true));
      }}
    />
  );
};
