import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

import { VideoApp } from '../../VideoApp/VideoApp.tsx';

import classes from './VideoFullSizeModal.module.css';

type Props = {
  show: boolean;
  videoSrc: string;
  videoDescription: string;
  closeModal: () => void;
  afterClose?: () => void;
};

export const VideoFullSizeModal = ({
  videoSrc,
  videoDescription,
  closeModal,
  show,
  afterClose,
}: Props) => {
  return (
    <Modal
      isOpen={show}
      onEsc={closeModal}
      position={'top'}
      onOpen={() => {
        document.body.style.overflow = 'hidden'; // отключаем прокрутку при открытии модального окна
      }}
      afterClose={() => {
        document.body.style.overflow = ''; // возвращаем прокрутку при закрытии модального окна
        afterClose?.();
      }}
      className={classes.modal}
    >
      <div className={classes.content}>
        <div className={classes.slide}>
          <VideoApp autoPlay className={classes.video}>
            <source src={videoSrc} />
          </VideoApp>
        </div>
        {videoDescription && <Text className={classes.text}>{videoDescription}</Text>}
      </div>
      <Button
        className={classes.closeButton}
        iconLeft={() => <IconClose style={{ color: '#FFF' }} />}
        view={'clear'}
        onClick={closeModal}
      />
    </Modal>
  );
};
