import { useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconForward } from '@consta/uikit/IconForward';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { divideNumIntoCategories } from '../../../../utils/divideNumIntoCategories.ts';
import { enumerate } from '../../../../utils/enumerate.ts';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';

import classes from './PorfolioMain.module.css';
import { PortfolioCard } from './components/PortfolioCard/PortfolioCard.tsx';

export const PortfolioMain = () => {
  const navigate = useNavigate();
  let sliderRef = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const portfolios = useAppSelector(mainPageSelectors.portfolios);
  const { PORTFOLIO_COUNT } = useAppSelector(mainPageSelectors.metricsObject);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  return (
    <div className={classes.container}>
      <div className={classes.greyBg}></div>
      <AppContentWrapper className={classes.sliderWrapper}>
        {portfolios && portfolios.length > 0 && (
          <>
            <div className={classes.sliderContainer}>
              {portfolios.length < 3 ? (
                <div className={cnMixFlex({ gap: 'm' })}>
                  {portfolios.map((item) => (
                    <PortfolioCard key={item._uuid} portfolio={item} isCurrent={true} />
                  ))}
                </div>
              ) : (
                <Slider
                  ref={(slider) => {
                    sliderRef = { current: slider };
                  }}
                  beforeChange={(_, nextSlide) => setCurrentSlide(nextSlide)}
                  arrows={false}
                  speed={300}
                  slidesToShow={3}
                  slidesToScroll={1}
                  variableWidth={true}
                  draggable={false}
                >
                  {portfolios.map((item, index) => (
                    <PortfolioCard
                      key={item._uuid}
                      portfolio={item}
                      isCurrent={currentSlide === index}
                    />
                  ))}
                </Slider>
              )}
            </div>

            {portfolios.length > 2 && (
              <div className={classes.arrows}>
                <Button
                  iconRight={IconBackward}
                  view={'ghost'}
                  form={'round'}
                  onlyIcon
                  onClick={() => sliderRef.current?.slickPrev()}
                />
                <Text>
                  {currentSlide + 1} из {portfolios.length}
                </Text>
                <Button
                  iconRight={IconForward}
                  view={'ghost'}
                  form={'round'}
                  onlyIcon
                  onClick={() => sliderRef.current?.slickNext()}
                />
              </div>
            )}
            <div className={classes.statistic}>
              <Text size={'2xl'} weight={'medium'}>
                {`${divideNumIntoCategories(PORTFOLIO_COUNT?.value)} ${PORTFOLIO_COUNT?.presentation_name}`}
              </Text>
              <Text className={cnMixSpace({ mT: '2xs' })}>{`по разным направлениям ${enumerate(
                PORTFOLIO_COUNT?.value,
                ['опубликован', 'опубликовано', 'опубликовано']
              )} \nв Портфолио и ${enumerate(PORTFOLIO_COUNT?.value, [
                'доступен',
                'доступно',
                'доступно',
              ])} для изучения`}</Text>
              <Button
                as={'a'}
                view={'primary'}
                iconRight={IconForward}
                form={'round'}
                label={'Смотреть все'}
                href={relativeToAbsoluteLink(
                  `${PageRoutes.DataLab_PortfolioPage}${isAdminMode ? '?mode=admin' : ''}`
                )}
                target={'_blank'}
                onClick={(event) => {
                  event.preventDefault();
                  navigate(
                    `${PageRoutes.DataLab_PortfolioPage}${isAdminMode ? '?mode=admin' : ''}`
                  );
                }}
                className={cnMixSpace({ mT: '3xl' })}
              />
            </div>
          </>
        )}
      </AppContentWrapper>
    </div>
  );
};
