import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';

import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../../../../../store/reducers/selectors.ts';

type Item = { label: string; value: string };
type Props = {
  filterQueryKey: string;
  pageNumberQueryKey?: string;
  wrapperClassName?: string;
  size?: 's' | 'm' | 'l';
  items: Item[] | null;
  allLabel?: string;
};
export const commonBlockValue = 'all';
export const ChoiceGroupWithQuery = ({
  filterQueryKey,
  pageNumberQueryKey,
  wrapperClassName,
  size = 'm',
  items,
  allLabel = 'Общие',
}: Props) => {
  const commonValue = useMemo(() => ({ label: allLabel, value: commonBlockValue }), [allLabel]);
  const [allItems, setAllItems] = useState<Item[]>([commonValue]);
  const values = useAppSelector(selectParamByKey(filterQueryKey));
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    items && setAllItems([commonValue, ...items]);
  }, [items]);

  const handlerOnChange = (value: Item[] | null, e: ChangeEvent<HTMLInputElement>) => {
    const selectedLabel = e.currentTarget.value.split('-')?.[1];
    const valueIsChecked = e.currentTarget.checked;
    setSearchParams((prev) => {
      prev.delete(filterQueryKey);
      if (valueIsChecked && selectedLabel === commonValue.label) {
        prev.set(filterQueryKey, commonValue.value);
      } else {
        value?.forEach((el) => {
          if (el.value !== commonValue.value) {
            prev.append(filterQueryKey, el.value);
          }
        });
      }
      pageNumberQueryKey && prev.set(pageNumberQueryKey, '0');
      return prev;
    });
  };

  return (
    <div className={wrapperClassName}>
      {items ? (
        <ChoiceGroup
          multiple
          value={allItems.filter((item) => values.some((el) => el === item.value))}
          size={size}
          items={allItems}
          onChange={({ value, e }) => handlerOnChange(value, e)}
          name={'choiceGroup'}
        />
      ) : (
        <Button loading size={size} width={'full'} />
      )}
    </div>
  );
};
