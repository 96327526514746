import { LinkKnowledgeDto } from '../../generateAxios';

import { KnowledgeFormValues } from '../components/KnowledgeEditor/KnowledgeEditor.tsx';

export const getLinksKnowledgeDtoFromForm = (
  formValues: KnowledgeFormValues
): LinkKnowledgeDto[] | null => {
  if (formValues.knowledge_type !== 'LINK') return null;

  const addedLinks = formValues.links ?? [];
  let newLink: LinkKnowledgeDto[] = [];
  const linkWithoutAdd = formValues.newLink;
  if (linkWithoutAdd && linkWithoutAdd.link) {
    const { link, description } = linkWithoutAdd;
    newLink = [
      {
        link,
        description: description
          ? description
          : link?.length > 50
            ? link.slice(0, 50) + '...'
            : link,
      },
    ];
  }
  return [...addedLinks, ...newLink];
};
