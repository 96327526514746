/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterSelectorsPortfolioDto } from '../models/FilterSelectorsPortfolioDto';
import type { FiltersPortfolioDto } from '../models/FiltersPortfolioDto';
import type { PortfolioCrudDto } from '../models/PortfolioCrudDto';
import type { PortfolioDto } from '../models/PortfolioDto';
import type { PortfolioDtoWithFilters } from '../models/PortfolioDtoWithFilters';
import type { PortfolioImageShortDto } from '../models/PortfolioImageShortDto';
import type { PortfolioSelectorsDto } from '../models/PortfolioSelectorsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PortfolioRestService {
  /**
   * Редактировать portfolio
   * @param id
   * @param admin
   * @param requestBody
   * @returns PortfolioDto OK
   * @throws ApiError
   */
  public static updatePortfolio(
    id: string,
    admin: boolean,
    requestBody: PortfolioCrudDto,
  ): CancelablePromise<PortfolioDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/portfolio/{id}',
      path: {
        'id': id,
      },
      query: {
        'admin': admin,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns PortfolioDto OK
   * @throws ApiError
   */
  public static getAll(): CancelablePromise<Array<PortfolioDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/portfolio',
    });
  }
  /**
   * @param requestBody
   * @returns PortfolioDtoWithFilters OK
   * @throws ApiError
   */
  public static getAllByFilter(
    requestBody: FiltersPortfolioDto,
  ): CancelablePromise<PortfolioDtoWithFilters> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/portfolio',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @returns PortfolioDto OK
   * @throws ApiError
   */
  public static publicPortfolio(
    id: string,
  ): CancelablePromise<PortfolioDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/portfolio/public/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * @param id
   * @returns PortfolioDto OK
   * @throws ApiError
   */
  public static cancelPublicPortfolio(
    id: string,
  ): CancelablePromise<PortfolioDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/portfolio/public/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Новое portfolio
   * @param admin
   * @param requestBody
   * @returns PortfolioDto OK
   * @throws ApiError
   */
  public static saveNewPortfolio(
    admin: boolean,
    requestBody: PortfolioCrudDto,
  ): CancelablePromise<PortfolioDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/portfolio/add',
      query: {
        'admin': admin,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Новое portfolio
   * @param formData
   * @returns PortfolioImageShortDto OK
   * @throws ApiError
   */
  public static saveNewPortfolioImages(
    formData?: {
      images: Array<Blob>;
    },
  ): CancelablePromise<Array<PortfolioImageShortDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/portfolio/addImages',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
  /**
   * Получить portfolio по uid
   * @param guid
   * @returns PortfolioDto OK
   * @throws ApiError
   */
  public static getPortfolioById(
    guid: string,
  ): CancelablePromise<PortfolioDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/portfolio/{guid}',
      path: {
        'guid': guid,
      },
    });
  }
  /**
   * Удалить portfolio по uid
   * @param guid
   * @returns any OK
   * @throws ApiError
   */
  public static deletePortfolioById(
    guid: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/portfolio/{guid}',
      path: {
        'guid': guid,
      },
    });
  }
  /**
   * @returns PortfolioSelectorsDto OK
   * @throws ApiError
   */
  public static getSelectors(): CancelablePromise<PortfolioSelectorsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/portfolio/selectors',
    });
  }
  /**
   * Получить список portfolio на модерацию
   * @returns PortfolioDto OK
   * @throws ApiError
   */
  public static getModeration(): CancelablePromise<Array<PortfolioDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/portfolio/moderation',
    });
  }
  /**
   * Получить список portfolio для главной страницы
   * @returns PortfolioDto OK
   * @throws ApiError
   */
  public static getForMainPage(): CancelablePromise<Array<PortfolioDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/portfolio/main',
    });
  }
  /**
   * @param forPublic
   * @returns FilterSelectorsPortfolioDto OK
   * @throws ApiError
   */
  public static getFilters(
    forPublic: boolean,
  ): CancelablePromise<FilterSelectorsPortfolioDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/portfolio/filters',
      query: {
        'forPublic': forPublic,
      },
    });
  }
}
