import { useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { ContextMenu, ContextMenuItemDefault } from '@consta/uikit/ContextMenu';
import { Switch } from '@consta/uikit/Switch';

import classNames from 'classnames';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { generalActions, generalSelectors } from '../../../store/reducers/generalSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';
import icnSettingsCustom from '../../assets/icons/svg/icn_settings_custom.svg';
import { IconCustomUser } from '../../assets/icons/tsx/IconCustomUser';
import { IconMarket } from '../../assets/icons/tsx/IconMarket';
import { IconSupport } from '../../assets/icons/tsx/IconSupport';
import { linksConfig } from '../../configs/linksConfig.ts';
import CustomHoverToolTip from '../../shared-front/CustomHoverToolTip/CustomHoverToolTip.tsx';
import { getMimeType } from '../../utils/getMimeType.ts';

import classes from './HeaderExtraActions.module.css';

// import classNames from "classnames";

export const HeaderExtraActions = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const [openSupport, setOpenSupport] = useState<boolean>(false);
  const [isOpenModeSwitcher, setIsOpenModeSwitcher] = useState<boolean>(false);
  const buttonRef = useRef(null);
  const userDetails = useAppSelector(generalSelectors.userDetails);
  // const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const hasSomeRole = useAppSelector(hasSomeRoleSelector);

  const handlerNavigateToMarket = () => {
    window.open(linksConfig.new_market, '_blank');
  };

  const handlerSupport = () => {
    dispatch(generalActions.setSupportModal('long'));
  };

  const contextItems: ContextMenuItemDefault[] = [
    {
      label: 'Контент-менеджер',
      disabled: false,
      rightSide: (
        <Switch
          size="m"
          checked={isAdminMode}
          onChange={({ checked }) => {
            if (location.pathname === PageRoutes.DataLab_MetricsPage && !checked) {
              window.location.replace(PageRoutes.DataLab_MainPage);
            } else {
              setSearchParams((prev) => {
                checked ? prev.set('mode', 'admin') : prev.delete('mode');
                return prev;
              });
              // todo добавить обновление на страница где идут разные запросы
            }

            // navigate(0);
          }}
          key="Switch"
          disabled={isAdminMode ? false : !hasSomeRole}
        />
      ),
    },
  ];

  let img: string | undefined = undefined;
  if (userDetails?.avatar) {
    const mimeType = getMimeType(userDetails.avatar);
    img = `data:${mimeType};base64,${userDetails.avatar.replace(/\s+/g, '')}`;
  }

  return (
    <div className={classes.container}>
      <Button
        size="s"
        view={'ghost'}
        iconLeft={IconMarket}
        iconSize="s"
        form="round"
        label={'Магазин данных'}
        onClick={handlerNavigateToMarket}
        className={classes.buttonMarket}
        disabled={!linksConfig.new_market}
      />
      <CustomHoverToolTip title={'Консультация'} direction={'downLeft'}>
        <Button
          size="s"
          view={'ghost'}
          iconLeft={IconSupport}
          iconSize="s"
          onlyIcon
          form="round"
          onClick={handlerSupport}
          className={classes.increaseIcon24}
        />
      </CustomHoverToolTip>
      <div className={classes.userButtonContainer}>
        <Button
          size="s"
          view={'ghost'}
          iconLeft={
            img ? () => <img src={img} alt={''} width={'100%'} height={'100%'} /> : IconCustomUser
          }
          iconSize="s"
          onlyIcon
          form="round"
          onClick={() => hasSomeRole && setIsOpenModeSwitcher(!isOpenModeSwitcher)}
          ref={buttonRef}
          className={classNames({
            [classes.increaseIcon32]: true,
            [classes.userButton]: userDetails?.avatar,
            [classes.disableClick]: !hasSomeRole,
          })}
        />
        {isAdminMode && (
          <div className={classes.userButtonIcon}>
            <img src={icnSettingsCustom} alt={''} />
          </div>
        )}
      </div>

      <ContextMenu
        isOpen={isOpenModeSwitcher}
        items={contextItems}
        anchorRef={buttonRef}
        onClickOutside={() => setIsOpenModeSwitcher(false)}
        direction="leftUp"
        style={{ zIndex: 1001 }}
      />
      {/*<SupportModal mode={'long'} open={openSupport} handlerClose={() => setOpenSupport(false)} />*/}
    </div>
  );
};
