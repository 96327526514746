import { Text } from '@consta/uikit/Text';

import classes from './PortfolioEditorInfo.module.css';

export const PortfolioEditorInfo = () => {
  return (
    <div className={classes.container}>
      <Text view={'secondary'} size={'s'}>
        После отправки заявки кейс уходит на модерацию. После рассмотрения заявки кейс будет
        опубликован в Портфолио
      </Text>
    </div>
  );
};
