import { useEffect, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconTop } from '@consta/uikit/IconTop';
import { withTooltip } from '@consta/uikit/withTooltip';

import { AppContentWrapper } from '../AppContentWrapper/AppContentWrapper.tsx';

import classes from './ScrollToTop.module.css';

const ButtonWithTooltip = withTooltip({ content: 'В начало', direction: 'downLeft' })(Button);

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > window.innerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <AppContentWrapper className={classes.container}>
      {isVisible && (
        <ButtonWithTooltip
          iconRight={IconTop}
          form={'round'}
          view={'ghost'}
          className={classes.icon}
          onlyIcon
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        />
      )}
    </AppContentWrapper>
  );
};
