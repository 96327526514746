import { Selector } from '@reduxjs/toolkit';

import { Button } from '@consta/uikit/Button';

import { IconArrowPrevious } from '@consta/icons/IconArrowPrevious';

import { SimpleFilterDto } from '../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import {
  knowledgePageActions,
  knowledgePageSelectors,
} from '../../../../../store/reducers/knowledgePageSlice.ts';
import { RootState } from '../../../../../store/store.ts';
import { FilterSideBarCheckBox } from '../../../../components/FilterSideBarCheckBox/FilterSideBarCheckBox.tsx';
import { SidebarWithResize } from '../../../../components/SidebarWithResize/SidebarWithResize.tsx';
import { knowledgeConfig } from '../../../../configs/knowledgeConfig.ts';

import classes from './KnowledgeFiltersSideBar.module.css';
import { KnowledgeFilterSideBarHeader } from './components/KnowledgeFilterSideBarHeader.tsx';

const items: {
  queryKey: string;
  valueKey?: keyof SimpleFilterDto;
  filterTitle: string;
  showTotal?: boolean;
  itemsSelector: Selector<RootState, SimpleFilterDto[] | undefined>;
  nameConverter?: (name: string) => string;
}[] = [
  {
    filterTitle: 'Блок',
    queryKey: knowledgeConfig.filterBlockQueryKey,
    itemsSelector: knowledgePageSelectors.blockFilters,
    // showTotal: knowledgeConfig.showTotalCountInFilters,
  },
  {
    filterTitle: 'Инструмент',
    queryKey: knowledgeConfig.filterToolQueryKey,
    itemsSelector: knowledgePageSelectors.toolsFilters,
    // showTotal: knowledgeConfig.showTotalCountInFilters,
  },
  {
    filterTitle: 'Сложность',
    queryKey: knowledgeConfig.filterComplexityQueryKey,
    itemsSelector: knowledgePageSelectors.complexityFilters,
    // showTotal: knowledgeConfig.showTotalCountInFilters,
  },
  {
    filterTitle: 'Вид материала',
    queryKey: knowledgeConfig.filterTypeQueryKey,
    itemsSelector: knowledgePageSelectors.typeFilters,
    // showTotal: knowledgeConfig.showTotalCountInFilters,
    valueKey: 'hash_key',
    // nameConverter: knowledgeTypeConvert,
  },
  {
    filterTitle: 'Тег',
    queryKey: knowledgeConfig.filterTagsQueryKey,
    itemsSelector: knowledgePageSelectors.tagsFilters,
    // showTotal: knowledgeConfig.showTotalCountInFilters,
  },
  // {
  //   filterTitle: 'Автор',
  //   queryKey: knowledgeConfig.filterAuthorQueryKey,
  //   itemsSelector: knowledgePageSelectors.authorFilters,
  //   showTotal: knowledgeConfig.showTotalCountInFilters,
  //   valueKey: 'hash_key',
  // },
];

export const KnowledgeFiltersSideBar = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(knowledgePageSelectors.showSideBar);

  const handleCloseSidebar = () => {
    dispatch(knowledgePageActions.setShowSideBar(false));
  };

  return (
    <SidebarWithResize
      className={classes.sidebar}
      isOpen={open}
      hasOverlay={false}
      onClickOutside={handleCloseSidebar}
      onEsc={handleCloseSidebar}
    >
      <div className={classes.closeButton}>
        <Button
          onClick={handleCloseSidebar}
          size={'s'}
          view={'ghost'}
          iconRight={IconArrowPrevious}
        />
      </div>
      <KnowledgeFilterSideBarHeader />
      <div className={classes.content}>
        {items.map((el) => (
          <FilterSideBarCheckBox
            key={el.queryKey}
            filterTitle={el.filterTitle}
            queryKey={el.queryKey}
            itemsSelector={el.itemsSelector}
            showTotal={el.showTotal}
            valueKey={el.valueKey}
            nameConverter={el.nameConverter}
          />
        ))}
      </div>
    </SidebarWithResize>
  );
};
