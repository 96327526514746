import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { portfolioPageActions } from '../../../store/reducers/portfolioPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';

import { HeroBlock } from './components/HeroBlock/HeroBlock.tsx';
import { PortfolioFilters } from './components/PortfiloFilters/PortfolioFilters.tsx';
import { PortfolioCards } from './components/PortfolioCards/PortfolioCards.tsx';
import { PortfolioFiltersSideBar } from './components/PortfolioFiltersSideBar/PortfolioFiltersSideBar.tsx';

export const PortfolioPage = () => {
  const dispatch = useAppDispatch();
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    document.title = 'Портфолио | Лаборатория данных';
    dispatch(portfolioPageActions.getPortfolioMetrics());
    return () => {
      dispatch(portfolioPageActions.clearState());
    };
  }, []);

  return (
    <>
      <HeroBlock />
      {isAdminMode && !hasRoles ? (
        <SwitchToUser403 />
      ) : (
        <>
          <PortfolioFilters />
          <PortfolioCards />
          <PortfolioFiltersSideBar />
        </>
      )}
    </>
  );
};
