import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';
import { useTheme } from '@consta/uikit/Theme';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { relativeToAbsoluteLink } from '../../../../../../../utils/relativeToAbsoluteLink.ts';

import { SliderItem } from '../TaskSlider.tsx';

import classes from './TaskItem.module.css';

export const TaskItem = ({ item }: { item: SliderItem }) => {
  const { themeClassNames } = useTheme();
  const navigate = useNavigate();
  const withLink = item.link !== undefined;
  return (
    <div className={classNames({ [classes.task]: true, [classes.withLink]: withLink })}>
      {item.icon && item.title && (
        <div className={classes.taskTitle}>
          <div className={classes.taskIcon}>
            <img src={item.icon} alt="" />
          </div>
          <Text weight={'semibold'}>{item.title}</Text>
        </div>
      )}
      <Text className={classNames({ [themeClassNames.color.invert]: withLink })}>{item.desc}</Text>
      {withLink &&
        (item.link ? (
          <Button
            as={'a'}
            href={relativeToAbsoluteLink(item.link)}
            view={'clear'}
            form={'round'}
            label={'Перейти'}
            iconRight={IconForward}
            className={themeClassNames.color.invert}
            onClick={(event) => {
              event.preventDefault();
              item.link && navigate(item.link);
            }}
          />
        ) : (
          <Button
            view={'clear'}
            form={'round'}
            label={'Перейти'}
            iconRight={IconForward}
            className={themeClassNames.color.invert}
            disabled
          />
        ))}
    </div>
  );
};
