import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';

import { LinkToolDto } from '../../../../../generateAxios';
import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { toolsPageSelectors } from '../../../../../store/reducers/toolsPageSlice.ts';
import { IconQr } from '../../../../assets/icons/tsx/IconQr.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { validateURL } from '../../../../utils/valudateURL.ts';

import { toolQueryKey } from '../../ToolsPage.tsx';

import classes from './ToolsInfoLinks.module.css';
import { LinkImgModal } from './components/LinkImgModal.tsx';

export const ToolsInfoLinks = () => {
  const tools = useAppSelector(toolsPageSelectors.tools);
  const [currentToolId] = useAppSelector(selectParamByKey(toolQueryKey));
  const currentTool = tools?.find((tool) => tool.tool._uuid === currentToolId) ?? tools?.[0];
  const [imgModal, setImgModal] = useState<{ data: LinkToolDto; open: boolean } | null>(null);

  return (
    <AppContentWrapper className={classes.container}>
      <Text size={'3xl'} weight={'bold'}>
        Информационные ссылки
      </Text>
      <Text size={'2xl'} weight={'medium'} className={classes.description}>
        По любым вопросам, связанным с работой в инструменте, вы можете обратиться за консультацией
        на текущем портале или попытаться найти ответы в базе знаний вендора и в telegram-канале
        инструмента
      </Text>
      {currentTool?.links && (
        <div className={classes.buttonGroup}>
          {currentTool.links.map((item, index) => {
            return item.image_title || item.uuid_image ? (
              <Button
                key={item.image_title ?? index}
                form={'round'}
                label={item.capture}
                view={'ghost'}
                iconRight={IconQr}
                onClick={() => setImgModal({ data: item, open: true })}
                className={classes.button}
              />
            ) : (
              <Button
                key={`${item.image_title ?? index}disable`}
                form={'round'}
                label={item.capture}
                view={'ghost'}
                iconRight={IconForward}
                as={'a'}
                href={validateURL(item.text) ? item.text : ''}
                target={'_blank'}
                className={classes.button}
              />
            );
          })}
        </div>
      )}
      <LinkImgModal imgModal={imgModal} setImgModal={setImgModal} />
    </AppContentWrapper>
  );
};
