import { ReactNode } from 'react';

import { SnackBarItemStatus } from '@consta/uikit/SnackBar';

import {
  type AboutComponentDto,
  AuthRestCommonService,
  AuthRestExtCommonService,
  AuthUserDetailsDto,
  BlockDto,
  HelpdeskDto,
  JwtResponseDto,
  NsiRestCommonService,
  NsiRestService,
} from '../../generateAxios';
import { startRefreshToken } from '../../src/utils/refreshToken.ts';

import { createAppSlice } from '../createAppSlice';
import { AppDispatch, RootState } from '../store.ts';

export type HelpdeskType = 'HELPDESK' | 'EMAIL' | 'RESOURCE' | 'LEARNING';
export type Notification = {
  key: number;
  message: ReactNode;
  status: SnackBarItemStatus;
  responseCode?: number | string;
  responseUrl?: string;
};

type GeneralState = {
  userDetails: AuthUserDetailsDto | null;
  showLoginModal: boolean;
  authError: string | null;
  pageLoader: boolean;
  notifications: Notification[];
  blocks: BlockDto[] | null;
  searchParams: { [key: string]: string[] | undefined };
  supportModal: 'long' | 'short' | null;
  learningModal: boolean;
  helpdeskLinks: HelpdeskDto[] | null;
  helpdeskLearning: HelpdeskDto[] | null;
  helpdeskEditModal: HelpdeskType | null;
  loadingHelpdesk: boolean;
  version: AboutComponentDto | null;
};

const initialState: GeneralState = {
  userDetails: null,
  showLoginModal: false,
  pageLoader: true,
  authError: null,
  notifications: [],
  blocks: null,
  searchParams: {},
  supportModal: null,
  helpdeskLinks: null,
  learningModal: false,
  helpdeskLearning: null,
  helpdeskEditModal: null,
  loadingHelpdesk: false,
  version: null,
};

const generalSlice = createAppSlice({
  name: 'general',
  initialState,
  reducers: (creators) => ({
    setShowLoginModal: creators.reducer<boolean>((state, action) => {
      state.showLoginModal = action.payload;
    }),
    setAuthError: creators.reducer<string | null>((state, action) => {
      state.authError = action.payload;
    }),
    // простое перезаписывание параметров приводит к лишним перерисовкам, т.к. создает новые массивы строк
    // setSearchParams2: creators.reducer<{ [key: string]: string[] }>((state, action) => {
    //   state.searchParams = action.payload;
    // }),
    // улучшенное позволяет менять только измененные параметры
    setSearchParams: creators.reducer<{ [key: string]: string[] }>((state, action) => {
      const newSearchParams = action.payload;
      const currentSearchParams = state.searchParams;

      // Удаление отсутствующих ключей
      Object.keys(currentSearchParams).forEach((key) => {
        if (!(key in newSearchParams)) {
          delete currentSearchParams[key];
        }
      });

      // Обновление или добавление новых ключей
      Object.keys(newSearchParams).forEach((key) => {
        if (
          !currentSearchParams[key] ||
          !arraysEqual(currentSearchParams[key], newSearchParams[key])
        ) {
          currentSearchParams[key] = newSearchParams[key];
        }
      });
    }),
    setSupportModal: creators.reducer<'long' | 'short' | null>((state, action) => {
      state.supportModal = action.payload;
    }),
    setLearningModal: creators.reducer<boolean>((state, action) => {
      state.learningModal = action.payload;
    }),
    setHelpdeskEditModal: creators.reducer<HelpdeskType | null>((state, action) => {
      state.helpdeskEditModal = action.payload;
    }),
    addNotification: creators.reducer<Omit<Notification, 'key'>>((state, action) => {
      const key = state.notifications.length + 1;
      state.notifications.push({
        key,
        message: action.payload.message,
        status: action.payload.status || 'alert',
        responseCode: action.payload.responseCode,
        responseUrl: action.payload.responseUrl,
      });
    }),

    deleteNotification: creators.reducer<number>((state, action) => {
      state.notifications = state.notifications.filter((n) => n.key !== action.payload);
    }),

    getUserInfo: creators.asyncThunk(
      async (_: undefined) => {
        const res = await AuthRestCommonService.getUserDetails();
        if (import.meta.env.VITE_AUTH_TYPE === 'TOKEN') {
          startRefreshToken();
        }
        return res;
      },
      {
        fulfilled: (state, action) => {
          // проверка 403
          // state.userDetails = { ...action.payload, roles: [] };
          state.userDetails = action.payload;
          state.version = action.payload.version ?? null;
          state.pageLoader = false;
        },
        rejected: (_, action) => {
          console.log(action);
        },
      }
    ),
    authUser: creators.asyncThunk(
      async (params: {
        authParams: {
          username: string;
          password: string;
        };
        callback?: () => void;
      }) => {
        const response: JwtResponseDto = await AuthRestExtCommonService.authenticate(
          params.authParams
        );
        if (response.access_token) localStorage.setItem('access_token', response.access_token);
        if (response.refresh_token) localStorage.setItem('refresh_token', response.refresh_token);
        startRefreshToken();
        params.callback?.();
        return response.user;
      },
      {
        fulfilled: (state, action) => {
          state.userDetails = action.payload ?? null;
          state.version = action.payload?.version ?? null;
          state.showLoginModal = false;
          state.pageLoader = false;
        },
        rejected: (_, action) => {
          console.log(action);
        },
      }
    ),
    getBlocks: creators.asyncThunk(
      async (_: undefined, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        if (!state.general.blocks) {
          const response = await NsiRestCommonService.getBlocks();
          return response ?? [];
        } else {
          return thunkAPI.rejectWithValue('Blocks already exist');
        }
      },
      {
        fulfilled: (state, action) => {
          state.blocks = action.payload;
        },
        rejected: (_, action) => {
          console.log(action.payload);
        },
      }
    ),
    getHelpdesk: creators.asyncThunk(
      async (type: 'HELPDESK' | 'EMAIL' | 'RESOURCE' | 'LEARNING', thunkAPI) => {
        const state = thunkAPI.getState() as RootState;

        switch (type) {
          case 'HELPDESK':
            if (!state.general.helpdeskLinks) {
              const response = await NsiRestService.getHelpdesk('HELPDESK');
              return { type, data: response ?? [] };
            } else {
              return thunkAPI.rejectWithValue('Helpdesk links already exist');
            }
          case 'LEARNING':
            if (!state.general.helpdeskLearning) {
              const response = await NsiRestService.getHelpdesk('LEARNING');
              return { type, data: response ?? [] };
            } else {
              return thunkAPI.rejectWithValue('Helpdesk links already exist');
            }
          default:
            return thunkAPI.rejectWithValue('Unknown helpdesk type');
        }
      },
      {
        pending: (state) => {
          state.loadingHelpdesk = true;
        },
        fulfilled: (state, action) => {
          action.payload.type === 'HELPDESK' && (state.helpdeskLinks = action.payload.data);
          action.payload.type === 'LEARNING' && (state.helpdeskLearning = action.payload.data);
        },
        rejected: (_, action) => {
          console.log(action.payload);
        },
        settled: (state) => {
          state.loadingHelpdesk = false;
        },
      }
    ),
    saveHelpdesk: creators.asyncThunk(
      async (
        {
          helpdesks,
          type,
        }: { helpdesks: HelpdeskDto[]; type: 'HELPDESK' | 'EMAIL' | 'RESOURCE' | 'LEARNING' },
        thunkAPI
      ) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        await NsiRestService.saveHelpdesk(helpdesks);
        dispatch(
          generalActions.addNotification({ message: 'Ссылки успешно сохранены', status: 'success' })
        );
        const response = await NsiRestService.getHelpdesk(type);
        return { type, data: response ?? [] };
      },
      {
        pending: (state) => {
          state.loadingHelpdesk = true;
        },
        fulfilled: (state, action) => {
          action.payload.type === 'HELPDESK' && (state.helpdeskLinks = action.payload.data);
          action.payload.type === 'LEARNING' && (state.helpdeskLearning = action.payload.data);

          state.helpdeskEditModal = null;
        },
        rejected: (_, action) => {
          console.log(action.payload);
        },
        settled: (state) => {
          state.loadingHelpdesk = false;
        },
      }
    ),
  }),
  selectors: {
    showLoginModal: (state) => state.showLoginModal,
    pageLoader: (state) => state.pageLoader,
    userDetails: (state) => state.userDetails,
    notifications: (state) => state.notifications,
    blocks: (state) => state.blocks,
    supportModal: (state) => state.supportModal,
    learningModal: (state) => state.learningModal,
    helpdeskLinks: (state) => state.helpdeskLinks,
    helpdeskLearning: (state) => state.helpdeskLearning,
    helpdeskEditModal: (state) => state.helpdeskEditModal,
    loadingHelpdesk: (state) => state.loadingHelpdesk,
    version: (state) => state.version,
  },
});

export const generalActions = generalSlice.actions;
export const generalSelectors = generalSlice.getSelectors(generalSlice.selectSlice);

export default generalSlice.reducer;

const arraysEqual = (arr1: string[], arr2: string[]): boolean => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
