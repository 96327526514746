import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { faqPageActions, faqPageSelectors } from '../../../../../store/reducers/faqPageSlice.ts';
import { DeleteModal } from '../../../../components/DeleteModal/DeleteModal.tsx';

export const FaqDeleteModal = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(faqPageSelectors.faqDeleteModal);
  const [loading, setLoading] = useState<boolean>(false);

  const confirm = async () => {
    setLoading(true);
    await dispatch(faqPageActions.deleteFaq());
    setLoading(false);
  };

  const cancel = () => {
    dispatch(faqPageActions.setFaqDeleteModal(null));
    dispatch(faqPageActions.setCurrentFaq(null));
  };

  return (
    <DeleteModal
      isOpen={!!open}
      onClose={cancel}
      confirmAction={confirm}
      loading={loading}
      title={'Удалить вопрос?'}
      content={`Вы действительно хотите удалить вопрос "${open?.question}" и ответ на него?`}
    />
  );
};
