import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';

import imgErrorSettings from '../../../assets/icons/svg/icn_settings_cross.svg';

import classes from './ImageFullSizeModal.module.css';

type Props = {
  show: boolean;
  imgSrc: string;
  imgDescription: string;
  closeModal: () => void;
  afterClose?: () => void;
};

export const ImageFullSizeModal = ({
  imgSrc,
  imgDescription,
  closeModal,
  show,
  afterClose,
}: Props) => {
  return (
    <Modal
      isOpen={show}
      onEsc={closeModal}
      position={'top'}
      onOpen={() => {
        document.body.style.overflow = 'hidden'; // отключаем прокрутку при открытии модального окна
      }}
      afterClose={() => {
        document.body.style.overflow = ''; // возвращаем прокрутку при закрытии модального окна
        afterClose?.();
      }}
      className={classes.modal}
    >
      <div className={classes.content}>
        <div className={classes.slide}>
          <img
            id={'image'}
            src={imgSrc}
            onError={(event) => {
              event.currentTarget.src = imgErrorSettings;
            }}
            alt={''}
            className={classes.img}
          />
        </div>
        {imgDescription && <Text className={classes.text}>{imgDescription}</Text>}
      </div>
      <Button
        className={classes.closeButton}
        iconLeft={() => <IconClose style={{ color: '#FFF' }} />}
        view={'clear'}
        onClick={closeModal}
      />
    </Modal>
  );
};
