import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { Collapse } from '@consta/uikit/Collapse';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { Text } from '@consta/uikit/Text';
import { withTooltip } from '@consta/uikit/withTooltip';

import { AnimateIconSwitcherProvider } from '@consta/icons/AnimateIconSwitcherProvider';
import { IconFavoriteFilled } from '@consta/icons/IconFavoriteFilled';
import { IconFavoriteStroked } from '@consta/icons/IconFavoriteStroked';
import { withAnimateSwitcherHOC } from '@consta/icons/withAnimateSwitcherHOC';

import parse from 'html-react-parser';

import { FaqDto } from '../../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks.ts';
import { faqPageActions } from '../../../../../../store/reducers/faqPageSlice.ts';
import { generalSelectors } from '../../../../../../store/reducers/generalSlice.ts';
import { isSuperAdminSelector } from '../../../../../../store/reducers/selectors.ts';

import classes from './FaqItem.module.scss';

const AddFavoriteAnimateIcon = withAnimateSwitcherHOC({
  startIcon: IconFavoriteStroked,
  endIcon: IconFavoriteFilled,
  endDirection: 288,
  transition: 500,
});

const ButtonWithTooltip = withTooltip({ size: 'l' })(Button);

export const FaqItem = ({
  item,
  addToFavorite,
  disableDnD = false,
}: {
  item: FaqDto;
  addToFavorite: (item: FaqDto) => void | Promise<any>;
  disableDnD?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const blocks = useAppSelector(generalSelectors.blocks);
  const isSuperAdmin = useAppSelector(isSuperAdminSelector);
  const [open, setOpen] = useState<boolean>(false);
  const [loadingToFavorites, setLoadingToFavorites] = useState<boolean>(false);

  const addToFavorites = async () => {
    setLoadingToFavorites(true);
    await addToFavorite(item);
    setLoadingToFavorites(false);
  };

  return (
    <Collapse
      key={item._uuid}
      iconPosition={'right'}
      iconView={'ghost'}
      horizontalSpace={'xl'}
      className={classes.collapseItem}
      label={
        <div className={cnMixFlex({ gap: 's', align: 'center' })}>
          <IconDraggable view={disableDnD ? 'ghost' : 'primary'} />
          <Text size={'l'}>{item.question}</Text>
        </div>
      }
      rightSide={
        <div className={cnMixFlex({ gap: 's', align: 'center' })}>
          {isSuperAdmin && item.blocks.length === blocks?.length && (
            <AnimateIconSwitcherProvider active={item.show_on_main_page}>
              <ButtonWithTooltip
                tooltipProps={{
                  content: item.show_on_main_page ? 'Убрать с главной' : 'Добавить на главную',
                  direction: 'downLeft',
                  size: 'l',
                  appearTimeout: 600,
                }}
                iconLeft={AddFavoriteAnimateIcon}
                view={'ghost'}
                form={'round'}
                size={'s'}
                onClick={(event) => {
                  event.stopPropagation();
                  addToFavorites();
                }}
                className={classes.favoriteButton}
                loading={loadingToFavorites}
              />
            </AnimateIconSwitcherProvider>
          )}

          <Button
            iconRight={IconEdit}
            view={'ghost'}
            form={'round'}
            size={'s'}
            onClick={(event) => {
              event.stopPropagation();
              dispatch(faqPageActions.setFaqEditModal(true));
              dispatch(faqPageActions.setCurrentFaq(item));
            }}
            disabled={item.read_only}
          />
          <Button
            iconRight={IconTrash}
            view={'ghost'}
            form={'round'}
            size={'s'}
            onClick={(event) => {
              event.stopPropagation();
              dispatch(faqPageActions.setFaqDeleteModal(item));
              dispatch(faqPageActions.setCurrentFaq(item));
            }}
            disabled={item.read_only}
          />
        </div>
      }
      isOpen={open}
      onClick={() => setOpen((prev) => !prev)}
    >
      {/*<Text view={'secondary'} className={classes.answer}>*/}
      {/*  {item.answer}*/}
      {/*</Text>*/}
      <div className={classes.answer}>{parse(item.answer)}</div>
    </Collapse>
  );
};
