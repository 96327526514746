import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { IconSettings } from '@consta/uikit/IconSettings';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageActions } from '../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector, selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { toolsPageSelectors } from '../../../../../store/reducers/toolsPageSlice.ts';
import imgErrorSettings from '../../../../assets/icons/svg/icn_settings_cross.svg';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { validateURL } from '../../../../utils/valudateURL.ts';

import { toolQueryKey } from '../../ToolsPage.tsx';

import classes from './ToolsInfo.module.css';

export const ToolsInfo = () => {
  const dispatch = useAppDispatch();
  const tools = useAppSelector(toolsPageSelectors.tools);
  const [currentToolId] = useAppSelector(selectParamByKey(toolQueryKey));
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const currentTool = tools?.find((tool) => tool.tool._uuid === currentToolId) ?? tools?.[0];

  return (
    currentTool && (
      <AppContentWrapper className={classes.container}>
        {isAdminMode && (
          <Button
            className={classes.settingsButton}
            iconLeft={IconSettings}
            view={'clear'}
            onClick={() => {
              dispatch(mainPageActions.setToolLinksEditModal(true));
            }}
          />
        )}
        <div className={classes.toolImg}>
          <img
            height={'40px'}
            src={
              currentTool?.tool.uuid_image
                ? `${OpenAPI.BASE}/image/${currentTool?.tool.uuid_image}`
                : imgErrorSettings
            }
            onError={(event) => {
              event.currentTarget.src = imgErrorSettings;
            }}
            alt={currentTool?.tool.name}
          />
        </div>
        <div className={classes.descriptionWithLinks}>
          <Text
            className={classNames(
              // themeClassNames.color.invert, // всегда ли белый цвет
              classes.description
            )}
          >
            {currentTool.description}
          </Text>
          <div className={classes.linksBlock}>
            {currentTool.instance_links?.instances.map((link) => (
              <Button
                as={'a'}
                key={link.uuid_instance}
                href={validateURL(link.link) ? link.link : undefined}
                target={'_blank'}
                label={link.block}
                form={'round'}
                view={'primary'}
                iconRight={IconForward}
                className={classes.linkBtn}
              />
            ))}
          </div>
        </div>
      </AppContentWrapper>
    )
  );
};
