import { JSX, ReactNode } from 'react';

import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import classes from './InfoCard.module.css';

type Props = {
  header: ReactNode;
  content: ReactNode;
  footer: JSX.Element | null;
  additionalClass?: string;
};
export const InfoCard = ({ header, content, footer, additionalClass }: Props) => {
  return (
    <Card
      shadow={false}
      form={'round'}
      verticalSpace={'2xl'}
      horizontalSpace={'2xl'}
      className={classNames(classes.card, additionalClass)}
    >
      {typeof header === 'string' ? (
        <Text size={'xl'} weight={'semibold'} className={classes.header}>
          {header}
        </Text>
      ) : (
        <div className={classes.header}>{header}</div>
      )}
      {typeof content === 'string' ? (
        <Text className={classes.content}>{content}</Text>
      ) : (
        <div className={classes.content}>{content}</div>
      )}
      {footer && <div className={classes.footer}>{footer}</div>}
    </Card>
  );
};
