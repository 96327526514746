import { DragNDropField } from '@consta/uikit/DragNDropField';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { DragDropContext, Draggable, DraggableLocation, Droppable } from 'react-beautiful-dnd';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { FileForShowDto } from '../../../../../../../generateAxios';
import { IconCustomMovie } from '../../../../../../assets/icons/tsx/IconCustomMovie.tsx';
import { getVideoThumbnailAsBase64 } from '../../../../../../utils/getVideoThumbnailAsBase64.ts';
import { KnowledgeFormValues } from '../../../../KnowledgeEditor.tsx';

import classes from './KnowledgeEditorVideo.module.css';
import { KnowledgeEditorVideoItem } from './VideoItem/KnowledgeEditorVideoItem.tsx';

export const KnowledgeEditorVideo = () => {
  const { control, getValues, setValue } = useFormContext<KnowledgeFormValues>();
  return (
    <div className={classes.container}>
      <Text size={'l'} weight={'medium'}>
        Видео-файл
        <Text size={'l'} weight={'medium'} view={'alert'} as={'span'}>
          {' '}
          *
        </Text>
      </Text>
      <Controller
        name={'video_files'}
        control={control}
        rules={{ required: 'Необходимо загрузить видеофайл' }}
        render={({ field, fieldState }) => {
          const deleteVideo = (index: number) => {
            field.onChange(field.value?.filter((_, i) => i !== index));
          };

          const changeDescription = (value: string, index: number) => {
            const newValues = field.value;
            if (newValues) newValues[index].description = value;
            setValue('video_files', newValues);
          };

          const onDragEnd = ({
            destination,
            source,
          }: {
            destination?: DraggableLocation | null;
            source: DraggableLocation;
          }) => {
            if (!destination) return;
            if (!field.value) return;
            const newItems = [...field.value];
            const [reorderedItem] = newItems.splice(source.index, 1);
            newItems.splice(destination.index, 0, reorderedItem);
            setValue('video_files', newItems);
          };
          return (
            <div>
              <div>
                <DragNDropField
                  ref={field.ref}
                  style={fieldState?.error ? { borderColor: 'red' } : undefined}
                  accept={['.mp4']}
                  multiple={false}
                  onDropFiles={async ([file]) => {
                    let thumbnail: string | undefined;
                    await getVideoThumbnailAsBase64(file)
                      .then((res) => (thumbnail = res))
                      .catch((error) => console.error('Error generating thumbnail:', error));
                    const oldFiles = getValues('video_files') ?? [];
                    const newFile: FileForShowDto & { data: File } = {
                      data: file,
                      description: '',
                      origin_file_size: file.size,
                      origin_file_name: file.name,
                      origin_file_extension: undefined,
                      _uuid: uuidv4(),
                      mini_image: thumbnail,
                      date: new Date().toISOString(),
                    };
                    field.onChange([...oldFiles, newFile]);
                  }}
                >
                  {({ openFileDialog }) => (
                    <>
                      <IconCustomMovie view={'ghost'} className={classes.iconPhoto} />
                      <Text size={'s'} view={'secondary'}>
                        Перетащите видео или{' '}
                        <Text size={'s'} view={'link'} as={'span'} onClick={openFileDialog}>
                          загрузите
                        </Text>
                      </Text>
                      <Text size={'xs'} view={'ghost'}>
                        Поддерживаемые форматы: MP4
                      </Text>
                    </>
                  )}
                </DragNDropField>
                {fieldState?.error?.message && (
                  <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                    {fieldState?.error?.message}
                  </Text>
                )}
              </div>
              {field.value && field.value?.length > 0 && (
                <div className={classes.imgList}>
                  <Text view={'secondary'}>Порядок отображения</Text>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={'images'}>
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={cnMixFlex({
                            flex: 'flex',
                            direction: 'column',
                            gap: 'm',
                            align: 'stretch',
                          })}
                        >
                          {field.value?.map((video, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <KnowledgeEditorVideoItem
                                    key={index}
                                    index={index}
                                    videoItem={video}
                                    onChangeDescription={changeDescription}
                                    deleteVideo={deleteVideo}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};
