import { instance } from '../../axios/apiInstance.ts';
import {
  FileForShowDto,
  KnowledgeCrudDto,
  KnowledgeDto,
  KnowledgeRestService,
  KnowledgeSelectorsDto,
  LikesRestService,
} from '../../generateAxios';
import { OpenAPI } from '../../generateAxios/core/OpenAPI.ts';
import { KnowledgeFormValues } from '../../src/components/KnowledgeEditor/KnowledgeEditor.tsx';
import { getLinksKnowledgeDtoFromForm } from '../../src/utils/getLinksKnowledgeDtoFromForm.ts';
import { hasImgWithHttpSrc } from '../../src/utils/hasImgWithHttpSrc.ts';

import { createAppSlice } from '../createAppSlice';
import { AppDispatch, RootState } from '../store.ts';

import { generalActions } from './generalSlice.ts';

type KnowledgeItemPageState = {
  knowledge: KnowledgeDto | null;
  knowledgeSelectors: KnowledgeSelectorsDto | null;
  // toolsOptions: ToolDto[] | null;
  pageLoader: boolean;
  loadingFilePercent: null | number;
};

const initialState: KnowledgeItemPageState = {
  knowledge: null,
  pageLoader: true,
  knowledgeSelectors: null,
  loadingFilePercent: null,
};

const knowledgeItemPageSlice = createAppSlice({
  name: 'knowledgeItemPage',
  initialState,
  reducers: (creators) => ({
    setLoadingFilePercent: creators.reducer<number | null>((state, action) => {
      state.loadingFilePercent = action.payload;
    }),
    clearState: creators.reducer(() => {
      return initialState;
    }),
    getKnowledgeItem: creators.asyncThunk(
      async (uuid: string, { signal }) => {
        const promise = KnowledgeRestService.getKnowledgeById(uuid);
        signal.onabort = () => {
          promise.cancel();
        };
        const res = await promise;
        return res;
      },
      {
        pending: (state) => {
          state.pageLoader = true;
        },
        fulfilled: (state, action) => {
          state.knowledge = action.payload;
          state.pageLoader = false;
        },
        rejected: (state) => {
          state.knowledge = null;
          state.pageLoader = false;
        },
      }
    ),

    setKnowledgeLike: creators.asyncThunk(
      async ({ knowledge, action }: { knowledge: KnowledgeDto; action: 'add' | 'delete' }) => {
        if (action === 'add') {
          const res = await LikesRestService.addLike({
            _uuid: '00000000-0000-0000-0000-000000000000',
            object_type: 'KNOWLEDGE',
            uuid_object: knowledge._uuid,
            thumb_up: 1,
          });
          return res;
        } else {
          const res = await LikesRestService.deleteLike(knowledge._uuid);
          return res;
        }
      },
      {
        fulfilled: (state, action) => {
          state.knowledge = state.knowledge
            ? (state.knowledge = {
                ...state.knowledge,
                count_like_by_user: action.payload.count_like_by_user,
                count_like_all: action.payload.count_like_all,
              })
            : null;
        },
      }
    ),
    getKnowledgeSelectors: creators.asyncThunk(
      async () => {
        const res = await KnowledgeRestService.getKnowledgeSelectors();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.knowledgeSelectors = action.payload;
        },
      }
    ),
    saveKnowledge: creators.asyncThunk(
      async ({ data }: { data: KnowledgeFormValues }, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as RootState;
        const knowledgeUuid = state.knowledgeItemPage.knowledge?._uuid;

        // заготовка для сохранения без контента
        const payload: KnowledgeCrudDto = {
          knowledge_type: data.knowledge_type,
          subject: data.subject,
          description: data.description,
          uuid_image_thumbnail: undefined,
          blocks: data.blocks?.map((el) => el._uuid),
          tags: data.tags?.map((el) => el._uuid),
          complexity: data.complexity?._uuid,
          // эти данные будут дополнены при необходимости
          tool: data.tool?._uuid,
          video_files: [],
          files: [],
          links: [],
          body: undefined,
          // wide_body сохраняем только для "статьи"  (INTERNAL)
          wide_body: false,
        };

        if (data.knowledge_type === 'FILE') {
          // разделяем новые и уже загруженные файлы
          const files = data.files?.reduce(
            (acc, el, index) => {
              if ('data' in el && el.data instanceof File) {
                acc.newFiles.push({ ...el, ord: index } as FileForShowDto & { data: File });
              } else {
                acc.loadedFiles.push({ ...el, ord: index } as FileForShowDto);
              }
              return acc;
            },
            {
              loadedFiles: [] as FileForShowDto[],
              newFiles: [] as (FileForShowDto & { data: File })[],
            }
          ) ?? {
            loadedFiles: [] as FileForShowDto[],
            newFiles: [] as (FileForShowDto & { data: File })[],
          };
          const { loadedFiles, newFiles } = files;

          let newFilesWithUuid: FileForShowDto[] = [];

          // сохраняем новые файлы если они есть и создаем данные с uuid
          if (newFiles.length > 0) {
            // без поддержки процента загрузки
            // const resImages = await KnowledgeRestService.saveFiles({
            //   file: newFiles.map((el) => el.data),
            // });
            const formData = new FormData();
            newFiles.forEach((file) => {
              formData.append('file', file.data);
            });
            const response = await instance.post(`${OpenAPI.BASE}/files`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                if (total !== undefined) {
                  const percentCompleted = Math.round((loaded * 100) / total);
                  dispatch(
                    knowledgeItemPageActions.setLoadingFilePercent(
                      percentCompleted === 100 ? 99 : percentCompleted
                    )
                  );
                }
              },
            });
            const resFiles = response.data as FileForShowDto[];

            newFilesWithUuid = resFiles.map((el, index) => ({
              ...el,
              ord: newFiles[index].ord,
              description: newFiles[index].description,
              mini_image: newFiles[index].mini_image,
            }));
          }
          // дополнение файлами
          payload.files = [...loadedFiles, ...newFilesWithUuid];
        }
        if (data.knowledge_type === 'VIDEO') {
          // разделяем новые и уже загруженные видео-файлы
          const videoFiles = data.video_files?.reduce(
            (acc, el, index) => {
              if ('data' in el && el.data instanceof File) {
                acc.newFiles.push({ ...el, ord: index } as FileForShowDto & { data: File });
              } else {
                acc.loadedFiles.push({ ...el, ord: index } as FileForShowDto);
              }
              return acc;
            },
            {
              loadedFiles: [] as FileForShowDto[],
              newFiles: [] as (FileForShowDto & { data: File })[],
            }
          ) ?? {
            loadedFiles: [] as FileForShowDto[],
            newFiles: [] as (FileForShowDto & { data: File })[],
          };

          const { loadedFiles, newFiles } = videoFiles;

          let newFilesWithUuid: FileForShowDto[] = [];

          // сохраняем новые файлы если они есть и создаем данные с uuid
          if (newFiles.length > 0) {
            // без поддержки процента загрузки
            // const resImages = await KnowledgeRestService.saveVideoFiles({
            //   file: newFiles.map((el) => el.data),
            // });
            const formData = new FormData();
            newFiles.forEach((file) => {
              formData.append('file', file.data);
            });
            const response = await instance.post(`${OpenAPI.BASE}/files`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                if (total !== undefined) {
                  const percentCompleted = Math.round((loaded * 100) / total);
                  dispatch(
                    knowledgeItemPageActions.setLoadingFilePercent(
                      percentCompleted === 100 ? 99 : percentCompleted
                    )
                  );
                }
              },
            });
            const resFiles = response.data as FileForShowDto[];

            newFilesWithUuid = resFiles.map((el, index) => ({
              ...el,
              ord: newFiles[index].ord,
              description: newFiles[index].description,
              mini_image: newFiles[index].mini_image,
            }));
          }
          // дополнение видео-файлами
          payload.video_files = [...loadedFiles, ...newFilesWithUuid];
        }

        if (data.knowledge_type === 'LINK') {
          payload.links = getLinksKnowledgeDtoFromForm(data) ?? [];
        }

        if (data.knowledge_type === 'INTERNAL') {
          payload.body = data.body;
          payload.wide_body = data.wide_body ?? false;
          const sourcesWithLink = hasImgWithHttpSrc(payload.body);

          if (sourcesWithLink) {
            dispatch(
              generalActions.addNotification({
                status: 'warning',
                message: `Внимание!
                  Статья содержит изображения со сторонних ресурсов, возможно некорректное отображение
                  ${sourcesWithLink.join(`\n`)}`,
              })
            );
          }
        }

        const res = knowledgeUuid
          ? await KnowledgeRestService.updateKnowledge(knowledgeUuid, payload)
          : await KnowledgeRestService.saveKnowledge(payload);

        dispatch(knowledgeItemPageActions.setLoadingFilePercent(null));
        const message = 'Ваш материал успешно добавлен';

        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: message,
          })
        );
      }
    ),
    deleteKnowledge: creators.asyncThunk(
      async ({ uuid, callback }: { uuid: string; callback?: () => void }, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        await KnowledgeRestService.deleteKnowledgeById(uuid);
        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: 'Материал успешно удален',
          })
        );
        callback?.();
        return uuid;
      },
      {
        pending: (state) => {
          state.pageLoader = true;
        },
        fulfilled: (state) => {
          state.pageLoader = false;
        },
        rejected: (state) => {
          state.pageLoader = false;
        },
      }
    ),
  }),

  selectors: {
    knowledge: (state) => state.knowledge,
    pageLoader: (state) => state.pageLoader,
    knowledgeSelectors: (state) => state.knowledgeSelectors,
    loadingFilePercent: (state) => state.loadingFilePercent,
  },
});

export const knowledgeItemPageActions = knowledgeItemPageSlice.actions;
export const knowledgeItemPageSelectors = knowledgeItemPageSlice.getSelectors(
  knowledgeItemPageSlice.selectSlice
);

export default knowledgeItemPageSlice.reducer;
