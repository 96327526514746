import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { IconCopy } from '@consta/icons/IconCopy';
import { IconShare } from '@consta/icons/IconShare';

import { PortfolioDto } from '../../../../generateAxios';
import outlookLogo from '../../../assets/images/img_portfolio_outlookLogo.png';
import { InfoCard } from '../../../components/InfoCard/InfoCard.tsx';

export const PortfolioShareInfoCard = ({ portfolio }: { portfolio: PortfolioDto }) => {
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const link = window.location.href.split('?')[0];
  const linkToCase = `mailto:?Subject=${portfolio?.name}&body=Добрый день!%0d%0a%0d%0a Ссылка на кейс Портфолио:%0d%0a ${link}`;
  const copyLink = async () => {
    await navigator.clipboard.writeText(link);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 1000);
  };
  return (
    <InfoCard
      header={<img src={outlookLogo} height={'48px'} alt={'img'} />}
      content={'Знаете, кому будет интересен этот кейс? Отправьте ссылку на почту в один клик'}
      footer={
        <div className={cnMixFlex({ gap: 'm', justify: 'space-between' })}>
          <Button
            label={'Поделиться'}
            iconLeft={IconShare}
            className={cnMixSpace({ pL: 'm', pR: 'l' })}
            onClick={() => {
              window.open(linkToCase, '_blank');
            }}
          />
          <Button
            onlyIcon
            iconLeft={() => <IconCopy view={isLinkCopied ? 'success' : 'primary'} size={'s'} />}
            view={'ghost'}
            onClick={() => {
              copyLink();
            }}
          />
        </div>
      }
    />
  );
};
