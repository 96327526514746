import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import { App } from './src/App';
import { WithAuth } from './src/WithAuth/WithAuth';
import { GetConfig } from './src/components/WithConfig/WithConfig.tsx';
import { acceptDayjsConfig } from './src/configs/dayjsConfig.ts';
import { ThemeWrapper } from './src/pages/MainPage/components/ThemeWrapper/ThemeWrapper.tsx';
import ErrorBoundary from './src/shared-front/ErrorBoundary/ErrorBoundary';
import store from './store/store';

acceptDayjsConfig();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <GetConfig>
        <ThemeWrapper>
          <BrowserRouter basename={`${import.meta.env.BASE_URL}`}>
            {/*<BrowserRouter basename={`${import.meta.env.VITE_BASE_PATH}`}> аналогичная запись, но без проверки валидности*/}
            <WithAuth>
              <App />
            </WithAuth>
          </BrowserRouter>
        </ThemeWrapper>
      </GetConfig>
    </Provider>
  </ErrorBoundary>
);
