import { useRef, useState } from 'react';

import { IconBag } from '@consta/uikit/IconBag';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import { PortfolioDto } from '../../../../../generateAxios';
import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import imgErrorSettings from '../../../../assets/icons/svg/icn_settings_cross.svg';
import { ToolWithIcon } from '../../../ToolWithIcon/ToolWithIcon.tsx';

import classes from './PortfolioOrganisationWithTool.module.css';

export const PortfolioOrganisationWithTool = ({ portfolio }: { portfolio: PortfolioDto }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <div className={showMore ? classes.containerColumn : classes.containerRow}>
      <ToolWithIcon
        text={portfolio.tool.name}
        imgSrc={
          portfolio.tool.uuid_icon
            ? `${OpenAPI.BASE}/image/${portfolio.tool.uuid_icon}`
            : imgErrorSettings
        }
      />
      <div className={classNames(classes.iconWithText, classes.truncateText)}>
        <IconBag size={'m'} view={'brand'} as={'div'} className={classes.icon} />
        <Text
          view={'secondary'}
          truncate={!showMore}
          ref={ref}
          onClick={() => {
            if (ref.current && ref.current?.scrollWidth > ref.current?.clientWidth) {
              setShowMore(true);
            }
          }}
          onMouseEnter={(e) => {
            e.currentTarget.setAttribute(
              'style',
              ref.current && ref.current?.scrollWidth > ref.current?.clientWidth
                ? 'cursor: pointer;'
                : ''
            );
          }}
        >
          {portfolio.organizations?.map((el) => el.name).join(' ,')}
        </Text>
      </div>
    </div>
  );
};
