import { Button } from '@consta/uikit/Button';

import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/hooks.ts';
import { isAdminModeSelector } from '../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../appMenuAndRouting/appRoutes.tsx';
import { InfoCard } from '../../../components/InfoCard/InfoCard.tsx';

export const PortfolioAddCaseInfoCard = () => {
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  return (
    <InfoCard
      header={'Вам есть о чем рассказать?'}
      content={
        'Вы можете поделиться с нами своим кейсом, который поможет создавать приложения вашим коллегам'
      }
      footer={
        <Button
          label={'Добавить свой кейс'}
          width={'full'}
          onClick={() =>
            navigate(`${PageRoutes.DataLab_PortfolioCreatePage}${isAdminMode ? '?mode=admin' : ''}`)
          }
        />
      }
    />
  );
};
