import { useEffect } from 'react';

import { Button } from '@consta/uikit/Button';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { Responses404 } from '@consta/uikit/Responses404';

import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/hooks.ts';
import { isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';

export const EmptyPage = () => {
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    document.title = 'Страница не найдена | Лаборатория данных';
  }, []);

  return (
    <div
      style={{ minHeight: 'calc(100vh - 130px - 60px)', marginTop: '60px' }}
      className={cnMixFlex({ flex: 'flex', align: 'center', justify: 'center' })}
    >
      <Responses404
        actions={
          <Button
            view={'ghost'}
            form={'round'}
            label={'Перейти на "Главную"'}
            onClick={() => {
              navigate(`${PageRoutes.DataLab_MainPage}${isAdminMode ? '?mode=admin' : ''}`);
            }}
          />
        }
      />
    </div>
  );
};
