import { RefObject, useMemo, useRef, useState } from 'react';

import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { IconComponent } from '@consta/uikit/Icon';
import { Tooltip } from '@consta/uikit/Tooltip';

import { IconCards } from '@consta/icons/IconCards';
import { IconTable2 } from '@consta/icons/IconTable2';

import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../../../../../../../hooks/hooks.ts';
import { knowledgePageActions } from '../../../../../../../store/reducers/knowledgePageSlice.ts';
import { knowledgeConfig } from '../../../../../../configs/knowledgeConfig.ts';

import classes from './SwitcherView.module.css';

export type KnowledgeSwitcherViewItem = {
  label: string;
  name: string;
  icon?: IconComponent;
  queryValue: 'cards' | 'table';
  ref: RefObject<HTMLButtonElement>;
};

export const SwitcherView = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const anchorRef1 = useRef<HTMLButtonElement>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [tooltipAnchor, setTooltipAnchor] = useState<RefObject<HTMLElement> | undefined>(undefined);
  const [tooltipText, setTooltipText] = useState<string>('');
  const items: KnowledgeSwitcherViewItem[] = useMemo(
    () => [
      {
        label: '',
        name: 'Карточный вид',
        icon: IconCards,
        queryValue: 'cards',
        ref: anchorRef,
      },
      {
        label: '',
        name: 'Табличный вид',
        icon: IconTable2,
        queryValue: 'table',
        ref: anchorRef1,
      },
    ],
    []
  );

  const changeView = (value: KnowledgeSwitcherViewItem) => {
    setSearchParams((prev) => {
      prev.set(knowledgeConfig.viewQueryKey, value.queryValue);
      if (value.queryValue === 'cards') {
        prev.delete(knowledgeConfig.tablePageNumberKey);
      }
      return prev;
    });
    dispatch(knowledgePageActions.setKnowledgeItems([]));
  };
  return (
    <>
      <ChoiceGroup
        items={items}
        getItemAttributes={(item) => ({
          onMouseEnter: (e) => {
            const target = e.currentTarget;
            timer.current = setTimeout(() => {
              setTooltipAnchor({ current: target });
              setTooltipText(item.name);
            }, 500);
          },
          onMouseLeave: () => {
            if (tooltipAnchor) {
              setTooltipAnchor(undefined);
              setTooltipText('');
            }
            clearTimeout(timer.current!);
          },
        })}
        name={'ChoiceGroup'}
        getItemLabel={(item) => item.name}
        view={'ghost'}
        // onlyIcon
        value={
          items.find(
            (item) => item.queryValue === searchParams.get(knowledgeConfig.viewQueryKey)
          ) || items[0]
        }
        onChange={({ value }) => changeView(value)}
        className={classes.switcher}
      />
      {tooltipAnchor && (
        <Tooltip
          possibleDirections={['downStartLeft']}
          direction="downCenter"
          size={'s'}
          anchorRef={tooltipAnchor}
        >
          {tooltipText}
        </Tooltip>
      )}
    </>
  );
};
