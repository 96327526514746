import { Dispatch, SetStateAction } from 'react';

import { Text } from '@consta/uikit/Text';

import { LinkToolDto } from '../../../../../../generateAxios';
import { OpenAPI } from '../../../../../../generateAxios/core/OpenAPI.ts';
import imgErrorSettings from '../../../../../assets/icons/svg/icn_settings_cross.svg';
import { AppModal } from '../../../../../components/AppModal/AppModal.tsx';
import { validateURL } from '../../../../../utils/valudateURL.ts';

import classes from './LinkImgModal.module.css';

export const LinkImgModal = ({
  setImgModal,
  imgModal,
}: {
  imgModal: { data: LinkToolDto; open: boolean } | null;
  setImgModal: Dispatch<SetStateAction<{ data: LinkToolDto; open: boolean } | null>>;
}) => {
  return (
    <AppModal
      isOpen={!!imgModal?.open}
      close={() => setImgModal((prev) => (prev ? { ...prev, open: false } : null))}
      afterClose={() => setImgModal(null)}
      className={classes.modal}
    >
      <img
        src={
          imgModal?.data.uuid_image
            ? `${OpenAPI.BASE}/image/${imgModal.data.uuid_image}`
            : imgErrorSettings
        }
        onError={(event) => {
          event.currentTarget.src = imgErrorSettings;
        }}
        width={'240px'}
        height={'240px'}
        alt={'QR'}
      />
      {imgModal?.data.image_title && (
        <Text
          size={'2xl'}
          weight={'semibold'}
          view={'link'}
          onClick={() =>
            validateURL(imgModal.data.text) &&
            window.open(imgModal.data.text, '_blank', 'noreferrer')
          }
          className={classes.link}
        >
          {imgModal?.data.image_title}
        </Text>
      )}
    </AppModal>
  );
};
