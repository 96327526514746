import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { knowledgePageSelectors } from '../../../../../store/reducers/knowledgePageSlice.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import classes from './HeroBlock.module.css';

export const HeroBlock = () => {
  const statistics = useAppSelector(knowledgePageSelectors.statistics);

  return (
    <div className={classes.container}>
      <div className={classes.greyBg}></div>
      <AppContentWrapper className={classes.content}>
        <AppBreadcrumbs
          crumbs={[
            { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
            { label: 'База знаний', rout: PageRoutes.DataLab_KnowledgesPage },
          ]}
          classname={classes.breadcrumbs}
        />
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xl' })}>
          База знаний
        </Text>
        <Text size={'xl'} className={cnMixSpace({ mT: 'm' })}>
          {`Здесь собраны справочные материалы и инструкции,
           которые сделают вашу работу в Лаборатории данных
            максимально удобной, понятной и простой`}
        </Text>
        <div className={classes.metrics}>
          <div className={classes.metric}>
            <Text size={'2xl'} view={'brand'} weight={'medium'}>
              {`${statistics?.totalKnowledge ?? '-'} ${statistics?.totalKnowledgeNameFirst}`}
            </Text>
            <Text view={'secondary'}> {statistics?.totalKnowledgeNameSecond}</Text>
          </div>
        </div>
      </AppContentWrapper>
    </div>
  );
};
