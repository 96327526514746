import { memo, useEffect, useState } from 'react';

import { Avatar } from '@consta/uikit/Avatar';
import { Button } from '@consta/uikit/Button';
import { IconSettings } from '@consta/uikit/IconSettings';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import {
  FullTeamItemDto,
  HelpdeskForShowDto,
  TeamItemIgnoreBlockDto,
} from '../../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks.ts';
import { mainPageActions } from '../../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../../store/reducers/selectors.ts';
import { getMimeType } from '../../../../../utils/getMimeType.ts';
import { validateURL } from '../../../../../utils/valudateURL.ts';

import classes from './ContactsBlock.module.scss';

export const ContactsBlock = ({ team }: { team: FullTeamItemDto }) => {
  const dispatch = useAppDispatch();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const maxContacts = 4;
  const [contacts, setContacts] = useState<TeamItemIgnoreBlockDto[]>([]);
  const showMore = contacts.length < (team.teams?.length ?? 0);

  useEffect(() => {
    setContacts(team.teams?.slice(0, maxContacts) ?? []);
  }, [team]);

  const handlerShowMore = () => {
    setContacts(team.teams ?? []);
  };

  const handlerEditTeam = () => {
    dispatch(mainPageActions.setCurrentTeam(team));
    dispatch(mainPageActions.setTeamEditModal(true));
  };

  return (
    <div className={classes.container}>
      <div className={classNames(classes.withBorder, classes.left)}>
        <div className={classes.leftTitle}>
          <Text size={'2xl'} weight={'medium'}>
            Лаборатория данных {team.block?.short_name}
          </Text>
          {isAdminMode && (
            <Button
              onlyIcon
              iconLeft={IconSettings}
              view={'clear'}
              form={'round'}
              onClick={handlerEditTeam}
              disabled={team.read_only}
            />
          )}
        </div>
        <div className={classes.links}>
          {team.emails?.map((item) => {
            const email: HelpdeskForShowDto = {
              ...item,
              link: item.link ? `mailto:${item.link} ` : '',
            };
            return <SourceLink key={`${email.link}${email.name}`} resource={email} />;
          })}
        </div>
        <div className={classes.links}>
          {team.resources?.map((resource) => (
            <SourceLink key={`${resource.link}${resource.name}`} resource={resource} />
          ))}
        </div>
      </div>
      <div className={classNames(classes.withBorder, classes.right)}>
        {contacts.length === 0 && <Text size={'xl'}>Нет контактов</Text>}
        {contacts.map((people) => {
          let img: string | undefined = undefined;
          if (people?.avatar) {
            const mimeType = getMimeType(people.avatar);
            img = `data:${mimeType};base64,${people.avatar.replace(/\s+/g, '')}`;
          }

          return (
            <div key={people._uuid}>
              <Avatar url={img} form={'round'} className={classes.avatar} />
              <Text size={'l'} weight={'semibold'} className={cnMixSpace({ mT: 'm' })}>
                {people.display_name}
              </Text>
              <Text view={'secondary'} className={cnMixSpace({ mT: '2xs' })}>
                {people.position}
              </Text>
              <div className={cnMixSpace({ mT: 'm' })}>
                <Text view={'ghost'} as={'a'} target={'_blank'} href={`mailto:${people.email}`}>
                  {people.email}
                </Text>
              </div>
            </div>
          );
        })}

        {showMore && (
          <Button
            label={'Показать ещё'}
            width={'full'}
            view={'ghost'}
            className={classes.showMore}
            onClick={handlerShowMore}
          />
        )}
      </div>
    </div>
  );
};

const SourceLink = memo(({ resource }: { resource: HelpdeskForShowDto }) => (
  <Text
    view={'link'}
    key={resource.link}
    as={'a'}
    target={'_blank'}
    href={resource.link && validateURL(resource.link) ? resource.link : undefined}
    rel={'noreferrer'}
  >
    {resource.name}
  </Text>
));
