export const getMimeType = (base64: string) => {
  const byteCharacters = atob(base64.substring(0, 30));
  const bytes = new Uint8Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    bytes[i] = byteCharacters.charCodeAt(i);
  }

  // Сигнатуры файлов
  const signatures: { [key: string]: string } = {
    '89504E47': 'image/png',
    '47494638': 'image/gif',
    FFD8FFDB: 'image/jpeg',
    FFD8FFE0: 'image/jpeg',
    FFD8FFE1: 'image/jpeg',
    // Добавьте другие сигнатуры по мере необходимости
  };

  const hex = bytes
    .slice(0, 4)
    .reduce((acc, byte) => acc + byte.toString(16).toUpperCase().padStart(2, '0'), '');
  return signatures[hex] || 'application/octet-stream'; // 'application/octet-stream' на случай неизвестного типа
};
