import { useEffect, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useForm } from 'react-hook-form';

import { FaqDto } from '../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { faqPageActions, faqPageSelectors } from '../../../../../store/reducers/faqPageSlice.ts';
import { generalActions, generalSelectors } from '../../../../../store/reducers/generalSlice.ts';
import { AppModal } from '../../../../components/AppModal/AppModal.tsx';
import { CustomEditor } from '../../../../components/CustomEditor/CustomEditor.tsx';
import { Divider } from '../../../../components/Divider/Divider.tsx';

import classes from './FaqEditModal.module.css';

const emptyValues: FaqDto = {
  _uuid: '00000000-0000-0000-0000-000000000000',
  answer: '',
  question: '',
  show_on_main_page: false,
  blocks: [],
};

export const FaqEditModal = () => {
  const dispatch = useAppDispatch();
  const currentFaq = useAppSelector(faqPageSelectors.currentFaq);
  const open = useAppSelector(faqPageSelectors.faqEditModal);
  const blocks = useAppSelector(generalSelectors.blocks);
  // const emptyBlock = useMemo(() => ({ label: 'Общие', value: '' }), []);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FaqDto>({
    defaultValues: emptyValues,
  });

  useEffect(() => {
    if (open && currentFaq) {
      reset({ ...currentFaq });
    }
  }, [currentFaq, open]);

  useEffect(() => {
    if (open) dispatch(generalActions.getBlocks());
  }, [open]);

  const close = () => dispatch(faqPageActions.setFaqEditModal(false));
  const save = async (data: FaqDto) => {
    // const newFaq = { ...data, blocks: data.blocks?.filter((el) => el.name !== commonBlock.name) };
    const newFaq = data;
    setLoadingSave(true);
    if (currentFaq) {
      await dispatch(faqPageActions.updateFaq(newFaq));
    } else {
      await dispatch(faqPageActions.createFaq(newFaq));
    }
    setLoadingSave(false);
  };

  return (
    <AppModal
      isOpen={open}
      close={close}
      afterClose={() => {
        reset(emptyValues);
        dispatch(faqPageActions.setCurrentFaq(null));
      }}
      disableCloseOnClickOutside
      className={classes.modal}
    >
      <Text size={'xl'} weight={'medium'} className={classes.title}>
        {currentFaq ? 'Редактирование вопроса' : 'Добавление вопроса в FAQ'}
      </Text>
      <Divider />
      <div className={classes.content}>
        <Controller
          name={'blocks'}
          control={control}
          rules={{ required: 'Выберите один или несколько блоков' }}
          render={({ field }) => (
            <div>
              <ChoiceGroup
                {...field}
                multiple
                size={'m'}
                value={field.value}
                items={blocks ?? []}
                onChange={({ value }) => field.onChange(value)}
                // value={field.value && field.value?.length > 0 ? field.value : [commonBlock]}
                // items={[commonBlock, ...blocks]}
                // onChange={({ value, e }) => {
                // let newValue;
                // const selectedLabel = e.currentTarget.value.split('-')?.[1];
                // const valueIsChecked = e.currentTarget.checked;
                // if (valueIsChecked && selectedLabel === commonBlock.name) {
                //   newValue = [commonBlock];
                // } else {
                //   newValue = value?.filter((el) => el.name !== commonBlock.name) ?? [];
                // }
                // field.onChange(newValue, e);
                // }}
                name={'choiceGroup'}
                getItemLabel={(item) => item.short_name}
                className={errors.blocks ? classes.choiceGroupWithError : undefined}
              />
              {errors.blocks && (
                <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                  {errors.blocks.message}
                </Text>
              )}
            </div>
          )}
        />
        <form className={classes.form}>
          <Controller
            name={'question'}
            control={control}
            rules={{
              required: 'Поле обязательно для заполнения',
              // minLength: { value: 1, message: 'Минимальная длина 1 символ' },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={'Вопрос'}
                placeholder={'Введите вопрос'}
                required
                width={'full'}
                value={field.value ?? ''}
                onChange={({ value }) => field.onChange(value ?? '')}
                status={errors.question ? 'alert' : undefined}
                caption={errors.question?.message}
              />
            )}
          />
          <Controller
            name={'answer'}
            control={control}
            rules={{
              required: 'Поле обязательно для заполнения',
              // minLength: { value: 1, message: 'Минимальная длина 1 символ' },
            }}
            render={({ field, fieldState }) => (
              <CustomEditor
                onChange={field.onChange}
                value={field.value}
                label={'Ответ'}
                required
                placeholder={'Введите ответ'}
                fieldState={fieldState}
                refC={field.ref}
                initProps={{ max_height: 500, height: 256, min_height: 200, resize: true }}
                // inModal
              />
            )}
          />
        </form>
      </div>
      <Divider />
      <div className={classes.footer}>
        <Button label={'Отмена'} view={'ghost'} onClick={close} />
        <Button
          label={'Сохранить'}
          onClick={handleSubmit((data) => save(data))}
          loading={loadingSave}
        />
      </div>
    </AppModal>
  );
};
