import { forwardRef } from 'react';

import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import classes from './Step1.module.css';
import { TaskSlider } from './components/TaskSlider/TaskSlider.tsx';

export const Step1 = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <>
      <AppContentWrapper className={classes.step1}>
        <Text view={'brand'} size={'l'} weight={'bold'} transform={'uppercase'} ref={ref}>
          Шаг №1
        </Text>
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xs', mB: 'm' })}>
          Опишите задачу
        </Text>
        <Text size={'xl'} weight={'regular'}>
          {`Корректная постановка позволит вам сфокусироваться на
          главном, не упуская важные моменты`}
        </Text>
      </AppContentWrapper>
      <AppContentWrapper>
        <TaskSlider />
      </AppContentWrapper>
    </>
  );
});
