import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { Loader } from '@consta/uikit/Loader';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Divider } from '../Divider/Divider.tsx';

import classes from './DnDSortList.module.css';

// для отслеживания состояния перетаскивания? при быстром перетаскивании обнаружены баги с дублированием элементов
let moveInProgress = false;

type DnDSortListProps<T> = {
  originalItems: T[];
  // поле уникального ключа
  uniqueKey: keyof T;
  renderItem: (item: T, index: number) => ReactNode;
  // разделительная черта между элементами
  withDivider?: boolean;
  afterMove?: (items: T[]) => Promise<any> | void;
  disabled?: boolean;
  // уникальный идентификатор для зоны перетаскивания
  type?: string;
};

export const DnDSortList = <T,>({
  originalItems,
  uniqueKey,
  renderItem,
  withDivider = false,
  afterMove,
  disabled,
  type = 'box',
}: DnDSortListProps<T>) => {
  const [items, setItems] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setItems(originalItems);
  }, [originalItems]);

  const findItem = useCallback(
    (dragItemId: string) => {
      const item = items.filter((c) => `${c[uniqueKey]}` === dragItemId)[0] as T;
      return {
        item,
        index: items.findIndex((c) => `${c[uniqueKey]}` === dragItemId),
      };
    },
    [items, uniqueKey]
  );

  const moveItem = useCallback(
    (dragItemId: string, atIndex: number) => {
      const { item, index: dragItemIndex } = findItem(dragItemId);

      setItems((prev) => {
        const newItems = [...prev];
        newItems.splice(dragItemIndex, 1);
        newItems.splice(atIndex, 0, item);
        return newItems;
      });
    },
    [findItem]
  );

  const afterMoveTrigger = useCallback(async () => {
    if (afterMove) {
      setIsLoading(true);
      await afterMove(items);
      setIsLoading(false);
    }
  }, [afterMove, items]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.container}>
        {items.map((item, index, array) =>
          withDivider ? (
            <Fragment key={String(item[uniqueKey])}>
              {/*<Divider />*/}
              {disabled ? (
                renderItem(item, index)
              ) : (
                <DragListItem
                  type={type}
                  key={String(item[uniqueKey])}
                  dragId={`${item[uniqueKey]}`}
                  item={item}
                  renderItem={renderItem}
                  index={index}
                  indexFrom={originalItems.findIndex((c) => c[uniqueKey] === item[uniqueKey])}
                  findItem={findItem}
                  moveItem={moveItem}
                  afterMoveTrigger={afterMove ? afterMoveTrigger : undefined}
                />
              )}
              {index !== array.length - 1 && <Divider />}
            </Fragment>
          ) : disabled ? (
            renderItem(item, index)
          ) : (
            <DragListItem
              type={type}
              key={String(item[uniqueKey])}
              dragId={`${item[uniqueKey]}`}
              item={item}
              renderItem={renderItem}
              index={index}
              indexFrom={originalItems.findIndex((c) => c[uniqueKey] === item[uniqueKey])}
              findItem={findItem}
              moveItem={moveItem}
              afterMoveTrigger={afterMove ? afterMoveTrigger : undefined}
            />
          )
        )}
        {isLoading && <Loader className={classes.loader} />}
      </div>
    </DndProvider>
  );
};

type DragItem = {
  dragId: string;
  originalIndex: number;
};

export type DraggableItemProps<T> = {
  dragId: string;
  item: T;
  index: number;
  indexFrom: number;
  moveItem: (id: string, to: number) => void;
  findItem: (id: string) => { index: number };
  // renderItem: (item: T, isDragging?: boolean) => ReactNode;
  renderItem: DnDSortListProps<T>['renderItem'];
  afterMoveTrigger?: () => void;
  type: string;
};

const DragListItem = <T,>({
  dragId,
  item,
  index,
  indexFrom,
  moveItem,
  findItem,
  renderItem,
  afterMoveTrigger,
  type,
}: DraggableItemProps<T>) => {
  const originalIndex = findItem(dragId).index;
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: type,
      item: { dragId, originalIndex },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
      end: (item, monitor) => {
        const { dragId: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppedId, originalIndex);
        }
        if (indexFrom !== originalIndex) afterMoveTrigger?.();
      },
    }),
    [dragId, originalIndex, moveItem]
  );

  const [, drop] = useDrop(
    () => ({
      accept: type,
      hover(draggedItem: DragItem, monitor) {
        if (!ref.current) {
          return;
        }
        // индекс элемента который перемещаем
        const dragIndex = draggedItem.originalIndex;

        // индекс элемента на который перемещаем
        const hoverIndex = index;

        // перемещен в рамках своего места
        if (dragIndex === hoverIndex) {
          return;
        }

        /* размеры элемента на который перетягиваем

        height: 80 -- высота элемента
        width: 1206 -- ширина элемента
        left: 80 -- координата X левой части элемента на который перетягиваем
        right: 1286 -- координата X правой части элемента на который перетягиваем
        top: 634 -- координата Y верхней части элемента на который перетягиваем
        bottom:714 -- координата Y нижней части элемента на который перетягиваем
        x: 80 -- координата X левого верхнего угла элемента на который перетягиваем
        y: 634 -- координата Y левого верхнего угла элемента на который перетягиваем

        * */
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        /* расстояние от верха элемента до его центра */
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        /*Последние координаты { x, y } указателя в процессе перетаскивания. null - если не ведется перетаскивание. */
        const clientOffset = monitor.getClientOffset();

        /* расстояние от верха элемента до указателя */
        const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

        /* не действовать если не пересекли середину элемента-соседа*/
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        if (moveInProgress) return;

        if (draggedItem.dragId !== dragId) {
          moveInProgress = true;
          const { index: overIndex } = findItem(dragId);

          moveItem(draggedItem.dragId, overIndex);
          draggedItem.originalIndex = hoverIndex;
          setTimeout(() => {
            moveInProgress = false;
          }, 100);
        }
      },
    }),
    [findItem, moveItem]
  );

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        opacity,
      }}
    >
      {renderItem(item, index)}
    </div>
  );
};
