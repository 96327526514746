import { useEffect, useMemo, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { ResponsesNothingFound } from '@consta/uikit/ResponsesNothingFound';

import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { KnowledgeDto } from '../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import {
  knowledgeItemPageActions,
  knowledgeItemPageSelectors,
} from '../../../store/reducers/knowledgeItemPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../components/AppContentWrapper/AppContentWrapper.tsx';
import { KnowledgePreview } from '../../components/KnowledgePreview/KnowledgePreview.tsx';
import { KnowledgeDeleteModal } from '../../components/Modals/KnowledgeDeleteModal/KnowledgeDeleteModal.tsx';
import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';
import { useKnowledgeActions } from '../../hooks/useKnowledgeActions.tsx';

import classes from './KnowledgeItemPage.module.css';
import { KnowledgeCopyLinkButton } from './components/Buttons/KnowledgeCopyLinkButton.tsx';
import { KnowledgeDeleteButton } from './components/Buttons/KnowledgeDeleteButton.tsx';
import { KnowledgeEditButton } from './components/Buttons/KnowledgeEditButton.tsx';
import { KnowledgeLikeButton } from './components/Buttons/KnowledgeLikeButton.tsx';
import { KnowledgeShareButton } from './components/Buttons/KnowledgeShareButton.tsx';
import { KnowledgeLikeInfoCard } from './components/KnowledgeLikeInfoCard.tsx';
import { KnowledgeShareInfoCard } from './components/KnowledgeShareInfoCard.tsx';

export const KnowledgeItemPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const knowledge = useAppSelector(knowledgeItemPageSelectors.knowledge);
  const pageLoader = useAppSelector(knowledgeItemPageSelectors.pageLoader);

  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const { getHrefKnowledges, navigateToKnowledges } = useKnowledgeActions();

  useEffect(() => {
    document.title = 'База знаний | Лаборатория данных';
    if (id !== undefined) dispatch(knowledgeItemPageActions.getKnowledgeItem(id));
    return () => {
      dispatch(knowledgeItemPageActions.clearState());
    };
  }, [id]);

  const deleteKnowledge = async () => {
    setLoadingDelete(true);
    knowledge?._uuid &&
      (await dispatch(
        knowledgeItemPageActions.deleteKnowledge({
          uuid: knowledge?._uuid ?? '',
          callback: navigateToKnowledges,
        })
      ));
    setLoadingDelete(false);
    setDeleteModal(false);
  };

  const wrapperWidth = useMemo(() => {
    if (knowledge?.knowledge_type === 'INTERNAL' && knowledge?.wide_body) return 'full';
    return 'standard';
  }, [knowledge?.knowledge_type, knowledge?.wide_body]);

  return (
    <AppContentWrapper
      width={wrapperWidth}
      className={classNames({
        [classes.withPadding]: wrapperWidth === 'full',
      })}
    >
      <AppBreadcrumbs
        crumbs={[
          { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
          { label: 'База знаний', rout: getHrefKnowledges() },
          {
            label: `${knowledge?.subject ?? '...'}`,
            rout: `${PageRoutes.DataLab_KnowledgesPage}/${knowledge?._uuid ?? ''}`,
          },
        ]}
        classname={classes.breadcrumbs}
      />
      {isAdminMode && !hasRoles ? (
        <SwitchToUser403 />
      ) : pageLoader ? (
        <Loader />
      ) : knowledge ? (
        knowledge.knowledge_type === 'INTERNAL' ? (
          <KnowledgeContentInternal knowledge={knowledge} onDelete={() => setDeleteModal(true)} />
        ) : (
          <KnowledgeContentStandard knowledge={knowledge} onDelete={() => setDeleteModal(true)} />
        )
      ) : (
        <ResponsesNothingFound
          actions={
            <Button
              view={'ghost'}
              label={'перейти к списку материалов'}
              onClick={navigateToKnowledges}
            />
          }
        />
      )}
      <KnowledgeDeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={deleteKnowledge}
        loading={loadingDelete}
        name={knowledge?.subject}
      />
    </AppContentWrapper>
  );
};

const KnowledgeContentStandard = ({
  knowledge,
  onDelete,
}: {
  knowledge: KnowledgeDto;
  onDelete: () => void;
}) => {
  const isAdminMode = useAppSelector(isAdminModeSelector);

  return (
    <div className={classes.content}>
      <AppContentWrapper width={'wide'}>
        <KnowledgePreview knowledge={knowledge} />
      </AppContentWrapper>

      {knowledge.knowledge_type !== 'INTERNAL' && (
        <AppContentWrapper width={'narrow'} className={classes.rightSide}>
          <div className={cnMixFlex({ direction: 'column', gap: '6xl' })}>
            {isAdminMode && (
              <div className={cnMixFlex({ gap: 'xs' })}>
                <KnowledgeEditButton knowledge={knowledge} />
                <KnowledgeDeleteButton knowledge={knowledge} onClick={onDelete} />
              </div>
            )}
            <div className={cnMixFlex({ direction: 'column', gap: '3xl' })}>
              <KnowledgeShareInfoCard knowledge={knowledge} />
              <KnowledgeLikeInfoCard knowledge={knowledge} />
            </div>
          </div>
        </AppContentWrapper>
      )}
    </div>
  );
};

const KnowledgeContentInternal = ({
  knowledge,
  onDelete,
}: {
  knowledge: KnowledgeDto;
  onDelete: () => void;
}) => {
  const isAdminMode = useAppSelector(isAdminModeSelector);

  return (
    <div className={classes.internalContent}>
      <KnowledgePreview knowledge={knowledge} showInternalActionButtons onDelete={onDelete} />
      {isAdminMode && (
        <div
          className={classNames(
            cnMixFlex({ gap: 'xs', align: 'center' }),
            cnMixSpace({ pT: '2xl' })
          )}
        >
          <KnowledgeShareButton knowledge={knowledge} />
          <KnowledgeCopyLinkButton />
          <KnowledgeLikeButton knowledge={knowledge} />
        </div>
      )}
    </div>
  );
};
