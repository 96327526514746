import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/hooks.ts';
import {
  portfolioItemPageActions,
  portfolioItemPageSelectors,
} from '../../store/reducers/portfolioItemPageSlice.ts';
import { isAdminModeSelector } from '../../store/reducers/selectors.ts';

import { PageRoutes } from '../appMenuAndRouting/appRoutes.tsx';
import { portfolioConfig } from '../configs/portfolioConfig.ts';

/* хук для использования действий над кейсами портфолио на страницах создания/редактирования/просмотра кейса*/
export const usePortfolioActions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const portfolio = useAppSelector(portfolioItemPageSelectors.portfolio);
  const [loading, setLoading] = useState<boolean>(false);
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const hrefPortfolioPage = `${PageRoutes.DataLab_PortfolioPage}${isAdminMode ? '?mode=admin' : ''}`;
  const hrefPortfolioPageModeration = `${PageRoutes.DataLab_PortfolioPage}?mode=admin&${portfolioConfig.statusPortfolioQueryKey}=MODERATION`;
  const hrefPortfolioItem = `${PageRoutes.DataLab_PortfolioPage}/${portfolio?._uuid}${isAdminMode ? '?mode=admin' : ''}`;
  const hrefPortfolioEdit = `${PageRoutes.DataLab_PortfolioEditPage.replace(':id', portfolio?._uuid ?? '')}?mode=admin `;

  const backToPortfolioCards = useCallback(() => {
    isAdminMode
      ? navigate(
          portfolio && portfolio.status === 'MODERATION'
            ? hrefPortfolioPageModeration
            : hrefPortfolioPage
        )
      : navigate(PageRoutes.DataLab_PortfolioPage);
  }, [isAdminMode, navigate, portfolio]);

  const navigateEditPortfolio = useCallback(() => {
    portfolio?._uuid && navigate(hrefPortfolioEdit);
  }, [portfolio?._uuid, navigate]);

  const navigateViewPortfolio = useCallback(() => {
    portfolio?._uuid && navigate(hrefPortfolioItem);
  }, [portfolio?._uuid, navigate]);

  /** изменение статуса портфолио "опубликован" "снят с публикации"
   * @Params колбэк для выполнения после изменения статуса
   * */
  const deletePortfolio = useCallback(
    async (callback?: () => void) => {
      if (portfolio?._uuid) {
        setLoading(true);
        await dispatch(
          portfolioItemPageActions.deletePortfolio({
            uuid: portfolio?._uuid ?? '',
            callback: callback,
          })
        );
        setLoading(false);
      }
    },
    [portfolio?._uuid, dispatch]
  );

  /** изменение статуса портфолио "опубликован" "снят с публикации"
   * @Params колбэк для выполнения после изменения статуса
   * */
  const changeStatusPortfolio = useCallback(
    async (callback?: () => void) => {
      if (portfolio?._uuid) {
        setLoading(true);
        await dispatch(
          portfolioItemPageActions.changeStatusPortfolio({
            uuid: portfolio?._uuid ?? '',
            newStatus: portfolio?.status === 'PUBLISHED' ? 'MODERATION' : 'PUBLISHED',
          })
        );
        callback?.();
        setLoading(false);
      }
    },
    [portfolio?._uuid, dispatch, portfolio?.status]
  );

  return {
    portfolio,
    backToPortfolioCards,
    navigateEditPortfolio,
    navigateViewPortfolio,
    deletePortfolio,
    changeStatusPortfolio,
    /** индикатор загрузки изменения статуса и/или удаления кейса */
    loading,
  };
};
