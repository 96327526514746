import { useEffect, useState } from 'react';

import { FaqDto } from '../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { faqPageActions, faqPageSelectors } from '../../../../../store/reducers/faqPageSlice.ts';
import { generalSelectors } from '../../../../../store/reducers/generalSlice.ts';
import {
  hasSomeRoleSelector,
  isAdminModeSelector,
  selectParamByKey,
} from '../../../../../store/reducers/selectors.ts';

import { faqBlockQueryKey, faqSearchQueryKey } from '../../FaqPage.tsx';

import { commonBlockValue } from '../FaqFilter/components/ChoiceGroupWithQuery/ChoiceGroupWithQuery.tsx';
import { FaqsAdmin } from '../FaqsAdmin/FaqsAdmin.tsx';
import { FaqsUser } from '../FaqsUser/FaqsUser.tsx';

export const FaqsList = () => {
  const dispatch = useAppDispatch();
  const allFaqs = useAppSelector(faqPageSelectors.faqItems);
  const hasSomeRole = useAppSelector(hasSomeRoleSelector);
  const blocks = useAppSelector(generalSelectors.blocks);
  const [items, setItems] = useState<FaqDto[] | null>(null);
  const blockUuid = useAppSelector(selectParamByKey(faqBlockQueryKey));
  const [searchValue] = useAppSelector(selectParamByKey(faqSearchQueryKey));
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    dispatch(faqPageActions.getFaqs());
  }, [dispatch]);

  useEffect(() => {
    let filteredFaqs: FaqDto[] = [];
    if (allFaqs && allFaqs.length > 0) {
      filteredFaqs = [...allFaqs];
      if (blockUuid.length > 0) {
        filteredFaqs = allFaqs.filter((faq) => {
          if (faq.blocks.length === blocks?.length && blockUuid.includes(commonBlockValue)) {
            return true;
          } else return faq.blocks?.some((block) => blockUuid.includes(block._uuid));
        });
      }
      if (searchValue) {
        filteredFaqs = filteredFaqs.filter(
          (faq) =>
            faq.question.toLowerCase().includes(searchValue.toLowerCase()) ||
            faq.answer.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    }
    setItems(filteredFaqs);
  }, [allFaqs, blockUuid, searchValue]);

  return isAdminMode ? (
    hasSomeRole ? (
      <FaqsAdmin items={items} />
    ) : (
      <>заглушка нет прав</>
    )
  ) : (
    <FaqsUser items={items} />
  );
};
