import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { FilterSelectorsKnowledgeDto, SimpleFilterDto } from '../../../../../../../generateAxios';
import { useAppSelector } from '../../../../../../../hooks/hooks.ts';
import { knowledgePageSelectors } from '../../../../../../../store/reducers/knowledgePageSlice.ts';
import { selectParamsByKeys } from '../../../../../../../store/reducers/selectors.ts';
import FilterBarWithQuery, {
  FilterBarItemType,
} from '../../../../../../components/FilterBarWithQuery/FilterBarWithQuery.tsx';
import { knowledgeConfig } from '../../../../../../configs/knowledgeConfig.ts';

import classes from './KnowledgeFilterBar.module.css';

type FilterItem = {
  queryKey: string;
  filterKey: keyof FilterSelectorsKnowledgeDto;
  valueKey?: keyof SimpleFilterDto;
  nameConverter?: (name: SimpleFilterDto) => string;
};
export const filters: FilterItem[] = [
  { queryKey: knowledgeConfig.filterBlockQueryKey, filterKey: 'blocks' },
  { queryKey: knowledgeConfig.filterTagsQueryKey, filterKey: 'tags' },
  { queryKey: knowledgeConfig.filterToolQueryKey, filterKey: 'tools' },
  {
    queryKey: knowledgeConfig.filterTypeQueryKey,
    filterKey: 'type',
    valueKey: 'hash_key',
    // nameConverter: knowledgeTypeConvert,
  },
  { queryKey: knowledgeConfig.filterComplexityQueryKey, filterKey: 'complexity' },
  { queryKey: knowledgeConfig.filterAuthorQueryKey, filterKey: 'authors', valueKey: 'hash_key' },
];

export const KnowledgeFilterBar = () => {
  // для корректной работы порядок массивов значений должен совпадать с порядком ключей в filters
  const [
    filterBlockValues,
    filterTagValues,
    filterToolsValues,
    filterTypeValues,
    filterComplexityValues,
    filterAuthorValues,
  ] = useAppSelector(selectParamsByKeys(filters.map((el) => el.queryKey)));
  const filterItems = useAppSelector(knowledgePageSelectors.knowledgeFilters);
  const [, setSearchParams] = useSearchParams();
  const [items, setItems] = useState<FilterBarItemType[]>([]);

  useEffect(() => {
    const filterValues = [
      filterBlockValues,
      filterTagValues,
      filterToolsValues,
      filterTypeValues,
      filterComplexityValues,
      filterAuthorValues,
    ];

    if (filterItems) {
      const newItems = filters
        .map((filter, index) => {
          return filterValues[index].map((val) => {
            const findFilter = filterItems[filter.filterKey]?.find(
              (item) => item[filter.valueKey ?? '_uuid'] === val
            );

            const title = findFilter
              ? filter.nameConverter
                ? filter.nameConverter(findFilter)
                : findFilter.name
              : '';

            return {
              title: title,
              uuid: val,
              queryKey: filter.queryKey,
            };
          });
        })
        .flat();
      setItems(newItems);
    }
  }, [
    filterBlockValues,
    filterTagValues,
    filterToolsValues,
    filterTypeValues,
    filterComplexityValues,
    filterItems,
    filterAuthorValues,
  ]);

  const onSave = (items: FilterBarItemType[]) => {
    setSearchParams((prev) => {
      filters.map((el) => el.queryKey).map((el) => prev.delete(el));
      items.map((el) => {
        prev.append(el.queryKey, el.uuid);
      });
      return prev;
    });
  };

  return <FilterBarWithQuery items={items} onSave={onSave} customClass={classes.bar} />;
};
