import { useMemo } from 'react';

import { Breadcrumbs, DefaultItem } from '@consta/uikit/Breadcrumbs';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/hooks.ts';
import { isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import classes from './AppBreadcrumbs.module.css';

export const AppBreadcrumbs = ({
  crumbs,
  classname,
}: {
  crumbs?: { label: string; rout: string }[];
  classname?: string;
}) => {
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);

  const items: DefaultItem[] =
    useMemo(() => {
      return crumbs?.map((el, index, array) => {
        const rout = el.rout.includes('?')
          ? el.rout
          : isAdminMode
            ? `${el.rout}?mode=admin`
            : el.rout;
        return {
          label: el.label,
          href: rout,
          onClick:
            index !== array.length - 1
              ? (e) => {
                  e.preventDefault();
                  navigate(rout);
                }
              : () => {},
        };
      });
    }, [crumbs, navigate, isAdminMode]) ?? [];

  if (!crumbs || crumbs.length < 1) return null;
  return <Breadcrumbs items={items} className={classNames(classes.breadcrumbs, classname)} />;
};
