export const hasImgWithHttpSrc = (html?: string | null): string[] | false => {
  if (!html) return false;
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const imgTags = doc.getElementsByTagName('img');
  const acc: string[] = [];
  for (let img of imgTags) {
    if (img.hasAttribute('src') && img.src.startsWith('http')) {
      acc.push(img.src);
    }
  }
  return acc.length ? acc : false;
};
