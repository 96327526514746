import { useCallback, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

import { IconConnection } from '@consta/icons/IconConnection';
import { IconDocFilled } from '@consta/icons/IconDocFilled';
import { IconPlay } from '@consta/icons/IconPlay';

import { AxiosProgressEvent } from 'axios';
import dayjs from 'dayjs';

import { KnowledgeDto } from '../../../../../generateAxios';
import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { knowledgePageActions } from '../../../../../store/reducers/knowledgePageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import imgErrorSettings from '../../../../assets/icons/svg/icn_settings_cross.svg';
import { ComplexityTag } from '../../../../components/ComplexityTag/ComplexityTag.tsx';
import { KnowledgeLikeButton } from '../../../../components/KnowledgeLikeButton/KnowledgeLikeButton.tsx';
import { KnowledgeDeleteModal } from '../../../../components/Modals/KnowledgeDeleteModal/KnowledgeDeleteModal.tsx';
import { ToolWithIcon } from '../../../../components/ToolWithIcon/ToolWithIcon.tsx';
import { useKnowledgeActions } from '../../../../hooks/useKnowledgeActions.tsx';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';
import { validateURL } from '../../../../utils/valudateURL.ts';

import classes from './KnowledgeCard.module.css';

export const KnowledgeCard = ({
  knowledge,
  setFirstPage,
}: {
  knowledge: KnowledgeDto;
  setFirstPage: () => void;
}) => {
  const dispatch = useAppDispatch();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onDownloadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
      setDownloadProgress(Math.round(percentComplete));
    }
  }, []);

  const {
    openLinksModal,
    openVideoModal,
    downloadFile,
    downloadZip,
    navigateToEdit,
    navigateToView,
    hrefKnowledgeItem,
    hrefKnowledgeEdit,
    deleteKnowledge,
    loadingDelete,
  } = useKnowledgeActions(knowledge, onDownloadProgress);

  const date = knowledge.date
    ? dayjs(knowledge.date).isValid()
      ? dayjs(knowledge.date).format('DD MMMM YYYY')
      : 'не валидная дата'
    : 'нет даты';

  const handlerDownloadFile = async () => {
    setDownloadProgress(1);
    await downloadFile();
    setDownloadProgress(null);
  };

  const handlerDownloadZip = async () => {
    setDownloadProgress(1);
    await downloadZip();
    setDownloadProgress(null);
  };

  const handlerDeleteCard = async () => {
    await deleteKnowledge(() => {
      setFirstPage();
      dispatch(knowledgePageActions.getKnowledgeItems({}));
      dispatch(knowledgePageActions.getKnowledgeMetrics());
    });
    setDeleteModal(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div>
          <div className={classes.contentHeader}>
            <div className={cnMixFlex({ gap: 'xl' })}>
              <ToolWithIcon
                imgSrc={
                  knowledge.tool?.uuid_icon
                    ? `${OpenAPI.BASE}/image/${knowledge.tool?.uuid_icon}`
                    : imgErrorSettings
                }
                text={knowledge.tool?.name ?? ''}
                weight={'medium'}
                size={'s'}
              />
              <ComplexityTag
                name={knowledge.complexity?.name ?? 'нет имени'}
                level={knowledge.complexity?.level ?? -1}
              />
            </div>
            <div className={cnMixFlex({ gap: 'xs' })}>
              {knowledge.blocks?.map((el) => (
                <Tag mode={'info'} size={'s'} label={el.short_name} key={el._uuid} />
              ))}
            </div>
          </div>
          <Text size={'xl'} weight={'semibold'} className={classes.title}>
            {knowledge.subject}
          </Text>
          <Text className={classes.description}>
            {/*{knowledge.description?.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '')}*/}
            {knowledge.description}
          </Text>
          <Text view={'secondary'} className={classes.date}>
            {date}
          </Text>
        </div>
        <div className={classes.footer}>
          <div className={cnMixFlex({ gap: 's' })}>
            <Button
              label={'Подробнее'}
              size={'s'}
              form={'round'}
              as={'a'}
              href={relativeToAbsoluteLink(hrefKnowledgeItem)}
              className={cnMixSpace({ pR: 'l', pL: 'l' })}
              onClick={(e) => {
                e.preventDefault();
                navigateToView();
              }}
            />
            {knowledge.knowledge_type === 'LINK' &&
              knowledge.links &&
              knowledge.links.length !== 0 &&
              (knowledge.links.length === 1 ? (
                <Button
                  size={'s'}
                  view={'ghost'}
                  iconRight={IconConnection}
                  label={'Ссылка'}
                  form={'round'}
                  className={cnMixSpace({ pL: 'l', pR: 'm' })}
                  as={'a'}
                  href={knowledge.links[0].link}
                  onClick={(event) => {
                    event.preventDefault();
                    validateURL(knowledge.links?.[0].link) &&
                      window.open(knowledge.links?.[0].link, '_blank');
                  }}
                />
              ) : (
                <Button
                  size={'s'}
                  view={'ghost'}
                  iconRight={IconConnection}
                  label={`Ссылки (${knowledge.links?.length})`}
                  form={'round'}
                  className={cnMixSpace({ pL: 'l', pR: 'm' })}
                  onClick={openLinksModal}
                />
              ))}
            {knowledge.knowledge_type === 'FILE' &&
              knowledge.files &&
              !!knowledge.files?.length && (
                <Button
                  size={'s'}
                  view={'ghost'}
                  iconRight={
                    downloadProgress
                      ? () => (
                          <ProgressSpin
                            value={downloadProgress}
                            size="s"
                            className={cnMixSpace({ mL: 's' })}
                          />
                        )
                      : IconDocFilled
                  }
                  // iconRight={IconDocFilled}
                  label={knowledge.files.length > 1 ? `Файлы (${knowledge.files.length})` : 'Файл'}
                  form={'round'}
                  className={cnMixSpace({ pL: 'l', pR: 'm' })}
                  loading={!!downloadProgress}
                  onClick={() => {
                    knowledge.files && knowledge.files.length > 1
                      ? handlerDownloadZip()
                      : handlerDownloadFile();
                  }}
                />
              )}
            {knowledge.knowledge_type === 'VIDEO' &&
              knowledge.video_files &&
              !!knowledge.video_files?.length && (
                <Button
                  size={'s'}
                  view={'ghost'}
                  iconRight={IconPlay}
                  label={
                    knowledge.video_files.length > 1
                      ? `Видео (${knowledge.video_files.length})`
                      : 'Видео'
                  }
                  form={'round'}
                  className={cnMixSpace({ pL: 'l', pR: 'm' })}
                  onClick={openVideoModal}
                />
              )}
            {isAdminMode && (
              <>
                {/*// disable не работает на кнопке типа "a"*/}
                {knowledge.read_only ? (
                  <Button disabled iconLeft={IconEdit} view={'ghost'} size={'s'} form={'round'} />
                ) : (
                  <Button
                    iconLeft={IconEdit}
                    as={'a'}
                    href={relativeToAbsoluteLink(hrefKnowledgeEdit)}
                    view={'ghost'}
                    size={'s'}
                    form={'round'}
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToEdit();
                    }}
                  />
                )}
                <Button
                  disabled={knowledge.read_only}
                  iconLeft={IconTrash}
                  iconSize={'s'}
                  view={'ghost'}
                  size={'s'}
                  form={'round'}
                  className={classes.deleteButton}
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                />
              </>
            )}
          </div>
          <KnowledgeLikeButton knowledge={knowledge} size={'s'} variant={'card'} />
        </div>
      </div>
      <KnowledgeDeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={handlerDeleteCard}
        name={knowledge?.subject}
        loading={loadingDelete}
      />
    </div>
  );
};
