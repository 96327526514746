import { useEffect, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks.ts';
import { generalActions, generalSelectors } from '../../../../store/reducers/generalSlice.ts';
import { validateURL } from '../../../utils/valudateURL.ts';

import { AppModal } from '../../AppModal/AppModal.tsx';
import { Divider } from '../../Divider/Divider.tsx';

import classes from './SupportModal.module.css';

export const SupportModal = () => {
  const supportModal = useAppSelector(generalSelectors.supportModal);
  const helpdeskLinks = useAppSelector(generalSelectors.helpdeskLinks);
  const dispatch = useAppDispatch();
  const [mode, setMode] = useState<'short' | 'long'>('short');
  const closeModal = () => dispatch(generalActions.setSupportModal(null));

  useEffect(() => {
    if (supportModal) {
      setMode(supportModal);
      dispatch(generalActions.getHelpdesk('HELPDESK'));
    }
  }, [supportModal]);

  return (
    <AppModal isOpen={!!supportModal} close={closeModal} className={classes.modal}>
      {mode === 'long' && <div className={classes.mainImg}></div>}
      <div className={classes.description}>
        <Text size="xl" view="primary" lineHeight="xs" weight={'bold'}>
          {mode === 'long' ? 'Получите консультацию' : 'Заявка на консультацию'}
        </Text>
        <Text size="l" view="primary" lineHeight="xs" align={'center'}>
          {mode === 'long'
            ? 'Сотрудники лаборатории расскажут с чего начать или как пройти определённый этап процесса — готовы помочь с любым вопросом. Для подачи заявки выберите блок, в котором состоите'
            : 'Выберите блок, в котором вы состоите'}
        </Text>
      </div>
      {mode === 'long' && <Divider />}
      <div className={classes.buttonsGroup}>
        {!helpdeskLinks && (
          <div>
            <Loader />
          </div>
        )}
        {helpdeskLinks?.map((helpdeskLink) => {
          return (
            <Button
              key={helpdeskLink._uuid}
              view={'ghost'}
              label={helpdeskLink.block?.short_name}
              onClick={() =>
                validateURL(helpdeskLink.link) && window.open(helpdeskLink.link, '_blank')
              }
            />
          );
        })}
      </div>
    </AppModal>
  );
};
