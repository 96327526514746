import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

// создание пустого массива необходимо для возврата ссылки на пустой массив, а не нового массива
const emptyArray: string[] = [];

export const selectParamByKey = (key: string) =>
  createSelector(
    (state: RootState): { [key: string]: string[] | undefined } => state.general.searchParams,
    (searchParams: { [key: string]: string[] | undefined }): string[] => {
      return searchParams[key] ?? emptyArray;
    }
  );
export const selectParamsByKeys = (keys: string[]) =>
  createSelector(
    keys.map((key) => selectParamByKey(key)),
    (...values): string[][] => {
      return values;
    }
  );

export const selectParamCountByKeys = (keys: string[]) =>
  createSelector(
    keys.map((key) => selectParamByKey(key)),
    (...args): number => {
      return args.reduce((acc, val) => (!!val.length ? acc + 1 : acc), 0);
    }
  );
export const isAdminModeSelector = createSelector(
  (state: RootState) => state.general.searchParams?.mode ?? emptyArray,
  ([mode]: string[]): boolean => {
    return mode === 'admin';
  }
);

export const hasSomeRoleSelector = createSelector(
  (state: RootState): string[] => state.general.userDetails?.roles ?? [],
  (roles: string[]): boolean => {
    const portalRoles = ['CF-PORTAL', 'BRD-PORTAL', 'BLPS-PORTAL', 'DCT-PORTAL-ADM'];
    return roles.some((role) => portalRoles.includes(role));
  }
);

export const isSuperAdminSelector = createSelector(
  (state: RootState): string[] => state.general.userDetails?.roles ?? [],
  (roles: string[]): boolean => {
    const superRoles = ['DCT-PORTAL-ADM'];
    return roles.some((role) => superRoles.includes(role));
  }
);
