// const basepath = process.env.REACT_APP_BASEPATH === '/' ? '/' : `${process.env.REACT_APP_BASEPATH}`;
// ссылки на внешние ресурсы перезаписываются при получении /public/assets/config.json
import { Navigate } from 'react-router-dom';

import { EmptyPage } from '../pages/EmptyPage/EmptyPage.tsx';
import { FaqPage } from '../pages/FaqPage/FaqPage.tsx';
import { KnowledgeCreatePage } from '../pages/KnowledgeCreatePage/KnowledgeCreatePage.tsx';
import { KnowledgeEditPage } from '../pages/KnowledgeEditPage/KnowledgeEditPage.tsx';
import { KnowledgeItemPage } from '../pages/KnowledgeItemPage/KnowledgeItemPage.tsx';
import { KnowledgePage } from '../pages/KnowledgePage/KnowledgePage.tsx';
import { MainPage } from '../pages/MainPage/MainPage.tsx';
import { MetricsPage } from '../pages/MetricsPage/MetricsPage.tsx';
import { PortfolioCreatePage } from '../pages/PortfolioCreatePage/PortfolioCreatePage.tsx';
import { PortfolioEditPage } from '../pages/PortfolioEditPage/PortfolioEditPage.tsx';
import { PortfolioItemPage } from '../pages/PortfolioItemPage/PortfolioItemPage.tsx';
import { PortfolioPage } from '../pages/PortolioPage/PortfolioPage.tsx';
import { ToolsPage } from '../pages/ToolsPage/ToolsPage.tsx';

export const PageRoutes = {
  DataLab: ``,
  DataLab_MainPage: `/main`,
  DataLab_ShopDataPage: '',
  DataLab_EducationPage: '',
  DataLab_KnowledgesPage: `/knowledge`,
  DataLab_PortfolioPage: `/portfolio`,
  DataLab_ToolsPage: `/tools`,
  DataLab_PortfolioCreatePage: `/portfolio/create`,
  DataLab_PortfolioEditPage: `/portfolio/edit/:id`,
  DataLab_KnowledgeCreatePage: `/knowledge/create`,
  DataLab_KnowledgeEditPage: `/knowledge/edit/:id`,
  DataLab_MetricsPage: `/metrics`,
  DataLab_Faq: `/faq`,
};

export const onLogoClickRoute = PageRoutes.DataLab_MainPage;
export const appRedirectRoute = PageRoutes.DataLab_MainPage;

// объект PageRoutes мутирует при получении данных из /public/assets/config.json,
// для получения актуальны данных требуется массив роутов должен формироваться
// в процессе вызова функции
export const getAppRoutes = () => [
  { path: PageRoutes.DataLab_MainPage, element: <MainPage /> },
  {
    path: `/`,
    element: <Navigate to={PageRoutes.DataLab_MainPage} replace />,
  },
  // {
  //   path: PageRoutes.DataLab_EducationPage,
  //   element: <EmptyPage title="Обучение" />,
  // },
  {
    path: PageRoutes.DataLab_PortfolioPage,
    element: <PortfolioPage />,
  },
  {
    path: `${PageRoutes.DataLab_PortfolioPage}/:id`,
    element: <PortfolioItemPage />,
  },
  {
    path: PageRoutes.DataLab_PortfolioCreatePage,
    element: <PortfolioCreatePage />,
  },
  {
    path: `${PageRoutes.DataLab_PortfolioEditPage}`,
    element: <PortfolioEditPage />,
  },
  { path: PageRoutes.DataLab_KnowledgesPage, element: <KnowledgePage /> },
  {
    path: PageRoutes.DataLab_KnowledgeCreatePage,
    element: <KnowledgeCreatePage />,
  },
  {
    path: `${PageRoutes.DataLab_KnowledgesPage}/:id`,
    element: <KnowledgeItemPage />,
  },
  {
    path: PageRoutes.DataLab_KnowledgeEditPage,
    element: <KnowledgeEditPage />,
  },
  {
    path: PageRoutes.DataLab_MetricsPage,
    element: <MetricsPage />,
  },
  // {
  //   path: PageRoutes.DataLab_PortfolioCreatePage,
  //   element: <MetricsPage />,
  // },
  {
    path: PageRoutes.DataLab_Faq,
    element: <FaqPage />,
  },
  {
    path: PageRoutes.DataLab_ToolsPage,
    element: <ToolsPage />,
  },
  {
    path: '*',
    element: <EmptyPage />,
  },
];
export const getAdminAppRoutes = () =>
  getAppRoutes().map((route) =>
    route.path !== '/' && route.path !== '*'
      ? { ...route, path: `${route.path}?mode=admin` }
      : route
  );

export const withoutFooterRoutes = [];
