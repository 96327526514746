import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../../../../hooks/hooks.ts';
import { isAdminModeSelector } from '../../../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../../../appMenuAndRouting/appRoutes.tsx';

export const ButtonAdd = () => {
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const navigate = useNavigate();
  return (
    <Button
      label={'Добавить кейс'}
      iconRight={IconAdd}
      className={cnMixSpace({ pL: 'l' })}
      onClick={() => {
        navigate(`${PageRoutes.DataLab_PortfolioCreatePage}${isAdminMode ? '?mode=admin' : ''}`);
      }}
    />
  );
};
