import React, { useEffect, useState } from 'react';

import { Selector } from '@reduxjs/toolkit';

import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { CheckboxGroup } from '@consta/uikit/CheckboxGroup';
import { IconClose } from '@consta/uikit/IconClose';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField } from '@consta/uikit/TextField';
import { TextFieldOnChangeArguments } from '@consta/uikit/__internal__/src/components/TextField/types';

import { useSearchParams } from 'react-router-dom';

import { SimpleFilterDto } from '../../../generateAxios';
import { useAppSelector } from '../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../store/reducers/selectors.ts';
import { RootState } from '../../../store/store.ts';

import { FilterSideBarCollapse } from '../FilterSideBarCollapse/FilterSideBarCollapse.tsx';

import classes from './FilterSideBarCheckBox.module.css';

type Props = {
  // data: SimpleFilterDto[];
  queryKey: string;
  valueKey?: keyof SimpleFilterDto;
  filterTitle: string;
  showTotal?: boolean;
  itemsSelector: Selector<RootState, SimpleFilterDto[] | undefined>;
  nameConverter?: (name: string) => string;
  // onChangeCheckBox?: (filterKey: keyof FilterType, items: SimpleFilterDto[] | null) => void;
};

export const FilterSideBarCheckBox = ({
  queryKey,
  valueKey = '_uuid',
  filterTitle,
  showTotal,
  itemsSelector,
  nameConverter,
}: Props) => {
  const values = useAppSelector(selectParamByKey(queryKey));
  const data = useAppSelector(itemsSelector);
  // значение в строке поиска
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [items, setItems] = useState<SimpleFilterDto[]>([]);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const newData: SimpleFilterDto[] = searchValue
      ? data?.filter((el) =>
          el.name?.toString().toLowerCase().includes(searchValue.toLowerCase())
        ) ?? []
      : data ?? [];

    setItems(newData);
  }, [data, searchValue]);

  const changeSearchHandler = (e: TextFieldOnChangeArguments) => {
    setSearchValue(e.value);
  };

  const clearFilter = () => {
    setSearchParams((prev) => {
      prev.delete(queryKey);
      return prev;
    });
  };

  const handleChangeSelectedValue = ({ value }: { value: SimpleFilterDto[] | null }) => {
    setSearchParams((prev) => {
      prev.delete(queryKey);
      value?.map((el) => {
        prev.append(queryKey, el[valueKey] as string);
      });
      return prev;
    });
    // filterKey && onChangeCheckBox?.(filterKey, value);
  };

  return (
    <FilterSideBarCollapse
      title={filterTitle}
      clearFilter={clearFilter}
      showClear={!!values.length}
    >
      <>
        {data && data.length > 5 && (
          <TextField
            inputMode={'text'}
            leftSide={IconSearch}
            // rightSide={() =>
            //   searchValue?.length ? (
            //     <IconClose
            //       style={{ color: 'var(--color-control-typo-placeholder)' }}
            //       size={'s'}
            //       onClick={(e) => changeSearchHandler({ value: '', e })}
            //     />
            //   ) : (
            //     <></>
            //   )
            // }
            rightSide={() => (
              <Button
                iconRight={() => (
                  <IconClose
                    size={'s'}
                    style={{ color: 'var(--color-control-typo-placeholder)' }}
                  />
                )}
                view={'clear'}
                size={'xs'}
                form={'round'}
                onClick={() => {
                  setSearchValue('');
                }}
                style={{ visibility: !searchValue?.length ? 'hidden' : 'visible' }}
              />
            )}
            size={'s'}
            placeholder={'Найти в списке'}
            form={'default'}
            width={'full'}
            value={searchValue}
            onChange={changeSearchHandler}
          />
        )}
        <Card
          className={classes.variantList}
          style={data && data.length > 5 ? {} : { height: 'auto' }}
          shadow={false}
          border={true}
        >
          <CheckboxGroup
            value={data && data.filter((item) => values.some((val) => val === item[valueKey]))}
            size={'l'}
            items={items}
            getItemLabel={(item: SimpleFilterDto) => {
              const name = nameConverter ? nameConverter(item.name) : item.name;
              const totalCount =
                !showTotal || item.count === null || item.count === undefined
                  ? ''
                  : `  (${item.count})`;
              return name + totalCount;
            }}
            getItemDisabled={(item: SimpleFilterDto) => !item.available}
            onChange={handleChangeSelectedValue}
            getItemKey={(item: SimpleFilterDto) => item._uuid}
          />
        </Card>
      </>
    </FilterSideBarCollapse>
  );
};
