import { ReactNode, useState } from 'react';

import { Theme, presetGpnDark, presetGpnDefault } from '@consta/uikit/Theme';
import { ThemeToggler } from '@consta/uikit/ThemeToggler';

import { IconMoon } from '@consta/icons/IconMoon';
import { IconSun } from '@consta/icons/IconSun';

import { themeConfig } from '../../../../configs/themeConfig.ts';

import classes from './ThemeWrapper.module.css';

type PropsType = { children: ReactNode };

const items = [
  {
    key: 'light',
    label: 'light',
    icon: IconSun,
  },
  {
    key: 'dark',
    label: 'dark',
    icon: IconMoon,
  },
];
// компонента-обертка для добавления возможности изменить тему
export const ThemeWrapper = (props: PropsType) => {
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(localStorage.getItem('theme') === 'dark');

  const changeTheme = () => {
    setIsDarkTheme((prev) => !prev);
    localStorage.setItem('theme', isDarkTheme ? 'light' : 'dark');
  };

  return (
    <Theme
      preset={
        themeConfig.changeTheme
          ? isDarkTheme
            ? presetGpnDark
            : presetGpnDefault
          : presetGpnDefault
      }
    >
      {props.children}
      {themeConfig.changeTheme && (
        <ThemeToggler
          size={'xs'}
          items={items}
          value={isDarkTheme ? items[1] : items[0]}
          onChange={changeTheme}
          className={classes.toggle}
        />
      )}
    </Theme>
  );
};
