import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Responses403 } from '@consta/uikit/Responses403';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';

import { AppContentWrapper } from '../AppContentWrapper/AppContentWrapper.tsx';

export const SwitchToUser403 = ({ navigateToMain = false }: { navigateToMain?: boolean }) => {
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  return (
    <AppContentWrapper className={cnMixSpace({ mT: '6xl', mB: '6xl' })}>
      <Responses403
        actions={
          navigateToMain ? (
            <Button
              onClick={() => navigate(PageRoutes.DataLab_MainPage)}
              view={'ghost'}
              label={'Перейти на Главную'}
            />
          ) : (
            <Button
              onClick={() =>
                setSearchParams((prev) => {
                  prev.delete('mode');
                  return prev;
                })
              }
              view={'ghost'}
              label={'Переключиться в режим пользователя'}
            />
          )
        }
        title={'Нет доступа'}
        description={'У Вас не достаточно прав для просмотра этой страницы в режиме администратора'}
        size={'m'}
      />
    </AppContentWrapper>
  );
};
