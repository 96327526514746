import { useEffect, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { IconSearch } from '@consta/uikit/IconSearch';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { TextField, TextFieldOnChangeArguments } from '@consta/uikit/TextField';

import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../store/reducers/selectors.ts';

type Props = {
  searchQueryKey: string;
  pageNumberQueryKey?: string;
  placeholder: string;
  buttonLabel?: string;
  wrapperClassName?: string;
  size?: 's' | 'm' | 'l';
  showSearchButton?: boolean;
};

export const SearchInput = ({
  searchQueryKey,
  pageNumberQueryKey,
  placeholder,
  buttonLabel = 'Найти',
  wrapperClassName,
  size = 'm',
  showSearchButton = false,
}: Props) => {
  const [value, setValue] = useState<string | null>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [valueFromSearchParams] = useAppSelector(selectParamByKey(searchQueryKey));

  useEffect(() => {
    setValue(valueFromSearchParams);
  }, [valueFromSearchParams]);

  // изменение инпута с кнопкой поиска
  const handlerOnChange = ({ value }: TextFieldOnChangeArguments) => {
    setValue(value);
    if (!value && !value?.length) {
      searchParams.delete(searchQueryKey);
      pageNumberQueryKey && searchParams.set(pageNumberQueryKey, '0');
      setSearchParams(searchParams);
    }
  };

  const handleKeyPressSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handlerSearch();
    }
  };

  const handlerSearch = () => {
    if (value) {
      searchParams.set(searchQueryKey, value);
      pageNumberQueryKey && searchParams.set(pageNumberQueryKey, '0');
      setSearchParams(searchParams);
    }
  };
  // изменение инпута без кнопки поиска
  const handlerSearchWithoutButton = ({ value }: TextFieldOnChangeArguments) => {
    setSearchParams((prev) => {
      if (!value && !value?.length) {
        prev.delete(searchQueryKey);
      } else {
        prev.set(searchQueryKey, value);
      }
      if (pageNumberQueryKey && prev.get(pageNumberQueryKey) !== '0') {
        prev.delete(pageNumberQueryKey);
      }
      return prev;
    });
  };

  return (
    <div className={classNames(cnMixFlex({ direction: 'row' }), wrapperClassName)}>
      {showSearchButton ? (
        <>
          <TextField
            inputMode={'search'}
            leftSide={IconSearch}
            rightSide={() => (
              <IconClose
                style={{
                  color: 'var(--color-control-typo-placeholder)',
                  visibility: !value?.length ? 'hidden' : 'visible',
                  cursor: 'pointer',
                }}
                size={size}
                onClick={(e) => handlerOnChange({ value: '', e })}
              />
            )}
            size={size}
            placeholder={placeholder}
            form={'defaultClear'}
            value={value}
            onChange={handlerOnChange}
            width={'full'}
            onKeyDown={handleKeyPressSearch}
          />
          <Button
            label={buttonLabel}
            size={size}
            view={'secondary'}
            form={'brickDefault'}
            disabled={!value?.length}
            onClick={handlerSearch}
            className={cnMixSpace({ pR: 'm', pL: 'm' })}
          />
        </>
      ) : (
        <TextField
          inputMode={'search'}
          leftSide={IconSearch}
          rightSide={() => (
            <IconClose
              style={{
                color: 'var(--color-control-typo-placeholder)',
                visibility: !valueFromSearchParams?.length ? 'hidden' : 'visible',
                cursor: 'pointer',
              }}
              size={size}
              onClick={(e) => handlerSearchWithoutButton({ value: '', e })}
            />
          )}
          size={size}
          placeholder={placeholder}
          form={'default'}
          value={valueFromSearchParams}
          onChange={handlerSearchWithoutButton}
          width={'full'}
        />
      )}
    </div>
  );
};
