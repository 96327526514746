import { useEffect } from 'react';

import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { Editor } from '@tinymce/tinymce-react';
import { InitOptions } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';

import classNames from 'classnames';
import { ControllerFieldState, RefCallBack } from 'react-hook-form';
import tinymce from 'tinymce';
import 'tinymce-i18n/langs6/ru';
import 'tinymce/icons/default/icons.min.js';
import 'tinymce/models/dom/model.min.js';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
// import 'tinymce/skins/content/default/content.css'; // ломает margin  в body
// import 'tinymce/skins/ui/oxide/content.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/tinymce.min.js';

import './CustomEditor.scss';

(window as any)['tinymce'] = tinymce;
// import '../../tinymce/icons/default/icons.min.js';
// DOM model
// import '../../tinymce/models/dom/model.min.js';
// importing the plugin index.js file.
// if you use a plugin that is not listed here the editor will fail to load
// import '../../tinymce/plugins/advlist';
// importing plugin resources
// import '../../tinymce/plugins/lists';
// import '../../tinymce/plugins/table';
// Content styles, including inline UI like fake cursors
// import '../../tinymce/skins/content/default/content.js';
// import '../../tinymce/skins/ui/oxide/content.js';
// Editor styles
// import '../../tinymce/skins/ui/oxide/skin.min.js';
// Theme
// import '../../tinymce/themes/silver/theme.min.js';
// TinyMCE so the global var exists
// import '../../tinymce/tinymce.min.js';

type CustomEditorProps = {
  onChange: (value: string) => void;
  value: any;
  refC?: RefCallBack;
  fieldState?: ControllerFieldState;
  placeholder?: string;
  label?: string;
  required?: boolean;
  initProps?: InitOptions;
  inModal?: boolean;
  fullscreen?: boolean;
};

const baseUrlDEV =
  import.meta.env.VITE_BASE_PATH && import.meta.env.VITE_BASE_PATH !== '/'
    ? import.meta.env.VITE_BASE_PATH
    : '';

const baseUrlPROD = import.meta.env.BASE_URL !== '/' ? `${import.meta.env.BASE_URL}` : '';

export const baseUrl = (import.meta.env.DEV ? baseUrlDEV : baseUrlPROD).replace(/\/{2,}/g, '/');

export const CustomEditor = ({
  value,
  onChange,
  fieldState,
  placeholder,
  refC,
  label,
  required = false,
  initProps,
  // inModal = false,
  fullscreen = false,
}: CustomEditorProps) => {
  // useEffect(() => {
  //   let styleElement: HTMLStyleElement | null = null;
  //
  //   const addStyle = () => {
  //     styleElement = document.createElement('style');
  //     // styleElement.type = 'text/css';
  //     styleElement.appendChild(
  //       document.createTextNode(`
  //       .tox-tinymce-aux {
  //         z-index: 1300 !important;
  //       }
  //     `)
  //     );
  //     document.head.appendChild(styleElement);
  //   };
  //
  //   inModal && addStyle();
  //
  //   // Очистка: удаление добавленного стиля при размонтировании компонента или изменении условия
  //   return () => {
  //     if (styleElement) {
  //       document.head.removeChild(styleElement);
  //     }
  //   };
  // }, [inModal]);

  return (
    <div>
      {label && (
        <Text view={'secondary'} className={cnMixSpace({ mB: 'xs' })}>
          {label}
          {required && (
            <Text as={'span'} view={'alert'}>
              {` *`}
            </Text>
          )}
        </Text>
      )}
      <div
        className={classNames({
          ['custom-editor-error']: !!fieldState?.error,
          ['custom-editor']: true,
        })}
      >
        <Editor
          tinymceScriptSrc={'/tinymce'}
          onEditorChange={onChange}
          value={value}
          licenseKey={'gpl'}
          ref={(instance) => {
            refC?.({
              focus: () => {
                instance?.editor?.container.scrollIntoView({
                  block: 'center',
                });
                instance?.editor?.focus();
              },
            });
          }}
          init={{
            height: 256,
            menubar: 'format table',
            language: 'ru',
            plugins: ['advlist', 'lists', 'table', fullscreen ? 'fullscreen' : ''],
            content_css: `${baseUrl}/tinymce.css`,
            content_style: `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: rgba(0, 32, 51, .35); font-size: 16px}`,
            placeholder: placeholder,
            toolbar: `${fullscreen ? 'fullscreen | ' : ''}undo redo |  blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent `,
            base_url: `${baseUrl}/tinymce`,
            // убираю лого tinyMCE
            branding: false,
            // привязка меню к редактору
            ui_mode: 'split',
            toolbar_sticky: true,
            toolbar_mode: 'sliding',
            /* удаление canvas - частный случай - копирование из PDF */
            paste_preprocess: (_, args) => {
              if (args.content.includes('<canvas')) {
                args.content = args.content.replace(/<canvas[^>]*>[\s\S]*?<\/canvas>/gi, '');
              }
            },
            ...initProps,
          }}
        />
      </div>
      {fieldState?.error?.message && (
        <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
          {fieldState?.error?.message}
        </Text>
      )}
    </div>
  );
};
