import { useState } from 'react';

import { Button } from '@consta/uikit/Button';

import { AnimateIconSwitcherProvider } from '@consta/icons/AnimateIconSwitcherProvider';
import { IconThumbUp } from '@consta/icons/IconThumbUp';
import { withAnimateSwitcherHOC } from '@consta/icons/withAnimateSwitcherHOC';

import classNames from 'classnames';

import { KnowledgeDto } from '../../../generateAxios';

import { useKnowledgeActions } from '../../hooks/useKnowledgeActions.tsx';
import { divideNumIntoCategories } from '../../utils/divideNumIntoCategories.ts';

import classes from './KnowledgeLikeButton.module.css';

const AddLikeAnimateIcon = withAnimateSwitcherHOC({
  startIcon: IconThumbUp,
  endIcon: IconThumbUp,
  // endDirection: -360,
  transition: 500,
});
type KnowledgeLikeButtonVariants = 'card' | 'table' | undefined;
export const KnowledgeLikeButton = ({
  knowledge,
  variant = 'card',
  size,
}: {
  knowledge: KnowledgeDto;
  variant?: KnowledgeLikeButtonVariants;
  size?: 's' | 'xs' | 'm' | 'l' | undefined;
}) => {
  const [likeLoading, setLikeLoading] = useState<boolean>(false);

  const { changeLikeCount } = useKnowledgeActions(knowledge);

  const handlerChangeLikeCount = async () => {
    setLikeLoading(true);
    await changeLikeCount();
    setLikeLoading(false);
  };

  switch (variant) {
    case 'card':
      return (
        <AnimateIconSwitcherProvider active={!knowledge.count_like_by_user}>
          <Button
            label={divideNumIntoCategories(knowledge.count_like_all)}
            view={!!knowledge.count_like_by_user ? 'secondary' : 'clear'}
            size={size}
            form={'round'}
            iconRight={AddLikeAnimateIcon}
            className={classNames({
              [classes.likeButtonS]: size === 's',
              [classes.likeButtonXS]: size === 'xs',
              [classes.likeButtonM]: !size || size === 'm',
            })}
            onClick={handlerChangeLikeCount}
            loading={likeLoading}
          />
        </AnimateIconSwitcherProvider>
      );
    case 'table':
      return (
        <AnimateIconSwitcherProvider active={!knowledge.count_like_by_user}>
          <Button
            label={divideNumIntoCategories(knowledge.count_like_all)}
            view={!!knowledge.count_like_by_user ? 'primary' : 'secondary'}
            size={size}
            form={'round'}
            iconRight={AddLikeAnimateIcon}
            className={classNames({
              [classes.likeButtonS]: size === 's',
              [classes.likeButtonXS]: size === 'xs',
              [classes.likeButtonM]: !size || size === 'm',
              [classes.secondaryGrey]: !knowledge.count_like_by_user,
            })}
            onClick={handlerChangeLikeCount}
            loading={likeLoading}
          />
        </AnimateIconSwitcherProvider>
      );
    default:
      return null;
  }
};
