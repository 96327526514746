import { cnMixSpace } from '@consta/uikit/MixSpace';

import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../hooks/hooks.ts';
import { knowledgePageSelectors } from '../../../../../../store/reducers/knowledgePageSlice.ts';
import { selectParamByKey } from '../../../../../../store/reducers/selectors.ts';
import { knowledgeConfig } from '../../../../../configs/knowledgeConfig.ts';
import Pagination from '../../../../../shared-front/Pagination/Pagination.tsx';

export const PaginatorContainer = () => {
  const [tablePageSize] = useAppSelector(selectParamByKey(knowledgeConfig.tablePageSizeKey));
  const [tablePageNumber] = useAppSelector(selectParamByKey(knowledgeConfig.tablePageNumberKey));
  const totalElements = useAppSelector(knowledgePageSelectors.totalElements);
  const totalPages = useAppSelector(knowledgePageSelectors.totalPages);

  const [_, setSearchParams] = useSearchParams();

  const onChangePagination = (queryString: string) => {
    const [pageSizeStr, pageNumberStr] = queryString.split('&');
    const size = pageSizeStr.split('=')[1];
    const number = pageNumberStr.split('=')[1];
    setSearchParams((prev) => {
      prev.set(knowledgeConfig.tablePageSizeKey, size);
      prev.set(knowledgeConfig.tablePageNumberKey, String(+number === 0 ? 0 : +number - 1));
      return prev;
    });
  };

  return (
    <Pagination
      className={cnMixSpace({ mT: 's' })}
      onChangePagination={onChangePagination}
      currentPage={tablePageNumber ? +tablePageNumber + 1 : 1}
      lastPage={totalPages}
      currentPageSize={tablePageSize ? +tablePageSize : knowledgeConfig.tablePageSizes[0]}
      pageSizes={knowledgeConfig.tablePageSizes}
      pageSizeDefault={tablePageSize ? +tablePageSize : knowledgeConfig.tablePageSizes[0]}
      total={totalElements}
    />
  );
};
