import { useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { IconList } from '@consta/uikit/IconList';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

import { ToolBlockToolDto } from '../../../../../../../generateAxios';
import { OpenAPI } from '../../../../../../../generateAxios/core/OpenAPI.ts';
import { useAppSelector } from '../../../../../../../hooks/hooks.ts';
import { isAdminModeSelector } from '../../../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../../../appMenuAndRouting/appRoutes.tsx';
import imgSanction from '../../../../../../assets/icons/svg/icn_sanction.svg';
import imgErrorSettings from '../../../../../../assets/icons/svg/icn_settings_cross.svg';
import { Divider } from '../../../../../../components/Divider/Divider.tsx';
import { relativeToAbsoluteLink } from '../../../../../../utils/relativeToAbsoluteLink.ts';

import classes from './ToolCard.module.css';

type ContextItem = { label: string; link: string; uuid: string; group: number };

export const ToolCard = ({ tool }: { tool: ToolBlockToolDto }) => {
  const [openContext, setOpenContext] = useState<boolean>(false);
  const buttonRef = useRef(null);
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const instances = tool.instances.map((instance, index) => ({
    label: `Для блока ${instance.block}`,
    link: instance.link,
    uuid: instance.uuid_instance,
    group: index,
  }));
  const contentItems: ContextItem[] = [
    ...instances,
    {
      label: `База знаний`,
      link: relativeToAbsoluteLink(
        `${PageRoutes.DataLab_KnowledgesPage}?${isAdminMode ? 'mode=admin&' : ''}tool=${tool.tool._uuid}`
      ),
      uuid: tool.tool._uuid,
      group: instances.length,
    },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.top}>
          <div className={classes.titleContainer}>
            <div className={classes.iconContainer}>
              <img
                src={
                  tool.tool.uuid_icon
                    ? `${OpenAPI.BASE}/image/${tool.tool.uuid_icon}`
                    : imgErrorSettings
                }
                alt={tool.tool.name ?? ''}
                height={'32px'}
                width={'32px'}
                onError={(event) => {
                  event.currentTarget.src = imgErrorSettings;
                }}
              />
            </div>
            <Text size={'xl'} weight={'semibold'}>
              {tool.tool.name}
            </Text>
          </div>
          {tool.tool.soon ? (
            <Text
              size={'s'}
              view={'link'}
              as={'a'}
              target={'_blank'}
              href={tool.tool.link}
              rel={'noreferrer'}
            >
              Открыть справочник
            </Text>
          ) : (
            <div>
              <Button
                size={'s'}
                view={'secondary'}
                iconRight={IconList}
                label={'Открыть'}
                form="round"
                // для корректного закрытия контекстного меню применяю onMouseDown и stopPropagation
                // onMouseDown={(e) => {
                //   e.stopPropagation();
                //   setOpenContext(!openContext);
                // }}
                onClick={() => {
                  setOpenContext(!openContext);
                }}
                className={classes.linkButton}
              />
              <span ref={buttonRef}></span>
            </div>
          )}
        </div>
        <Divider />
        <Text view={'secondary'} size={'l'}>
          {tool.tool.description}
        </Text>
      </div>
      <div className={cnMixFlex({ flex: 'flex', justify: 'space-between' })}>
        <div className={cnMixFlex({ gap: 'xs', wrap: 'wrap' })}>
          {tool.instances.map((instance) => {
            return (
              <Tag key={instance.uuid_instance} mode={'info'} size={'s'} label={instance.block} />
            );
          })}
        </div>
        {tool.tool.sanctions && (
          <div className={cnMixFlex({ direction: 'row', gap: '2xs', wrap: 'nowrap' })}>
            <img src={imgSanction} alt={'imgsanction'} width={'24px'} height={'24px'} />
            <Text view={'secondary'}>Санкционный</Text>
          </div>
        )}
      </div>
      <ContextMenu
        isOpen={openContext}
        items={contentItems}
        getItemLabel={(item) => item.label}
        anchorRef={buttonRef}
        getItemKey={(item) => item.uuid}
        onClickOutside={() => setOpenContext(false)}
        // onClick={() => {
        //   setOpenContext(false);
        // }}
        direction={'downLeft'}
        className={classes.contextMenu}
        getItemAs={() => 'a'}
        getItemAttributes={(item) => ({ href: item.link, target: '_blank' })}
        offset={'s'}
      />
    </div>
  );
};
