import parse, { DOMNode, Element, HTMLReactParserOptions, domToReact } from 'html-react-parser';

/* убирает все теги кроме <br> */
export const parseHtml = (str: string) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.type === 'tag' && domNode.name !== 'br') {
        return <>{domToReact(domNode.children as DOMNode[], options)}</>;
      }
      return domNode;
    },
  };
  return parse(str, options);
};
