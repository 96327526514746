import { Checkbox } from '@consta/uikit/Checkbox';
import { Text } from '@consta/uikit/Text';

import { Controller, useFormContext } from 'react-hook-form';

import { CustomEditor } from '../../../../../CustomEditor/CustomEditor.tsx';
import { KnowledgeFormValues } from '../../../../KnowledgeEditor.tsx';

import classes from './KnowledgeEditorArticle.module.css';

export const KnowledgeEditorArticle = () => {
  const { control } = useFormContext<KnowledgeFormValues>();
  return (
    <div className={classes.container}>
      <Controller
        name={'wide_body'}
        control={control}
        // rules={{ required: 'Необходимое поле' }}
        render={({ field }) => {
          return (
            <div>
              <Text view={'secondary'} lineHeight={'xs'} className={classes.checkBoxLabel}>
                Режим статьи
              </Text>
              <Checkbox
                className={classes.checkBox}
                {...field}
                checked={field.value}
                label={'Расширенная версия'}
                inputRef={field.ref}
                onChange={(e) => field.onChange(e.checked)}
              />
            </div>
          );
        }}
      />
      <Controller
        name={'body'}
        control={control}
        rules={{ required: 'Необходимое поле' }}
        render={({ field, fieldState }) => {
          return (
            <CustomEditor
              label={'Текст статьи'}
              required
              onChange={(value) => field.onChange(value)}
              value={field.value}
              placeholder={'Введите текст статьи'}
              fieldState={fieldState}
              refC={field.ref}
              fullscreen
            />
          );
        }}
      />
    </div>
  );
};
