import { forwardRef } from 'react';

import { cnMixCard } from '@consta/uikit/MixCard';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { generalActions } from '../../../../../store/reducers/generalSlice.ts';
import { mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import educationImg from '../../../../assets/images/main-education.png';
import knowledgeImg from '../../../../assets/images/main-knowledge.png';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { divideNumIntoCategories } from '../../../../utils/divideNumIntoCategories.ts';

import classes from './IncreasingCompetencies.module.scss';
import { ConsultationCard } from './components/ConsultationCard.tsx';
import { IncreasingCompetenciesCard } from './components/IncreasingCompetenciesCard.tsx';

export const IncreasingCompetencies = forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useAppDispatch();
  const { KNOWLEDGE_COUNT, COURSE_LEARNED_COUNT } = useAppSelector(mainPageSelectors.metricsObject);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  return (
    <>
      <AppContentWrapper className={classes.increasingCompetencies}>
        <Text view={'brand'} size={'l'} weight={'bold'} transform={'uppercase'} ref={ref}>
          На каждом шаге
        </Text>
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xs', mB: 'm' })}>
          Повышайте компетенции
        </Text>
        <Text size={'xl'} weight={'regular'} className={cnMixCard({})}>
          {`Наши курсы и гайды научат выбирать, обрабатывать,
           анализировать и визуализировать данные`}
        </Text>
      </AppContentWrapper>
      <AppContentWrapper className={classes.increasingCompetenciesCards}>
        <IncreasingCompetenciesCard
          link={`${PageRoutes.DataLab_KnowledgesPage}${isAdminMode ? '?mode=admin' : ''}`}
          title={'База знаний'}
          description={
            'Изучите обучающие материалы и инструкции, которые помогут вам при работе в Лаборатории данных.'
          }
          value={divideNumIntoCategories(KNOWLEDGE_COUNT?.value)}
          valueDescription={KNOWLEDGE_COUNT?.presentation_name ?? ''}
          img={knowledgeImg}
        />
        <IncreasingCompetenciesCard
          action={() => {
            dispatch(generalActions.setLearningModal(true));
          }}
          title={'Обучение'}
          description={
            'Не знаете, с чего начать работу в Лаборатории данных? Записывайтесь на онлайн-курсы обучения.'
          }
          value={divideNumIntoCategories(COURSE_LEARNED_COUNT?.value)}
          valueDescription={COURSE_LEARNED_COUNT?.presentation_name ?? ''}
          img={educationImg}
          settingAction={
            isAdminMode
              ? () => {
                  dispatch(generalActions.setHelpdeskEditModal('LEARNING'));
                }
              : undefined
          }
        />
        <div className={classes.consultation} id={'consultation'}>
          <ConsultationCard />
        </div>
      </AppContentWrapper>
    </>
  );
});
