import { forwardRef, useMemo } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { IconQuestion } from '@consta/uikit/IconQuestion';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import excelIcon from '../../../../assets/icons/svg/icn_main_about_process_excel.svg';
import pixIcon from '../../../../assets/icons/svg/icn_pix.svg';
import qlikIcon from '../../../../assets/icons/svg/icn_qlik.svg';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { knowledgeConfig } from '../../../../configs/knowledgeConfig.ts';
import { linksConfig, tagHashKeysConfig } from '../../../../configs/linksConfig.ts';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';
import { validateURL } from '../../../../utils/valudateURL.ts';

import classes from './Step2.module.scss';

export const Step2 = forwardRef<HTMLDivElement>((_, ref) => {
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const tags = useAppSelector(mainPageSelectors.knowledgeTagUuids);

  // формирование ссылки для 'Читать подробнее о каждом шаге описания задачи'
  const excelLink: string | null = useMemo(() => {
    const tagUuid = tags[tagHashKeysConfig.tag_excel];
    if (!tagUuid) return null;
    return `${PageRoutes.DataLab_KnowledgesPage}?${knowledgeConfig.filterTagsQueryKey}=${tagUuid}${isAdminMode ? '&mode=admin' : ''}`;
  }, [tags, isAdminMode]);

  const marketLinks = useMemo(
    () => [
      {
        name: 'Магазин данных',
        link: linksConfig.new_market,
        icon: pixIcon,
        disabled: !linksConfig.new_market,
      },
      {
        name: 'Магазин данных БЛПС',
        link: linksConfig.old_blps_market,
        icon: qlikIcon,
      },
      {
        name: 'Аналоговый Магазин данных БРД',
        link: linksConfig.old_brd_analog_market,
        icon: qlikIcon,
      },
    ],
    []
  );
  return (
    <AppContentWrapper className={classes.step2}>
      <div className={classes.step2Image}></div>
      <div>
        <Text view={'brand'} size={'l'} weight={'bold'} transform={'uppercase'} ref={ref}>
          Шаг №2
        </Text>
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xs', mB: 'm' })}>
          Определите источники данных
        </Text>
        <Text size={'xl'} weight={'regular'}>
          {`Для этого используйте Магазин данных — единое окно
          доступа к данным из информационных систем`}
        </Text>
        <div className={classes.marketsLinksGroup}>
          {marketLinks.map((el) => (
            <div className={classes.marketLink} key={el.name}>
              <div className={cnMixFlex({ gap: 'm', align: 'center' })}>
                <img src={el.icon} alt={''} />
                <Text size={'l'} weight={'semibold'}>
                  {el.name}
                </Text>
              </div>
              {el.disabled ? (
                <Button
                  size={'s'}
                  form={'round'}
                  label={'Скоро появится'}
                  className={classes.marketLinkButton}
                  disabled
                />
              ) : (
                <Button
                  as={'a'}
                  href={el.link}
                  size={'s'}
                  form={'round'}
                  label={'Перейти'}
                  onClick={(event) => {
                    event.preventDefault();
                    validateURL(el.link) && window.open(el.link, '_blank');
                  }}
                  className={classes.marketLinkButton}
                />
              )}
            </div>
          ))}
          <Text view={'secondary'} size={'l'} className={cnMixSpace({ mT: 'm' })}>
            {`Здесь публикуются наборы данных и приложения,
               доступные вам для использования при решении
               аналитической задачи`}
          </Text>
          <div className={classNames(cnMixFlex({ gap: 'xs' }), cnMixSpace({ mT: '2xl' }))}>
            <IconQuestion view={'link'} size={'m'} />
            <div>
              <Text view={'secondary'} size={'s'}>
                Обратитесь к нам, если не нашли подходящий набор данных
              </Text>
              <Button
                className={cnMixSpace({ pL: '3xs' })}
                view={'clear'}
                size="s"
                label={'Скоро появится'}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.excelBlock}>
        <div className={cnMixFlex({ gap: 'xl' })}>
          <div className={classes.iconWrapper}>
            <img src={excelIcon} alt="" />
          </div>
          <Text>
            {`Также для работы вы можете использовать данные из Excel-файлов, которые хранятся
                на вашем компьютере или сетевом ресурсе.`}
          </Text>
        </div>
        {excelLink ? (
          <Button
            as={'a'}
            href={relativeToAbsoluteLink(excelLink)}
            view={'clear'}
            form={'round'}
            onClick={(event) => {
              event.preventDefault();
              navigate(excelLink);
            }}
            iconRight={IconForward}
            label={'Подробнее'}
          />
        ) : (
          <Button
            view={'clear'}
            form={'round'}
            iconRight={IconForward}
            label={'Подробнее'}
            disabled
          />
        )}
      </div>
    </AppContentWrapper>
  );
});
