export const getVideoThumbnailAsBase64 = (
  videoFile: File,
  maxWidth: number = 100,
  maxHeight: number = 50
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      reject(new Error('Canvas context is not available'));
      return;
    }

    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      video.currentTime = 1; // Устанавливаем время на 1 секунду для захвата кадра
    };

    video.onseeked = () => {
      const originalWidth = video.videoWidth;
      const originalHeight = video.videoHeight;

      // Вычисляем новые размеры с сохранением пропорций
      let newWidth = originalWidth;
      let newHeight = originalHeight;

      if (originalWidth > maxWidth || originalHeight > maxHeight) {
        const widthRatio = maxWidth / originalWidth;
        const heightRatio = maxHeight / originalHeight;
        const minRatio = Math.min(widthRatio, heightRatio);

        newWidth = originalWidth * minRatio;
        newHeight = originalHeight * minRatio;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
      context.drawImage(video, 0, 0, newWidth, newHeight);

      const base64String = canvas.toDataURL('image/png');
      resolve(base64String);
    };

    video.onerror = (event) => {
      reject(new Error(`Failed to load video: ${event}`));
    };

    video.src = URL.createObjectURL(videoFile);
  });
};

// Пример использования:
// const videoFile = /* Ваш видео файл */
// getVideoThumbnailAsBase64(videoFile, 50, 100)
//   .then((resizedBase64) => {
//     console.log('Resized Base64 string:', resizedBase64);
//     // Теперь вы можете использовать уменьшенное изображение
//   })
//   .catch((error) => {
//     console.error('Error generating and resizing thumbnail:', error);
//   });
