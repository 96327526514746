import { RefObject, useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { Tooltip } from '@consta/uikit/Tooltip';

import { IconCopy } from '@consta/icons/IconCopy';

import { useNavigate } from 'react-router-dom';

import { LinkKnowledgeDto } from '../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks.ts';
import {
  knowledgePageActions,
  knowledgePageSelectors,
} from '../../../../store/reducers/knowledgePageSlice.ts';
import { isAdminModeSelector } from '../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../appMenuAndRouting/appRoutes.tsx';

import { AppModal } from '../../AppModal/AppModal.tsx';
import { Divider } from '../../Divider/Divider.tsx';

import classes from './KnowledgeLinksModal.module.css';

export const KnowledgeLinksModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const linksModal = useAppSelector(knowledgePageSelectors.linksModal);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  const close = () => {
    dispatch(
      knowledgePageActions.setLinksModal({
        isOpen: false,
        knowledge: linksModal.knowledge,
      })
    );
  };

  const afterClose = () => {
    dispatch(
      knowledgePageActions.setLinksModal({
        isOpen: false,
        knowledge: null,
      })
    );
  };

  return (
    <AppModal
      isOpen={linksModal.isOpen}
      close={close}
      afterClose={afterClose}
      className={classes.modal}
    >
      <Text
        size={'xl'}
        weight={'semibold'}
        className={cnMixSpace({ pL: '2xl', pR: '4xl', pT: 'l', pB: 'l' })}
      >
        Ссылки
      </Text>
      <Divider />
      <div className={classes.content}>
        {linksModal.knowledge?.links?.map((link, index) => (
          <LinkWithCopy link={link} key={index} />
        ))}
      </div>
      <Divider />
      <div className={classes.buttonContainer}>
        <Button
          label={'Открыть материал'}
          className={classes.button}
          onClick={() => {
            close();
            navigate(
              `${PageRoutes.DataLab_KnowledgesPage}/${linksModal.knowledge?._uuid}${isAdminMode ? '?mode=admin' : ''}`
            );
          }}
        />
      </div>
    </AppModal>
  );
};

const LinkWithCopy = ({ link }: { link: LinkKnowledgeDto }) => {
  const nameRef = useRef<HTMLDivElement>(null);
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const [anchorRef, setAnchorRef] = useState<RefObject<HTMLDivElement> | undefined>(undefined);
  const copyLink = async () => {
    if (link.link) {
      await navigator.clipboard.writeText(link.link);
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 1000);
    }
  };
  return (
    <div className={cnMixFlex({ direction: 'column', gap: 'xs' })}>
      <Text
        ref={nameRef}
        view={'ghost'}
        className={classes.linkDescription}
        onMouseEnter={(e) => {
          if (nameRef.current && nameRef.current?.scrollHeight > nameRef.current?.clientHeight) {
            e.currentTarget.setAttribute(
              'style',
              nameRef.current && nameRef.current?.scrollHeight > nameRef.current?.clientHeight
                ? 'cursor: pointer;'
                : ''
            );
            setAnchorRef(nameRef);
          }
        }}
        onMouseLeave={() => {
          setAnchorRef(undefined);
        }}
      >
        {link.description}
      </Text>
      {anchorRef && (
        <Tooltip anchorRef={anchorRef} style={{ zIndex: 1001 }} className={classes.tooltip}>
          {link.description}
        </Tooltip>
      )}
      <div className={classes.linkContainer}>
        <Text as={'a'} href={link.link} target={'_blank'} truncate>
          {link.link}
        </Text>
        <Button
          onlyIcon
          size={'xs'}
          iconLeft={() => <IconCopy view={isLinkCopied ? 'success' : 'ghost'} size={'s'} />}
          view={'clear'}
          onClick={() => {
            copyLink();
          }}
        />
      </div>
    </div>
  );
};
