import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Loader } from '@consta/uikit/Loader';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { Table, TableColumn } from '@consta/uikit/Table';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

import { IconConnection } from '@consta/icons/IconConnection';
import { IconDocFilled } from '@consta/icons/IconDocFilled';
import { IconPlay } from '@consta/icons/IconPlay';
import { IconTopRight } from '@consta/icons/IconTopRight';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { KnowledgeDto } from '../../../../../generateAxios';
import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import {
  knowledgePageActions,
  knowledgePageSelectors,
} from '../../../../../store/reducers/knowledgePageSlice.ts';
import { isAdminModeSelector, selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import imgErrorSettings from '../../../../assets/icons/svg/icn_settings_cross.svg';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { ComplexityTag } from '../../../../components/ComplexityTag/ComplexityTag.tsx';
import { KnowledgeLikeButton } from '../../../../components/KnowledgeLikeButton/KnowledgeLikeButton.tsx';
import { KnowledgeDeleteModal } from '../../../../components/Modals/KnowledgeDeleteModal/KnowledgeDeleteModal.tsx';
import { knowledgeConfig } from '../../../../configs/knowledgeConfig.ts';
import { useKnowledgeActions } from '../../../../hooks/useKnowledgeActions.tsx';
import CustomHoverToolTip from '../../../../shared-front/CustomHoverToolTip/CustomHoverToolTip.tsx';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';
import { validateURL } from '../../../../utils/valudateURL.ts';

import classes from './KnowledgeTable.module.css';
import { PaginatorContainer } from './PaginationContainer/PaginationContainer.tsx';

type KnowledgeTableRow = KnowledgeDto & {
  id: string;
};

export const KnowledgeTable = () => {
  const dispatch = useAppDispatch();
  const [searchValue] = useAppSelector(selectParamByKey(knowledgeConfig.searchQueryKey));
  const filterBlocks = useAppSelector(selectParamByKey(knowledgeConfig.filterBlockQueryKey));
  const filterTypes = useAppSelector(selectParamByKey(knowledgeConfig.filterTypeQueryKey));
  const filterTools = useAppSelector(selectParamByKey(knowledgeConfig.filterToolQueryKey));
  const filterTags = useAppSelector(selectParamByKey(knowledgeConfig.filterTagsQueryKey));
  const [tablePageSize] = useAppSelector(selectParamByKey(knowledgeConfig.tablePageSizeKey));
  const [tablePageNumber] = useAppSelector(selectParamByKey(knowledgeConfig.tablePageNumberKey));
  const filterComplexity = useAppSelector(
    selectParamByKey(knowledgeConfig.filterComplexityQueryKey)
  );
  const filterAuthors = useAppSelector(selectParamByKey(knowledgeConfig.filterAuthorQueryKey));
  const [sortValue] = useAppSelector(selectParamByKey(knowledgeConfig.sortQueryKey));
  const pageLoader = useAppSelector(knowledgePageSelectors.pageLoader);
  const knowledgeItems = useAppSelector(knowledgePageSelectors.knowledgeItems);

  const data: KnowledgeTableRow[] = useMemo(
    () =>
      knowledgeItems?.map((item) => ({
        ...item,
        id: item._uuid,
        date: item.date
          ? dayjs(item.date).isValid()
            ? dayjs(item.date).format('DD.MM.YYYY')
            : 'не валидная дата'
          : 'нет даты',
      })) ?? [],
    [knowledgeItems]
  );

  useEffect(() => {
    const promise = dispatch(knowledgePageActions.getKnowledgeItems({}));
    return () => {
      promise.abort();
    };
  }, [
    searchValue,
    tablePageNumber,
    tablePageSize,
    filterTools,
    filterBlocks,
    filterComplexity,
    sortValue,
    filterTags,
    filterTypes,
    filterAuthors,
  ]);

  const columns: TableColumn<KnowledgeTableRow>[] = [
    {
      accessor: 'id',
      title: 'Действия',
      width: 136,
      renderCell: (row) => {
        return <KnowledgeTableActions row={row} />;
      },
    },
    {
      accessor: 'subject',
      title: 'Название',
      width: 254,
      renderCell: (row) => <KnowledgeTableSubject subject={row.subject} />,
    },
    {
      accessor: 'knowledge_type',
      title: 'Тип материала',
      width: 104,
      renderCell: (row) => {
        switch (row.knowledge_type) {
          case 'VIDEO':
            return 'Видео';
          case 'INTERNAL':
            return 'Статья';
          case 'LINK':
            return 'Ссылка';
          case 'FILE':
            return 'Файл';
          default:
            return 'Неизвестно';
        }
      },
    },
    {
      accessor: 'blocks',
      title: 'Блок',
      width: 162,
      renderCell: (row) => {
        return (
          <div className={cnMixFlex({ gap: 'xs' })}>
            {row.blocks?.map((block) => (
              <Tag
                label={block.short_name}
                key={block._uuid}
                mode={'info'}
                size={'s'}
                className={classes.tag}
              />
            ))}
          </div>
        );
      },
    },
    {
      accessor: 'complexity',
      title: 'Сложность',
      width: 156,
      renderCell: (row) => {
        return (
          <ComplexityTag
            level={row.complexity?.level ?? 0}
            name={row.complexity?.name ?? ''}
            view={'primary'}
          />
        );
      },
    },
    {
      accessor: 'tool',
      title: 'Инструмент',
      width: 182,
      renderCell: (row) => {
        const toolImgSrc = row.tool?.uuid_icon
          ? `${OpenAPI.BASE}/image/${row.tool.uuid_icon}`
          : imgErrorSettings;
        return (
          <div className={cnMixFlex({ gap: 'xs', wrap: 'nowrap', align: 'center' })}>
            <img src={toolImgSrc} alt={''} width={24} height={24} />
            <Text size={'s'}>{row.tool?.name}</Text>
          </div>
        );
      },
    },
    {
      accessor: 'count_like_all',
      title: 'Количество лайков',
      width: 96,
      renderCell: (row) => {
        return <KnowledgeLikeButton knowledge={row} size={'xs'} variant={'table'} />;
      },
    },
    {
      accessor: 'date',
      title: 'Дата публикации',
      width: 110,
    },
  ];

  // const adminColumns = [...userColumns].map((col) => {
  //   switch (col.title) {
  //     case 'Действия':
  //       return { ...col, width: 140, withoutPadding: true };
  //     case 'Название':
  //       return { ...col, width: 120 };
  //     default:
  //       return { ...col };
  //   }
  // });

  return (
    <AppContentWrapper className={classes.container}>
      <div className={classes.tableWrapper}>
        <Table
          columns={columns}
          rows={data}
          size={'s'}
          getCellWrap={() => 'break'}
          borderBetweenRows={true}
          borderBetweenColumns={true}
          className={classes.table}
          verticalAlign={'top'}
          stickyHeader
          emptyRowsPlaceholder={
            pageLoader ||
            (tablePageNumber && tablePageNumber !== '0'
              ? 'на этой странице данных нет'
              : 'Нет данных')
          }
        />
        {pageLoader && <Loader className={classes.loader} />}
      </div>

      <PaginatorContainer />
    </AppContentWrapper>
  );
};

const KnowledgeTableActions = ({ row }: { row: KnowledgeTableRow }) => {
  const dispatch = useAppDispatch();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const {
    openVideoModal,
    openLinksModal,
    downloadFile,
    downloadZip,
    navigateToEdit,
    deleteKnowledge,
    loadingDelete,
  } = useKnowledgeActions(row);
  const navigate = useNavigate();
  const hrefKnowledgeItem = `${PageRoutes.DataLab_KnowledgesPage}/${row._uuid}${isAdminMode ? '?mode=admin' : ''}`;
  const handlerDeleteKnowledge = async () => {
    await deleteKnowledge(() => {
      dispatch(knowledgePageActions.getKnowledgeItems({}));
      dispatch(knowledgePageActions.getKnowledgeMetrics());
    });
    setDeleteModal(false);
  };
  return (
    <div className={cnMixFlex({ gap: 'xs', wrap: 'nowrap' })}>
      <CustomHoverToolTip title={'Подробнее'}>
        <Button
          view={'ghost'}
          size={'xs'}
          iconSize={'s'}
          iconRight={IconTopRight}
          onlyIcon
          as={'a'}
          href={relativeToAbsoluteLink(hrefKnowledgeItem)}
          onClick={(e) => {
            e.preventDefault();
            navigate(hrefKnowledgeItem);
          }}
        />
      </CustomHoverToolTip>
      {row.knowledge_type === 'VIDEO' ? (
        <CustomHoverToolTip title={'Открыть видео'}>
          <Button
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            iconRight={IconPlay}
            onlyIcon
            onClick={openVideoModal}
          />
        </CustomHoverToolTip>
      ) : row.knowledge_type === 'LINK' && row.links && row.links.length !== 0 ? (
        row.links.length === 1 ? (
          <CustomHoverToolTip title={'Открыть'}>
            <Button
              size={'xs'}
              view={'ghost'}
              iconSize={'s'}
              iconRight={IconConnection}
              onlyIcon
              as={'a'}
              href={row.links[0].link}
              onClick={(event) => {
                event.preventDefault();
                validateURL(row.links?.[0].link) && window.open(row.links?.[0].link, '_blank');
              }}
            />
          </CustomHoverToolTip>
        ) : (
          <CustomHoverToolTip title={`Ссылки (${row.links?.length})`}>
            <Button
              size={'xs'}
              view={'ghost'}
              iconSize={'s'}
              iconRight={IconConnection}
              onlyIcon
              onClick={openLinksModal}
            />
          </CustomHoverToolTip>
        )
      ) : row.knowledge_type === 'FILE' ? (
        <CustomHoverToolTip title={'Скачать'}>
          <Button
            view={'ghost'}
            size={'xs'}
            iconSize={'s'}
            iconRight={IconDocFilled}
            onlyIcon
            onClick={row.files && row.files.length > 1 ? downloadZip : downloadFile}
          />
        </CustomHoverToolTip>
      ) : (
        // заглушка для пустого места
        <Button
          view={'ghost'}
          size={'xs'}
          iconSize={'s'}
          iconRight={IconDocFilled}
          onlyIcon
          style={{ visibility: 'hidden' }}
        />
      )}

      {isAdminMode && (
        <>
          <CustomHoverToolTip title={'Редактировать'}>
            <Button
              view={'ghost'}
              size={'xs'}
              iconSize={'s'}
              iconRight={IconEdit}
              onlyIcon
              disabled={row.read_only}
              onClick={() => navigateToEdit()}
            />
          </CustomHoverToolTip>
          <CustomHoverToolTip title={'Удалить'}>
            <Button
              view={'ghost'}
              size={'xs'}
              iconSize={'s'}
              iconRight={IconTrash}
              onlyIcon
              disabled={row.read_only}
              onClick={() => {
                setDeleteModal(true);
                // dispatch(knowledgePageActions.setPageLoader(true));
                // await deleteKnowledge(() => dispatch(knowledgePageActions.getKnowledgeItems({})));
                // dispatch(knowledgePageActions.setPageLoader(false));
              }}
            />
          </CustomHoverToolTip>
        </>
      )}
      <KnowledgeDeleteModal
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={handlerDeleteKnowledge}
        name={row?.subject}
        loading={loadingDelete}
      />
    </div>
  );
};

const KnowledgeTableSubject = ({ subject }: { subject: string }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  return (
    <Text
      ref={ref}
      size={'s'}
      className={classNames({
        [classes.subjectCollapsed]: isCollapsed,
      })}
      onClick={() => setIsCollapsed(!isCollapsed)}
      onMouseEnter={(e) => {
        e.currentTarget.setAttribute(
          'style',
          ref.current && ref.current?.scrollHeight > ref.current?.clientHeight
            ? 'cursor: pointer;'
            : ''
        );
      }}
    >
      {subject}
    </Text>
  );
};
