import { Text, TextPropSize, TextPropWeight } from '@consta/uikit/Text';

import imgErrorSettings from '../../assets/icons/svg/icn_settings_cross.svg';

import classes from './ToolWithIcon.module.css';

export const ToolWithIcon = ({
  imgSrc,
  text,
  weight,
  size,
}: {
  imgSrc: string;
  text: string;
  weight?: TextPropWeight;
  size?: TextPropSize;
}) => {
  return (
    <div className={classes.iconWithText}>
      <img
        src={imgSrc}
        onError={(event) => {
          event.currentTarget.src = imgErrorSettings;
        }}
        width={'24px'}
        height={'24px'}
        alt={'иконка'}
      />
      <Text view={'secondary'} className={classes.toolText} weight={weight} size={size}>
        {text}
      </Text>
    </div>
  );
};
