import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { KnowledgeDto } from '../../../../../generateAxios';

export const KnowledgeDeleteButton = ({
  knowledge,
  onClick,
}: {
  knowledge: KnowledgeDto;
  onClick: () => void;
}) => {
  return (
    <Button
      view={'secondary'}
      label={'Удалить'}
      className={cnMixSpace({ pR: 'm', pL: 'm' })}
      onClick={onClick}
      disabled={knowledge.read_only}
    />
  );
};
