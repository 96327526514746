import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

export const PortfolioEditorTitle = ({ title }: { title?: string }) => {
  return (
    <div>
      <Text size={'xl'} weight={'semibold'}>
        {title ?? 'Добавление кейса'}
      </Text>
      {!title && (
        <Text view={'secondary'} className={cnMixSpace({ mT: 'm' })}>
          Поделитесь вашим приложением, созданным в Лаборатории данных. Для этого заполните поля
          ниже и отправьте заявку на модерацию. После успешной модерации кейс будет опубликован в
          Портфолио.
        </Text>
      )}
    </div>
  );
};
