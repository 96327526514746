export const knowledgeConfig = {
  pageSize: 6,
  tablePageSizes: [10, 20, 30],
  filterToolQueryKey: 'tool',
  filterBlockQueryKey: 'block',
  filterTypeQueryKey: 'type',
  filterTagsQueryKey: 'tag',
  filterComplexityQueryKey: 'complexity',
  filterAuthorQueryKey: 'author',
  sortQueryKey: 'sort',
  searchQueryKey: 'search',
  viewQueryKey: 'view',
  viewLocalStorageKey: 'knowledgeView',
  tablePageSizeKey: 'pageSize',
  tablePageNumberKey: 'pageNumber',
  knowledgeLinkDescriptionMaxLength: 255,
  // statusPortfolioQueryKey: 'status',
  showTotalCountInFilters: true,
  // Регулярное выражение для проверки валидных ссылок
  // urlRegex: /^https?:\/\/([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
  // urlRegex: /^https?:\/\/([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*(&amp;|[\w@?^=%&/~+#-]))?$/,
  urlRegex: /^https?:\/\//,
};
