import { SVGProps } from 'react';

import { createIcon } from '@consta/icons/Icon';

const svg = ({ ...props }: SVGProps<any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="16"
    // height="16"
    viewBox="0 0 16 16"
    // fill="none"
  >
    <path
      d="M8.66667 14V12.6667H10V14H8.66667ZM7.33333 12.6667V9.33333H8.66667V12.6667H7.33333ZM12.6667 10.6667V8H14V10.6667H12.6667ZM11.3333 8V6.66667H12.6667V8H11.3333ZM3.33333 9.33333V8H4.66667V9.33333H3.33333ZM2 8V6.66667H3.33333V8H2ZM8 3.33333V2H9.33333V3.33333H8ZM3 5H5V3H3V5ZM2 6V2H6V6H2ZM3 13H5V11H3V13ZM2 14V10H6V14H2ZM11 5H13V3H11V5ZM10 6V2H14V6H10ZM11.3333 14V12H10V10.6667H12.6667V12.6667H14V14H11.3333ZM8.66667 9.33333V8H11.3333V9.33333H8.66667ZM6 9.33333V8H4.66667V6.66667H8.66667V8H7.33333V9.33333H6ZM6.66667 6V3.33333H8V4.66667H9.33333V6H6.66667ZM3.5 4.5V3.5H4.5V4.5H3.5ZM3.5 12.5V11.5H4.5V12.5H3.5ZM11.5 4.5V3.5H12.5V4.5H11.5Z"
      // fill="current"
      // fill="#00395C"
      // fill-opacity="0.8"
      {...props}
    />
  </svg>
);

export const IconQr = createIcon({
  l: svg,
  m: svg,
  s: svg,
  xs: svg,
  name: 'IconQr',
  color: 'mono',
});
