import { ReactNode, useEffect, useState } from 'react';

import { Sidebar } from '@consta/uikit/Sidebar';

import classes from './SidebarWithResize.module.css';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  hasOverlay?: boolean;
  onClickOutside?: (event: MouseEvent) => void;
  onEsc?: (event: KeyboardEvent) => void;
  rootClassName?: string;
  children?: ReactNode;
  container?: HTMLDivElement | undefined;
  afterClose?: () => void;
  className?: string;
};
const sideBarMinWidth = 300;
const sideBarQueryKey = 'sideBarWidth';
const sideBarDefaultWidth = 640;

export const SidebarWithResize = (props: Props) => {
  const [width, setWidth] = useState(() => {
    const savedWidth = localStorage.getItem(sideBarQueryKey);
    return savedWidth ? parseInt(savedWidth, 10) : sideBarDefaultWidth;
  }); // начальная ширина сайдбара

  const [isResizableNow, setIsResizableNow] = useState<boolean>(false);

  useEffect(() => {
    const stopResize = () => {
      setIsResizableNow(false);
      document.body.style.cursor = ''; // Восстанавливаем стандартный курсор
      document.body.style.userSelect = ''; // Восстанавливаем возможность выделения
    };
    const changeSize = (e: MouseEvent) => {
      const newWidth = window.innerWidth - e.clientX;
      setWidth(newWidth > sideBarMinWidth ? newWidth : sideBarMinWidth);
      localStorage.setItem(sideBarQueryKey, newWidth.toString());
    };
    if (isResizableNow) {
      document.addEventListener('mouseup', stopResize);
      document.addEventListener('mousemove', changeSize);
      document.body.style.cursor = 'ew-resize'; // Меняем курсор на ресайз
      document.body.style.userSelect = 'none'; // Отключаем выделение текста
    }
    return () => {
      document.removeEventListener('mouseup', stopResize);
      document.removeEventListener('mousemove', changeSize);
    };
  }, [isResizableNow]);

  const handleMouseDown = () => {
    setIsResizableNow(true);
  };

  return (
    <Sidebar
      {...props}
      style={{
        width: width,
      }}
    >
      <div className={classes.resizer} onMouseDown={handleMouseDown} />
      {props.children}
    </Sidebar>
  );
};
