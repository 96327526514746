import { PortfolioDto } from '../../../generateAxios';

import { AppContentWrapper } from '../AppContentWrapper/AppContentWrapper.tsx';

import classes from './PortfolioPreview.module.css';
import { PortfolioDateAndAuthor } from './components/PortfolioDateAndAuthor/PortfolioDateAndAuthor.tsx';
import { PortfolioDomains } from './components/PortfolioDomains/PortfolioDomains.tsx';
import { PortfolioFeedbacks } from './components/PortfolioFeedbacks/PortfolioFeedbacks.tsx';
import { PortfolioImgSlider } from './components/PortfolioImgSlider/PortfolioImgSlider.tsx';
import { PortfolioOrganisation } from './components/PortfolioOrganisation/PortfolioOrganisation.tsx';
import { PortfolioOrganisationWithTool } from './components/PortfolioOrganisationWithTool/PortfolioOrganisationWithTool.tsx';
import { PortfolioTextBlock } from './components/PortfolioTextBlock/PortfolioTextBlock.tsx';
import { PortfolioTitle } from './components/PortfolioTitle/PortfolioTitle.tsx';

export const PortfolioPreview = ({
  portfolio,
  withTool = false,
}: {
  portfolio: PortfolioDto;
  withTool?: boolean;
}) => {
  return (
    <>
      <AppContentWrapper width={'wide'} className={classes.portfolioInfoContainer}>
        <PortfolioTitle portfolio={portfolio} />

        {withTool ? (
          <PortfolioOrganisationWithTool portfolio={portfolio} />
        ) : (
          <PortfolioOrganisation portfolio={portfolio} />
        )}
        <PortfolioDomains portfolio={portfolio} />
        <PortfolioDateAndAuthor portfolio={portfolio} />
        <PortfolioImgSlider images={portfolio.images ?? []} />
        <PortfolioTextBlock title={'Описание'} description={portfolio.description} />
        <PortfolioTextBlock title={'Цели и задачи'} description={portfolio.goals} />
        <PortfolioTextBlock title={'Результаты'} description={portfolio.results} />
        <PortfolioTextBlock title={'Пользователи'} description={portfolio.users} />
        <PortfolioTextBlock title={'Планы на развитие'} description={portfolio.plan} />
      </AppContentWrapper>
      {portfolio.feedbacks && <PortfolioFeedbacks feedbacks={portfolio.feedbacks} />}
    </>
  );
};
