import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utcPlugin from 'dayjs/plugin/utc';

// use 'ru' location in all App
export const acceptDayjsConfig = () => {
  // dayjs.extend(MinMax);
  dayjs.locale('ru');
  dayjs.extend(updateLocale);
  dayjs.extend(utcPlugin);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Europe/Moscow');
  dayjs.updateLocale('ru', {
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  });
};
