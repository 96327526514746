import { IconWorldFilled } from '@consta/uikit/IconWorldFilled';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { PortfolioDto } from '../../../../../../../generateAxios';
import { OpenAPI } from '../../../../../../../generateAxios/core/OpenAPI.ts';
import { useAppSelector } from '../../../../../../../hooks/hooks.ts';
import { isAdminModeSelector } from '../../../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../../../appMenuAndRouting/appRoutes.tsx';
import imgErrorSettings from '../../../../../../assets/icons/svg/icn_settings_cross.svg';
import CustomHoverToolTip from '../../../../../../shared-front/CustomHoverToolTip/CustomHoverToolTip.tsx';
import { getMimeType } from '../../../../../../utils/getMimeType.ts';
import { parseHtml } from '../../../../../../utils/parseHtml.tsx';
import { relativeToAbsoluteLink } from '../../../../../../utils/relativeToAbsoluteLink.ts';

import classes from './PortfolioCard.module.css';

export const PortfolioCard = ({
  portfolio,
  isCurrent,
}: {
  portfolio: PortfolioDto;
  isCurrent: boolean;
}) => {
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const url = `${PageRoutes.DataLab_PortfolioPage}/${portfolio._uuid}${isAdminMode ? '?mode=admin' : ''}`;
  const publicDate = !portfolio.public_date
    ? 'нет даты'
    : dayjs(portfolio.public_date).isValid()
      ? dayjs(portfolio.public_date).format('DD MMM YYYY')
      : 'некорректная дата';

  // вариант для получения картинки по uuid
  // const screenUuid =
  //   portfolio.images?.find((image) => image.is_main)?.uuid_image ??
  //   portfolio.images?.[0].uuid_image;

  // вариант для получения картинки из base64
  let imgBase64src: string | undefined = undefined;
  let imgBase64 = portfolio.images?.find((image) => image.is_main)?.image;
  if (imgBase64) {
    const mimeType = getMimeType(imgBase64);
    imgBase64src = `data:${mimeType};base64,${imgBase64.replace(/\s+/g, '')}`;
  }

  return (
    <a
      href={relativeToAbsoluteLink(url)}
      onClick={(e) => {
        e.preventDefault();
        navigate(url);
      }}
      className={classes.link}
    >
      <div className={classes.container}>
        <div className={classNames({ [classes.header]: true, [classes.hidden]: !isCurrent })}>
          <div className={classes.logo}>
            <img
              src={
                portfolio.tool.uuid_icon
                  ? `${OpenAPI.BASE}/image/${portfolio.tool.uuid_icon}`
                  : imgErrorSettings
              }
              onError={(event) => {
                event.currentTarget.src = imgErrorSettings;
              }}
              width={'48px'}
              height={'48px'}
              alt={'иконка'}
            />
          </div>
          <div className={classes.screen}>
            <img
              src={imgBase64src ? imgBase64src : imgErrorSettings}
              onError={(event) => {
                event.currentTarget.src = imgErrorSettings;
              }}
              width={'100%'}
              height={'100%'}
              alt={'скриншот'}
            />
          </div>
        </div>

        <div className={classes.content}>
          <div className={classes.contentTitle}>
            <div className={classes.tags}>
              <Tag
                mode={'info'}
                size={'s'}
                label={portfolio.block.short_name}
                className={classes.blockTag}
              />
              {portfolio.domains && portfolio.domains.length > 1 && (
                <CustomHoverToolTip title={portfolio.domains.map((el) => el.name).join('; ')}>
                  <Tag mode={'info'} size={'s'} icon={IconWorldFilled} label={'Домены...'} />
                </CustomHoverToolTip>
              )}
              {portfolio.domains && portfolio.domains.length === 1 && (
                <CustomHoverToolTip title={portfolio.domains[0].name}>
                  <Tag
                    mode={'info'}
                    size={'s'}
                    label={portfolio.domains[0].name}
                    className={classes.domainTag}
                  />
                </CustomHoverToolTip>
              )}
            </div>
            <Text size={'xl'} weight={'semibold'} className={classes.title}>
              {portfolio.name}
            </Text>
            <Text className={classes.description}>
              {portfolio.description && parseHtml(portfolio.description)}
              {/*{portfolio.description?.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '')}*/}
            </Text>
          </div>
          <div className={classes.footer}>
            <Text view={'secondary'}>{portfolio.tool.name}</Text>
            <Text view={'secondary'}>{publicDate}</Text>
          </div>
        </div>
      </div>
    </a>
  );
};
