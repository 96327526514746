import React from 'react';

import { indicatorConfig } from '../../configs/indicatorColorConfig.ts';

export const AppIndicator = () => {
  if (indicatorConfig.color && typeof indicatorConfig.color === 'string')
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '60px',
          zIndex: 1000,
          backgroundColor: indicatorConfig.color,
          opacity: 0.4,
          pointerEvents: 'none',
        }}
      ></div>
    );
  else return null;
};
