import { Text } from '@consta/uikit/Text';

import dayjs from 'dayjs';

import { PortfolioDto } from '../../../../../generateAxios';

import classes from './PortfolioDateAndAuthor.module.css';

export const PortfolioDateAndAuthor = ({ portfolio }: { portfolio: PortfolioDto }) => {
  const formattedDate = portfolio.public_date
    ? dayjs(portfolio.public_date).isValid()
      ? dayjs(portfolio.public_date).format('DD MMMM YYYY')
      : 'ошибка при чтении даты'
    : 'нет даты';

  return (
    <div className={classes.container}>
      <Text view={'secondary'}>{formattedDate}</Text>
      <Text view={'secondary'} weight={'medium'}>
        {portfolio.user?.display_name || 'Автор не указан'}
      </Text>
    </div>
  );
};
