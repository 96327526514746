import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { IconShare } from '@consta/icons/IconShare';

import { KnowledgeDto } from '../../../../../generateAxios';

export const KnowledgeShareButton = ({ knowledge }: { knowledge: KnowledgeDto }) => {
  const link = window.location.href.split('?')[0];
  const linkToCase = `mailto:?Subject=${knowledge?.subject}&body=Добрый день!%0d%0a%0d%0a Ссылка на материал Базы знаний:%0d%0a ${link}`;
  return (
    <Button
      label={'Поделиться'}
      iconLeft={IconShare}
      className={cnMixSpace({ pL: 'm', pR: 'l' })}
      onClick={() => {
        window.open(linkToCase, '_blank');
      }}
    />
  );
};
