import { Text } from '@consta/uikit/Text';

import classes from './ProcessStepIcon.module.css';

type Props = {
  stepValue: string;
  imgSrc: string;
};

export const ProcessStepIcon = ({ stepValue, imgSrc }: Props) => {
  return (
    <div className={classes.container}>
      <Text size={'s'} weight={'semibold'} className={classes.step}>
        {stepValue}
      </Text>
      <img src={imgSrc} alt={''} width={'48px'} height={'48px'} />
    </div>
  );
};
