import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconSortUp } from '@consta/uikit/IconSortUp';

import { FiltersKnowledgeDto } from '../../../../../generateAxios';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { SearchInput } from '../../../../components/SearchInput/SearchInput.tsx';
import { CustomSortItem, SortSelect } from '../../../../components/SortSelect/SortSelect.tsx';
import { knowledgeConfig } from '../../../../configs/knowledgeConfig.ts';

import classes from './KnowledgeFilters.module.css';
import { ButtonAdd } from './components/ButtonAdd/ButtonAdd.tsx';
import { ButtonFilters } from './components/ButtonFilters/ButtonFilters.tsx';
import { KnowledgeFilterBar } from './components/KnowledgeFilterBar/KnowledgeFilterBar.tsx';
import { SwitcherView } from './components/SwitcherView/SwitcherView.tsx';

type KnowledgeSortItem = CustomSortItem & {
  value: NonNullable<FiltersKnowledgeDto['order']>;
};

const items: KnowledgeSortItem[] = [
  { id: 1, label: 'Сначала новые', icon: IconSortDown, value: 'KNOWLEDGE_DATE_DESC' },
  { id: 2, label: 'Сначала старые', icon: IconSortUp, value: 'KNOWLEDGE_DATE_ASC' },
  { id: 3, label: 'А - Я', icon: IconSortUp, value: 'KNOWLEDGE_NAME_ASC' },
  { id: 4, label: 'Я - А', icon: IconSortDown, value: 'KNOWLEDGE_NAME_DESC' },
  { id: 5, label: 'Понравившиеся', icon: IconSortDown, value: 'KNOWLEDGE_LIKE_DESC' },
];

export const KnowledgeFilters = () => {
  return (
    <AppContentWrapper className={classes.container}>
      <div className={classes.filters}>
        <ButtonAdd />
        <SearchInput
          searchQueryKey={knowledgeConfig.searchQueryKey}
          placeholder={'Поиск по наименованию и описанию'}
          showSearchButton
          size={'m'}
          wrapperClassName={classes.searchInput}
        />
        <SortSelect sortQueryKey={knowledgeConfig.sortQueryKey} items={items} />
        <SwitcherView />
        <ButtonFilters />
      </div>
      <div className={classes.filterTags}>
        <KnowledgeFilterBar />
      </div>
    </AppContentWrapper>
  );
};
