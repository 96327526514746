import { Dispatch, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';
import dayjs from 'dayjs';

import { useAppDispatch } from '../../../../hooks/hooks.ts';
import { knowledgeItemPageActions } from '../../../../store/reducers/knowledgeItemPageSlice.ts';
import { getLinksKnowledgeDtoFromForm } from '../../../utils/getLinksKnowledgeDtoFromForm.ts';

import { AppModal } from '../../AppModal/AppModal.tsx';
import { Divider } from '../../Divider/Divider.tsx';
import { KnowledgeFormValues } from '../../KnowledgeEditor/KnowledgeEditor.tsx';
import { LoaderFiles } from '../../KnowledgeEditor/components/LoaderFiles/LoaderFiles.tsx';
import {
  KnowledgeDtoWithFileData,
  KnowledgePreview,
} from '../../KnowledgePreview/KnowledgePreview.tsx';

import classes from './KnowledgePreviewModal.module.css';

export type KnowledgePreviewModalType = { data: KnowledgeFormValues | null; open: boolean };

export const KnowledgePreviewModal = ({
  knowledgePreviewModal: modal,
  setKnowledgePreviewModal,
  goBack,
}: {
  knowledgePreviewModal: KnowledgePreviewModalType;
  setKnowledgePreviewModal: Dispatch<KnowledgePreviewModalType>;
  goBack: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async (data: KnowledgeFormValues) => {
    setLoading(true);
    const result = await dispatch(knowledgeItemPageActions.saveKnowledge({ data }));
    setLoading(false);
    if (result.type === 'knowledgeItemPage/saveKnowledge/fulfilled') {
      setKnowledgePreviewModal({ open: false, data: modal.data });
      goBack();
    }
  };

  const data: KnowledgeDtoWithFileData | null = modal.data
    ? {
        _uuid: '00000000-0000-0000-0000-000000000000',
        // пока автора не указываем нужна будет доработка послед добавления поля "Автор"
        create_user: '',
        date: dayjs().toISOString(),
        knowledge_type: modal.data.knowledge_type,
        subject: modal.data.subject,
        description: modal.data?.description,
        body: modal.data.body,
        links: getLinksKnowledgeDtoFromForm(modal.data) ?? [],
        // при предпросмотре не применяется
        uuid_image_thumbnail: undefined,
        blocks: modal.data.blocks,
        tags: modal.data.tags,
        // не используется
        domains: undefined,
        complexity: modal.data.complexity,
        tool: modal.data.tool,
        read_only: false,
        // при предпросмотре не применяется
        count_like_all: undefined,
        // при предпросмотре не применяется
        count_like_by_user: undefined,
        files: modal.data.files,
        video_files: modal.data.video_files,
        wide_body: !!modal.data.wide_body,
      }
    : null;

  return (
    <AppModal
      isOpen={modal.open}
      className={classes.modal}
      close={() => setKnowledgePreviewModal({ open: false, data: modal.data })}
      afterClose={() => setKnowledgePreviewModal({ open: false, data: null })}
    >
      <Text size={'xl'} weight={'medium'} className={classes.title}>
        Предпросмотр
      </Text>
      <Divider />
      <div className={classes.content}>
        <div
          className={classNames({
            [classes.wide]: data?.knowledge_type !== 'INTERNAL',
            [classes.standard]: data?.knowledge_type === 'INTERNAL' && !data?.wide_body,
            [classes.full]: data?.knowledge_type === 'INTERNAL' && data?.wide_body,
          })}
        >
          {data && <KnowledgePreview knowledge={data} />}
        </div>
      </div>
      <Divider />
      <div className={classes.buttonGroup}>
        <LoaderFiles />
        <Button
          label={'Отмена'}
          view={'ghost'}
          onClick={() => setKnowledgePreviewModal({ open: false, data: modal.data })}
          loading={loading}
        />
        <Button
          label={'Опубликовать'}
          view={'primary'}
          onClick={() => modal.data && submit(modal.data)}
          loading={loading}
        />
      </div>
    </AppModal>
  );
};
