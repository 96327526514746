import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { Controller, useFormContext } from 'react-hook-form';

import { KnowledgeDto } from '../../../../../generateAxios';

import { KnowledgeFormValues } from '../../KnowledgeEditor.tsx';

import classes from './KnowledgeEditorTypeSwitcher.module.css';

const knowledgeTypeOptions: { value: KnowledgeDto['knowledge_type']; label: string }[] = [
  { label: 'Видео', value: 'VIDEO' },
  { label: 'Файл', value: 'FILE' },
  { label: 'Ссылка', value: 'LINK' },
  { label: 'Статья', value: 'INTERNAL' },
];

export const KnowledgeEditorTypeSwitcher = () => {
  const { control } = useFormContext<KnowledgeFormValues>();

  return (
    <div className={classes.container}>
      <Text size={'l'} weight={'medium'} className={cnMixSpace({ mB: '2xs' })}>
        Тип материала
      </Text>
      <Controller
        name={'knowledge_type'}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <ChoiceGroup
            {...field}
            items={knowledgeTypeOptions}
            name={'knowledgeType'}
            ref={ref}
            value={knowledgeTypeOptions.find((el) => el.value === field.value)}
            onChange={({ value }) => field.onChange(value.value)}
          />
        )}
      />
    </div>
  );
};
