import { forwardRef, useMemo } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { IconSettings } from '@consta/uikit/IconSettings';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageActions, mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import shareIcon from '../../../../assets/icons/svg/icn_main_about_process_share.svg';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { knowledgeConfig } from '../../../../configs/knowledgeConfig.ts';
import { tagHashKeysConfig } from '../../../../configs/linksConfig.ts';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';

import classes from './Step3.module.scss';
import { ToolCard } from './components/ToolCard/ToolCard.tsx';

export const Step3 = forwardRef<HTMLDivElement>((_, ref) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const tools = useAppSelector(mainPageSelectors.tools);
  const tags = useAppSelector(mainPageSelectors.knowledgeTagUuids);

  // формирование ссылки для 'решения вашей аналитической задачи'
  const analyticalLink: string | null = useMemo(() => {
    const tagUuid = tags[tagHashKeysConfig.tag_analytical_application];
    if (!tagUuid) return null;
    return `${PageRoutes.DataLab_KnowledgesPage}?${knowledgeConfig.filterTagsQueryKey}=${tagUuid}${isAdminMode ? '&mode=admin' : ''}`;
  }, [tags, isAdminMode]);

  return (
    <>
      <AppContentWrapper className={classes.step3}>
        <Text view={'brand'} size={'l'} weight={'bold'} transform={'uppercase'} ref={ref}>
          Шаг №3
        </Text>
        <div
          className={classNames(
            cnMixFlex({ gap: 'm', align: 'center' }),
            cnMixSpace({ mT: '3xs', mB: 'm' })
          )}
        >
          <Text size={'3xl'} weight={'semibold'}>
            Создайте приложение
          </Text>
          {isAdminMode && (
            <Button
              iconLeft={IconSettings}
              view={'clear'}
              onClick={() => dispatch(mainPageActions.setToolLinksEditModal(true))}
            />
          )}
        </div>
        <Text size={'xl'} weight={'regular'}>
          {`Ознакомьтесь с доступными инструментами и выберите
           подходящий для решения вашей аналитической задачи`}
        </Text>
        {/*<div className={classNames(cnMixSpace({ mT: '2xl' }), cnMixFlex({ gap: 'm' }))}>*/}
        <Button
          as={'a'}
          href={relativeToAbsoluteLink(
            `${PageRoutes.DataLab_ToolsPage}${isAdminMode ? '?mode=admin' : ''}`
          )}
          view={'ghost'}
          iconRight={IconForward}
          label={'Об инструментах'}
          form={'round'}
          onClick={(event) => {
            event.preventDefault();
            navigate(`${PageRoutes.DataLab_ToolsPage}${isAdminMode ? '?mode=admin' : ''}`);
          }}
          className={classes.aboutToolButton}
        />
        {/*</div>*/}
      </AppContentWrapper>
      <AppContentWrapper className={classes.tools}>
        {tools?.map((tool) => <ToolCard key={tool.tool._uuid} tool={tool} />)}
      </AppContentWrapper>
      <AppContentWrapper>
        <div className={classes.shareBlock}>
          <div className={cnMixFlex({ gap: 'xl', align: 'center' })}>
            <img src={shareIcon} alt="share" width={'48px'} height={'48px'} />
            <div className={cnMixFlex({ direction: 'column', gap: 'xs' })}>
              <Text size={'2xl'} weight={'medium'}>{`Поделитесь приложением с коллегами`}</Text>
              <Text>
                {`Когда получен результат в созданном вами приложении, опубликуйте его в директории
               для совместного использования и настройте права доступа`}
              </Text>
            </div>
          </div>
          {analyticalLink ? (
            <Button
              as={'a'}
              href={relativeToAbsoluteLink(analyticalLink)}
              view={'clear'}
              form={'round'}
              onClick={(event) => {
                event.preventDefault();
                navigate(analyticalLink);
              }}
              iconRight={IconForward}
              label={'Перейти'}
            />
          ) : (
            <Button
              view={'clear'}
              form={'round'}
              iconRight={IconForward}
              label={'Перейти'}
              disabled
            />
          )}
        </div>
      </AppContentWrapper>
    </>
  );
});
