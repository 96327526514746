import { createIcon } from '@consta/icons/Icon';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="65" viewBox="0 0 52 65" fill="none">
    <path
      d="M45.5 64.5C47.2239 64.5 48.8772 63.8257 50.0962 62.6255C51.3152 61.4253 52 59.7974 52 58.1V19.7L32.5 0.5H6.5C4.77609 0.5 3.12279 1.17428 1.90381 2.37452C0.684819 3.57475 0 5.20261 0 6.9V58.1C0 59.7974 0.684819 61.4253 1.90381 62.6255C3.12279 63.8257 4.77609 64.5 6.5 64.5H45.5ZM29.25 6.9L45.5 22.9H29.25V6.9ZM9.75 19.7H19.5V26.1H9.75V19.7ZM9.75 32.5H42.25V38.9H9.75V32.5ZM9.75 45.3H42.25V51.7H9.75V45.3Z"
      fill="#002033"
      fillOpacity="0.3"
    />
  </svg>
);

export const IconCustomDoc = createIcon({
  l: svg,
  m: svg,
  s: svg,
  xs: svg,
  name: 'IconCustomDoc',
});
