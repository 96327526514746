import { KeyboardEvent, RefObject, forwardRef, useEffect, useRef, useState } from 'react';

import { cnMixFlex } from '@consta/uikit/MixFlex';
import { Text } from '@consta/uikit/Text';
import { User } from '@consta/uikit/User';
import { PropGetItemDisabled, PropRenderValue, UserSelect } from '@consta/uikit/UserSelect';
import { PropStatus } from '@consta/uikit/__internal__/src/components/SelectComponents/types';

import { FilterUserDto, UserRestCommonService } from '../../../generateAxios';

import useDebouncedFunction from '../../shared-front/hooks/useDebounceFunction.ts';
import { getMimeType } from '../../utils/getMimeType.ts';

import classes from './CustomUserSelect.module.css';

type Props = {
  placeholder?: string;
  label?: string;
  currentUser: FilterUserDto | null;
  setCurrentUser: (user: FilterUserDto | null) => void;
  showPhoto?: boolean;
  required?: boolean;
  getItemDisabled?: PropGetItemDisabled<FilterUserDto>;
  caption?: string;
  status?: PropStatus;
};
export const CustomUserSelect = forwardRef<HTMLInputElement, Props>(
  ({ showPhoto = false, currentUser, setCurrentUser, ...otherProps }, ref) => {
    const [users, setUsers] = useState<FilterUserDto[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputValueRef = useRef<string | null>(null);
    const abortRef = useRef<any | null>(null);

    useEffect(() => {
      return () => {
        abortRef?.current?.cancel();
        setUsers(null);
        setIsLoading(false);
      };
    }, []);

    useEffect(() => {
      setUsers(currentUser ? [currentUser] : null);
    }, [currentUser]);

    const sendRequest = useDebouncedFunction(
      async (input) => {
        try {
          const request = UserRestCommonService.findUsersFromLdap(input.value);
          abortRef.current = request;
          const response = await request;
          setUsers(response);
          setIsLoading(false);
        } catch (error) {
          if ((error as Error)?.name !== 'AbortError') throw error;
        } finally {
          abortRef.current = undefined;
        }
      },
      250,
      true
    );

    const handleSearchEmployee = async (event: KeyboardEvent<HTMLDivElement>) => {
      const input = event.target as HTMLInputElement;

      // для предотвращения повторного вызова при неизменном значении инпута (нажатие на стрелки, home, end и т.д.)
      if (inputValueRef.current === input.value) return;
      else inputValueRef.current = input.value;

      if (input.value.length < 1 || !input.value.length) {
        abortRef.current?.cancel();
        setUsers([]);
        setIsLoading(false);
      } else {
        abortRef.current?.cancel();
        setIsLoading(true);
        sendRequest(input);
      }
    };

    const renderWithPhoto: PropRenderValue<FilterUserDto> = ({ item }) => {
      let img: string | undefined = undefined;
      if (item?.avatar) {
        const mimeType = getMimeType(item.avatar);
        img = `data:${mimeType};base64,${item.avatar.replace(/\s+/g, '')}`;
      }
      return (
        <User
          view="ghost"
          name={item.display_name}
          avatarUrl={img}
          info={item.position ?? item.email}
          // width={'full'}
        />
      );
    };

    const renderWithoutPhoto: PropRenderValue<FilterUserDto> = ({ item }) => {
      return (
        <div className={cnMixFlex({ align: 'center' })}>
          <Text>{item.display_name}</Text>
        </div>
      );
    };

    return (
      <UserSelect
        {...otherProps}
        dropdownClassName={classes.userList}
        items={users || []}
        value={currentUser}
        isLoading={isLoading}
        onKeyUp={(e) => handleSearchEmployee(e)}
        onChange={(item) => setCurrentUser(item.value)}
        searchFunction={() => true}
        getItemLabel={(item) => item.display_name ?? 'нет displayName'}
        getItemKey={(item) => item.sso_id ?? ''}
        getItemAvatarUrl={(item) => {
          let img: string | undefined = undefined;
          if (item?.avatar) {
            const mimeType = getMimeType(item.avatar);
            img = `data:${mimeType};base64,${item.avatar.replace(/\s+/g, '')}`;
          }
          return img;
        }}
        getItemSubLabel={(item) => item.position ?? item.email ?? 'Не заполнено'}
        renderValue={showPhoto ? renderWithPhoto : renderWithoutPhoto}
        labelForEmptyItems={
          !inputValueRef.current ? 'Введите значение для поиска' : 'Ничего не найдено'
        }
        inputRef={ref as RefObject<HTMLInputElement>}
      />
    );
  }
);
