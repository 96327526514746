import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { AnimateIconSwitcherProvider } from '@consta/icons/AnimateIconSwitcherProvider';
import { IconThumbUp } from '@consta/icons/IconThumbUp';
import { withAnimateSwitcherHOC } from '@consta/icons/withAnimateSwitcherHOC';

import { KnowledgeDto } from '../../../../../generateAxios';
import { useAppDispatch } from '../../../../../hooks/hooks.ts';
import { knowledgeItemPageActions } from '../../../../../store/reducers/knowledgeItemPageSlice.ts';
import { divideNumIntoCategories } from '../../../../utils/divideNumIntoCategories.ts';

export const AddLikeAnimateIcon = withAnimateSwitcherHOC({
  startIcon: IconThumbUp,
  endIcon: IconThumbUp,
  // endDirection: -360,
  transition: 500,
});

export const KnowledgeLikeButton = ({ knowledge }: { knowledge: KnowledgeDto }) => {
  const dispatch = useAppDispatch();
  const [likeLoading, setLikeLoading] = useState<boolean>(false);

  const changeLikeCount = async () => {
    setLikeLoading(true);
    await dispatch(
      knowledgeItemPageActions.setKnowledgeLike({
        knowledge,
        action: !knowledge.count_like_by_user ? 'add' : 'delete',
      })
    );
    setLikeLoading(false);
  };

  return (
    <AnimateIconSwitcherProvider active={!knowledge.count_like_by_user}>
      <Button
        // label={`${portfolio.count_like_all}`}
        label={`Мне нравится (${divideNumIntoCategories(knowledge.count_like_all)})`}
        view={!!knowledge.count_like_by_user ? 'secondary' : 'ghost'}
        size={'m'}
        iconLeft={AddLikeAnimateIcon}
        className={cnMixSpace({ pL: 'm', pR: 'l' })}
        onClick={() => changeLikeCount()}
        loading={likeLoading}
      />
    </AnimateIconSwitcherProvider>
  );
};
