import { useEffect } from 'react';

import { Loader } from '@consta/uikit/Loader';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';
import { toolsPageActions, toolsPageSelectors } from '../../../store/reducers/toolsPageSlice.ts';

import { AppContentWrapper } from '../../components/AppContentWrapper/AppContentWrapper.tsx';
import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';

import classes from './ToolsPage.module.css';
import { ToolsHero } from './components/ToolsHero/ToolsHero.tsx';
import { ToolsInfo } from './components/ToolsInfo/ToolsInfo.tsx';
import { ToolsInfoLinks } from './components/ToolsInfoLinks/ToolsInfoLinks.tsx';
import { ToolsPluses } from './components/ToolsPluses/ToolsPluses.tsx';
import { ToolsProperties } from './components/ToolsProperties/ToolsProperties.tsx';
import { ToolsSecondaryLinks } from './components/ToolsSecondaryLinks/ToolsSecondaryLinks.tsx';
import { ToolsSwitcher } from './components/ToolsSwitcher/ToolsSwitcher.tsx';

export const toolQueryKey = 'tool';

export const ToolsPage = () => {
  const dispatch = useAppDispatch();
  const pageLoader = useAppSelector(toolsPageSelectors.pageLoader);
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  // useEffect(() => {
  //   document.title = 'Инструменты | Лаборатория данных';
  //   if ((isAdminMode && hasRoles) || !isAdminMode) {
  //     dispatch(toolsPageActions.getTools());
  //   }
  //   return () => {
  //     dispatch(toolsPageActions.clearState());
  //   };
  // }, [isAdminMode, hasRoles]);

  useEffect(() => {
    document.title = 'Инструменты | Лаборатория данных';
    dispatch(toolsPageActions.getTools());
    return () => {
      dispatch(toolsPageActions.clearState());
    };
  }, []);

  return (
    <>
      <ToolsHero />
      {isAdminMode && !hasRoles ? (
        <SwitchToUser403 />
      ) : pageLoader ? (
        <AppContentWrapper className={classes.loaderWrapper}>
          <Loader />
        </AppContentWrapper>
      ) : (
        <>
          <ToolsPluses />
          <ToolsInfo />
          <ToolsSecondaryLinks />
          <ToolsInfoLinks />
          <ToolsProperties />
          <ToolsSwitcher />
        </>
      )}
    </>
  );
};
