import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFormContext } from 'react-hook-form';

import { FilterUserDto } from '../../../../../generateAxios';
import { CustomUserSelect } from '../../../CustomUserSelect/CustomUserSelect.tsx';

import { PortfolioFormValues } from '../../PortfolioEditor.tsx';

import classes from './PortfolioEditorNewFeedbacks.module.css';

export const PortfolioEditorNewFeedbacks = () => {
  const { control, getValues, setValue } = useFormContext<PortfolioFormValues>();
  return (
    <div className={classes.container}>
      <Controller
        name={'newFeedback'}
        control={control}
        /* валидация отключена, новая цитата без автора и/или текста цитаты игнорируется  */
        // rules={{
        //   validate: {
        //     noValidNewFeedBack: (value) => {
        //       if (!value) return;
        //       if (value.user?.sso_id && value.body) return;
        //       return 'Заполните поле сотрудника и такста';
        //     },
        //   },
        // }}
        render={({ field }) => {
          const selectUser = (user: FilterUserDto | null) => {
            const oldValue = field.value;
            field.onChange({ ...oldValue, user });
          };
          const changeText = (value: string | null) => {
            const oldValue = field.value;
            field.onChange({ ...oldValue, body: value });
          };
          const addFeedback = () => {
            if (field.value?.body && field.value?.user) {
              const oldFeedbacks = getValues('feedbacks');
              const newFeedback = { ...field.value, guid: '00000000-0000-0000-0000-000000000000' };
              const newFeedbacks: PortfolioFormValues['feedbacks'] = oldFeedbacks
                ? [...oldFeedbacks, newFeedback]
                : [newFeedback];
              setValue('feedbacks', newFeedbacks);
              field.onChange(undefined);
            }
          };
          return (
            <div className={cnMixFlex({ flex: 'flex', direction: 'column', gap: 'l' })}>
              <CustomUserSelect
                label={'Сотрудник'}
                placeholder={'Выберите сотрудника'}
                currentUser={field.value?.user ?? null}
                setCurrentUser={(user) => {
                  selectUser(user);
                }}
                required
                showPhoto
              />
              <TextField
                label={'Цитата'}
                placeholder={'Введите текст цитаты'}
                type={'textarea'}
                width={'full'}
                rows={5}
                value={field.value?.body}
                onChange={({ value }) => changeText(value)}
              />
              <Button
                view={'ghost'}
                size={'s'}
                iconLeft={IconAdd}
                label={'Добавить ещё'}
                onClick={addFeedback}
                className={cnMixSpace({ pR: 'm', mT: '2xs' })}
              />
            </div>
          );
        }}
      />
    </div>
  );
};
