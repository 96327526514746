import { KnowledgeDto } from '../../../../generateAxios';
import { InfoCard } from '../../../components/InfoCard/InfoCard.tsx';

import { KnowledgeLikeButton } from './Buttons/KnowledgeLikeButton.tsx';

export const KnowledgeLikeInfoCard = ({ knowledge }: { knowledge: KnowledgeDto }) => {
  return (
    <InfoCard
      header={'Материал показался вам полезным?'}
      content={
        'Оцените его, чтобы другие пользователи смогли быстрее ориентироваться в материалах и находить самые полезные'
      }
      footer={<KnowledgeLikeButton knowledge={knowledge} />}
    />
  );
};
