import { configureStore } from '@reduxjs/toolkit';

import faqPageSlice from './reducers/faqPageSlice.ts';
import generalSlice from './reducers/generalSlice.ts';
import knowledgeItemPageSlice from './reducers/knowledgeItemPageSlice.ts';
import knowledgePageSlice from './reducers/knowledgePageSlice.ts';
import mainPageSlice from './reducers/mainPageSlice.ts';
import metricsPageSlice from './reducers/metricsPageSlice.ts';
import portfolioItemPageSlice from './reducers/portfolioItemPageSlice.ts';
import portfolioPageSlice from './reducers/portfolioPageSlice.ts';
import toolsPageSlice from './reducers/toolsPageSlice.ts';

const store = configureStore({
  reducer: {
    mainPage: mainPageSlice,
    general: generalSlice,
    faqPage: faqPageSlice,
    toolsPage: toolsPageSlice,
    portfolioPage: portfolioPageSlice,
    portfolioItemPage: portfolioItemPageSlice,
    knowledgePage: knowledgePageSlice,
    knowledgeItemPage: knowledgeItemPageSlice,
    metricsPage: metricsPageSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

// Создаем типы для RootState и AppDispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
