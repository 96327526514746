import { DeleteModal } from '../../DeleteModal/DeleteModal.tsx';

export const KnowledgeDeleteModal = ({
  name,
  isOpen,
  onDelete,
  onClose,
  loading,
}: {
  name?: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  loading?: boolean;
}) => {
  return (
    <DeleteModal
      title={`Удалить материал?`}
      content={`Вы действительно хотите удалить ${name ? `"${name}"` : 'материал'} и все данные в нем?`}
      isOpen={isOpen}
      onClose={onClose}
      confirmAction={onDelete}
      loading={loading}
    />
  );
};
