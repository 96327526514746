import { useState } from 'react';

import { Button } from '@consta/uikit/Button';

import { IconCopy } from '@consta/icons/IconCopy';

export const KnowledgeCopyLinkButton = () => {
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const link = window.location.href.split('?')[0];
  const copyLink = async () => {
    await navigator.clipboard.writeText(link);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 1000);
  };
  return (
    <Button
      onlyIcon
      iconLeft={() => <IconCopy view={isLinkCopied ? 'success' : 'primary'} size={'s'} />}
      view={'ghost'}
      onClick={() => {
        copyLink();
      }}
    />
  );
};
