import { useEffect, useMemo, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Loader } from '@consta/uikit/Loader';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { BlockBlockToolDto, BlockToolLinksDto } from '../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks.ts';
import { mainPageActions, mainPageSelectors } from '../../../../store/reducers/mainPageSlice.ts';
import { toolsPageActions } from '../../../../store/reducers/toolsPageSlice.ts';
import { PageRoutes } from '../../../appMenuAndRouting/appRoutes.tsx';

import { AppModal } from '../../AppModal/AppModal.tsx';
import { Divider } from '../../Divider/Divider.tsx';

import classes from './ToolLinksEditModal.module.css';

type FormValues = { links: BlockToolLinksDto[] };

// Регулярное выражение для проверки валидных ссылок
// const urlRegex = /^https?:\/\/([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
const urlRegex = /^https?:\/\//;

export const ToolLinksEditModal = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const callback = useMemo(() => {
    if (location.pathname === PageRoutes.DataLab_ToolsPage) {
      return () => dispatch(toolsPageActions.getTools());
    }
    return undefined;
  }, [location.pathname, dispatch]);
  const open = useAppSelector(mainPageSelectors.toolLinksEditModal);
  const toolLinksEditData = useAppSelector(mainPageSelectors.toolLinksEditData);
  const toolLinksEditLoading = useAppSelector(mainPageSelectors.toolLinksEditLoading);
  const [newToolLinksEditData, setNewToolLinksEditData] = useState<BlockBlockToolDto[] | null>(
    null
  );
  const [currentBlock, setCurrentBlock] = useState<BlockBlockToolDto | null>(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    // defaultValues: {
    //   links: newToolLinksEditData?.find((el) => !el.read_only)?.instances || [],
    // },
  });
  const { fields } = useFieldArray({ control, name: 'links' });

  useEffect(() => {
    open && dispatch(mainPageActions.getToolLinksData());
  }, [open]);

  useEffect(() => {
    toolLinksEditData && setNewToolLinksEditData(toolLinksEditData);
    const editableBlock = toolLinksEditData?.find((el) => !el.read_only) || null;
    setCurrentBlock(editableBlock);
    if (editableBlock) {
      reset({ links: editableBlock.instances || [] });
    }
  }, [toolLinksEditData]);

  const changeCurrentBlock = (data: FormValues, newBlock: BlockBlockToolDto) => {
    setNewToolLinksEditData(
      (prevState) =>
        prevState?.map((el) =>
          el.block._uuid === currentBlock?.block._uuid ? { ...el, instances: data.links } : el
        ) ?? null
    );
    setCurrentBlock(newBlock);
    reset({ links: newBlock.instances || [] });
  };

  const save = ({ links }: FormValues) => {
    if (newToolLinksEditData) {
      const data = newToolLinksEditData.map((el) =>
        el.block._uuid === currentBlock?.block._uuid ? { ...el, instances: links } : el
      );
      dispatch(mainPageActions.saveToolLinks({ data, callback }));
    }
  };

  const cancel = () => dispatch(mainPageActions.setToolLinksEditModal(false));

  const afterClose = () => {
    setNewToolLinksEditData(null);
    setCurrentBlock(null);
    reset({ links: [] });
    dispatch(mainPageActions.setToolLinksEditData(null));
  };

  return (
    <AppModal isOpen={open} close={cancel} className={classes.modal} afterClose={afterClose}>
      <Text
        size={'xl'}
        weight={'medium'}
        className={cnMixSpace({ mT: 'l', mB: 'l', mL: '2xl', mR: '4xl' })}
      >
        Инструменты Лаборатории данных
      </Text>
      <Divider />
      <div className={classes.contentWrapper}>
        {!toolLinksEditData && (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
        {toolLinksEditData && toolLinksEditData.length > 0 && (
          <ChoiceGroup
            items={newToolLinksEditData ?? []}
            name={'выбор блока'}
            value={currentBlock}
            getItemLabel={(item) => item.block?.short_name}
            getItemDisabled={(item) => item.read_only}
            onChange={({ value }) => {
              handleSubmit((formData) => {
                changeCurrentBlock(formData, value);
              })();
            }}
            className={classes.choiceGroup}
          />
        )}

        <div className={classes.content}>
          {toolLinksEditData && toolLinksEditData.filter((el) => !el.read_only).length === 0 && (
            <Text size={'l'} weight={'medium'}>
              Нет ссылок доступных для редактирования
            </Text>
          )}
          {toolLinksEditData && (
            <>
              <Text size={'l'} weight={'medium'} className={cnMixSpace({ mB: 'xl' })}>
                Ссылки на инструменты
              </Text>
              <form className={classes.form}>
                {fields.map((el, index) => (
                  <Controller
                    key={el.id}
                    name={`links.${index}.link`}
                    control={control}
                    rules={{
                      required: 'Поле обязательно для заполнения',
                      pattern: {
                        value: urlRegex,
                        message: 'Введите корректный URL',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={el.tool}
                        placeholder={`https:// - введите ссылку `}
                        onChange={({ value }) => {
                          field.onChange(value ?? '');
                        }}
                        width={'full'}
                        status={errors.links?.[index]?.link ? 'alert' : undefined}
                        caption={errors.links?.[index]?.link?.message}
                      />
                    )}
                  />
                ))}
              </form>
            </>
          )}
        </div>
      </div>
      <Divider />
      <div className={classes.buttonsGroup}>
        <Button label={'Отмена'} view={'ghost'} onClick={cancel} />
        <Button
          label={'Сохранить'}
          onClick={handleSubmit((data) => save(data))}
          loading={toolLinksEditLoading}
        />
      </div>
    </AppModal>
  );
};
