import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFormContext } from 'react-hook-form';

import { CustomEditor } from '../../../CustomEditor/CustomEditor.tsx';

import { PortfolioFormValues } from '../../PortfolioEditor.tsx';

import classes from './PortfolioEditorContent.module.css';

export const PortfolioEditorContent = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PortfolioFormValues>();

  return (
    <div className={classes.container}>
      <Text size={'l'} weight={'medium'} className={cnMixSpace({ mB: '2xs' })}>
        Контент кейса
      </Text>

      <Controller
        name={'description'}
        control={control}
        rules={{ required: 'Поле обязательно для заполнения' }}
        render={({ field, fieldState }) => (
          <CustomEditor
            label={'Описание'}
            required
            onChange={(value) => field.onChange(value)}
            value={field.value}
            placeholder={'Добавьте описание'}
            fieldState={fieldState}
            refC={field.ref}
          />
        )}
      />
      <Controller
        name={'goals'}
        control={control}
        rules={{ required: 'Поле обязательно для заполнения' }}
        render={({ field, fieldState }) => (
          <CustomEditor
            label={'Цели и задачи'}
            required
            onChange={(value) => field.onChange(value)}
            value={field.value}
            placeholder={'Укажите цели и задачи'}
            fieldState={fieldState}
            refC={field.ref}
          />
        )}
      />
      <Controller
        name={'results'}
        control={control}
        rules={{ required: 'Поле обязательно для заполнения' }}
        render={({ field, fieldState }) => (
          <CustomEditor
            label={'Результаты'}
            required
            onChange={(value) => field.onChange(value)}
            value={field.value}
            placeholder={'Расскажите о результатах'}
            fieldState={fieldState}
            refC={field.ref}
          />
        )}
      />
      <Controller
        name={'users'}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={'Пользователи'}
            placeholder={
              'Укажите пользователей приложения, например: Специалисты, Руководство, Высшее руководство'
            }
            type={'textarea'}
            rows={5}
            width={'full'}
            value={field.value ?? ''}
            onChange={({ value }) => field.onChange(value ?? '')}
            status={errors.users ? 'alert' : undefined}
            caption={errors.users?.message}
          />
        )}
      />
      <Controller
        name={'plan'}
        control={control}
        render={({ field, fieldState }) => (
          <CustomEditor
            label={'Планы на развитие'}
            onChange={(value) => field.onChange(value)}
            value={field.value}
            placeholder={'Расскажите о планах на развитие'}
            fieldState={fieldState}
            refC={field.ref}
          />
        )}
      />
    </div>
  );
};
