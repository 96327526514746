import { Fragment } from 'react';

import { Text } from '@consta/uikit/Text';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { toolsPageSelectors } from '../../../../../store/reducers/toolsPageSlice.ts';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { Divider } from '../../../../components/Divider/Divider.tsx';

import { toolQueryKey } from '../../ToolsPage.tsx';

import classes from './ToolsProperties.module.css';

export const ToolsProperties = () => {
  const tools = useAppSelector(toolsPageSelectors.tools);
  const [currentToolId] = useAppSelector(selectParamByKey(toolQueryKey));
  const currentTool = tools?.find((tool) => tool.tool._uuid === currentToolId) ?? tools?.[0];

  return (
    currentTool?.properties && (
      <AppContentWrapper className={classes.container}>
        <Text size={'3xl'} weight={'bold'}>
          Подробные характеристики {currentTool.tool.name}
        </Text>
        <div className={classes.contentWrapper}>
          {currentTool.properties.map((item, index) => {
            return (
              <Fragment key={item.capture}>
                <div className={classes.content}>
                  <Text weight={'semibold'} className={classes.title}>
                    {item.capture}
                  </Text>
                  {item.text && item.text.length > 0 && (
                    <Text className={classes.description}>
                      {item.text.length < 2 ? (
                        <Text>{item.text[0]}</Text>
                      ) : (
                        <ul>
                          {item.text.map((text, index) => (
                            <li key={index}>{text}</li>
                          ))}
                        </ul>
                      )}
                    </Text>
                  )}
                </div>
                {index !== currentTool.properties.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </div>
      </AppContentWrapper>
    )
  );
};
