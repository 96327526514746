import { Fragment, useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import { KnowledgeDto, LinkKnowledgeDto } from '../../../../../generateAxios';
import { validateURL } from '../../../../utils/valudateURL.ts';
import { Divider } from '../../../Divider/Divider.tsx';

import classes from './KnowledgeContentLinks.module.css';

export const KnowledgeContentLinks = ({ knowledge }: { knowledge: KnowledgeDto }) => {
  if (!knowledge.links) return null;

  return (
    <div className={classes.listContainer}>
      {knowledge.links.map((link, index) => {
        return (
          <Fragment key={index}>
            <LinkItem link={link} />
            {index !== knowledge.links!.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </div>
  );
};

const LinkItem = ({ link }: { link: LinkKnowledgeDto }) => {
  const [showFullDescription, setShowFullDescription] = useState<boolean>(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  return (
    <div className={classes.listItem}>
      <Text
        ref={descriptionRef}
        size={'l'}
        weight={'semibold'}
        className={classNames({
          [classes.linkDescription]: true,
          [classes.linkCollapsedDescription]: !showFullDescription,
          [classes.pointer]: showFullDescription,
        })}
        onMouseEnter={(e) => {
          e.currentTarget.setAttribute(
            'style',
            descriptionRef.current &&
              descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight
              ? 'cursor: pointer;'
              : ''
          );
        }}
        onClick={() => {
          if (
            descriptionRef.current &&
            descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight
          ) {
            setShowFullDescription(true);
          } else {
            setShowFullDescription(false);
          }
        }}
      >
        {link.description}
      </Text>
      <Button
        className={cnMixSpace({ pL: 'm' })}
        as={'a'}
        size={'s'}
        href={link.link}
        target={'_blank'}
        iconRight={IconForward}
        onClick={(e) => {
          e.preventDefault();
          validateURL(link.link) && window.open(link.link, '_blank');
        }}
        label={validateURL(link.link) ? 'Перейти' : '!!Не валидная ссылка'}
      />
    </div>
  );
};
