import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { Text } from '@consta/uikit/Text';

import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import {
  FullTeamItemDto,
  type HelpdeskForShowDto,
  TeamItemIgnoreBlockDto,
} from '../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageActions, mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import { AppModal } from '../../../../components/AppModal/AppModal.tsx';
import { Divider } from '../../../../components/Divider/Divider.tsx';

import classes from './TeamEditModal.module.css';
import { DraggableContacts } from './components/DraggableContacts/DraggableContacts.tsx';
import { DraggableEmails } from './components/DraggableEmails/DraggableEmails.tsx';
import { DraggableResources } from './components/DraggableResources/DraggableResources.tsx';
import { EditContact } from './components/EditContact/EditContact.tsx';
import { EditEmail } from './components/EditEmail/EditEmail.tsx';
import { EditResource } from './components/EditResource/EditResource.tsx';
import { EmptyContent } from './components/EnptyContent/EmptyContent.tsx';

type ChoiceItem = { selectorLabel: string };

export const TeamEditModal = () => {
  const open = useAppSelector(mainPageSelectors.teamEditModal);
  const currentTeam = useAppSelector(mainPageSelectors.currentTeam);
  const loadingSaveTeam = useAppSelector(mainPageSelectors.loadingSaveTeam);
  const dispatch = useAppDispatch();

  const [selectedContact, setSelectedContact] = useState<TeamItemIgnoreBlockDto | null>(null);
  const [selectedResource, setSelectedResource] = useState<HelpdeskForShowDto | null>(null);
  const [selectedEmail, setSelectedEmail] = useState<HelpdeskForShowDto | null>(null);

  const methods = useForm<FullTeamItemDto>({ mode: 'onChange' });

  useEffect(() => {
    currentTeam && methods.reset(currentTeam);
  }, [currentTeam]);

  useEffect(() => {
    setSelectedContact(null);
    setSelectedResource(null);
    setSelectedEmail(null);
  }, [currentTeam]);

  const afterCloseModal = () => {
    setSelectedContact(null);
    setSelectedResource(null);
    setSelectedEmail(null);
    dispatch(mainPageActions.setCurrentTeam(null));
  };

  const createEmptyContact = useCallback((): TeamItemIgnoreBlockDto => {
    return {
      _uuid: uuidv4(),
      email: '',
      display_name: '',
      sso_id: '',
      is_new: true,
      username: '',
    };
  }, []);

  const createEmptyResource = useCallback(
    (type: HelpdeskForShowDto['type']): HelpdeskForShowDto => {
      return { _uuid: uuidv4(), type: type, link: '', name: '', is_new: true };
    },
    []
  );

  const saveTeam = () => {
    methods.handleSubmit((data) => {
      dispatch(mainPageActions.saveTeam(data));
    })();
  };

  const addContact = async () => {
    const checked = await methods.trigger();
    if (checked) {
      const newContact = createEmptyContact();
      const oldContacts = methods.getValues('teams');
      methods.setValue('teams', [newContact, ...(oldContacts ?? [])]);
      setSelectedContact(newContact);
      setSelectedResource(null);
      setSelectedEmail(null);
    }
  };

  const addResource = async () => {
    const checked = await methods.trigger();
    if (checked) {
      const newResource = createEmptyResource('RESOURCE');
      const oldResources = methods.getValues('resources');
      methods.setValue('resources', [newResource, ...(oldResources ?? [])]);
      setSelectedResource(newResource);
      setSelectedContact(null);
      setSelectedEmail(null);
    }
  };

  const addEmail = async () => {
    const checked = await methods.trigger();
    if (checked) {
      const newEmail = createEmptyResource('EMAIL');
      const oldEmails = methods.getValues('emails');
      methods.setValue('emails', [newEmail, ...(oldEmails ?? [])]);
      setSelectedEmail(newEmail);
      setSelectedContact(null);
      setSelectedResource(null);
    }
  };

  const deleteResource = useCallback(
    (resource: HelpdeskForShowDto) => {
      methods.setValue(
        'resources',
        methods.getValues('resources')?.filter((item) => item._uuid !== resource._uuid)
      );
      if (selectedResource?._uuid === resource._uuid) setSelectedResource(null);
    },
    [methods, selectedResource?._uuid]
  );

  const deleteEmail = useCallback(
    (email: HelpdeskForShowDto) => {
      methods.setValue(
        'emails',
        methods.getValues('emails')?.filter((item) => item._uuid !== email._uuid)
      );
      if (selectedEmail?._uuid === email._uuid) setSelectedEmail(null);
    },
    [methods, selectedEmail?._uuid]
  );

  const deleteContact = useCallback(
    (contact: TeamItemIgnoreBlockDto) => {
      methods.setValue(
        'teams',
        methods.getValues('teams')?.filter((item) => item._uuid !== contact._uuid)
      );
      if (selectedEmail?._uuid === contact._uuid) setSelectedContact(null);
    },
    [methods, selectedEmail?._uuid]
  );

  const editResource = useCallback(
    async (resource: HelpdeskForShowDto) => {
      const checked = await methods.trigger();
      if (checked) {
        setSelectedResource(resource);
        setSelectedContact(null);
        setSelectedEmail(null);
      }
    },
    [methods]
  );

  const editContact = useCallback(
    async (contact: TeamItemIgnoreBlockDto) => {
      const checked = await methods.trigger();
      if (checked) {
        setSelectedContact(contact);
        setSelectedResource(null);
        setSelectedEmail(null);
      }
    },
    [methods]
  );

  const editEmail = useCallback(
    async (email: HelpdeskForShowDto) => {
      const checked = await methods.trigger();
      if (checked) {
        setSelectedEmail(email);
        setSelectedContact(null);
        setSelectedResource(null);
      }
    },
    [methods]
  );

  const choiceItems: ChoiceItem[] = useMemo(
    () => [{ selectorLabel: 'Ресурсы' }, { selectorLabel: 'Сотрудники' }],
    []
  );

  const [choiceItem, setChoiceItem] = useState<ChoiceItem>(choiceItems[0]);

  return (
    <AppModal
      isOpen={open}
      close={() => {
        dispatch(mainPageActions.setTeamEditModal(false));
      }}
      afterClose={afterCloseModal}
      className={classes.container}
    >
      <FormProvider {...methods}>
        <Text
          size={'xl'}
          weight={'medium'}
          className={classes.title}
        >{`Контакты Лаборатории данных ${currentTeam?.block.short_name}`}</Text>
        <Divider />
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <div className={classes.contentSelector}>
              <div className={classes.choiceGroup}>
                <ChoiceGroup
                  items={choiceItems}
                  value={choiceItem}
                  onChange={({ value }) => setChoiceItem(value)}
                  name={'choiceData'}
                  getItemLabel={(item) => item.selectorLabel}
                  width={'full'}
                />
              </div>
              <div className={classes.listContainer}>
                {choiceItem.selectorLabel === 'Ресурсы' ? (
                  <div>
                    <DraggableResources
                      onEdit={editResource}
                      onDelete={deleteResource}
                      selectedResource={selectedResource}
                      onAdd={addResource}
                    />

                    <DraggableEmails
                      onEdit={editEmail}
                      onAdd={addEmail}
                      selectedEmail={selectedEmail}
                      onDelete={deleteEmail}
                    />
                  </div>
                ) : (
                  <DraggableContacts
                    onEdit={editContact}
                    onDelete={deleteContact}
                    selectedContact={selectedContact}
                    onAdd={addContact}
                  />
                )}
              </div>
            </div>
            <div className={classes.contentActions}>
              {!selectedContact && !selectedResource && !selectedEmail && <EmptyContent />}
              {selectedContact && <EditContact contact={selectedContact} />}
              {selectedResource && <EditResource resource={selectedResource} />}
              {selectedEmail && <EditEmail email={selectedEmail} />}
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.actions}>
          <Button
            label={'Отмена'}
            view={'ghost'}
            onClick={() => dispatch(mainPageActions.setTeamEditModal(false))}
          />
          <Button
            label={'Сохранить'}
            loading={loadingSaveTeam}
            onClick={saveTeam}
          />
        </div>
      </FormProvider>
    </AppModal>
  );
};
