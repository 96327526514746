import { IconEyeClose } from '@consta/icons/IconEyeClose';

import { DeleteModal } from '../../DeleteModal/DeleteModal.tsx';

export const PortfolioUnpublishModal = ({
  name,
  isOpen,
  loading,
  onUnpublish,
  onClose,
}: {
  name?: string;
  isOpen: boolean;
  loading?: boolean;
  onUnpublish: () => void;
  onClose: () => void;
}) => {
  return (
    <DeleteModal
      title={`Снять с публикации кейс?`}
      content={`Вы действительно хотите снять с публикации ${name ? `"${name}"` : 'кейс'}? Кейс будет перемещен в раздел "Заявки на публикацию"`}
      isOpen={isOpen}
      onClose={onClose}
      confirmAction={onUnpublish}
      loading={loading}
      deleteButtonLabel={'Снять с публикации'}
      deleteButtonIcon={IconEyeClose}
    />
  );
};
