import { useCallback, useEffect, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { FiltersPortfolioDto, PortfolioDto } from '../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { portfolioItemPageActions } from '../../../../../store/reducers/portfolioItemPageSlice.ts';
import {
  portfolioPageActions,
  portfolioPageSelectors,
} from '../../../../../store/reducers/portfolioPageSlice.ts';
import { selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { portfolioConfig } from '../../../../configs/portfolioConfig.ts';

import { PortfolioCard } from '../PortfolioCard/PortfolioCard.tsx';

import classes from './PortfolioCards.module.css';

export const PortfolioCards = () => {
  const dispatch = useAppDispatch();

  const [searchValue] = useAppSelector(selectParamByKey(portfolioConfig.searchQueryKey));
  const filterBlocks = useAppSelector(selectParamByKey(portfolioConfig.filterBlockQueryKey));
  const filterDomains = useAppSelector(selectParamByKey(portfolioConfig.filterDomainQueryKey));
  const filterTools = useAppSelector(selectParamByKey(portfolioConfig.filterToolQueryKey));
  const [sortValue] = useAppSelector(selectParamByKey(portfolioConfig.sortQueryKey));
  const [statusPortfolio] = useAppSelector(
    selectParamByKey(portfolioConfig.statusPortfolioQueryKey)
  );
  const totalElements = useAppSelector(portfolioPageSelectors.totalElements);
  const portfolioItems = useAppSelector(portfolioPageSelectors.portfolioItems);
  const pageLoader = useAppSelector(portfolioPageSelectors.pageLoader);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const refetchPortfolio = useCallback(async () => {
    setPageNumber(0);
    await Promise.all([
      dispatch(
        portfolioPageActions.getPortfolioItems({
          requestBody: {
            filter: searchValue,
            page_number: 0,
            page_size: portfolioConfig.pageSize,
            order: sortValue as FiltersPortfolioDto['order'],
            blocks: filterBlocks,
            domain: filterDomains,
            tools: filterTools,
            status: statusPortfolio === 'MODERATION' ? 'MODERATION' : 'PUBLISHED',
          },
        })
      ),
      dispatch(portfolioPageActions.getPortfolioMetrics()),
    ]);
    // dispatch(
    //   portfolioPageActions.getPortfolioItems({
    //     requestBody: {
    //       filter: searchValue,
    //       page_number: 0,
    //       page_size: portfolioConfig.pageSize,
    //       order: sortValue as FiltersPortfolioDto['order'],
    //       blocks: filterBlocks,
    //       domain: filterDomains,
    //       tools: filterTools,
    //       status: statusPortfolio === 'MODERATION' ? 'MODERATION' : 'PUBLISHED',
    //     },
    //   })
    // );
    // dispatch(portfolioPageActions.getPortfolioMetrics());
  }, [dispatch, searchValue, sortValue, filterBlocks, filterDomains, filterTools, statusPortfolio]);

  const getStatisticsText = (
    displayedItems: number | undefined,
    totalElements: number | null
  ): string => {
    if (displayedItems === undefined || totalElements === null) return '';
    if (totalElements === 0) {
      return 'Ничего не найдено';
    }
    return `${displayedItems} из ${totalElements}`;
  };
  // при применении сортировки или фильтрации сбрасываем страницу на 0
  useEffect(() => {
    setPageNumber(0);

    const promise = dispatch(
      portfolioPageActions.getPortfolioItems({
        requestBody: {
          filter: searchValue,
          page_number: 0,
          page_size: portfolioConfig.pageSize,
          order: sortValue as FiltersPortfolioDto['order'],
          blocks: filterBlocks,
          domain: filterDomains,
          tools: filterTools,
          status: statusPortfolio === 'MODERATION' ? 'MODERATION' : 'PUBLISHED',
        },
      })
    );
    return () => {
      promise.abort();
    };
  }, [searchValue, sortValue, statusPortfolio, filterDomains, filterTools, filterBlocks]);

  // действия только на "показать еще" (со второй (index = 1) страницы)
  useEffect(() => {
    if (pageNumber !== 0) {
      dispatch(
        portfolioPageActions.getPortfolioItems({
          requestBody: {
            filter: searchValue,
            page_number: pageNumber,
            page_size: portfolioConfig.pageSize,
            order: sortValue as FiltersPortfolioDto['order'],
            blocks: filterBlocks,
            domain: filterDomains,
            tools: filterTools,
            status: statusPortfolio === 'MODERATION' ? 'MODERATION' : 'PUBLISHED',
          },
          addItems: true,
        })
      );
    }
  }, [pageNumber]);

  /* для корректной работы лодеров удаление использовать из portfolioPageActions а не хука*/
  const deletePortfolio = useCallback(
    async (uuid: string) => {
      await dispatch(portfolioPageActions.deletePortfolio(uuid));
      await refetchPortfolio();
    },
    [dispatch, refetchPortfolio]
  );

  const changePortfolioStatus = useCallback(
    async (uuid: string, newStatus: PortfolioDto['status']) => {
      await dispatch(
        portfolioItemPageActions.changeStatusPortfolio({ uuid, newStatus, updatePortfolio: false })
      );
      refetchPortfolio();
    },
    [dispatch, refetchPortfolio]
  );

  return (
    <AppContentWrapper className={classes.container}>
      {pageLoader && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      <div className={classes.cards}>
        {portfolioItems?.map((el) => (
          <PortfolioCard
            portfolio={el}
            key={el._uuid}
            deletePortfolio={deletePortfolio}
            changePortfolioStatus={changePortfolioStatus}
          />
        ))}
      </div>
      <div className={classes.showMore}>
        {portfolioItems && portfolioItems.length !== totalElements && (
          <Button
            view={'ghost'}
            label={'Показать еще'}
            className={cnMixSpace({ pL: 'l', pR: 'l' })}
            onClick={() => setPageNumber((prevState) => prevState + 1)}
            loading={pageLoader}
          />
        )}
        <Text view={'secondary'}>{getStatisticsText(portfolioItems?.length, totalElements)}</Text>
      </div>
    </AppContentWrapper>
  );
};
