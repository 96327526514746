import { memo } from 'react';

import { useFormContext } from 'react-hook-form';

import { FullTeamItemDto, type TeamItemIgnoreBlockDto } from '../../../../../../../generateAxios';
import { DnDSortList } from '../../../../../../components/DnDSortList/DnDSortList.tsx';

import { ContactListItem } from '../ContactListItem/ContactListItem.tsx';
import { ListTitle } from '../ListTitle/ListTitle.tsx';

export const DraggableContacts = memo(
  ({
    onDelete,
    onEdit,
    selectedContact,
    onAdd,
  }: {
    onEdit: (item: TeamItemIgnoreBlockDto) => void;
    onDelete: (item: TeamItemIgnoreBlockDto) => void;
    onAdd: () => void;
    selectedContact: TeamItemIgnoreBlockDto | null;
  }) => {
    const { watch, setValue } = useFormContext<FullTeamItemDto>();
    const contacts = watch('teams');

    const handlerAfterMove = (items: TeamItemIgnoreBlockDto[]) => {
      setValue('teams', items);
    };

    return (
      <div>
        <ListTitle
          title={'Список сотрудников'}
          buttonLabel={'Добавить сотрудника'}
          onClick={onAdd}
        />
        <DnDSortList
          type={'contacts'}
          renderItem={(item) => {
            return (
              <ContactListItem
                item={item}
                onClickDelete={onDelete}
                onClickEdit={onEdit}
                isSelected={selectedContact?._uuid === item._uuid}
              />
            );
          }}
          originalItems={contacts ?? []}
          uniqueKey={'_uuid'}
          afterMove={handlerAfterMove}
        />
      </div>
    );
  }
);
