import { Text } from '@consta/uikit/Text';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { knowledgeItemPageSelectors } from '../../../../../store/reducers/knowledgeItemPageSlice.ts';

export const LoaderFiles = () => {
  const loading = useAppSelector(knowledgeItemPageSelectors.loadingFilePercent);
  if (!loading) return null;
  return <Text view={'secondary'}>{loading}%</Text>;
};
