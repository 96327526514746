import { useRef, useState } from 'react';

import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

import { PortfolioDto } from '../../../../../generateAxios';

import classes from './PortfolioTitle.module.css';

export const PortfolioTitle = ({ portfolio }: { portfolio: PortfolioDto }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <div className={classes.container}>
      <Text
        size={'xl'}
        weight={'semibold'}
        truncate={!showMore}
        ref={ref}
        onClick={() => {
          if (ref.current && ref.current?.scrollWidth > ref.current?.clientWidth) {
            setShowMore(true);
          }
        }}
        onMouseEnter={(e) => {
          e.currentTarget.setAttribute(
            'style',
            ref.current && ref.current?.scrollWidth > ref.current?.clientWidth
              ? 'cursor: pointer;'
              : ''
          );
        }}
      >
        {portfolio.name}
      </Text>
      <Tag label={portfolio.block.short_name} mode={'info'} size={'s'} className={classes.tag} />
    </div>
  );
};
