import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { knowledgePageActions } from '../../../store/reducers/knowledgePageSlice.ts';
import {
  hasSomeRoleSelector,
  isAdminModeSelector,
  selectParamByKey,
} from '../../../store/reducers/selectors.ts';

import { KnowledgeLinksModal } from '../../components/Modals/KnowledgeLinksModal/KnowledgeLinksModal.tsx';
import { VideoSliderModal } from '../../components/Modals/VideoSliderModal/VideoSliderModal.tsx';
import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';
import { knowledgeConfig } from '../../configs/knowledgeConfig.ts';

import { HeroBlock } from './components/HeroBlock/HeroBlock.tsx';
import { KnowledgeCards } from './components/KnowledgeCards/KnowledgeCards.tsx';
import { KnowledgeFilters } from './components/KnowledgeFilters/KnowledgeFilters.tsx';
import { KnowledgeFiltersSideBar } from './components/KnowledgeFiltersSideBar/KnowledgeFiltersSideBar.tsx';
import { KnowledgeTable } from './components/KnowledgeTable/KnowledgeTable.tsx';

export const KnowledgePage = () => {
  const dispatch = useAppDispatch();
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const [view] = useAppSelector(selectParamByKey(knowledgeConfig.viewQueryKey));

  useEffect(() => {
    document.title = 'База знаний | Лаборатория данных';
    dispatch(knowledgePageActions.getKnowledgeMetrics());
    return () => {
      dispatch(knowledgePageActions.clearState());
    };
  }, []);

  useEffect(() => {
    view && localStorage.setItem(knowledgeConfig.viewLocalStorageKey, view);
  }, [view]);

  return (
    <>
      <HeroBlock />
      {isAdminMode && !hasRoles ? (
        <SwitchToUser403 />
      ) : (
        <>
          <KnowledgeFilters />
          {view === 'table' ? <KnowledgeTable /> : <KnowledgeCards />}

          <KnowledgeFiltersSideBar />
          <VideoSliderModal />
          <KnowledgeLinksModal />
        </>
      )}
    </>
  );
};
