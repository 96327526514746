import { createIcon } from '@consta/icons/Icon';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none">
    <path
      d="M16.004 0.5H48.004V8.488H56.004V0.5C58.125 0.501061 60.1588 1.34438 61.6583 2.84456C63.1577 4.34473 64 6.37896 64 8.5V56.5C64 58.621 63.1577 60.6553 61.6583 62.1554C60.1588 63.6556 58.125 64.4989 56.004 64.5V56.488H48.004V64.5H16.004V56.488H8.004V64.5C5.88227 64.5 3.84344 63.6571 2.34315 62.1569C0.842854 60.6566 0 58.6217 0 56.5V8.5C0 6.37827 0.842854 4.34344 2.34315 2.84315C3.84344 1.34285 5.87827 0.5 8 0.5L8.004 8.488H16.004V0.5ZM24 44.5L42 32.5L24 20.5V44.5ZM56.004 48.488V36.488H48.004V48.488H56.004ZM56.004 28.488V16.488H48.004V28.488H56.004ZM16.004 48.488V36.488H8.004V48.488H16.004ZM16.004 28.488V16.488H8.004V28.488H16.004Z"
      fill="#002033"
      fillOpacity="0.3"
    />
  </svg>
);

export const IconCustomMovie = createIcon({
  l: svg,
  m: svg,
  s: svg,
  xs: svg,
  name: 'IconCustomMovie',
});
