import { Text } from '@consta/uikit/Text';

export const KnowledgeEditorTitle = ({ title }: { title: string }) => {
  return (
    <div>
      <Text size={'xl'} weight={'semibold'}>
        {title}
      </Text>
    </div>
  );
};
