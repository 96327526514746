import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';

import { Collapse } from '@consta/uikit/Collapse';
import { Layout } from '@consta/uikit/Layout';
import { Text } from '@consta/uikit/Text';

import classes from './FilterSideBarCollapse.module.css';

export const FilterSideBarCollapse = ({
  showClear,
  title,
  clearFilter,
  children,
}: {
  showClear: boolean;
  title: string;
  clearFilter: () => void;
  children: ReactNode;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const blockRef = useRef<HTMLDivElement>(null);

  const handlerClearFilter = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    clearFilter();
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        // таймаут - чтобы успела отработать анимация коллапса
        if (blockRef.current) {
          // проверка на видимость блока на экране
          const rect = blockRef.current.getBoundingClientRect();
          const isVisible =
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
          if (!isVisible)
            blockRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            });
        }
      }, 300);
    }
  }, [open]);

  return (
    <Collapse
      className={classes.filterCollapse}
      horizontalSpace={'xl'}
      ref={blockRef}
      label={
        <div className={classes.filterTitle}>
          <Text size={'l'} weight={'bold'}>
            {title}
          </Text>
          {showClear && (
            <Text size={'s'} lineHeight={'xs'} view={'link'} onClick={(e) => handlerClearFilter(e)}>
              Сбросить
            </Text>
          )}
        </div>
      }
      isOpen={open}
      onClick={() => setOpen(!open)}
      iconPosition={'right'}
    >
      <Layout className={classes.collapseContent} direction={'column'}>
        {children}
      </Layout>
    </Collapse>
  );
};
