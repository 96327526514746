// объект PageRoutes мутирует при получении данных из /public/assets/config.json,
// для получения актуальны данных требуется массив ссылок должен формироваться
// в процессе вызова функции
import { PageRoutes } from './appRoutes.tsx';

export const getAppHeaderMenuItems = () => [
  { label: 'Главная', href: PageRoutes.DataLab_MainPage },
  {
    label: 'Инструменты',
    href: PageRoutes.DataLab_ToolsPage,
  },
  // {
  //   label: 'Обучение',
  //   extUrl: PageRoutes.DataLab_EducationPage,
  //   disabled: true,
  // },
  {
    label: 'База знаний',
    href: PageRoutes.DataLab_KnowledgesPage,
    disabled: false,
  },
  {
    label: 'Портфолио',
    href: PageRoutes.DataLab_PortfolioPage,
  },
  {
    label: 'FAQ',
    href: PageRoutes.DataLab_Faq,
  },
];
export const getAppHeaderAdminMenuItems = () => [
  ...getAppHeaderMenuItems().map((item) => ({ ...item, href: `${item.href}?mode=admin` })),
  {
    label: 'Метрики',
    href: `${PageRoutes.DataLab_MetricsPage}?mode=admin`,
  },
];
