import { useCallback } from 'react';

import { cnMixFlex } from '@consta/uikit/MixFlex';
import { Text } from '@consta/uikit/Text';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import {
  metricsPageActions,
  metricsPageSelectors,
} from '../../../../../store/reducers/metricsPageSlice.ts';
import { selectParamByKey } from '../../../../../store/reducers/selectors.ts';

import { MetricItem } from '../MetricItem/MetricItem.tsx';
import { metricsBlockQueryKey } from '../MetricsBlockSwitcher/MetricsBlockSwitcher.tsx';

export const MetricsContent = () => {
  const dispatch = useAppDispatch();
  const [blockUuid] = useAppSelector(selectParamByKey(metricsBlockQueryKey));
  const metrics = useAppSelector(metricsPageSelectors.metrics);
  const totalMetrics = useAppSelector(metricsPageSelectors.totalMetrics);
  if (!metrics || metrics.length === 0) return null;
  const currentBlock = metrics.find((item) => item.block?._uuid === blockUuid) ?? metrics[0];

  if (currentBlock.metrics && currentBlock.metrics.length === 0)
    return <Text>Нет доступных метрик</Text>;

  const changeMetric = useCallback(
    async (uuid: string, newValue: number) => {
      await dispatch(metricsPageActions.changeMetric({ uuid_metric: uuid, value: newValue }));
    },
    [dispatch]
  );

  return (
    <div className={cnMixFlex({ flex: 'flex', direction: 'column', gap: '5xl', align: 'stretch' })}>
      {currentBlock.metrics.map((item) => (
        <MetricItem
          key={item.metric._uuid}
          metric={item}
          total={totalMetrics[item.metric.key]}
          changeMetric={changeMetric}
        />
      ))}
    </div>
  );
};
