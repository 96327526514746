import { useEffect, useMemo, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconEyeClose } from '@consta/uikit/IconEyeClose';
import { IconTrash } from '@consta/uikit/IconTrash';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { ResponsesNothingFound } from '@consta/uikit/ResponsesNothingFound';
import { Text } from '@consta/uikit/Text';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  BlockDto,
  DomainDto,
  FeedbackDto,
  FilterUserDto,
  OrganizationDto,
  type PortfolioImageDto,
  ToolDto,
} from '../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { generalSelectors } from '../../../store/reducers/generalSlice.ts';
import {
  portfolioItemPageActions,
  portfolioItemPageSelectors,
} from '../../../store/reducers/portfolioItemPageSlice.ts';
import { isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { blpsRights, brdRights, cfRights } from '../../configs/roles.ts';
import { usePortfolioActions } from '../../hooks/usePortfolioActions.tsx';
import { checkPermission } from '../../utils/checkPermission.ts';

import { AppContentWrapper } from '../AppContentWrapper/AppContentWrapper.tsx';
import { PortfolioDeleteModal } from '../Modals/PortfolioDeleteModal/PortfolioDeleteModal.tsx';
import { PortfolioPreviewModal } from '../Modals/PortfolioPreviewModal/PortfolioPreviewModal.tsx';
import { PortfolioUnpublishModal } from '../Modals/PortfolioUnpublishModal/PortfolioUnpublishModal.tsx';

import classes from './PortfolioEditor.module.css';
import { PortfolioEditorAbout } from './components/PortfolioEditorAbout/PortfolioEditorAbout.tsx';
import { PortfolioEditorContent } from './components/PortfolioEditorContent/PortfolioEditorContent.tsx';
import { PortfolioEditorFeedbacks } from './components/PortfolioEditorFeedbacks/PortfolioEditorFeedbacks.tsx';
import { PortfolioEditorFormStatus } from './components/PortfolioEditorFormStatus/PortfolioEditorFormStatus.tsx';
import { PortfolioEditorImages } from './components/PortfolioEditorImages/PortfolioEditorImages.tsx';
import { PortfolioEditorInfo } from './components/PortfolioEditorInfo/PortfolioEditorInfo.tsx';
import { PortfolioEditorNewFeedbacks } from './components/PortfolioEditorNewFeedback/PortfolioEditorNewFeedbacks.tsx';
import { PortfolioEditorTitle } from './components/PortfolioEditorTitle/PortfolioEditorTitle.tsx';

export type PortfolioFormValues = {
  // about
  name: string;
  application_link: string;
  block: BlockDto;
  organizations: OrganizationDto[];
  domains?: DomainDto[] | null;
  tool: ToolDto;
  user: FilterUserDto;

  // content
  description: string;
  goals: string;
  results: string;
  users: string;
  plan: string;

  // images
  images?: (PortfolioImageDto | (PortfolioImageDto & { data?: File }))[];

  // текст для "как получить доступ"
  application_access_info?: string;

  // отзывы
  feedbacks?:
    | FeedbackDto[]
    | {
        user: FilterUserDto;
        body: string;
        guid: string;
      }[];
  newFeedback?: {
    user: FilterUserDto;
    body: string;
    guid: string;
  };
};

export const PortfolioEditor = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const portfolioError = useAppSelector(portfolioItemPageSelectors.portfolioItemError);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [unpublishModal, setUnpublishModal] = useState<boolean>(false);

  const isAdminMode = useAppSelector(isAdminModeSelector);
  const {
    portfolio,
    backToPortfolioCards,
    deletePortfolio,
    changeStatusPortfolio,
    loading: loadingDeleteChangeStatus,
  } = usePortfolioActions();

  const [loading, setLoading] = useState<boolean>(false);

  const [portfolioPreviewModal, setPortfolioPreviewModal] = useState<{
    data: PortfolioFormValues | null;
    open: boolean;
  }>({ data: null, open: false });

  const methods = useForm<PortfolioFormValues>({
    mode: 'onChange',
    defaultValues: !id
      ? {}
      : portfolio
        ? {
            ...portfolio,
          }
        : {},
  });

  const roles = useAppSelector(generalSelectors.userDetails)?.roles;
  const selectedBlock = methods.watch('block');

  const hasBlockRight = useMemo<boolean>(() => {
    switch (selectedBlock?.short_name) {
      case 'БРД':
        return checkPermission(brdRights, roles);
      case 'БЛПС':
        return checkPermission(blpsRights, roles);
      case 'КФ':
        return checkPermission(cfRights, roles);
    }
    return false;
  }, [roles, selectedBlock?.short_name]);

  const canPublish = isAdminMode && hasBlockRight;
  let saveButtonName = 'Отправить заявку';
  if (canPublish) {
    if (!portfolio || portfolio.status === 'MODERATION') {
      saveButtonName = 'Опубликовать';
    }
    if (portfolio && portfolio.status === 'PUBLISHED') {
      saveButtonName = 'Сохранить';
    }
  }

  useEffect(() => {
    dispatch(portfolioItemPageActions.getPortfolioSelectors());
  }, []);

  const submit = async (data: PortfolioFormValues) => {
    setLoading(true);
    let newFeedback = data.newFeedback;
    if (!newFeedback?.user?.sso_id || !newFeedback?.body) newFeedback = undefined;
    const validData: PortfolioFormValues = { ...data, newFeedback };
    const result = await dispatch(
      portfolioItemPageActions.savePortfolio({
        data: validData,
        isAdminMode,
        alreadyPublished: portfolio?.status === 'PUBLISHED',
      })
    );
    setLoading(false);
    result.type === 'portfolioItemPage/savePortfolio/fulfilled' && backToPortfolioCards();
  };

  const preview = (data: PortfolioFormValues) => {
    console.log('11');
    let newFeedback = data.newFeedback;
    if (!newFeedback?.user?.sso_id || !newFeedback?.body) newFeedback = undefined;
    const validData: PortfolioFormValues = { ...data, newFeedback };
    setPortfolioPreviewModal({ data: validData, open: true });
  };

  if (portfolioError === 404) {
    return (
      <AppContentWrapper width={'standard'} className={classes.pageContainer}>
        <ResponsesNothingFound
          actions={
            <Button
              view={'ghost'}
              label={'перейти к списку портфолио'}
              onClick={backToPortfolioCards}
            />
          }
        />
      </AppContentWrapper>
    );
  }

  return (
    <FormProvider {...methods}>
      <AppContentWrapper width={'standard'} className={classes.pageContainer}>
        <AppContentWrapper width={'wide'} className={classes.formContainer}>
          <PortfolioEditorTitle title={portfolio?.name} />
          <PortfolioEditorAbout />
          <PortfolioEditorContent />
          <PortfolioEditorImages />
          <div className={cnMixFlex({ direction: 'column', gap: 'l' })}>
            <Text size={'l'} weight={'medium'} className={cnMixSpace({ mB: '2xs' })}>
              Цитаты
            </Text>
            <PortfolioEditorFeedbacks />
            <PortfolioEditorNewFeedbacks />
          </div>
          {!canPublish && <PortfolioEditorInfo />}
          <div className={classes.buttonGroup}>
            <Button label={'Отмена'} view={'ghost'} onClick={backToPortfolioCards} />
            <Button
              label={'Предпросмотр'}
              view={'ghost'}
              onClick={methods.handleSubmit((data) => preview(data))}
            />
            <Button
              label={saveButtonName}
              onClick={methods.handleSubmit((data) => submit(data))}
              loading={loading}
            />
          </div>
        </AppContentWrapper>
        <AppContentWrapper width={'narrow'} className={classes.rightSide}>
          {id && (
            <div className={classes.actionButtons}>
              {portfolio?.status !== 'MODERATION' && (
                <Button
                  label={'Снять с публикации'}
                  view={'secondary'}
                  iconRight={IconEyeClose}
                  onClick={() => setUnpublishModal(true)}
                  // className={classNames({ [classes.hidden]: portfolio?.status === 'MODERATION' })}
                  disabled={portfolio?.read_only}
                  loading={loading || loadingDeleteChangeStatus}
                />
              )}
              <Button
                label={'Удалить'}
                view={'secondary'}
                iconRight={IconTrash}
                onClick={() => {
                  setDeleteModal(true);
                }}
                className={classes.deleteButton}
                disabled={portfolio?.read_only}
                loading={loading || loadingDeleteChangeStatus}
              />
            </div>
          )}
          <AppContentWrapper width={'narrow'} className={classes.infoCard}>
            <PortfolioEditorFormStatus
              onSubmit={methods.handleSubmit((data) => submit(data))}
              onPreview={methods.handleSubmit((data) => preview(data))}
              hasBlockRight={hasBlockRight}
              loading={loading}
            />
          </AppContentWrapper>
        </AppContentWrapper>
      </AppContentWrapper>
      <PortfolioPreviewModal
        setPortfolioPreviewModal={setPortfolioPreviewModal}
        portfolioPreviewModal={portfolioPreviewModal}
        goBack={backToPortfolioCards}
        saveButtonName={saveButtonName}
      />
      <PortfolioDeleteModal
        name={portfolio?.name}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={() => deletePortfolio(backToPortfolioCards)}
        loading={loadingDeleteChangeStatus}
      />
      <PortfolioUnpublishModal
        name={portfolio?.name}
        isOpen={unpublishModal}
        onUnpublish={() => changeStatusPortfolio(backToPortfolioCards)}
        onClose={() => setUnpublishModal(false)}
        loading={loadingDeleteChangeStatus}
      />
    </FormProvider>
  );
};
