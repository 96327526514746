import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import classes from './HeroBlock.module.css';

export const HeroBlock = () => {
  return (
    <div className={classes.container}>
      <div className={classes.greyBg}></div>
      <AppContentWrapper className={classes.content}>
        <AppBreadcrumbs
          crumbs={[
            { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
            { label: 'FAQ', rout: PageRoutes.DataLab_Faq },
          ]}
          classname={classes.breadcrumbs}
        />
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xl' })}>
          FAQ
        </Text>
        <Text size={'xl'} className={cnMixSpace({ mT: 'm', mB: '3xl' })}>
          Получите ответы на интересующие вас вопросы
        </Text>
      </AppContentWrapper>
    </div>
  );
};
