import { cnMixFlex } from '@consta/uikit/MixFlex';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

import dayjs from 'dayjs';

import { KnowledgeDto } from '../../../generateAxios';
import { OpenAPI } from '../../../generateAxios/core/OpenAPI.ts';
import { useAppSelector } from '../../../hooks/hooks.ts';
import { isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import imgErrorSettings from '../../assets/icons/svg/icn_settings_cross.svg';
import { KnowledgeCopyLinkButton } from '../../pages/KnowledgeItemPage/components/Buttons/KnowledgeCopyLinkButton.tsx';
import { KnowledgeDeleteButton } from '../../pages/KnowledgeItemPage/components/Buttons/KnowledgeDeleteButton.tsx';
import { KnowledgeEditButton } from '../../pages/KnowledgeItemPage/components/Buttons/KnowledgeEditButton.tsx';
import { KnowledgeLikeButton } from '../../pages/KnowledgeItemPage/components/Buttons/KnowledgeLikeButton.tsx';
import { KnowledgeShareButton } from '../../pages/KnowledgeItemPage/components/Buttons/KnowledgeShareButton.tsx';

import { ComplexityTag } from '../ComplexityTag/ComplexityTag.tsx';
import { FileForShowDtoWithData } from '../KnowledgeEditor/KnowledgeEditor.tsx';
import { ToolWithIcon } from '../ToolWithIcon/ToolWithIcon.tsx';

import classes from './KnowledgePreview.module.css';
import { KnowledgeContentFiles } from './components/KnowledgeContentFiles/KnowledgeContentFiles.tsx';
import { KnowledgeContentInternal } from './components/KnowledgeContentInternal/KnowledgeContentInternal.tsx';
import { KnowledgeContentLinks } from './components/KnowledgeContentLinks/KnowledgeContentLinks.tsx';
import { KnowledgeContentVideo } from './components/KnowledgeContentVideo/KnowledgeContentVideo.tsx';

// Определяем новый тип для KnowledgeDto, который будет использовать новый тип FileForShowDtoWithData,
// для использования предпросмотра в режиме редактирования с новыми загруженными файлами (видео)
export type KnowledgeDtoWithFileData = Omit<KnowledgeDto, 'files' | 'video_files'> & {
  files?: Array<FileForShowDtoWithData>;
  video_files?: Array<FileForShowDtoWithData>;
};

const knowledgeTypesValues: Record<KnowledgeDto['knowledge_type'], string> = {
  FILE: 'Файл',
  LINK: 'Ссылка',
  INTERNAL: 'Статья',
  VIDEO: 'Видео',
};

type PropsWithDelete = {
  knowledge: KnowledgeDtoWithFileData;
  showInternalActionButtons: true;
  onDelete: () => void;
};

type PropsWithoutDelete = {
  knowledge: KnowledgeDtoWithFileData;
  showInternalActionButtons?: false;
  onDelete?: never;
};

type Props = PropsWithDelete | PropsWithoutDelete;

export const KnowledgePreview = ({
  knowledge,
  showInternalActionButtons = false,
  onDelete,
}: Props) => {
  const isAdminMode = useAppSelector(isAdminModeSelector);

  const toolImgSrc = knowledge.tool?.uuid_icon
    ? `${OpenAPI.BASE}/image/${knowledge.tool.uuid_icon}`
    : imgErrorSettings;

  const dateValue = knowledge.date
    ? dayjs(knowledge.date).isValid()
      ? dayjs(knowledge.date).format('DD MMMM YYYY')
      : 'не валидная дата'
    : 'нет даты';

  return (
    <div className={classes.container}>
      <div className={classes.headerWrapper}>
        <div className={cnMixFlex({ gap: 'm', direction: 'column' })}>
          {/* блоки, инструмент, сложность */}
          <div className={cnMixFlex({ flex: 'flex', gap: 'xl' })}>
            <div className={cnMixFlex({ gap: 's' })}>
              {knowledge.blocks?.map((block) => {
                return <Tag key={block._uuid} label={block.short_name} mode={'info'} size={'s'} />;
              })}
            </div>
            <ToolWithIcon imgSrc={toolImgSrc} text={knowledge.tool?.name ?? ''} />
            <ComplexityTag
              level={knowledge.complexity?.level ?? -1}
              name={knowledge.complexity?.name ?? 'нет имени'}
            />
          </div>
          {/* тема */}
          <Text size={'xl'} weight={'semibold'} className={classes.text}>
            {knowledge.subject ?? 'нет заголовка'}
          </Text>
          {/* описание */}
          {knowledge.description && (
            <Text view={'secondary'} className={classes.text}>
              {knowledge.description}
            </Text>
          )}
          {/* тип и дата */}
          <div className={cnMixFlex({ gap: 'm' })}>
            <Text view={'secondary'}>{knowledgeTypesValues[knowledge.knowledge_type]}</Text>
            <Text view={'secondary'}>{dateValue}</Text>
          </div>
        </div>
        {/*кнопки действий не показываются в модалке*/}
        {showInternalActionButtons && knowledge.knowledge_type === 'INTERNAL' && (
          <div className={classes.actionButtons}>
            {isAdminMode ? (
              <>
                <KnowledgeEditButton knowledge={knowledge} />
                <KnowledgeDeleteButton knowledge={knowledge} onClick={onDelete!} />
              </>
            ) : (
              <>
                <KnowledgeShareButton knowledge={knowledge} />
                <KnowledgeCopyLinkButton />
                <KnowledgeLikeButton knowledge={knowledge} />
              </>
            )}
          </div>
        )}
      </div>
      {/*контент*/}
      {knowledge.knowledge_type === 'LINK' && <KnowledgeContentLinks knowledge={knowledge} />}
      {knowledge.knowledge_type === 'FILE' && <KnowledgeContentFiles knowledge={knowledge} />}
      {knowledge.knowledge_type === 'VIDEO' && <KnowledgeContentVideo knowledge={knowledge} />}
      {knowledge.knowledge_type === 'INTERNAL' && (
        <KnowledgeContentInternal knowledge={knowledge} />
      )}
    </div>
  );
};
