import { VideoHTMLAttributes } from 'react';

import { globalConfig } from '../../configs/globalConfig.ts';

type VideoAppProps = VideoHTMLAttributes<HTMLVideoElement> & {
  allowDownload?: boolean;
};

export const VideoApp = ({ children, allowDownload, ...rest }: VideoAppProps) => {
  allowDownload = allowDownload ?? globalConfig.allowDownloadVideo ?? false;
  return (
    <video
      controls
      playsInline
      preload={'auto'}
      controlsList={allowDownload ? '' : 'nodownload'}
      onDragStart={(event) => !allowDownload && event.preventDefault()}
      onContextMenu={(event) => !allowDownload && event.preventDefault()}
      {...rest}
    >
      {children}
    </video>
  );
};
