import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconQuestion } from '@consta/uikit/IconQuestion';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { IconOpenInNew } from '@consta/icons/IconOpenInNew';

import { PortfolioDto } from '../../../../generateAxios';
import { OpenAPI } from '../../../../generateAxios/core/OpenAPI.ts';
import imgErrorSettings from '../../../assets/icons/svg/icn_settings_cross.svg';
import { InfoCard } from '../../../components/InfoCard/InfoCard.tsx';
import { ApplicationGetAccessModal } from '../../../components/Modals/ApplicationGetAccessModal/ApplicationGetAccessModal.tsx';
import { validateURL } from '../../../utils/valudateURL.ts';

export const PortfolioAppLinkInfoCard = ({ portfolio }: { portfolio: PortfolioDto }) => {
  return (
    <InfoCard
      header={
        <img
          src={
            portfolio?.tool.uuid_image
              ? `${OpenAPI.BASE}/image/${portfolio?.tool.uuid_image}`
              : imgErrorSettings
          }
          onError={(event) => {
            event.currentTarget.src = imgErrorSettings;
          }}
          alt={'Изображение инструмента'}
          height={'32px'}
        />
      }
      content={'Вы можете использовать в своей работе приложения, созданные вашими коллегами'}
      footer={<CardFooter portfolio={portfolio} />}
    />
  );
};

const CardFooter = ({ portfolio }: { portfolio: PortfolioDto }) => {
  const [getAccessModal, setGetAccessModal] = useState<{ isOpen: boolean; content: string | null }>(
    { content: null, isOpen: false }
  );
  return (
    <>
      <div className={cnMixFlex({ flex: 'flex', align: 'center', justify: 'space-between' })}>
        <Button
          className={cnMixSpace({ pL: 'l' })}
          // style={{ visibility: portfolio.application_access_info ? 'visible' : 'hidden' }}
          disabled={!portfolio.application_access_info}
          view={'ghost'}
          hidden={!portfolio.application_access_info}
          iconRight={IconQuestion}
          label={'Как получить доступ'}
          onClick={() =>
            setGetAccessModal({
              content: portfolio.application_access_info ?? null,
              isOpen: true,
            })
          }
        />
        {portfolio.application_link ? (
          <Button
            view={'ghost'}
            iconRight={IconOpenInNew}
            iconSize={'s'}
            as={'a'}
            href={portfolio.application_link}
            onClick={(e) => {
              e.preventDefault();
              validateURL(portfolio.application_link) &&
                window.open(portfolio.application_link, '_blank');
            }}
          />
        ) : (
          <Button view={'ghost'} disabled iconRight={IconOpenInNew} iconSize={'s'} />
        )}
      </div>
      <ApplicationGetAccessModal
        content={getAccessModal.content}
        isOpen={getAccessModal.isOpen}
        onClose={() => setGetAccessModal((prev) => ({ content: prev.content, isOpen: false }))}
        afterClose={() => setGetAccessModal({ content: null, isOpen: false })}
      />
    </>
  );
};
