/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BlockBlockToolDto,
  FaqDto,
  FaqRestService,
  FullTeamItemDto,
  KnowledgeRestService,
  MetricRestService,
  MetricValueGroupDto,
  NsiRestService,
  PortfolioDto,
  PortfolioRestService,
  ToolBlockToolDto,
  UserTeamRestService,
} from '../../generateAxios';

import { createAppSlice } from '../createAppSlice';
import { AppDispatch } from '../store';

import { generalActions } from './generalSlice.ts';

type MainPageState = {
  teamItems: FullTeamItemDto[] | null;
  currentTeam: FullTeamItemDto | null;
  metrics: MetricValueGroupDto[] | null;
  metricsObject: Partial<Record<MetricValueGroupDto['key'], MetricValueGroupDto>>;
  teamEditModal: boolean;
  pageLoader: boolean;
  faqs: FaqDto[] | null;
  tools: ToolBlockToolDto[] | null;
  portfolios: PortfolioDto[] | null;
  loadingSaveTeam: boolean;
  toolLinksEditModal: boolean;
  toolLinksEditData: BlockBlockToolDto[] | null;
  toolLinksEditLoading: boolean;
  knowledgeTagUuids: Record<string, string>;
};

const initialState: MainPageState = {
  teamItems: null,
  currentTeam: null,
  teamEditModal: false,
  pageLoader: true,
  metrics: null,
  metricsObject: {},
  faqs: null,
  tools: null,
  portfolios: null,
  loadingSaveTeam: false,
  toolLinksEditModal: false,
  toolLinksEditData: null,
  toolLinksEditLoading: false,
  knowledgeTagUuids: {},
};

const mainPageSlice = createAppSlice({
  name: 'mainPage',
  initialState,
  reducers: (creators) => ({
    setTeamEditModal: creators.reducer<boolean>((state, action) => {
      state.teamEditModal = action.payload;
    }),
    setToolLinksEditModal: creators.reducer<boolean>((state, action) => {
      state.toolLinksEditModal = action.payload;
    }),
    setToolLinksEditData: creators.reducer<BlockBlockToolDto[] | null>((state, action) => {
      state.toolLinksEditData = action.payload;
    }),
    setCurrentTeam: creators.reducer<FullTeamItemDto | null>((state, action) => {
      state.currentTeam = action.payload;
    }),
    clearState: creators.reducer(() => {
      return initialState;
    }),

    getMainPageData: creators.asyncThunk(
      async (_: undefined, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const responses = await Promise.all([
          dispatch(mainPageActions.getTeams()),
          dispatch(mainPageActions.getMetrics()),
          dispatch(mainPageActions.getFaqs()),
          dispatch(mainPageActions.getTools()),
          dispatch(mainPageActions.getPortfolios()),
          dispatch(mainPageActions.getKnowledgeTagUuid()),
        ]);
        if (responses.every((res) => res.meta.requestStatus === 'fulfilled')) {
          return;
        } else {
          dispatch(
            generalActions.addNotification({
              status: 'warning',
              message: 'Возможны ошибки на странице',
            })
          );
          return thunkAPI.rejectWithValue('Error');
        }
      },
      {
        fulfilled: () => {},
        rejected: () => {},
        settled: (state) => {
          state.pageLoader = false;
        },
      }
    ),
    getTeams: creators.asyncThunk(
      async (_: undefined) => {
        const res = await UserTeamRestService.getTeamItemsWithHelpDesks();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.teamItems = action.payload;
        },
        rejected: (_) => {},
      }
    ),
    saveTeam: creators.asyncThunk(
      async (newTeam: FullTeamItemDto, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const res = await UserTeamRestService.saveTeamItemsWithHelpDesks(newTeam);
        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: 'Контакты успешно сохранены',
          })
        );
        return res;
      },
      {
        pending: (state) => {
          state.loadingSaveTeam = true;
        },
        fulfilled: (state, action) => {
          if (state.teamItems) {
            state.teamItems = state.teamItems.map((item) =>
              item.block._uuid === action.payload.block._uuid ? action.payload : item
            );
          }
          state.teamEditModal = false;
        },
        rejected: (_) => {},
        settled: (state) => {
          state.loadingSaveTeam = false;
        },
      }
    ),
    getMetrics: creators.asyncThunk(
      async (_: undefined) => {
        const res = await MetricRestService.getGroupMetrics();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.metrics = action.payload;
          state.metricsObject = action.payload.reduce(
            (acc, item) => ({ ...acc, [item.key]: item }),
            {}
          );
        },
        rejected: (_) => {},
      }
    ),
    getFaqs: creators.asyncThunk(
      async (_: undefined) => {
        const res = await FaqRestService.getFaqsMain();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.faqs = action.payload;
        },
        rejected: (_) => {},
      }
    ),
    getTools: creators.asyncThunk(
      async (_: undefined) => {
        const res = await NsiRestService.getToolWithInstances();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.tools = action.payload;
        },
        rejected: (_) => {},
      }
    ),
    getPortfolios: creators.asyncThunk(
      async (_: undefined) => {
        const res = await PortfolioRestService.getForMainPage();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.portfolios = action.payload;
        },
        rejected: (_) => {},
      }
    ),
    getToolLinksData: creators.asyncThunk(
      async (_: undefined) => {
        const res = await NsiRestService.getBlockWithInstances();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.toolLinksEditData = action.payload;
        },
        rejected: (_) => {},
      }
    ),
    saveToolLinks: creators.asyncThunk(
      async (arg: { data: BlockBlockToolDto[]; callback?: () => void | Promise<any> }) => {
        const res = await NsiRestService.saveBlockWithInstances(arg.data);
        if (arg.callback) await arg.callback();
        return res;
      },
      {
        pending: (state) => {
          state.toolLinksEditLoading = true;
        },
        fulfilled: (state, action) => {
          state.tools = action.payload;
          state.toolLinksEditModal = false;
          state.toolLinksEditLoading = false;
        },
        rejected: (_) => {},
      }
    ),
    /* формирование объекта типа {[hash_key]:uuid} чтобы получить UUID тега для формирования ссылок на материалы базы знаний с главной страницы*/
    getKnowledgeTagUuid: creators.asyncThunk(
      async (_: undefined) => {
        const res = await KnowledgeRestService.getKnowledgeFilters();
        const tags = res.tags ?? [];
        return tags.reduce(
          (acc, tag) => (tag.hash_key ? { ...acc, [tag.hash_key]: tag._uuid } : acc),
          {}
        );
      },
      {
        fulfilled: (state, action) => {
          state.knowledgeTagUuids = action.payload;
        },
        rejected: (_) => {},
      }
    ),
  }),

  selectors: {
    pageLoader: (state) => state.pageLoader,
    currentTeam: (state) => state.currentTeam,
    teamEditModal: (state) => state.teamEditModal,
    toolLinksEditModal: (state) => state.toolLinksEditModal,
    teamItems: (state) => state.teamItems,
    metricsObject: (state) => state.metricsObject,
    faqs: (state) => state.faqs,
    tools: (state) => state.tools,
    portfolios: (state) => state.portfolios,
    loadingSaveTeam: (state) => state.loadingSaveTeam,
    toolLinksEditData: (state) => state.toolLinksEditData,
    toolLinksEditLoading: (state) => state.toolLinksEditLoading,
    knowledgeTagUuids: (state) => state.knowledgeTagUuids,
  },
});

export const mainPageActions = mainPageSlice.actions;
export const mainPageSelectors = mainPageSlice.getSelectors(mainPageSlice.selectSlice);

export default mainPageSlice.reducer;
