type LinksConfig = {
  // guide_link: string;
  // task_slider_last_element_link: string;
  // excel_link: string;
  // publish_link: string;
  old_blps_market: string;
  old_brd_analog_market: string;
  new_market?: string;
  // приходит с бэка
  // support_blps: string;
  // support_brd: string;
  // support_kf: string;
};

// настройки по умолчанию, новые настройки приходят из файла /public/config.json
// для исключения использования не актуальных данных использовать только внутри компонент
export const linksConfig: LinksConfig = {
  // guide_link: '/knowledge?tag=SYSTEM_PROCESS',
  // task_slider_last_element_link: '/knowledge?tag=SYSTEM_TASK_STAGING',
  // excel_link: '/knowledge?tag=SYSTEM_EXCEL',
  // publish_link: '/knowledge?tag=SYSTEM_ANALYTICAL_APPLICATION',
  old_blps_market: 'https://data-blps.gazprom-neft.local/data-market?tab=tables',
  old_brd_analog_market: 'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=131829725',
  // ссылка берется только из конфига, значения по -умолчанию нет
  new_market: undefined,
  // new_market: 'https://market.berzhok.ru/release1/datasets/all',

  // приходит с бэка
  // support_brd:
  //   'http://selfservice.gazprom-neft.local/SitePages/Home20.aspx#/lk/s2|s3|697|147|272|670',
  // support_blps: 'http://selfservice.gazprom-neft.local/SitePages/Home20.aspx#/lk/s2|s3|479|574|495',
  // support_kf:
  //   'http://selfservice.gazprom-neft.local/SitePages/Home20.aspx#/lk/s2|s3|697|698|365|453',
};

/**
 * @description ключи для фильтрации по тегам
 * @key tag_analytical_application - аналитическое приложение
 * @key tag_task_staging - постановка задач
 * @key tag_process - процесс
 * @key tag_excel - excel
 *
 * мутирует в общем конфиге
 * */
type TagHashKeysConfig = {
  tag_analytical_application: string;
  tag_task_staging: string;
  tag_process: string;
  tag_excel: string;
};
export const tagHashKeysConfig: TagHashKeysConfig = {
  tag_analytical_application: 'SYSTEM_ANALYTICAL_APPLICATION',
  tag_task_staging: 'SYSTEM_TASK_STAGING',
  tag_process: 'SYSTEM_PROCESS',
  tag_excel: 'SYSTEM_EXCEL',
};
