import React from 'react';

import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import classes from './filterSideBarHeader.module.css';

export const FilterSideBarHeader = ({
  resetAll,
  count,
}: {
  count?: string | number;
  resetAll: () => void;
}) => {
  return (
    <div className={classes.container}>
      <Text weight={'bold'}>Фильтры{count ? ` (${count})` : ''}</Text>
      {!!count && (
        <Button
          className={cnMixSpace({ pL: 'm', pR: 'm' })}
          label={'Сбросить фильтры'}
          size={'xs'}
          view={'ghost'}
          onClick={resetAll}
        />
      )}
    </div>
  );
};
