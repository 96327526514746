import { useMemo, useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconForward } from '@consta/uikit/IconForward';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useAppSelector } from '../../../../../../../hooks/hooks.ts';
import { mainPageSelectors } from '../../../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../../../appMenuAndRouting/appRoutes.tsx';
import filtersIcon from '../../../../../../assets/icons/svg/icn_main_about_process_task_filters.svg';
import goalIcon from '../../../../../../assets/icons/svg/icn_main_about_process_task_goal.svg';
import modelIcon from '../../../../../../assets/icons/svg/icn_main_about_process_task_model.svg';
import questionIcon from '../../../../../../assets/icons/svg/icn_main_about_process_task_question.svg';
import smileIcon from '../../../../../../assets/icons/svg/icn_main_about_process_task_smile.svg';
import { knowledgeConfig } from '../../../../../../configs/knowledgeConfig.ts';
import { tagHashKeysConfig } from '../../../../../../configs/linksConfig.ts';

import { TaskItem } from './TaskItem/TaskItem.tsx';
import classes from './TaskSlider.module.css';

export type SliderItem = {
  icon?: string;
  title?: string;
  desc: string;
  link?: string | null;
};

export const TaskSlider = () => {
  let sliderRef = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const tags = useAppSelector(mainPageSelectors.knowledgeTagUuids);

  // формирование ссылки для 'Читать подробнее о каждом шаге описания задачи'
  const taskLink: string | null = useMemo(() => {
    const tagUuid = tags[tagHashKeysConfig.tag_task_staging];
    if (!tagUuid) return null;
    return `${PageRoutes.DataLab_KnowledgesPage}?${knowledgeConfig.filterTagsQueryKey}=${tagUuid}${isAdminMode ? '&mode=admin' : ''}`;
  }, [tags, isAdminMode]);

  const items: SliderItem[] = useMemo(
    () => [
      { icon: smileIcon, title: 'Аудитория', desc: 'Кто будет пользоваться приложением?' },
      {
        icon: questionIcon,
        title: 'Вопросы',
        desc: 'На какие вопросы пользователей должно ответить приложение?',
      },
      { icon: goalIcon, title: 'Показатели', desc: 'Какие показатели помогут на них ответить?' },
      {
        icon: filtersIcon,
        title: 'Макет',
        desc: 'В каких разрезах нужно детализировать показатели?',
      },
      {
        icon: modelIcon,
        title: 'Аудитория',
        desc: 'Какие визуализации выбрать и как разместить их на дашборде?',
      },
      {
        desc: 'Читать подробнее о каждом шаге описания задачи',
        link: taskLink,
      },
    ],
    [taskLink]
  );

  return (
    <div className={classes.container}>
      <Slider
        ref={(slider) => {
          sliderRef = { current: slider };
        }}
        afterChange={setCurrentSlide}
        arrows={false}
        dots={true}
        infinite={false}
        speed={300}
        slidesToShow={3}
        slidesToScroll={1}
        appendDots={(dots) => (
          <div>
            <ul className={classes.dotContainer}>{dots}</ul>
          </div>
        )}
        customPaging={() => <div className={classes.dot}></div>}
        adaptiveHeight={false}
      >
        {items.map((item, index) => (
          <TaskItem key={index} item={item} />
        ))}
      </Slider>
      <div className={classes.arrows}>
        <Button
          iconRight={IconBackward}
          view={'ghost'}
          form={'round'}
          disabled={currentSlide === 0}
          onlyIcon
          onClick={() => sliderRef.current?.slickPrev()}
        />
        <Button
          iconRight={IconForward}
          view={'ghost'}
          form={'round'}
          disabled={currentSlide === items.length - 3}
          onlyIcon
          onClick={() => sliderRef.current?.slickNext()}
        />
      </div>
    </div>
  );
};
