import { useEffect } from 'react';

import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { HelpdeskDto } from '../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import {
  HelpdeskType,
  generalActions,
  generalSelectors,
} from '../../../../../store/reducers/generalSlice.ts';
import { AppModal } from '../../../../components/AppModal/AppModal.tsx';
import { Divider } from '../../../../components/Divider/Divider.tsx';

import classes from './HelpeskEditModal.module.css';

interface FormValues {
  links: HelpdeskDto[];
}

// Регулярное выражение для проверки валидных ссылок
// const urlRegex = /^https?:\/\/([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
const urlRegex = /^https?:\/\//;

export const HelpdeskEditModal = () => {
  const dispatch = useAppDispatch();
  const helpdeskEditModal = useAppSelector(generalSelectors.helpdeskEditModal);
  const helpdeskLinks = useAppSelector(generalSelectors.helpdeskLinks);
  const helpdeskLearning = useAppSelector(generalSelectors.helpdeskLearning);
  const loadingHelpdesk = useAppSelector(generalSelectors.loadingHelpdesk);
  const links =
    helpdeskEditModal === 'HELPDESK'
      ? helpdeskLinks
      : helpdeskEditModal === 'LEARNING'
        ? helpdeskLearning
        : null;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      links: links?.filter((el) => !el.read_only) || [],
    },
  });

  const { fields } = useFieldArray({ control, name: 'links' });

  useEffect(() => {
    if (helpdeskEditModal) {
      reset({ links: links?.filter((el) => !el.read_only) || [] });
    }
  }, [links, helpdeskEditModal, reset]);

  const onSubmit = (data: FormValues) =>
    helpdeskEditModal &&
    dispatch(generalActions.saveHelpdesk({ helpdesks: data.links, type: helpdeskEditModal }));

  useEffect(() => {
    helpdeskEditModal && dispatch(generalActions.getHelpdesk(helpdeskEditModal));
  }, [helpdeskEditModal]);

  const cancel = () => dispatch(generalActions.setHelpdeskEditModal(null));

  const typeName = (helpdeskEditModal: HelpdeskType | null) => {
    if (!helpdeskEditModal) return '';
    return {
      LEARNING: 'на обучение',
      HELPDESK: 'на консультацию',
      EMAIL: '',
      RESOURCE: '',
    }[helpdeskEditModal];
  };

  return (
    <AppModal
      isOpen={!!helpdeskEditModal}
      close={() => {
        dispatch(generalActions.setHelpdeskEditModal(null));
      }}
      className={classes.modal}
    >
      <Text
        size={'xl'}
        weight={'medium'}
        className={cnMixSpace({ mT: 'l', mB: 'l', mL: '2xl', mR: '4xl' })}
      >
        {fields?.length === 1
          ? `Ссылка ${typeName(helpdeskEditModal)} ${fields[0].block?.short_name}`
          : `Ссылки ${typeName(helpdeskEditModal)}`}
      </Text>
      <Divider />
      <div className={classes.content}>
        {!links && (
          <div className={classes.loaderWrapper}>
            <Loader />
          </div>
        )}
        {links && links.filter((el) => !el.read_only).length === 0 && (
          <div className={classes.form}>
            <Text>Нет ссылок доступных для редактирования</Text>
          </div>
        )}
        {links && (
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            {fields.map((el, index) => (
              <Controller
                key={el._uuid}
                name={`links.${index}.link`}
                control={control}
                rules={{
                  required: 'Поле обязательно для заполнения',
                  pattern: {
                    value: urlRegex,
                    message: 'Введите корректный URL',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={
                      fields?.length === 1
                        ? 'Ссылка'
                        : el.block?.short_name ?? 'Имя блока не указано'
                    }
                    placeholder={`https:// - введите ссылку для блока ${el.block?.short_name} `}
                    onChange={({ value }) => {
                      field.onChange(value ?? '');
                    }}
                    width={'full'}
                    status={errors.links?.[index]?.link ? 'alert' : undefined}
                    caption={errors.links?.[index]?.link?.message}
                  />
                )}
              />
            ))}
          </form>
        )}
      </div>
      <Divider />
      <div className={classes.buttonsGroup}>
        <Button label={'Отмена'} view={'ghost'} onClick={cancel} />
        <Button
          label={'Сохранить'}
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || isSubmitting}
          loading={loadingHelpdesk}
        />
      </div>
    </AppModal>
  );
};
