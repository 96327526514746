import { useEffect, useState } from 'react';

import { Button } from '@consta/uikit/Button';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BlockDto,
  type ComplexityDto,
  FileForShowDto,
  KnowledgeDto,
  type LinkKnowledgeDto,
  type TagDto,
  ToolDto,
} from '../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import {
  knowledgeItemPageActions,
  knowledgeItemPageSelectors,
} from '../../../store/reducers/knowledgeItemPageSlice.ts';
import { isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';

import { AppContentWrapper } from '../AppContentWrapper/AppContentWrapper.tsx';
import {
  KnowledgePreviewModal,
  KnowledgePreviewModalType,
} from '../Modals/KnowledgePreviewModal/KnowledgePreviewModal.tsx';

import classes from './KnowledgeEditor.module.css';
import { KnowledgeEditorAbout } from './components/KnowledgeEditorAbout/KnowledgeEditorAbout.tsx';
import { KnowledgeEditorContent } from './components/KnowledgeEditorContent/KnowledgeEditorContent.tsx';
import { KnowledgeEditorTitle } from './components/KnowledgeEditorTitle/KnowledgeEditorTitle.tsx';
import { KnowledgeEditorTypeSwitcher } from './components/KnowledgeEditorTypeSwitcher/KnowledgeEditorTypeSwitcher.tsx';
import { LoaderFiles } from './components/LoaderFiles/LoaderFiles.tsx';

// с добавлением поля data?: File
export type FileForShowDtoWithData = FileForShowDto & {
  data?: File;
};

export type KnowledgeFormValues = {
  // typeSwitcher
  // knowledge_type: 'INTERNAL' | 'LINK' | 'FILE' | 'VIDEO';
  knowledge_type: KnowledgeDto['knowledge_type'];

  // about
  blocks?: BlockDto[];
  subject: string;
  description?: string;
  tags?: TagDto[];
  complexity?: ComplexityDto;
  tool?: ToolDto;

  // content
  wide_body?: boolean;
  body?: string;
  links?: LinkKnowledgeDto[];
  newLink?: LinkKnowledgeDto;
  files?: FileForShowDtoWithData[];
  video_files?: FileForShowDtoWithData[];
};

export const KnowledgeEditor = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const saveButtonName = id ? 'Сохранить' : 'Опубликовать';
  const [knowledgePreviewModal, setKnowledgePreviewModal] = useState<KnowledgePreviewModalType>({
    open: false,
    data: null,
  });

  const isAdminMode = useAppSelector(isAdminModeSelector);
  const knowledge = useAppSelector(knowledgeItemPageSelectors.knowledge);

  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<KnowledgeFormValues>({
    mode: 'onChange',
    defaultValues: !id
      ? { knowledge_type: 'VIDEO' }
      : knowledge
        ? {
            ...knowledge,
          }
        : {},
  });

  const goBack = () => {
    navigate(`${PageRoutes.DataLab_KnowledgesPage}${isAdminMode ? '?mode=admin' : ''}`);
  };

  useEffect(() => {
    dispatch(knowledgeItemPageActions.getKnowledgeSelectors());
  }, []);

  const submit = async (data: KnowledgeFormValues) => {
    setLoading(true);
    const result = await dispatch(knowledgeItemPageActions.saveKnowledge({ data }));
    setLoading(false);
    result.type === 'knowledgeItemPage/saveKnowledge/fulfilled' && goBack();
  };

  const preview = (data: KnowledgeFormValues) => {
    setKnowledgePreviewModal({ data, open: true });
  };

  return (
    <FormProvider {...methods}>
      <AppContentWrapper width={'standard'} className={classes.pageContainer}>
        <AppContentWrapper width={'wide'} className={classes.formContainer}>
          <KnowledgeEditorTitle
            title={knowledge ? 'Редактирование материала' : 'Добавление материала'}
          />
          <KnowledgeEditorTypeSwitcher />
          <KnowledgeEditorAbout />
          <KnowledgeEditorContent />
          <div className={classes.buttonGroup}>
            <LoaderFiles />
            <Button label={'Отмена'} view={'ghost'} onClick={goBack} />
            <Button
              label={'Предпросмотр'}
              view={'ghost'}
              onClick={methods.handleSubmit((data) => preview(data))}
            />
            <Button
              label={saveButtonName}
              onClick={methods.handleSubmit((data) => submit(data))}
              loading={loading}
            />
          </div>
        </AppContentWrapper>
        {/*<AppContentWrapper width={'narrow'} className={classes.infoCard}>*/}
        {/*  <PortfolioEditorFormStatus*/}
        {/*    onSubmit={methods.handleSubmit((data) => submit(data))}*/}
        {/*    onPreview={methods.handleSubmit((data) => preview(data))}*/}
        {/*    hasBlockRight={hasBlockRight}*/}
        {/*  />*/}
        {/*</AppContentWrapper>*/}
      </AppContentWrapper>
      <KnowledgePreviewModal
        knowledgePreviewModal={knowledgePreviewModal}
        setKnowledgePreviewModal={setKnowledgePreviewModal}
        goBack={goBack}
      />
    </FormProvider>
  );
};
