import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { IconSettings } from '@consta/uikit/IconSettings';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useNavigate } from 'react-router-dom';

import { relativeToAbsoluteLink } from '../../../../../utils/relativeToAbsoluteLink.ts';

import classes from './IncreasingCompetenciesCard.module.scss';

type BaseProps = {
  title: string;
  description: string;
  img: string;
  value: string;
  valueDescription: string;
  disabled?: boolean;
  settingAction?: () => void;
};

type Props =
  | (BaseProps & { link?: string; action?: never })
  | (BaseProps & { link?: never; action?: () => void });

export const IncreasingCompetenciesCard = ({
  img,
  title,
  description,
  link, // ссылка только на текущий портал
  action,
  value,
  valueDescription,
  settingAction,
  disabled = false,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      {settingAction && (
        <Button
          iconLeft={IconSettings}
          view={'ghost'}
          className={classes.settingsButton}
          onClick={settingAction}
        />
      )}
      <div className={classes.titleBlock}>
        <Text size={'l'} weight={'semibold'} className={cnMixSpace({ mT: 's' })}>
          {title}
        </Text>

        <Text className={cnMixSpace({ mT: 'xs' })}>{description}</Text>

        {disabled && (
          <Button
            view={'clear'}
            form={'round'}
            iconRight={IconForward}
            className={cnMixSpace({ mT: 'l' })}
            label={'Скоро появится'}
            disabled
          />
        )}

        {link && !disabled && (
          <Button
            as={'a'}
            href={relativeToAbsoluteLink(link)}
            view={'clear'}
            form={'round'}
            iconRight={IconForward}
            className={cnMixSpace({ mT: 'l' })}
            label={'Открыть'}
            onClick={(event) => {
              event.preventDefault();
              navigate(link);
            }}
          />
        )}

        {action && !disabled && (
          <Button
            view={'clear'}
            form={'round'}
            iconRight={IconForward}
            className={cnMixSpace({ mT: 'l' })}
            label={'Открыть'}
            onClick={action}
          />
        )}
      </div>
      <div className={classes.valueBlock}>
        <img src={img} alt="" width={'264px'} height={'220px'} />
        <div>
          <Text size={'3xl'} view={'link'} weight={'bold'}>
            {value}
          </Text>
          <Text weight={'semibold'}>{valueDescription}</Text>
        </div>
      </div>
    </div>
  );
};
