import { ReactNode } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Text } from '@consta/uikit/Text';

import { IconComponent } from '@consta/icons/__internal__/src/icons/Icon/helpers';

import classNames from 'classnames';

import { AppModal } from '../AppModal/AppModal.tsx';

import classes from './DeleteModal.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  cancelAction?: () => void;
  confirmAction: () => void;
  title: string;
  content?: ReactNode;
  loading?: boolean;
  deleteButtonIcon?: IconComponent;
  deleteButtonLabel?: string;
};
export const DeleteModal = ({
  onClose,
  loading,
  confirmAction,
  cancelAction,
  isOpen,
  content,
  title,
  deleteButtonIcon = IconTrash,
  deleteButtonLabel = 'Удалить',
}: Props) => {
  return (
    <AppModal
      isOpen={isOpen}
      close={onClose}
      className={classes.modal}
      hideCloseIcon={loading}
      disableCloseOnClickOutside={loading}
      disableCloseOnEsc={loading}
    >
      <Text as="p" size="xl" view="primary" lineHeight="xs" weight={'bold'}>
        {title}
      </Text>
      <Text as="p" view="primary" lineHeight="s" className={classes.text}>
        {content}
      </Text>
      <div className={classes.divider}></div>
      <div className={classes.buttonGroup}>
        <Button
          view={'ghost'}
          label={'Отменить'}
          onClick={cancelAction ? cancelAction : onClose}
          disabled={loading}
          className={classes.button}
        />
        <Button
          view={'primary'}
          label={deleteButtonLabel}
          loading={loading}
          iconLeft={deleteButtonIcon}
          onClick={loading ? undefined : confirmAction}
          className={classNames(classes.deleteButton, classes.buttonWithLeftIcon)}
        />
      </div>
    </AppModal>
  );
};
