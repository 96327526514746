import { memo } from 'react';

import { useFormContext } from 'react-hook-form';

import { FullTeamItemDto, type HelpdeskForShowDto } from '../../../../../../../generateAxios';
import { DnDSortList } from '../../../../../../components/DnDSortList/DnDSortList.tsx';

import { ListTitle } from '../ListTitle/ListTitle.tsx';
import { ResourceListItem } from '../ResourceListItem/ResourceListItem.tsx';

export const DraggableEmails = memo(
  ({
    onDelete,
    onEdit,
    selectedEmail,
    onAdd,
  }: {
    onEdit: (item: HelpdeskForShowDto) => void;
    onDelete: (item: HelpdeskForShowDto) => void;
    onAdd: () => void;
    selectedEmail: HelpdeskForShowDto | null;
  }) => {
    const { watch, setValue } = useFormContext<FullTeamItemDto>();

    const emails = watch('emails');

    const handlerAfterMove = (items: HelpdeskForShowDto[]) => {
      setValue('emails', items);
    };

    return (
      <>
        <ListTitle title={'Список адресов почты'} buttonLabel={'Добавить адрес'} onClick={onAdd} />
        <DnDSortList
          type={'email'}
          renderItem={(item) => {
            return (
              <ResourceListItem
                item={item}
                onClickEdit={onEdit}
                onClickDelete={onDelete}
                isSelected={selectedEmail?._uuid === item._uuid}
              />
            );
          }}
          originalItems={emails ?? []}
          uniqueKey={'_uuid'}
          afterMove={handlerAfterMove}
        />
      </>
    );
  }
);
