import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { useNavigate } from 'react-router-dom';

import { KnowledgeDto } from '../../../../../generateAxios';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';

export const KnowledgeEditButton = ({ knowledge }: { knowledge: KnowledgeDto }) => {
  const navigate = useNavigate();
  const editKnowledge = () => {
    knowledge?._uuid &&
      navigate(
        `${PageRoutes.DataLab_KnowledgeEditPage.replace(':id', knowledge._uuid)}?mode=admin `
      );
  };
  return (
    <Button
      label={'Редактировать'}
      className={cnMixSpace({ pR: 'm', pL: 'm' })}
      onClick={editKnowledge}
      disabled={knowledge.read_only}
    />
  );
};
