import {
  type FeedbackTempDto,
  LikesRestService,
  type PortfolioCrudDto,
  PortfolioDto,
  PortfolioImageShortDto,
  PortfolioRestService,
  PortfolioSelectorsDto,
  ToolDto,
} from '../../generateAxios';
import { ApiError } from '../../generateAxios/core/ApiError.ts';
import { PortfolioFormValues } from '../../src/components/PortfolioEditor/PortfolioEditor.tsx';

import { createAppSlice } from '../createAppSlice';
import { AppDispatch, RootState } from '../store.ts';

import { generalActions } from './generalSlice.ts';

type PortfolioItemPageState = {
  portfolio: PortfolioDto | null;
  portfolioSelectors: PortfolioSelectorsDto | null;
  // organizationsOptions: OrganizationDto[] | null;
  // domainOptions: DomainDto[] | null;
  toolsOptions: ToolDto[] | null;
  pageLoader: boolean;
  error?: number | null;
};

const initialState: PortfolioItemPageState = {
  portfolio: null,
  pageLoader: true,
  // organizationsOptions: null,
  // domainOptions: null,
  toolsOptions: null,
  portfolioSelectors: null,
  error: null,
};

const portfolioItemPageSlice = createAppSlice({
  name: 'portfolioItemPage',
  initialState,
  reducers: (creators) => ({
    clearState: creators.reducer(() => {
      return initialState;
    }),
    getPortfolioItem: creators.asyncThunk<
      PortfolioDto | undefined,
      { portfolioUuid: string },
      {
        rejectValue: { code?: number };
      }
    >(
      async ({ portfolioUuid }, { rejectWithValue, signal }) => {
        const promise = PortfolioRestService.getPortfolioById(portfolioUuid);
        signal.onabort = () => {
          promise.cancel();
        };
        try {
          const res = await promise;
          return res;
        } catch (e) {
          if (e instanceof ApiError)
            return rejectWithValue({
              code: e.status,
            });
        }
      },
      {
        pending: (state) => {
          state.pageLoader = true;
        },
        fulfilled: (state, action) => {
          action.payload && (state.portfolio = action.payload);
          state.pageLoader = false;
        },
        rejected: (state, action) => {
          if (action.payload?.code === 404) {
            state.error = 404;
          }
          state.portfolio = null;
          state.pageLoader = false;
        },
      }
    ),

    setPortfolioLike: creators.asyncThunk(
      async ({ portfolio, action }: { portfolio: PortfolioDto; action: 'add' | 'delete' }) => {
        if (action === 'add') {
          const res = await LikesRestService.addLike({
            _uuid: '00000000-0000-0000-0000-000000000000',
            object_type: 'PORTFOLIO',
            uuid_object: portfolio._uuid,
            thumb_up: 1,
          });
          return res;
        } else {
          const res = await LikesRestService.deleteLike(portfolio._uuid);
          return res;
        }
      },
      {
        fulfilled: (state, action) => {
          state.portfolio = state.portfolio
            ? (state.portfolio = {
                ...state.portfolio,
                count_like_by_user: action.payload.count_like_by_user,
                count_like_all: action.payload.count_like_all,
              })
            : null;
        },
      }
    ),
    getPortfolioSelectors: creators.asyncThunk(
      async () => {
        const res = await PortfolioRestService.getSelectors();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.portfolioSelectors = action.payload;
        },
      }
    ),
    savePortfolio: creators.asyncThunk(
      async (
        {
          data,
          isAdminMode,
          alreadyPublished,
        }: { data: PortfolioFormValues; isAdminMode: boolean; alreadyPublished: boolean },
        thunkAPI
      ) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as RootState;
        const portfolioUuid = state.portfolioItemPage.portfolio?._uuid;

        // разделяем новые и уже загруженные картинки
        const images = data.images?.reduce(
          (acc, el, index) => {
            if ('data' in el && el.data instanceof File) {
              acc.newImages.push({
                description: el.description,
                ord: index,
                data: el.data,
                file_name: el.origin_file_name,
                guid: el.uuid_image,
              });
            } else {
              acc.loadedImages.push({
                description: el.description,
                ord: index,
                guid: el.uuid_image,
              });
            }
            return acc;
          },
          {
            loadedImages: [] as PortfolioImageShortDto[],
            newImages: [] as (PortfolioImageShortDto & { data: File })[],
          }
        ) ?? {
          loadedImages: [] as PortfolioImageShortDto[],
          newImages: [] as (PortfolioImageShortDto & { data: File })[],
        };
        const { loadedImages, newImages } = images;

        let newImagesWithUuid: PortfolioImageShortDto[] = [];

        // сохраняем новые картинки если они есть и создаем данные с uuid
        if (newImages.length > 0) {
          const resImages = await PortfolioRestService.saveNewPortfolioImages({
            images: newImages.map((el) => el.data),
          });
          newImagesWithUuid = resImages.map((el, index) => ({
            ...el,
            ord: newImages[index].ord,
            description: newImages[index].description,
          }));
        }

        // приводим к типу новый отзыв
        const newFeedback: FeedbackTempDto[] =
          data.newFeedback?.user.username && data.newFeedback.body
            ? [
                {
                  username: data.newFeedback.user.username,
                  body: data.newFeedback.body,
                  guid: '00000000-0000-0000-0000-000000000000',
                },
              ]
            : [];
        // приводим к типу добавленные отзывы
        const feedbacks: FeedbackTempDto[] =
          data.feedbacks?.map((el) => ({
            username: el.user.username,
            body: el.body,
            guid: el.guid,
          })) ?? [];

        const payload: PortfolioCrudDto = {
          description: data.description,
          status: 'MODERATION', // при возможности на бэке статус будет переведен на публикацию
          block: data.block._uuid,
          complexity: undefined,
          tool: data.tool._uuid,
          username: data.user.username,
          name: data.name,
          target: data.goals,
          plan_to: data.plan,
          why_lab: data.results,
          users: data.users,
          application: data.application_link,
          application_access_info: data.application_access_info,
          domains: data.domains?.map((el) => el._uuid),
          organizations: data.organizations?.map((el) => el._uuid),
          feedbacks: [...feedbacks, ...newFeedback],
          images: [...loadedImages, ...newImagesWithUuid],
          subject_areas: undefined,
          developers: undefined,
        };
        const resPortfolio = portfolioUuid
          ? await PortfolioRestService.updatePortfolio(portfolioUuid, isAdminMode, payload)
          : await PortfolioRestService.saveNewPortfolio(isAdminMode, payload);

        const message =
          resPortfolio.status === 'PUBLISHED'
            ? alreadyPublished
              ? 'Изменения успешно сохранены'
              : 'Кейс успешно опубликован'
            : 'Ваш кейс успешно отправлен на рассмотрение';

        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: message,
          })
        );
      }
    ),
    deletePortfolio: creators.asyncThunk(
      async ({ uuid, callback }: { uuid: string; callback?: () => void }, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        await PortfolioRestService.deletePortfolioById(uuid);
        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: 'Кейс успешно удален',
          })
        );
        callback?.();
        return uuid;
      },
      {
        pending: () => {},
        fulfilled: () => {},
        rejected: () => {},
      }
    ),
    changeStatusPortfolio: creators.asyncThunk(
      async (
        {
          uuid,
          newStatus,
          updatePortfolio = false,
        }: {
          uuid: string;
          newStatus: 'PUBLISHED' | 'MODERATION';
          /* если нужно обновлять портфолио (вызов вне страницы редактирования/просмотра) */
          updatePortfolio?: boolean;
        },
        thunkAPI
      ) => {
        let res: PortfolioDto | null = null;
        let message: string | null = null;
        if (newStatus === 'PUBLISHED') {
          res = await PortfolioRestService.publicPortfolio(uuid);
          message = 'Кейс успешно опубликован';
        } else if (newStatus === 'MODERATION') {
          res = await PortfolioRestService.cancelPublicPortfolio(uuid);
          message = 'Кейс успешно снят с публикации';
        }
        !!message &&
          (thunkAPI.dispatch as AppDispatch)(
            generalActions.addNotification({
              status: 'success',
              message,
            })
          );
        return updatePortfolio ? res : null;
      },
      {
        fulfilled: (state, action) => {
          action.payload && (state.portfolio = action.payload);
        },
      }
    ),
  }),

  selectors: {
    portfolio: (state) => state.portfolio,
    pageLoader: (state) => state.pageLoader,
    // organizationsOptions: (state) => state.organizationsOptions,
    // domainOptions: (state) => state.domainOptions,
    portfolioSelectors: (state) => state.portfolioSelectors,
    portfolioItemError: (state) => state.error,
  },
});

export const portfolioItemPageActions = portfolioItemPageSlice.actions;
export const portfolioItemPageSelectors = portfolioItemPageSlice.getSelectors(
  portfolioItemPageSlice.selectSlice
);

export default portfolioItemPageSlice.reducer;
