import { useEffect, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Loader } from '@consta/uikit/Loader';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { ResponsesNothingFound } from '@consta/uikit/ResponsesNothingFound';

import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import {
  portfolioItemPageActions,
  portfolioItemPageSelectors,
} from '../../../store/reducers/portfolioItemPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../components/AppContentWrapper/AppContentWrapper.tsx';
import { PortfolioDeleteModal } from '../../components/Modals/PortfolioDeleteModal/PortfolioDeleteModal.tsx';
import { PortfolioUnpublishModal } from '../../components/Modals/PortfolioUnpublishModal/PortfolioUnpublishModal.tsx';
import { PortfolioPreview } from '../../components/PortfolioPreview/PortfolioPreview.tsx';
import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';
import { usePortfolioActions } from '../../hooks/usePortfolioActions.tsx';

import classes from './PortfolioItemPage.module.css';
import { PortfolioAddCaseInfoCard } from './components/PortfolioAddCaseInfoCard.tsx';
import { PortfolioAppLinkInfoCard } from './components/PortfolioAppLinkInfoCard.tsx';
import { PortfolioLikeInfoCard } from './components/PortfolioLikeInfoCard.tsx';
import { PortfolioShareInfoCard } from './components/PortfolioShareInfoCard.tsx';

export const PortfolioItemPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [unpublishModal, setUnpublishModal] = useState<boolean>(false);
  const {
    portfolio,
    backToPortfolioCards,
    navigateEditPortfolio,
    deletePortfolio,
    changeStatusPortfolio,
    loading,
  } = usePortfolioActions();
  const pageLoader = useAppSelector(portfolioItemPageSelectors.pageLoader);

  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    document.title = 'Портфолио | Лаборатория данных';
    if (id !== undefined)
      dispatch(portfolioItemPageActions.getPortfolioItem({ portfolioUuid: id }));
    return () => {
      dispatch(portfolioItemPageActions.clearState());
    };
  }, [id]);

  return (
    <AppContentWrapper width={'standard'}>
      <AppContentWrapper width={'wide'}>
        <AppBreadcrumbs
          crumbs={[
            { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
            { label: 'Портфолио', rout: PageRoutes.DataLab_PortfolioPage },
            {
              label: `${portfolio?.name ?? '...'}`,
              rout: `${PageRoutes.DataLab_PortfolioPage}/${portfolio?._uuid ?? ''}`,
            },
          ]}
          classname={classes.breadcrumbs}
        />
      </AppContentWrapper>
      {isAdminMode && !hasRoles ? (
        <SwitchToUser403 />
      ) : pageLoader ? (
        <Loader />
      ) : portfolio ? (
        <div className={classes.content}>
          <AppContentWrapper width={'wide'} className={classes.leftSide}>
            <PortfolioPreview portfolio={portfolio} />
          </AppContentWrapper>
          <AppContentWrapper width={'narrow'} className={classes.rightSide}>
            <div className={cnMixFlex({ direction: 'column', gap: '6xl' })}>
              {isAdminMode && (
                <div className={cnMixFlex({ gap: 'm' })}>
                  <Button
                    label={
                      portfolio.status === 'MODERATION' ? 'Опубликовать' : 'Снять с публикации'
                    }
                    disabled={portfolio.read_only}
                    className={cnMixSpace({ pR: 'm', pL: 'm' })}
                    onClick={() =>
                      portfolio.status === 'MODERATION'
                        ? changeStatusPortfolio(backToPortfolioCards)
                        : setUnpublishModal(true)
                    }
                    loading={loading}
                  />
                  <Button
                    // label={'Редактировать'}
                    onlyIcon
                    iconLeft={IconEdit}
                    iconSize={'m'}
                    view={'ghost'}
                    className={cnMixSpace({ pR: 'm', pL: 'm' })}
                    onClick={navigateEditPortfolio}
                    disabled={portfolio.read_only}
                    loading={loading}
                  />
                  <Button
                    // label={'Удалить'}
                    onlyIcon
                    iconLeft={IconTrash}
                    iconSize={'m'}
                    view={'ghost'}
                    className={cnMixSpace({ pR: 'm', pL: 'm' })}
                    onClick={() => setDeleteModal(true)}
                    disabled={portfolio.read_only}
                    loading={loading}
                  />
                </div>
              )}
              <div className={cnMixFlex({ direction: 'column', gap: '3xl' })}>
                <PortfolioAppLinkInfoCard portfolio={portfolio} />
                <PortfolioShareInfoCard portfolio={portfolio} />
                <PortfolioLikeInfoCard portfolio={portfolio} />
              </div>
            </div>
            <PortfolioAddCaseInfoCard />
            <PortfolioDeleteModal
              name={portfolio?.name}
              isOpen={deleteModal}
              onClose={() => {
                setDeleteModal(false);
              }}
              onDelete={() => deletePortfolio(backToPortfolioCards)}
              loading={loading}
            />
            <PortfolioUnpublishModal
              name={portfolio?.name}
              isOpen={unpublishModal}
              onUnpublish={() => changeStatusPortfolio(backToPortfolioCards)}
              onClose={() => setUnpublishModal(false)}
              loading={loading}
            />
          </AppContentWrapper>
        </div>
      ) : (
        <ResponsesNothingFound
          actions={
            <Button
              view={'ghost'}
              label={'перейти к списку портфолио'}
              onClick={backToPortfolioCards}
            />
          }
        />
      )}
    </AppContentWrapper>
  );
};
