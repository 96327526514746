import { Button } from '@consta/uikit/Button';
import { CollapseGroup } from '@consta/uikit/CollapseGroup';
import { IconForward } from '@consta/uikit/IconForward';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';

import classes from './FaqMain.module.scss';

export const FaqMain = () => {
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);

  const faqs = useAppSelector(mainPageSelectors.faqs);
  const items =
    faqs?.map((faq, index) => ({
      preLabel: String(index + 1).padStart(2, '0'),
      label: faq.question,
      content: faq.answer,
    })) ?? [];

  return (
    <AppContentWrapper className={classes.container}>
      <div
        className={classNames(
          cnMixSpace({}),
          cnMixFlex({ flex: 'flex', justify: 'space-between' })
        )}
      >
        <Text size={'2xl'} weight={'medium'}>
          Ответы на частые вопросы
        </Text>
        <Button
          as={'a'}
          href={relativeToAbsoluteLink(
            `${PageRoutes.DataLab_Faq}${isAdminMode ? '?mode=admin' : ''}`
          )}
          label={'Смотреть все'}
          iconRight={IconForward}
          form={'round'}
          view={'ghost'}
          onClick={(e) => {
            e.preventDefault();
            navigate(
              isAdminMode ? `${PageRoutes.DataLab_Faq}?mode=admin` : `${PageRoutes.DataLab_Faq}`
            );
          }}
        />
      </div>
      <CollapseGroup
        className={classes.collapseGroup}
        items={items}
        iconPosition={'right'}
        iconView={'ghost'}
        view={'clear'}
        getItemLabel={(item) => (
          <div className={classes.collapseLabel}>
            <Text size={'l'} view={'ghost'}>
              {item.preLabel}.
            </Text>
            <Text size={'l'}>{item.label}</Text>
          </div>
        )}
        getItemContent={(item) => (
          // <Text view={'secondary'} className={classes.collapseContent}>
          //   {item.content}
          // </Text>
          <div className={classes.collapseContent}>{parse(item.content)}</div>
        )}
      />
    </AppContentWrapper>
  );
};
