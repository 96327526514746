import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import arrowDownIcon from '../../../../../../assets/icons/svg/icn_main_about_process_arrow_down.svg';
import arrowRightIcon from '../../../../../../assets/icons/svg/icn_main_about_process_arrow_right.svg';

import { ProcessStepIcon } from '../ProcessStepIcon/ProcessStepIcon.tsx';

import classes from './ProcessStepCard.module.css';

type Props = {
  onClick: (step?: string) => void;
  step: string;
  imgSrc: string;
  title: string;
  rightArrow?: boolean;
  bottomArrow?: boolean;
  additionalClass?: string;
};

export const ProcessStepCard = ({
  step,
  imgSrc,
  title,
  onClick,
  bottomArrow,
  rightArrow,
  additionalClass,
}: Props) => {
  return (
    <div className={classNames(additionalClass, classes.item)} onClick={() => onClick(step)}>
      <ProcessStepIcon stepValue={step} imgSrc={imgSrc} />
      <Text>{title}</Text>
      {rightArrow && <img src={arrowRightIcon} alt={''} className={classes.arrow} />}
      {bottomArrow && <img src={arrowDownIcon} alt={''} className={classes.arrowBottom} />}
    </div>
  );
};
