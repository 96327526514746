/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  MetricRestService,
  MetricValueGroupDto,
  type MetricsByBlocksDto,
  type SetMetricValueDto,
} from '../../generateAxios';

import { createAppSlice } from '../createAppSlice';

import { generalActions } from './generalSlice.ts';

type MetricsPageState = {
  pageLoader: boolean;
  metrics: MetricsByBlocksDto[];
  totalMetrics: Partial<Record<MetricValueGroupDto['key'], MetricValueGroupDto>>;
};

const initialState: MetricsPageState = {
  metrics: [],
  pageLoader: true,
  totalMetrics: {},
};

const metricsPageSlice = createAppSlice({
  name: 'metricsPage',
  initialState,
  reducers: (creators) => ({
    clearState: creators.reducer(() => {
      return initialState;
    }),
    getMetrics: creators.asyncThunk(
      async (_: undefined) => {
        const [byBlock, allMetrics] = await Promise.all([
          MetricRestService.getMetricsByBlock(),
          MetricRestService.getGroupMetrics(),
        ]);

        const result: {
          metrics: MetricsByBlocksDto[];
          totalMetrics: Partial<Record<MetricValueGroupDto['key'], MetricValueGroupDto>>;
        } = {
          // read_only false - недоступны для просмотра и редактирования
          metrics: byBlock.filter((el) => !el.read_only),
          totalMetrics: allMetrics.reduce(
            (acc, curr) => {
              acc[curr.key] = curr;
              return acc;
            },
            {} as Partial<Record<MetricValueGroupDto['key'], MetricValueGroupDto>>
          ),
        };
        return result;
      },
      {
        fulfilled: (state, action) => {
          state.metrics = action.payload.metrics;
          state.totalMetrics = action.payload.totalMetrics;
        },
        rejected: (_) => {},
        settled: (state) => {
          state.pageLoader = false;
        },
      }
    ),
    changeMetric: creators.asyncThunk(async (payload: SetMetricValueDto, { dispatch }) => {
      await MetricRestService.addMetricVale(payload);
      await dispatch(metricsPageActions.getMetrics());
      dispatch(
        generalActions.addNotification({
          status: 'success',
          message: 'Метрика обновлена успешно',
        })
      );
    }, {}),
  }),

  selectors: {
    pageLoader: (state) => state.pageLoader,
    metrics: (state) => state.metrics,
    totalMetrics: (state) => state.totalMetrics,
    oneAvailableBlockName: (state) => {
      const metrics = state.metrics;
      if (metrics.length === 1) return metrics[0].block.short_name;
      else return null;
    },
  },
});

export const metricsPageActions = metricsPageSlice.actions;
export const metricsPageSelectors = metricsPageSlice.getSelectors(metricsPageSlice.selectSlice);

export default metricsPageSlice.reducer;
