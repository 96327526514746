import { useEffect, useRef } from 'react';

import { Loader } from '@consta/uikit/Loader';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { mainPageActions, mainPageSelectors } from '../../../store/reducers/mainPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';

import { AboutProcess } from './components/AboutProcess/AboutProcess.tsx';
import { AboutProduct } from './components/AboutProduct/AboutProduct.tsx';
import { Contacts } from './components/Contacts/Contacts.tsx';
import { FaqMain } from './components/FaqMain/FaqMain.tsx';
import { HelpdeskEditModal } from './components/HelpdeskEditModal/HelpdeskEditModal.tsx';
import { HeroBlock } from './components/HeroBlock/HeroBlock.tsx';
import { IncreasingCompetencies } from './components/IncreasingCompetencies/IncreasingCompetencies.tsx';
import { PortfolioMain } from './components/PortfolioMain/PorfolioMain.tsx';
import { Step1 } from './components/Step1/Step1.tsx';
import { Step2 } from './components/Step2/Step2.tsx';
import { Step3 } from './components/Step3/Step3.tsx';
import { Step4 } from './components/Step4/Step4.tsx';
import { TeamEditModal } from './components/TeamEditModal/TeamEditModal.tsx';

export const MainPage = () => {
  const dispatch = useAppDispatch();
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const step4Ref = useRef<HTMLDivElement>(null);
  const refIncrease = useRef<HTMLDivElement>(null);
  const pageLoader = useAppSelector(mainPageSelectors.pageLoader);
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    document.title = 'Лаборатория данных';
  }, []);

  useEffect(() => {
    dispatch(mainPageActions.getMainPageData()).then(() => {
      if (window.location.hash) {
        const hash = window.location.hash.slice(1);
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    });

    return () => {
      dispatch(mainPageActions.clearState());
    };
  }, []);

  const scrollTo = (step: number) => {
    const stepRef = [step1Ref, step2Ref, step3Ref, step4Ref, refIncrease][step - 1];
    stepRef &&
      window.scrollTo({
        behavior: 'smooth',
        top: (stepRef.current?.offsetTop || 0) - 90,
      });
  };

  return isAdminMode && !hasRoles ? (
    <>
      <HeroBlock />
      <SwitchToUser403 />
    </>
  ) : pageLoader ? (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--color-bg-default)',
      }}
    >
      <Loader />
    </div>
  ) : (
    <>
      <HeroBlock />
      <AboutProduct />
      <AboutProcess scrollTo={scrollTo} />
      <Step1 ref={step1Ref} />
      <Step2 ref={step2Ref} />
      <Step3 ref={step3Ref} />
      <Step4 ref={step4Ref} />
      <PortfolioMain />
      <IncreasingCompetencies ref={refIncrease} />
      <FaqMain />
      <Contacts />
      <TeamEditModal />
      <HelpdeskEditModal />
    </>
  );
};
