import React, { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../hooks/hooks.ts';
import { selectParamCountByKeys } from '../../../../../../store/reducers/selectors.ts';
import { FilterSideBarHeader } from '../../../../../components/FilterSideBarHeader/FilterSideBarHeader.tsx';
import { knowledgeConfig } from '../../../../../configs/knowledgeConfig.ts';

export const KnowledgeFilterSideBarHeader = () => {
  const keys = useMemo(
    () => [
      knowledgeConfig.filterToolQueryKey,
      knowledgeConfig.filterBlockQueryKey,
      knowledgeConfig.filterTypeQueryKey,
      knowledgeConfig.filterComplexityQueryKey,
      knowledgeConfig.filterTagsQueryKey,
    ],
    []
  );

  const count = useAppSelector(selectParamCountByKeys(keys));
  const [, setSearchParams] = useSearchParams();

  const resetAll = () => {
    setSearchParams((prev) => {
      keys.map((key) => prev.delete(key));
      return prev;
    });
  };

  return <FilterSideBarHeader count={count} resetAll={resetAll} />;
};
