import { useEffect } from 'react';

import { Loader } from '@consta/uikit/Loader';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import {
  metricsPageActions,
  metricsPageSelectors,
} from '../../../store/reducers/metricsPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { AppContentWrapper } from '../../components/AppContentWrapper/AppContentWrapper.tsx';
import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';

import { EmptyPage } from '../EmptyPage/EmptyPage.tsx';

import classes from './MetricsPage.module.css';
import { MetricsBlockSwitcher } from './components/MetricsBlockSwitcher/MetricsBlockSwitcher.tsx';
import { MetricsContent } from './components/MetricsContent/MetricsContent.tsx';
import { MetricsHero } from './components/MetricsHero/MetricsHero.tsx';

export const MetricsPage = () => {
  const dispatch = useAppDispatch();
  const pageLoader = useAppSelector(metricsPageSelectors.pageLoader);
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    document.title = 'Метрики | Лаборатория данных';
    hasRoles && dispatch(metricsPageActions.getMetrics());
    return () => {
      dispatch(metricsPageActions.clearState());
    };
  }, [hasRoles]);

  if (!isAdminMode) return <EmptyPage />;

  return (
    <>
      <MetricsHero />
      {!isAdminMode ? (
        // 404 если в режиме пользователя
        <EmptyPage />
      ) : !hasRoles ? (
        <SwitchToUser403 navigateToMain />
      ) : (
        <AppContentWrapper className={classes.contentWrapper}>
          {pageLoader ? (
            <div className={classes.loaderWrapper}>
              <Loader />
            </div>
          ) : (
            <>
              <MetricsBlockSwitcher />
              <MetricsContent />
            </>
          )}
        </AppContentWrapper>
      )}
    </>
  );
};
