import { Text } from '@consta/uikit/Text';

import parse from 'html-react-parser';

import classes from './PortfolioTextBlock.module.css';

export const PortfolioTextBlock = ({
  title,
  description,
}: {
  title: string;
  description: string | null | undefined;
}) => {
  return (
    description && (
      <div className={classes.container}>
        <Text weight={'bold'}>{title}</Text>
        <div className={classes.description}>{parse(description || '')}</div>
      </div>
    )
  );
};
