/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FileForShowDto,
  type FilterSelectorsKnowledgeDto,
  FiltersKnowledgeDto,
  KnowledgeDto,
  KnowledgeRestService,
  LikesRestService,
  MetricRestService,
} from '../../generateAxios';
import { knowledgeConfig } from '../../src/configs/knowledgeConfig.ts';
import { KnowledgeSwitcherViewItem } from '../../src/pages/KnowledgePage/components/KnowledgeFilters/components/SwitcherView/SwitcherView.tsx';
import { enumerate } from '../../src/utils/enumerate.ts';

import { createAppSlice } from '../createAppSlice';
import { RootState } from '../store.ts';

type KnowledgePageState = {
  knowledgeItems: KnowledgeDto[] | null;
  knowledgeFilters: FilterSelectorsKnowledgeDto | null;
  pageLoader: boolean;
  // общее количество элементов в пагинации
  totalElements: number | null;
  // общее количество страниц в пагинации
  totalPages: number | null;
  // статистика для hero block
  statistics: {
    totalKnowledge: number | null;
    totalKnowledgeNameFirst: string;
    totalKnowledgeNameSecond: string;
  } | null;
  showSideBar: boolean;
  videoModal: { isOpen: boolean; files: FileForShowDto[] };
  linksModal: { isOpen: boolean; knowledge: KnowledgeDto | null };
};

const initialState: KnowledgePageState = {
  knowledgeItems: null,
  knowledgeFilters: null,
  pageLoader: true,
  totalElements: null,
  totalPages: null,
  statistics: null,
  showSideBar: false,
  videoModal: { isOpen: false, files: [] },
  linksModal: { isOpen: false, knowledge: null },
};

const knowledgePageSlice = createAppSlice({
  name: 'knowledgePage',
  initialState,
  reducers: (creators) => ({
    setKnowledgeItems: creators.reducer<KnowledgeDto[]>((state, action) => {
      state.knowledgeItems = action.payload;
    }),
    setShowSideBar: creators.reducer<boolean>((state, action) => {
      state.showSideBar = action.payload;
    }),
    setVideoModal: creators.reducer<{ isOpen: boolean; files: FileForShowDto[] }>(
      (state, action) => {
        state.videoModal = action.payload;
      }
    ),
    setPageLoader: creators.reducer<boolean>((state, action) => {
      state.pageLoader = action.payload;
    }),
    setLinksModal: creators.reducer<{ isOpen: boolean; knowledge: KnowledgeDto | null }>(
      (state, action) => {
        state.linksModal = action.payload;
      }
    ),
    clearState: creators.reducer(() => {
      return initialState;
    }),
    getKnowledgeItems: creators.asyncThunk(
      async (
        {
          pageNumber = 0,
          addItems = false,
        }: {
          pageNumber?: number;
          addItems?: boolean;
        },
        { getState, signal }
      ) => {
        const state = getState() as RootState;

        /*
         * получение материалов возможны в нескольких случаях
         * 1 в карточном виде при "показать еще" параметры из стора, страница из параметров, кол-во на странице из конфига
         * 2 в карточном виде после удаления материала.  параметры из стора, страница - 0, также надо обнулить страницу в локальном стейте, кол-во на странице из конфига
         * 3 в табличном виде, все параметры из стора
         * 4 в табличном виде после удаления материала, все параметры из стора, возможный нюанс - удаление последнего материала на текущей странице
         * */
        // вид - таблица или карточки
        let knowledgeViewMode: KnowledgeSwitcherViewItem['queryValue'] = 'cards';
        if (state.general.searchParams?.[knowledgeConfig.viewQueryKey]?.[0] === 'table') {
          knowledgeViewMode = 'table';
        }

        // запрашиваемая страница
        const queryPageNumber = state.general.searchParams[knowledgeConfig.tablePageNumberKey]?.[0];
        const payloadPageNumber =
          knowledgeViewMode === 'cards' ? pageNumber : queryPageNumber ? +queryPageNumber : 0;

        const queryPageSize = state.general.searchParams[knowledgeConfig.tablePageSizeKey]?.[0];
        const payloadPageSize =
          knowledgeViewMode === 'cards'
            ? knowledgeConfig.pageSize
            : queryPageSize
              ? +queryPageSize
              : knowledgeConfig.tablePageSizes[0];

        const body: FiltersKnowledgeDto = {
          blocks: state.general.searchParams[knowledgeConfig.filterBlockQueryKey],
          complexity: state.general.searchParams[knowledgeConfig.filterComplexityQueryKey],
          tags: state.general.searchParams[knowledgeConfig.filterTagsQueryKey],
          types: state.general.searchParams[knowledgeConfig.filterTypeQueryKey],
          tools: state.general.searchParams[knowledgeConfig.filterToolQueryKey],
          authors: state.general.searchParams[knowledgeConfig.filterAuthorQueryKey],
          filter: state.general.searchParams[knowledgeConfig.searchQueryKey]?.[0],
          order: state.general.searchParams[
            knowledgeConfig.sortQueryKey
          ]?.[0] as FiltersKnowledgeDto['order'],
          page_number: payloadPageNumber,
          page_size: payloadPageSize,
        };

        const promise = KnowledgeRestService.getAllKnowledgeByFilter(body);
        signal.onabort = () => {
          promise.cancel();
        };
        const res = await promise;
        return { data: res, addItems, changeTotalPages: knowledgeViewMode === 'table' };
      },
      {
        pending: (state) => {
          state.pageLoader = true;
        },
        fulfilled: (state, action) => {
          const { data, addItems } = action.payload;
          if (!state.knowledgeItems) {
            state.knowledgeItems = action.payload.data.dtos?.content ?? [];
          } else {
            state.knowledgeItems = addItems
              ? [...state.knowledgeItems, ...(data.dtos?.content ?? [])]
              : data.dtos?.content ?? [];
          }
          state.knowledgeFilters = data.filters ?? null;
          state.totalElements = action.payload.data.dtos?.totalElements ?? null;
          state.totalPages = action.payload.changeTotalPages
            ? action.payload.data.dtos?.totalPages ?? null
            : null;
          state.pageLoader = false;
        },
      }
    ),
    getKnowledgeMetrics: creators.asyncThunk(
      async () => {
        const res = await MetricRestService.getGroupMetrics();
        const metric = res.find((item) => item.key === 'KNOWLEDGE_COUNT');
        const totalKnowledge = metric?.value ?? 0;
        const name = metric?.presentation_name || ''; // Или пустая строка
        const [firstWord, ...rest] = name.split(' ');
        const remaining = rest.join(' ');

        const totalKnowledgeNameFirst: string = !!firstWord
          ? firstWord.toLowerCase()
          : enumerate(totalKnowledge, ['материал', 'материала', 'материалов']);
        const totalKnowledgeNameSecond: string = !!remaining ? remaining : '';
        return { totalKnowledge, totalKnowledgeNameFirst, totalKnowledgeNameSecond };
      },
      {
        fulfilled: (state, action) => {
          state.statistics = action.payload;
        },
      }
    ),
    setKnowledgeLike: creators.asyncThunk(
      async ({ knowledge, action }: { knowledge: KnowledgeDto; action: 'add' | 'delete' }) => {
        if (action === 'add') {
          const res = await LikesRestService.addLike({
            _uuid: '00000000-0000-0000-0000-000000000000',
            object_type: 'KNOWLEDGE',
            uuid_object: knowledge._uuid,
            thumb_up: 1,
          });
          return res;
        } else {
          const res = await LikesRestService.deleteLike(knowledge._uuid);
          return res;
        }
      },
      {
        fulfilled: (state, action) => {
          const index = state.knowledgeItems?.findIndex(
            (item) => item._uuid === action.payload.uuid_object
          );
          if (state.knowledgeItems && index !== undefined) {
            state.knowledgeItems[index] = {
              ...state.knowledgeItems[index],
              count_like_by_user: action.payload.count_like_by_user,
              count_like_all: action.payload.count_like_all,
            };
          }
        },
      }
    ),
  }),

  selectors: {
    pageLoader: (state) => state.pageLoader,
    totalElements: (state) => state.totalElements,
    totalPages: (state) => state.totalPages,
    statistics: (state) => state.statistics,
    showSideBar: (state) => state.showSideBar,
    knowledgeItems: (state) => state.knowledgeItems,
    knowledgeFilters: (state) => state.knowledgeFilters,
    blockFilters: (state) => state.knowledgeFilters?.blocks,
    toolsFilters: (state) => state.knowledgeFilters?.tools,
    tagsFilters: (state) => state.knowledgeFilters?.tags,
    typeFilters: (state) => state.knowledgeFilters?.type,
    complexityFilters: (state) => state.knowledgeFilters?.complexity,
    authorFilters: (state) => state.knowledgeFilters?.authors,
    videoModal: (state) => state.videoModal,
    linksModal: (state) => state.linksModal,
  },
});

export const knowledgePageActions = knowledgePageSlice.actions;
export const knowledgePageSelectors = knowledgePageSlice.getSelectors(
  knowledgePageSlice.selectSlice
);

export default knowledgePageSlice.reducer;
