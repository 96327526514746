import { ReactNode, forwardRef } from 'react';

import classNames from 'classnames';

import classes from './AppContentWrapper.module.css';

type Props = {
  children: ReactNode;
  className?: string;
  width?: 'full' | 'standard' | 'wide' | 'narrow';
};

export const AppContentWrapper = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div
      className={classNames(
        {
          [classes.appContentWrapper]: true,
          [classes.wide]: props.width === 'wide',
          [classes.narrow]: props.width === 'narrow',
          [classes.fullScreen]: props.width === 'full',
        },
        props.className
      )}
      ref={ref}
    >
      {props.children}
    </div>
  );
});
