import { useCallback } from 'react';

import { Loader } from '@consta/uikit/Loader';

import { FaqDto } from '../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { faqPageActions, faqPageSelectors } from '../../../../../store/reducers/faqPageSlice.ts';
import { generalActions } from '../../../../../store/reducers/generalSlice.ts';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { Divider } from '../../../../components/Divider/Divider.tsx';
import { DnDSortList } from '../../../../components/DnDSortList/DnDSortList.tsx';
import { faqConfig } from '../../../../configs/faqConfig.ts';

import { FaqItem } from './FaqItem/FaqItem.tsx';
import classes from './FaqsAdmin.module.scss';

export const FaqsAdmin = ({ items }: { items: FaqDto[] | null }) => {
  const dispatch = useAppDispatch();
  // const blockUuid = useAppSelector(selectParamByKey(faqBlockQueryKey));
  // const searchValue = useAppSelector(selectParamByKey(faqSearchQueryKey));
  // const withFilters = blockUuid.length > 0 || searchValue.length > 0;
  const allFaqs = useAppSelector(faqPageSelectors.faqItems);
  const faqCountInMainPage = useAppSelector(faqPageSelectors.faqCountInMainPage);
  const pageLoader = useAppSelector(faqPageSelectors.pageLoader);

  const afterDrag = async (filteredItems: FaqDto[]) => {
    const filteredItemsMap = new Map(filteredItems.map((item) => [item._uuid, item]));

    let filteredItemIndex = 0;
    const newFaqs = (allFaqs ?? []).reduce((acc: FaqDto[], item) => {
      return filteredItemsMap.has(item._uuid)
        ? [...acc, filteredItems[filteredItemIndex++]]
        : [...acc, item];
    }, []);

    await dispatch(faqPageActions.sortFaqs(newFaqs));
  };

  const addToFavorite = useCallback(
    async (item: FaqDto) => {
      const isAdd = !item.show_on_main_page;
      if (isAdd && faqCountInMainPage > faqConfig.mainPageMaxCount - 1) {
        dispatch(
          generalActions.addNotification({
            status: 'warning',
            message: `На главную страницу можно добавить не более ${faqConfig.mainPageMaxCount} вопросов. Чтобы добавить новый вопрос — уберите ранее выбранный`,
          })
        );
        return;
      }
      await dispatch(faqPageActions.addToFavorite(item));
    },
    [faqCountInMainPage, dispatch]
  );

  return (
    <AppContentWrapper className={classes.container}>
      {pageLoader && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}

      <DnDSortList
        renderItem={(faq) => {
          return (
            <FaqItem
              item={faq}
              addToFavorite={addToFavorite}
              // disableDnD={withFilters}
            />
          );
        }}
        originalItems={items ?? []}
        uniqueKey={'_uuid'}
        withDivider
        afterMove={afterDrag}
        // disabled={withFilters}
      />
      <Divider />
    </AppContentWrapper>
  );
};
