import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Loader } from '@consta/uikit/Loader';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { IconEye } from '@consta/icons/IconEye';

import { FileForShowDto } from '../../../../../../../../generateAxios';
import { OpenAPI } from '../../../../../../../../generateAxios/core/OpenAPI.ts';
import imgErrorSettings from '../../../../../../../assets/icons/svg/icn_settings_cross.svg';
import { VideoFullSizeModal } from '../../../../../../Modals/VideoFullSizeModal/VideoFullSizeModal.tsx';

import classes from './KnowledgeEditorVideoItem.module.css';

export const KnowledgeEditorVideoItem = ({
  videoItem,
  onChangeDescription,
  deleteVideo,
  index,
}: {
  videoItem: (FileForShowDto & { data?: File }) | FileForShowDto;
  index: number;
  onChangeDescription: (value: string, index: number) => void;
  deleteVideo: (index: number) => void;
}) => {
  const [videoPreview, setVideoPreview] = useState<{
    isOpen: boolean;
    videoSrc: string;
    videoDescription: string;
  }>({
    videoSrc: '',
    isOpen: false,
    videoDescription: '',
  });

  const videoTitle = videoItem.origin_file_name ?? 'нет имени файла';
  const videoSizeMb = videoItem.origin_file_size ? videoItem.origin_file_size / (1024 * 1024) : 0;
  const videoInfo = `${videoSizeMb.toFixed(2)} Мб`;

  const handlerWatchVideo = () => {
    setVideoPreview({
      videoSrc:
        'data' in videoItem && videoItem.data instanceof File
          ? // для новых видео
            URL.createObjectURL(videoItem.data)
          : // для загруженных ранее
            `${OpenAPI.BASE}/files/video/${videoItem._uuid}`,
      isOpen: true,
      videoDescription: videoItem.description ?? '',
    });
  };

  return (
    <div className={classes.container}>
      <IconDraggable size={'s'} className={classes.draggable} />
      <div className={classes.img}>
        {videoItem.mini_image ? (
          <img
            src={videoItem.mini_image}
            onError={(event) => {
              event.currentTarget.src = imgErrorSettings;
            }}
            alt={'миниатюра'}
          />
        ) : (
          <Loader />
        )}
      </div>
      <div className={classes.content}>
        <Text size={'s'} lineHeight={'xs'}>
          {videoTitle}
        </Text>
        <Text view={'ghost'} size={'xs'} lineHeight={'xs'} className={cnMixSpace({ mT: '2xs' })}>
          {videoInfo}
        </Text>
        <TextField
          value={videoItem.description}
          size={'s'}
          width={'full'}
          onChange={({ value }) => onChangeDescription(value ?? '', index)}
        />
      </div>
      <Button view={'clear'} onlyIcon iconRight={IconEye} size={'s'} onClick={handlerWatchVideo} />
      <Button
        view={'clear'}
        onlyIcon
        iconRight={IconTrash}
        size={'s'}
        onClick={() => deleteVideo(index)}
      />
      <VideoFullSizeModal
        show={videoPreview.isOpen}
        videoDescription={videoPreview.videoDescription}
        videoSrc={videoPreview.videoSrc}
        closeModal={() => setVideoPreview((prevState) => ({ ...prevState, isOpen: false }))}
        afterClose={() =>
          setVideoPreview((prev) => ({ ...prev, videoSrc: '', videoDescription: '' }))
        }
      />
    </div>
  );
};
