import { useMemo } from 'react';

import { Text } from '@consta/uikit/Text';

import dayjs from 'dayjs';

import { useAppSelector } from '../hooks/hooks.ts';
import { generalSelectors } from '../store/reducers/generalSlice.ts';
import { isAdminModeSelector } from '../store/reducers/selectors.ts';

import classes from './App.module.css';
import {
  getAppHeaderAdminMenuItems,
  getAppHeaderMenuItems,
} from './appMenuAndRouting/appHeaderMenuItems.ts';
import {
  PageRoutes,
  appRedirectRoute,
  getAppRoutes,
  onLogoClickRoute,
  withoutFooterRoutes,
} from './appMenuAndRouting/appRoutes.tsx';
import logo from './assets/icons/svg/icn_footer_logo.svg';
import { AppIndicator } from './components/AppIndicator/AppIndicator.tsx';
import { HeaderExtraActions } from './components/HeaderExtraActions/HeaderExtraActions.tsx';
import { SupportModal } from './components/Modals/GlobalSupportModal/SupportModal.tsx';
import { LearningModal } from './components/Modals/LearningModal/LearningModal.tsx';
import { ToolLinksEditModal } from './components/Modals/ToolLinksEditModal/ToolLinksEditModal.tsx';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop.tsx';
import { linksConfig } from './configs/linksConfig.ts';
import AppFooter from './shared-front/AppFooter/AppFooter.tsx';
import AppHeader from './shared-front/AppHeader/AppHeader.tsx';
import labIco from './shared-front/AppHeader/assets/ico_datalab.svg';
import { PortalsEnum } from './shared-front/AppHeader/sharedSideBarMenuItems.tsx';
import AppRouter from './shared-front/AppRouter/AppRouter.tsx';

export const App = () => {
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const version = useAppSelector(generalSelectors.version);

  const versionFront = useMemo(() => {
    const v = import.meta.env.__PACKAGEJSON_VERSION;
    try {
      // пример VITE_BUILD_INFO
      // const info = {"date":"2024-07-01T14:15:33+03:00","pipeline":4199,"commit_id":"d604c3aaa6b7aa9f7733b638052229a2ece52ff4","author":"Egor <Kirillov.EV@gazprom-neft.ru>","git_br":"release/1"}
      const pipeline = JSON.parse(import.meta.env.VITE_BUILD_INFO).pipeline;
      const date = JSON.parse(import.meta.env.VITE_BUILD_INFO).date;
      return `Клиент v${v} ${pipeline} от ${dayjs(date).format('DD.MM.YYYY')}`;
    } catch (e) {
      return `Клиент v${v}`;
    }
  }, []);

  const versionBack = useMemo(() => {
    const mainVersion = version?.type === 'MAIN' ? version : null;
    if (!mainVersion) return '';
    const v = mainVersion.version ?? '';
    const build = mainVersion.uniq_build ?? '';
    const date =
      mainVersion.time && dayjs(mainVersion.time).isValid()
        ? dayjs(mainVersion.time).format('DD.MM.YYYY')
        : null;
    return `Сервер v${v} ${build}${date ? ` от ${date}` : ''}`;
  }, [version]);

  const footerLogo = (
    <img
      src={logo}
      style={{
        alignSelf: 'flex-start',
      }}
      alt={'logo'}
    />
  );

  /** appSideBarMenuItems необходимо использовать в компоненте, linksConfig.new_market получаем из конфига */
  const appSideBarMenuItems = useMemo(
    () => ({
      label: 'Лаборатория данных',
      href: PageRoutes.DataLab,
      icon: <img src={labIco} alt={''} />,
      children: [
        { label: 'Главная', href: PageRoutes.DataLab_MainPage },
        {
          label: 'Магазин данных',
          extUrl: linksConfig.new_market,
          disabled: !linksConfig.new_market,
        },
        { label: 'Инструменты', href: PageRoutes.DataLab_ToolsPage },
        { label: 'База Знаний', href: PageRoutes.DataLab_KnowledgesPage },
        { label: 'Портфолио', href: PageRoutes.DataLab_PortfolioPage },
        { label: 'FAQ', href: PageRoutes.DataLab_Faq },
      ],
    }),
    []
  );

  const handlerChangeLocation = () => {
    // лооигирование
  };

  return (
    <div className={classes.container}>
      <AppHeader
        portal={PortalsEnum.Laboratory}
        appHeaderMenuItems={isAdminMode ? getAppHeaderAdminMenuItems() : getAppHeaderMenuItems()}
        appSideBarMenuItems={appSideBarMenuItems}
        onLogoClickRoute={onLogoClickRoute}
        dqBarComponent={HeaderExtraActions}
      />
      <div className={classes.pageWrapper}>
        <AppRouter
          appRoutes={getAppRoutes()}
          appRedirectRoute={appRedirectRoute}
          onChangeLocation={handlerChangeLocation}
        />
        <SupportModal />
        <ToolLinksEditModal />
        <LearningModal />
        <ScrollToTop />
      </div>

      {/*индикатор на каком стенде развернуто приложение, зависит от цвета в config.json HEADER_INDICATOR_COLOR, по умолчанию не отображается*/}
      <AppIndicator />
      <div className={classes.footerWrapper}>
        <AppFooter
          footerLogo={footerLogo}
          portalName={'Дата-Платформе'}
          questionEmail={'data-platform@gazprom-neft.ru'}
          onLogoClickRoute={onLogoClickRoute}
          withoutFooterRoutes={withoutFooterRoutes}
          showEso
          versionNode={
            <div className={classes.versionNode}>
              <Text view={'ghost'} lineHeight={'xs'}>
                Лаборатория данных
              </Text>
              <Text view={'ghost'} lineHeight={'xs'}>
                {versionFront}
              </Text>
              <Text view={'ghost'} lineHeight={'xs'}>
                {versionBack}
              </Text>
            </div>
          }
        />
      </div>
    </div>
  );
};
