import { SnackBar } from '@consta/uikit/SnackBar';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { generalActions, generalSelectors } from '../../../store/reducers/generalSlice.ts';

import classes from './NotificationsBar.module.css';

export const NotificationsBar = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(generalSelectors.notifications);

  return (
    <div className={classes.snackBar}>
      <SnackBar
        items={notifications}
        onItemClose={(item) => {
          dispatch(generalActions.deleteNotification(item.key));
        }}
        getItemAutoClose={() => 5}
        getItemShowProgress={() => 'line'}
        getItemMessage={(item) => (
          <div>
            {item.message} <br /> {item.responseCode && `статус код ${item.responseCode}`}
            <br /> {item.responseUrl && `url запроса ${item.responseUrl}`}
          </div>
        )}
      />
    </div>
  );
};
