/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthUserDetailsDto } from '../models/AuthUserDetailsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthRestCommonService {
  /**
   * Get My Auth (UserDetails) info
   * Returns an AuthUserDetails of current user
   * @returns AuthUserDetailsDto Successfully retrieved
   * @throws ApiError
   */
  public static getUserDetails(): CancelablePromise<AuthUserDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/me',
      errors: {
        401: `Not auth`,
      },
    });
  }
}
