import { Combobox } from '@consta/uikit/Combobox';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFormContext } from 'react-hook-form';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { portfolioItemPageSelectors } from '../../../../../store/reducers/portfolioItemPageSlice.ts';
import { portfolioConfig } from '../../../../configs/portfolioConfig.ts';
import { CustomEditor } from '../../../CustomEditor/CustomEditor.tsx';
import { CustomUserSelect } from '../../../CustomUserSelect/CustomUserSelect.tsx';

import { PortfolioFormValues } from '../../PortfolioEditor.tsx';

import classes from './PortfolioEditorAbout.module.css';

export const PortfolioEditorAbout = () => {
  const selectorsOptions = useAppSelector(portfolioItemPageSelectors.portfolioSelectors);
  const {
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<PortfolioFormValues>();

  const domainOptions =
    selectorsOptions?.domains?.filter((el) => el.block?._uuid === getValues('block')?._uuid) ?? [];

  return (
    <div className={classes.container}>
      <Text size={'l'} weight={'medium'} className={cnMixSpace({ mB: '2xs' })}>
        О кейсе
      </Text>
      <Controller
        name={'name'}
        control={control}
        rules={{
          required: 'Поле обязательно для заполнения',
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            label={'Название'}
            placeholder={'Введите название кейса'}
            required
            inputRef={ref}
            width={'full'}
            value={field.value ?? ''}
            onChange={({ value }) => field.onChange(value ?? '')}
            status={errors.name ? 'alert' : undefined}
            caption={errors.name?.message}
          />
        )}
      />
      <Controller
        name={'block'}
        control={control}
        rules={{ required: 'Выберите блок' }}
        render={({ field: { ref, ...field } }) => (
          <div>
            <Combobox
              {...field}
              required
              placeholder={'Выберите блок'}
              label={'Блок'}
              items={selectorsOptions?.blocks ?? []}
              getItemLabel={(item) => item.short_name}
              getItemKey={(item) => item._uuid}
              onChange={({ value }) => {
                field.onChange(value);
                setValue('domains', null);
                trigger('domains');
              }}
              // className={fieldState.error ? 'form-error' : ''}
              status={errors.block ? 'alert' : undefined}
              inputRef={ref}
              value={field.value}
            />
            {errors.block && (
              <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                {errors.block.message}
              </Text>
            )}
          </div>
        )}
      />
      <Controller
        name={'organizations'}
        control={control}
        rules={{ required: 'Выберите одну или несколько организаций' }}
        render={({ field: { ref, ...field } }) => (
          <div>
            <Combobox
              {...field}
              required
              placeholder={'Выберите организацию'}
              label={'Организация'}
              multiple
              items={selectorsOptions?.organizations ?? []}
              getItemLabel={(item) => item.name}
              getItemKey={(item) => item._uuid}
              onChange={({ value }) => {
                field.onChange(value);
              }}
              status={errors.organizations ? 'alert' : undefined}
              inputRef={ref}
              value={field.value}
            />
            {errors.organizations && (
              <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                {errors.organizations.message}
              </Text>
            )}
          </div>
        )}
      />
      <Controller
        name={'domains'}
        control={control}
        // rules={{ required: 'Выберите один или несколько доменов' }}
        render={({ field: { ref, ...field } }) => (
          <div>
            <Combobox
              {...field}
              // required
              placeholder={getValues('block') ? 'Определяется' : 'Сначала выберите блок'}
              disabled={!getValues('block')}
              label={'Домен'}
              multiple
              items={domainOptions}
              getItemLabel={(item) => item.name}
              getItemKey={(item) => item._uuid}
              onChange={({ value }) => {
                field.onChange(value);
              }}
              // className={fieldState.error ? 'form-error' : ''}
              status={errors.domains ? 'alert' : undefined}
              inputRef={ref}
              value={field.value?.length === 0 ? null : field.value}
            />
            {errors.domains && (
              <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                {errors.domains.message}
              </Text>
            )}
          </div>
        )}
      />
      <Controller
        name={'tool'}
        control={control}
        rules={{ required: 'Выберите инструмент' }}
        render={({ field: { ref, ...field } }) => (
          <div>
            <Combobox
              {...field}
              required
              placeholder={'Выберите инструмент'}
              label={'Инструмент'}
              items={selectorsOptions?.tools ?? []}
              getItemLabel={(item) => item.name}
              getItemKey={(item) => item._uuid}
              onChange={({ value }) => {
                field.onChange(value);
              }}
              status={errors.tool ? 'alert' : undefined}
              inputRef={ref}
              value={field.value}
            />
            {errors.tool && (
              <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                {errors.tool.message}
              </Text>
            )}
          </div>
        )}
      />
      <Controller
        name={'application_link'}
        control={control}
        rules={{
          pattern: {
            value: portfolioConfig.urlRegex,
            message: 'Введите корректный URL',
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label={'Ссылка на приложение'}
            placeholder={'Введите ссылку'}
            width={'full'}
            value={field.value ?? ''}
            onChange={({ value }) => field.onChange(value ?? '')}
            status={errors.application_link ? 'alert' : undefined}
            caption={errors.application_link?.message}
          />
        )}
      />

      <Controller
        name={'application_access_info'}
        control={control}
        render={({ field, fieldState }) => (
          <CustomEditor
            label={'Как получить доступ'}
            // required
            onChange={(value) => field.onChange(value)}
            value={field.value}
            placeholder={'Введите информацию о способе получения доступа к приложению'}
            fieldState={fieldState}
            refC={field.ref}
          />
        )}
      />
      <Controller
        name={'user'}
        control={control}
        rules={{ required: 'Выберите сотрудника' }}
        render={({ field }) => (
          <CustomUserSelect
            label={'Автор кейса'}
            placeholder={'Выберите сотрудника'}
            currentUser={field.value}
            setCurrentUser={(user) => {
              field.onChange(user);
            }}
            required
            showPhoto
            ref={field.ref}
          />
        )}
      />
    </div>
  );
};
