import { Text } from '@consta/uikit/Text';
import { TextPropView } from '@consta/uikit/__internal__/src/components/Text/Text';

import classNames from 'classnames';

import classes from './ComplexityTag.module.css';

export const ComplexityTag = ({
  level,
  name,
  view,
}: {
  level: number;
  name: string;
  view?: TextPropView;
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <div
          className={classNames({
            [classes.noColor]: level === -1,
            [classes.successColor]: level === 0,
            [classes.warningColor]: level === 1,
            [classes.alertColor]: level === 2,
          })}
        />
        <div
          className={classNames({
            [classes.noColor]: level < 1,
            [classes.warningColor]: level === 1,
            [classes.alertColor]: level === 2,
          })}
        />
        <div
          className={classNames({
            [classes.noColor]: level < 2,
            [classes.alertColor]: level === 2,
          })}
        />
      </div>
      <Text size={'s'} view={view ?? 'secondary'} weight={'medium'} className={classes.text}>
        {name}
      </Text>
    </div>
  );
};
