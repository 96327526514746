import { Combobox } from '@consta/uikit/Combobox';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFormContext } from 'react-hook-form';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { knowledgeItemPageSelectors } from '../../../../../store/reducers/knowledgeItemPageSlice.ts';

import { KnowledgeFormValues } from '../../KnowledgeEditor.tsx';

import classes from './KnowledgeEditorAbout.module.css';

export const KnowledgeEditorAbout = () => {
  const selectorsOptions = useAppSelector(knowledgeItemPageSelectors.knowledgeSelectors);
  const {
    control,
    formState: { errors },
  } = useFormContext<KnowledgeFormValues>();

  return (
    <div className={classes.container}>
      <Text size={'l'} weight={'medium'} className={cnMixSpace({ mB: '2xs' })}>
        О материале
      </Text>
      <Controller
        name={'subject'}
        control={control}
        rules={{
          required: 'Поле обязательно для заполнения',
          maxLength: { value: 255, message: 'Максимальное количество символов 255' },
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            label={'Название'}
            placeholder={'Введите название'}
            required
            inputRef={ref}
            width={'full'}
            value={field.value ?? ''}
            onChange={({ value }) => field.onChange(value ?? '')}
            status={errors.subject ? 'alert' : undefined}
            caption={errors.subject?.message}
          />
        )}
      />
      <Controller
        name={'description'}
        control={control}
        rules={{
          maxLength: { value: 4000, message: 'Максимальное количество символов 4000' },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label={'Описание'}
            placeholder={'Введите описание материала'}
            type={'textarea'}
            rows={5}
            width={'full'}
            value={field.value ?? ''}
            onChange={({ value }) => field.onChange(value ?? '')}
            status={errors.description ? 'alert' : undefined}
            caption={errors.description?.message}
          />
        )}
      />
      <Controller
        name={'blocks'}
        control={control}
        rules={{ required: 'Выберите блок' }}
        render={({ field: { ref, ...field } }) => (
          <div>
            <Combobox
              {...field}
              required
              placeholder={'Выберите блок'}
              multiple
              label={'Блок'}
              items={selectorsOptions?.blocks ?? []}
              getItemLabel={(item) => item.short_name}
              getItemKey={(item) => item._uuid}
              onChange={({ value }) => {
                field.onChange(value);
              }}
              status={errors.blocks ? 'alert' : undefined}
              inputRef={ref}
              value={field.value}
            />
            {errors.blocks && (
              <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                {errors.blocks.message}
              </Text>
            )}
          </div>
        )}
      />
      <Controller
        name={'tool'}
        control={control}
        rules={{ required: 'Выберите инструмент' }}
        render={({ field: { ref, ...field } }) => (
          <div>
            <Combobox
              {...field}
              required
              placeholder={'Выберите инструмент'}
              label={'Используемый инструмент'}
              items={selectorsOptions?.tools ?? []}
              getItemLabel={(item) => item.name}
              getItemKey={(item) => item._uuid}
              onChange={({ value }) => {
                field.onChange(value);
              }}
              status={errors.tool ? 'alert' : undefined}
              inputRef={ref}
              value={field.value}
            />
            {errors.tool && (
              <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                {errors.tool.message}
              </Text>
            )}
          </div>
        )}
      />
      <Controller
        name={'complexity'}
        control={control}
        rules={{ required: 'Выберите сложность' }}
        render={({ field: { ref, ...field } }) => (
          <div>
            <Combobox
              {...field}
              required
              placeholder={'Выберите сложность'}
              label={'Сложность'}
              items={selectorsOptions?.complexities ?? []}
              getItemLabel={(item) => item.name ?? ''}
              getItemKey={(item) => item._uuid}
              onChange={({ value }) => {
                field.onChange(value);
              }}
              status={errors.complexity ? 'alert' : undefined}
              inputRef={ref}
              value={field.value}
            />
            {errors.complexity && (
              <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                {errors.complexity.message}
              </Text>
            )}
          </div>
        )}
      />
      <Controller
        name={'tags'}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <div>
            <Combobox
              {...field}
              placeholder={'Выберите теги'}
              multiple
              label={'Теги'}
              items={selectorsOptions?.tags ?? []}
              getItemLabel={(item) => item.name ?? ''}
              getItemKey={(item) => item._uuid}
              onChange={({ value }) => {
                field.onChange(value);
              }}
              status={errors.tags ? 'alert' : undefined}
              inputRef={ref}
              value={field.value?.length === 0 ? null : field.value}
            />
            {errors.tags && (
              <Text size={'xs'} view={'alert'} className={cnMixSpace({ mT: 'xs', mL: 's' })}>
                {errors.tags.message}
              </Text>
            )}
          </div>
        )}
      />
    </div>
  );
};
