import { useMemo, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconTrash } from '@consta/uikit/IconTrash';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { IconDownload } from '@consta/icons/IconDownload';

import dayjs from 'dayjs';

import { PortfolioImageDto } from '../../../../../../../generateAxios';
import { OpenAPI } from '../../../../../../../generateAxios/core/OpenAPI.ts';
import imgErrorSettings from '../../../../../../assets/icons/svg/icn_settings_cross.svg';
import CustomHoverToolTip from '../../../../../../shared-front/CustomHoverToolTip/CustomHoverToolTip.tsx';
import { getMimeType } from '../../../../../../utils/getMimeType.ts';
import { ImageFullSizeModal } from '../../../../../Modals/ImageFullSizeModal/ImageFullSizeModal.tsx';

import classes from './PortfolioEditorImageItem.module.css';

export const PortfolioEditorImageItem = ({
  image,
  onChangeDescription,
  deleteImage,
  index,
}: {
  image: (PortfolioImageDto & { data?: File }) | PortfolioImageDto;
  index: number;
  onChangeDescription: (value: string, index: number) => void;
  deleteImage: (index: number) => void;
}) => {
  const [imagePreview, setImagePreview] = useState<{
    isOpen: boolean;
    imgSrc: string;
    imgDescription: string;
  }>({
    imgSrc: '',
    isOpen: false,
    imgDescription: '',
  });
  const imgSrc = useMemo(() => {
    return 'data' in image && image.data instanceof File
      ? URL.createObjectURL(image.data)
      : image.image
        ? `data:${getMimeType(image.image)};base64,${image.image.replace(/\s+/g, '')}`
        : image.uuid_image
          ? `${OpenAPI.BASE}/image/${image.uuid_image}`
          : imgErrorSettings;
  }, [image]);
  const imgSrcForPreview = useMemo(() => {
    return 'data' in image && image.data instanceof File
      ? URL.createObjectURL(image.data)
      : image.uuid_image
        ? `${OpenAPI.BASE}/image/${image.uuid_image}`
        : imgErrorSettings;
  }, [image]);

  const imgTitle = image.origin_file_name ?? 'нет имени файла';
  const imgSizeMb = image.origin_file_size ? image.origin_file_size / (1024 * 1024) : 0;

  const imgInfo = `${imgSizeMb.toFixed(2)} Mb ${dayjs(image instanceof File ? undefined : image.date).format('DD.MM.YYYY HH.mm')} `;

  const imgDescription = image.description;

  const handlerDownload = () => {
    if ('data' in image && image.data instanceof File) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(image.data);
      link.download = image.data.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const link = document.createElement('a');
      link.href = `${OpenAPI.BASE}/image/${image.uuid_image}`;
      link.download = image.origin_file_name ?? 'file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className={classes.container}>
      <IconDraggable size={'s'} className={classes.draggable} />
      <CustomHoverToolTip title={'Просмотр'}>
        <div
          className={classes.img}
          onClick={() =>
            setImagePreview({
              imgSrc: imgSrcForPreview,
              isOpen: true,
              imgDescription: imgDescription ?? '',
            })
          }
        >
          <img
            src={imgSrc}
            onError={(event) => {
              event.currentTarget.src = imgErrorSettings;
            }}
            alt={'миниатюра'}
          />
        </div>
      </CustomHoverToolTip>
      <div className={classes.content}>
        <Text size={'s'} lineHeight={'xs'}>
          {imgTitle}
        </Text>
        <Text view={'ghost'} size={'xs'} lineHeight={'xs'} className={cnMixSpace({ mT: '2xs' })}>
          {imgInfo}
        </Text>
        <TextField
          value={imgDescription}
          size={'s'}
          width={'full'}
          placeholder={'Добавьте описание...'}
          onChange={({ value }) => onChangeDescription(value ?? '', index)}
        />
      </div>
      <Button
        view={'clear'}
        onlyIcon
        iconRight={IconDownload}
        size={'s'}
        onClick={handlerDownload}
      />
      <Button
        view={'clear'}
        onlyIcon
        iconRight={IconTrash}
        size={'s'}
        onClick={() => deleteImage(index)}
      />
      <ImageFullSizeModal
        show={imagePreview.isOpen}
        imgSrc={imagePreview.imgSrc}
        imgDescription={imagePreview.imgDescription}
        afterClose={() => setImagePreview((prev) => ({ ...prev, imgSrc: '', imgDescription: '' }))}
        closeModal={() => setImagePreview((prevState) => ({ ...prevState, isOpen: false }))}
      />
    </div>
  );
};
