import { Avatar } from '@consta/uikit/Avatar';
import { Button } from '@consta/uikit/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { FeedbackDto, FilterUserDto } from '../../../generateAxios';

import { IconQuote } from '../../assets/icons/tsx/IconQuote.tsx';
import { getMimeType } from '../../utils/getMimeType.ts';

import classes from './PortfolioComment.module.css';

export const PortfolioComment = ({
  feedback,
  compactView = false,
  deleteComment,
  index,
}: {
  feedback:
    | FeedbackDto
    | {
        user: FilterUserDto;
        body: string;
      };
  compactView?: boolean;
  deleteComment?: (id: number) => void;
  index?: number;
}) => {
  let img: string | undefined = undefined;
  if (feedback.user.avatar) {
    const mimeType = getMimeType(feedback.user.avatar);
    img = `data:${mimeType};base64,${feedback.user.avatar.replace(/\s+/g, '')}`;
  }
  // img = feedback.user.uuid_image
  //   ? `${OpenAPI.BASE}/image/${feedback.user.uuid_image}`
  //   : imgErrorSettings;

  const showDeleteButton = !!deleteComment && index !== undefined;

  return (
    <div className={classes.container}>
      <IconQuote className={classes.noShrink} view={'ghost'} />
      <div className={classes.comment}>
        <div className={classes.header}>
          <Avatar url={img} className={classes.avatar} />
          <div className={classes.description}>
            <Text size={'s'} lineHeight={'xs'}>
              {feedback.user.display_name}
            </Text>
            <Text size={compactView ? '2xs' : 'xs'} view={'ghost'} lineHeight={'xs'}>
              {`${feedback.user.position}`}
            </Text>
          </div>
        </div>
        <Text className={cnMixSpace({ mT: compactView ? 'xs' : 'l' })}>{feedback.body}</Text>
      </div>
      {showDeleteButton && (
        <Button
          view={'clear'}
          onlyIcon
          iconRight={IconTrash}
          size={'s'}
          onClick={() => deleteComment(index)}
          className={classes.noShrink}
        />
      )}
    </div>
  );
};
