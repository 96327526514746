import { MouseEvent, ReactNode } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { IconScreen } from '@consta/uikit/IconScreen';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { generalActions } from '../../../../../store/reducers/generalSlice.ts';
import { isAdminModeSelector, selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { toolsPageSelectors } from '../../../../../store/reducers/toolsPageSlice.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import process from '../../../../assets/icons/svg/icn_main_about_process_task_filters.svg';
import cupImg from '../../../../assets/images/tools-block-img.png';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';

import { toolQueryKey } from '../../ToolsPage.tsx';

import classes from './ToolsSecondaryLinks.module.scss';

export const ToolsSecondaryLinks = () => {
  const dispatch = useAppDispatch();
  const tools = useAppSelector(toolsPageSelectors.tools);
  const [currentToolId] = useAppSelector(selectParamByKey(toolQueryKey));
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const currentTool = tools?.find((tool) => tool.tool._uuid === currentToolId) ?? tools?.[0];
  const portfolioHref = `${PageRoutes.DataLab_PortfolioPage}?tool=${currentTool?.tool._uuid ? currentTool.tool._uuid : ''}${isAdminMode ? '&mode=admin' : ''}`;
  const knowledgeHref = `${PageRoutes.DataLab_KnowledgesPage}?tool=${currentTool?.tool._uuid ? currentTool.tool._uuid : ''}${isAdminMode ? '&mode=admin' : ''}`;

  return (
    currentTool && (
      <AppContentWrapper className={classes.container}>
        <div className={classes.bigContent}>
          <img src={cupImg} alt={''} width={'382px'} />
          <div className={classes.bigContentDescription}>
            <div>
              <Text size={'3xl'} weight={'semibold'}>
                {currentTool.application_count} {currentTool.application_count_text}
              </Text>
              <Text size={'xl'} className={cnMixSpace({ mT: 'm' })}>
                {currentTool.portfolio_count} {currentTool.portfolio_count_text}
              </Text>
            </div>
            <Button
              label={'Перейти'}
              iconRight={IconForward}
              form={'round'}
              as={'a'}
              href={relativeToAbsoluteLink(portfolioHref)}
              onClick={(e) => {
                e.preventDefault();
                navigate(portfolioHref);
              }}
            />
          </div>
        </div>
        <div className={classes.smallContentWrapper}>
          <SecondaryLink
            img={<img src={process} alt={'icon'} />}
            title={'База знаний'}
            description={
              'Изучите обучающие материалы и инструкции, которые помогут вам при работе с инструментом'
            }
            href={relativeToAbsoluteLink(knowledgeHref)}
            onClick={(e) => {
              e.preventDefault();
              navigate(knowledgeHref);
            }}
          />
          <SecondaryLink
            img={<IconScreen view={'brand'} size={'s'} />}
            title={'Обучение'}
            description={
              'Не знаете, с чего начать работу с инструментом? Записывайтесь на онлайн-курсы обучения'
            }
            onClick={() => {
              dispatch(generalActions.setLearningModal(true));
            }}
          />
          {currentTool.has_consult && (
            <SecondaryLink
              img={<IconScreen view={'brand'} size={'s'} />}
              title={'Консультации'}
              description={
                'Сотрудники лаборатории расскажут с чего начать или как пройти определённый этап процесса'
              }
              onClick={() => dispatch(generalActions.setSupportModal('short'))}
            />
          )}
        </div>
      </AppContentWrapper>
    )
  );
};

const SecondaryLink = ({
  img,
  title,
  description,
  href,
  onClick,
  buttonLabel = 'Перейти',
  disabled = false,
}: {
  img: ReactNode;
  title: string;
  description: string;
  href?: string;
  onClick: (e: MouseEvent<Element, globalThis.MouseEvent>) => void;
  buttonLabel?: string;
  disabled?: boolean;
}) => {
  return (
    <div className={classes.smallContent}>
      <div className={classes.smallContentText}>
        <div className={classes.smallContentTitle}>
          {img}
          <Text weight={'semibold'}>{title}</Text>
        </div>
        <Text className={cnMixSpace({ mT: 's' })}>{description}</Text>
      </div>
      {href ? (
        <Button
          form={'round'}
          label={buttonLabel}
          size={'s'}
          view={'clear'}
          iconRight={IconForward}
          as={'a'}
          href={href}
          onClick={onClick}
          disabled={disabled}
        />
      ) : (
        <Button
          form={'round'}
          label={buttonLabel}
          size={'s'}
          view={'clear'}
          iconRight={IconForward}
          onClick={onClick}
          disabled={disabled}
        />
      )}
    </div>
  );
};
