import { ReactNode, useEffect, useState } from 'react';

import axios from 'axios';

import { OpenAPI } from '../../../generateAxios/core/OpenAPI.ts';

import { faqConfig } from '../../configs/faqConfig.ts';
import { globalConfig } from '../../configs/globalConfig.ts';
import { indicatorConfig } from '../../configs/indicatorColorConfig.ts';
import { linksConfig, tagHashKeysConfig } from '../../configs/linksConfig.ts';
import { themeConfig } from '../../configs/themeConfig.ts';

type GlobalConfig = {
  // "https://sss.ss/api",
  API_BASE_PATH: string; // адрес апи для смены "https://sss.ss/api",

  // менять ли адрес апи для смены "/api" на "https://sss.ss/api",
  CHANGE_BASE_PATH: boolean;

  // цвет индикации в хедере "rgba(0, 32, 51, 0.6)" "#123456", "red", "green"
  HEADER_INDICATOR_COLOR: string | false;

  // максимальное количество записей FAQ на главной странице (по умолчанию 6)
  FAQ_MAIN_PAGE_MAX_COUNT: number;

  // ссылка на магазин, если ссылки не будет, то кнопка дизэйблится
  DATA_MARKET_LINK: string | undefined;

  /* hash_keys тега "аналитическое приложение" для формирования ссылок с главной страницы, регистр имеет значение,
   по умолчанию 'SYSTEM_ANALYTICAL_APPLICATION'*/
  TAG_ANALYTICAL_APPLICATION: string;

  /* hash_keys тега "постановка задач" для формирования ссылок с главной страницы, регистр имеет значение
  по умолчанию 'SYSTEM_TASK_STAGING' */
  TAG_TASK_STAGING: string;

  /* hash_keys тега "процесс" для формирования ссылок с главной страницы, регистр имеет значение
  по умолчанию 'SYSTEM_PROCESS' */
  TAG_PROCESS: string;

  /* hash_keys тега "excel" для формирования ссылок с главной страницы, регистр имеет значение
  по умолчанию 'SYSTEM_EXCEL' */
  TAG_EXCEL: string;

  /* включение возможности изменить тему, по умолчанию false (тема presetGpnDefault - светлая), для включения true либо любая строка */
  THEME_CHANGE: boolean | string;

  /* глобальный параметр возможности скачивания видео, по умолчанию false, для включения true либо любая не пустая строка  */
  ALLOW_DOWNLOAD_VIDEO: boolean | string;
};

type PropsType = { children: ReactNode };
export const GetConfig = (props: PropsType) => {
  const [configChecked, setConfigChecked] = useState<boolean>(false);
  console.log(`BASE_URL:${import.meta.env.BASE_URL}`);
  useEffect(() => {
    if (!configChecked) {
      axios
        .get<GlobalConfig>(
          `${import.meta.env.BASE_URL === '/' ? '' : import.meta.env.BASE_URL}/config.json`
        )
        .then(({ data }) => {
          console.log(data);
          if (data.CHANGE_BASE_PATH && data.API_BASE_PATH) {
            OpenAPI.BASE = data.API_BASE_PATH;
            console.log(`API_BASE_PATH = "${data.API_BASE_PATH}"`);
            console.log(`CHANGE_BASE_PATH = "${data.CHANGE_BASE_PATH}"`);
          }
          if (data.FAQ_MAIN_PAGE_MAX_COUNT) {
            faqConfig.mainPageMaxCount = data.FAQ_MAIN_PAGE_MAX_COUNT;
          }
          if (
            data.hasOwnProperty('HEADER_INDICATOR_COLOR') &&
            typeof data.HEADER_INDICATOR_COLOR === 'string'
          ) {
            indicatorConfig.color = data.HEADER_INDICATOR_COLOR;
          }
          if (
            data.hasOwnProperty('DATA_MARKET_LINK') &&
            typeof data.DATA_MARKET_LINK === 'string'
          ) {
            linksConfig.new_market = data.DATA_MARKET_LINK;
          }
          isAvailableString(data, 'TAG_ANALYTICAL_APPLICATION') &&
            (tagHashKeysConfig.tag_analytical_application = data.TAG_ANALYTICAL_APPLICATION);
          isAvailableString(data, 'TAG_TASK_STAGING') &&
            (tagHashKeysConfig.tag_task_staging = data.TAG_TASK_STAGING);
          isAvailableString(data, 'TAG_PROCESS') &&
            (tagHashKeysConfig.tag_process = data.TAG_PROCESS);
          isAvailableString(data, 'TAG_EXCEL') && (tagHashKeysConfig.tag_excel = data.TAG_EXCEL);

          if (data.hasOwnProperty('THEME_CHANGE')) {
            themeConfig.changeTheme = !!data.THEME_CHANGE;
          }
          if (data.hasOwnProperty('ALLOW_DOWNLOAD_VIDEO')) {
            globalConfig.allowDownloadVideo = !!data.ALLOW_DOWNLOAD_VIDEO;
          }

          // if (res.CHANGE_NAMES) {
          //   emptyNames.business_description =
          //     res.business_description ?? emptyNames.business_description;
          //   emptyNames.organization = res.organization ?? emptyNames.organization;
          //   emptyNames.domain = res.domain ?? emptyNames.domain;
          //   emptyNames.database = res.database ?? emptyNames.database;
          //   emptyNames.origin_source = res.origin_source ?? emptyNames.origin_source;
          //   emptyNames.ddl_create_date = res.ddl_create_date ?? emptyNames.ddl_create_date;
          //   emptyNames.any_empty_field = res.any_empty_field ?? emptyNames.any_empty_field;
          //   emptyNames.any_empty_field_without_message =
          //     res.any_empty_field_without_message ?? emptyNames.any_empty_field_without_message;
          //   emptyNames.subject_area = res.subject_area ?? emptyNames.subject_area;
          //   emptyNames.access_groups_name = res.access_groups_name ?? emptyNames.access_groups_name;
          //   emptyNames.access_groups_description =
          //     res.access_groups_description ?? emptyNames.access_groups_description;
          //   emptyNames.responsible = res.responsible ?? emptyNames.responsible;
          //   emptyNames.manager = res.manager ?? emptyNames.manager;
          //   emptyNameStyle.color = res.emptyNameColor ?? emptyNameStyle.color;
          // }
          // if (res.hasOwnProperty('SHOW_COUNT_IN_FILTERS')) {
          //   generalFilterConfig.showTotalCount = !!res.SHOW_COUNT_IN_FILTERS;
          // }
          // if (res.hasOwnProperty('DISABLE_NOT_AVAILABLE_DATASET_FILTERS')) {
          //   generalFilterConfig.disableNotAvailableDatasetFilters =
          //     !!res.DISABLE_NOT_AVAILABLE_DATASET_FILTERS;
          // }
          // // замена ссылок в разделе "О магазине"
          // if (res.hasOwnProperty('aboutMarketLinks')) {
          // }
          // // замена внешних ссылок ссылок
          // if (res.hasOwnProperty('externalLinks')) {
          //   res.externalLinks?.laboratory &&
          //   (externalLinks.laboratory = res.externalLinks.laboratory);
          //   res.externalLinks?.supportBRD &&
          //   (externalLinks.supportBRD = res.externalLinks.supportBRD);
          //   res.externalLinks?.supportBLPS &&
          //   (externalLinks.supportBLPS = res.externalLinks.supportBLPS);
          //   res.externalLinks?.supportKF && (externalLinks.supportKF = res.externalLinks.supportKF);
          //   res.externalLinks?.knowledgeBase &&
          //   (externalLinks.knowledgeBase = res.externalLinks?.knowledgeBase);
          //   res.externalLinks?.learning && (externalLinks.learning = res.externalLinks?.learning);
          // }
          //
          // // изменение регулярок
          // if (res.hasOwnProperty('regExpConfig')) {
          //   res.regExpConfig?.url && (regExpConfig.url = new RegExp(res.regExpConfig.url));
          //   res.regExpConfig?.email && (regExpConfig.email = new RegExp(res.regExpConfig.email));
          // }
          // // применение метрик, временно до появление эндпоинта
          // result.pix_bi_all && (temporaryMetricsConfig.pix_bi_all = result.pix_bi_all);
          // result.pix_bi_portfolio &&
          //   (temporaryMetricsConfig.pix_bi_portfolio = result.pix_bi_portfolio);
          // result.qlik_all && (temporaryMetricsConfig.qlik_all = result.qlik_all);
          // result.qlik_portfolio && (temporaryMetricsConfig.qlik_portfolio = result.qlik_portfolio);
          // result.python_all && (temporaryMetricsConfig.python_all = result.python_all);
          // result.python_portfolio &&
          //   (temporaryMetricsConfig.python_portfolio = result.python_portfolio);
          // //изменение роутов
          // result.data_market_link && (PageRoutes.DataLab_ShopDataPage = result.data_market_link);
          // result.education_link && (PageRoutes.DataLab_EducationPage = result.education_link);
          //
          // // изменение ссылок
          // result.guide_link && (linksConfig.guide_link = result.guide_link);
          // result.task_slider_last_element_link &&
          //   (linksConfig.task_slider_last_element_link = result.task_slider_last_element_link);
          // result.excel_link && (linksConfig.excel_link = result.excel_link);
          // result.publish_link && (linksConfig.publish_link = result.publish_link);
          // result.old_blps_market && (linksConfig.old_blps_market = result.old_blps_market);
          // result.old_brd_analog_market &&
          //   (linksConfig.old_brd_analog_market = result.old_brd_analog_market);
          // result.data_market_link && (linksConfig.new_market = result.data_market_link);
          // result.support_brd && (linksConfig.support_brd = result.support_brd);
          // result.support_blps && (linksConfig.support_blps = result.support_blps);
          // result.support_kf && (linksConfig.support_kf = result.support_kf);
          //
          // // uuid картинки на аватар по умолчанию
          // result.default_avatar_image_uuid &&
          //   (imgConfig.defaultAvatarUuid = result.default_avatar_image_uuid);
          // console.log('настройки в соответствии с Config:', result);
        })
        .catch((e) => {
          console.log(
            'Ошибка при обработке файла config.json, применяются настройки по умолчанию',
            e
          );
        })
        .finally(() => {
          setConfigChecked(true);
        });
    }
  }, [configChecked]);
  if (!configChecked) return <></>;

  return <> {props.children}</>;
};

const isAvailableString = (config: GlobalConfig, key: keyof GlobalConfig) => {
  return config.hasOwnProperty(key) && typeof config[key] === 'string';
};
