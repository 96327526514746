const base64urlDecode = (base64url: string): string => {
  // Добавляем недостающие символы для корректного декодирования Base64 URL
  let paddedBase64 = base64url.replace(/-/g, '+').replace(/_/g, '/');

  while (paddedBase64.length % 4) {
    paddedBase64 += '=';
  }
  // Декодируем Base64
  return atob(paddedBase64);
};
export const parseJwt = (token: string) => {
  const payload = token.split('.')[1];
  return JSON.parse(base64urlDecode(payload));
};
