import { memo } from 'react';

import { Avatar } from '@consta/uikit/Avatar';
import { Button } from '@consta/uikit/Button';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import { TeamItemIgnoreBlockDto } from '../../../../../../../generateAxios';
import { getMimeType } from '../../../../../../utils/getMimeType.ts';

import classes from './ContactListItem.module.css';

type Props = {
  item: TeamItemIgnoreBlockDto;
  onClickEdit: (item: TeamItemIgnoreBlockDto) => void;
  onClickDelete: (item: TeamItemIgnoreBlockDto) => void;
  isSelected?: boolean;
};

export const ContactListItem = memo(({ item, onClickDelete, onClickEdit, isSelected }: Props) => {
  let img: string | undefined = undefined;
  if (item?.avatar) {
    const mimeType = getMimeType(item.avatar);
    img = `data:${mimeType};base64,${item.avatar.replace(/\s+/g, '')}`;
  }
  return (
    <div className={classNames({ [classes.container]: true, [classes.isSelected]: isSelected })}>
      <IconDraggable size={'xs'} view={'primary'} className={classes.draggable} />
      <Avatar url={img} className={classes.avatar} />
      <div className={classes.description}>
        <Text size={'s'} lineHeight={'xs'}>
          {item?.display_name}
        </Text>
        <Text size={'xs'} view={'ghost'} lineHeight={'xs'}>
          {`${item?.position ? item?.position : 'Роль не указана'} ${item?.email ?? ''}`}
        </Text>
      </div>
      <Button
        onlyIcon
        iconLeft={IconEdit}
        size={'s'}
        view={'clear'}
        onClick={() => onClickEdit(item)}
      />
      <Button
        onlyIcon
        iconLeft={IconTrash}
        size={'s'}
        view={'clear'}
        onClick={() => onClickDelete(item)}
      />
    </div>
  );
});
