import { Text } from '@consta/uikit/Text';

import parse from 'html-react-parser';

import { AppModal } from '../../AppModal/AppModal.tsx';
import { Divider } from '../../Divider/Divider.tsx';

import classes from './ApplicationGetAccessModal.module.css';

export const ApplicationGetAccessModal = ({
  onClose,
  afterClose,
  content,
  isOpen,
}: {
  content: string | null;
  isOpen: boolean;
  onClose: () => void;
  afterClose: () => void;
}) => {
  return (
    <AppModal isOpen={isOpen} className={classes.modal} close={onClose} afterClose={afterClose}>
      <Text size={'xl'} weight={'medium'} className={classes.title}>
        Как получить доступ
      </Text>
      <Divider />
      <div className={classes.content}>{parse(content || '')}</div>
    </AppModal>
  );
};
