export const enumerate = (
  count: string | number | undefined | null,
  words: string[] | undefined
): string => {
  if (!words) {
    return '';
  }
  if (count === undefined || count === null) return words[2];
  let countAsNumber = Number(count);
  if (countAsNumber > 100) countAsNumber = countAsNumber % 100;
  if (countAsNumber <= 20 && countAsNumber >= 10) return words[2];
  if (countAsNumber > 20) countAsNumber = countAsNumber % 10;
  return countAsNumber === 1
    ? words[0]
    : countAsNumber > 1 && countAsNumber < 5
      ? words[1]
      : words[2];
};
