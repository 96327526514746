type GlobalConfig = {
  allowDownloadVideo: boolean;
};

/* настройки распространяемые на все приложение */
export const globalConfig: GlobalConfig = {
  /* глобальный параметр возможности скачивания видео,
   *  по умолчанию false
   *  true если в config.json параметр ALLOW_DOWNLOAD_VIDEO true или не пустая строка */
  allowDownloadVideo: false,
};
