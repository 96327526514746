import { memo, useCallback, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconCheck } from '@consta/uikit/IconCheck';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';

import { type MetricValueActualDto, MetricValueGroupDto } from '../../../../../generateAxios';
import { divideNumIntoCategories } from '../../../../utils/divideNumIntoCategories.ts';

import classes from './MetricItem.module.css';

type Props = {
  metric: MetricValueActualDto;
  total: MetricValueGroupDto | undefined;
  changeMetric: (uuid: string, newValue: number) => Promise<void>;
};

export const MetricItem = memo(({ metric, total, changeMetric }: Props) => {
  const metricDate =
    metric.date && dayjs(metric.date).isValid() ? dayjs(metric.date).format('DD.MM.YY') : null;
  const inputLabel = `Текущее значение ${metric.metric.block?.short_name}: ${divideNumIntoCategories(metric.value ?? 0)} ${metricDate ? `от ${metricDate}` : ''}`;

  const updateMetric = useCallback(
    async (newValue: number) => {
      await changeMetric(metric.metric._uuid, newValue);
    },
    [metric.metric._uuid, changeMetric]
  );

  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        <div className={classes.fullWidth}>
          <Text size={'xl'} weight={'semibold'}>
            {metric.metric.description}
          </Text>
          <Text className={classes.description}>{metric.metric.key_description}</Text>
        </div>
        <div className={classes.fullWidth}>
          <InputWithButton changeMetricValue={updateMetric} />
          <Text view={'secondary'} className={cnMixSpace({ mT: 'xs' })}>
            {inputLabel}
          </Text>
        </div>
      </div>
      <div className={classes.rightSide}>
        <Text view={'secondary'}>Общее количество у блоков:</Text>
        <div>
          <Text size={'3xl'} weight={'bold'} view={'brand'}>
            {total?.value ? divideNumIntoCategories(total.value) : 'нет данных'}
          </Text>
          {total?.presentation_name && <Text weight={'semibold'}>{total?.presentation_name}</Text>}
        </div>
      </div>
    </div>
  );
});

const InputWithButton = memo(
  ({ changeMetricValue }: { changeMetricValue: (newValue: number) => Promise<void> }) => {
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<{ value: string }>({
      defaultValues: {
        value: '',
      },
    });
    const [loading, setLoading] = useState<boolean>(false);

    const submit = async (data: { value: string }) => {
      setLoading(true);
      await changeMetricValue(Number(data.value));
      reset();
      setLoading(false);
    };

    return (
      <form onSubmit={handleSubmit(submit)}>
        <Controller
          name={`value`}
          control={control}
          rules={{
            required: 'Поле обязательно для заполнения',
            min: { value: 0, message: 'Значение не может быть меньше 0' },
            max: { value: 100000, message: 'Значение не может быть больше 100 000' },
            validate: {
              pattern: (value) => {
                const numberValue = parseInt(value);
                return !isNaN(numberValue) || 'Введите число';
              },
            },
          }}
          render={({ field }) => (
            <div className={classes.inputContainer}>
              <TextField
                {...field}
                placeholder={'Введите новое значение'}
                width={'full'}
                value={field.value ?? ''}
                onChange={({ value }) => field.onChange(value ?? '')}
                status={errors.value ? 'alert' : undefined}
                caption={errors.value?.message}
                form={'defaultClear'}
                inputRef={field.ref}
              />
              <Button
                onlyIcon
                iconLeft={IconCheck}
                form={'brickDefault'}
                type={'submit'}
                className={cnMixSpace({ pR: 'm', pL: 'm' })}
                loading={loading}
              />
            </div>
          )}
        />
      </form>
    );
  }
);
