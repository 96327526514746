import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { knowledgeItemPageActions } from '../../../store/reducers/knowledgeItemPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../components/AppContentWrapper/AppContentWrapper.tsx';
import { KnowledgeEditor } from '../../components/KnowledgeEditor/KnowledgeEditor.tsx';
import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';
import { useKnowledgeActions } from '../../hooks/useKnowledgeActions.tsx';

import classes from './KnowledgeCreatePage.module.css';

export const KnowledgeCreatePage = () => {
  const dispatch = useAppDispatch();
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const { getHrefKnowledges } = useKnowledgeActions();

  useEffect(() => {
    document.title = 'База знаний | Лаборатория данных';
    dispatch(knowledgeItemPageActions.clearState());
  }, []);

  return (
    <AppContentWrapper>
      <AppBreadcrumbs
        crumbs={[
          { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
          { label: 'База знаний', rout: getHrefKnowledges() },
          {
            label: `Добавление материала`,
            rout: ``,
          },
        ]}
        classname={classes.breadcrumbs}
      />
      {isAdminMode && !hasRoles ? <SwitchToUser403 /> : <KnowledgeEditor />}
    </AppContentWrapper>
  );
};
