import { memo } from 'react';

import { CollapseGroup } from '@consta/uikit/CollapseGroup';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

import parse from 'html-react-parser';

import { FaqDto } from '../../../../../generateAxios';
import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { faqPageSelectors } from '../../../../../store/reducers/faqPageSlice.ts';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import classes from './FaqsUser.module.scss';

export const FaqsUser = memo(({ items }: { items: FaqDto[] | null }) => {
  const pageLoader = useAppSelector(faqPageSelectors.pageLoader);
  return (
    <AppContentWrapper className={classes.container}>
      {pageLoader && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}

      <CollapseGroup
        className={classes.collapseGroup}
        items={items ?? []}
        iconPosition={'right'}
        iconView={'ghost'}
        view={'clear'}
        getItemLabel={(item) => (
          <div className={classes.collapseLabel}>
            <Text size={'l'}>{item.question}</Text>
          </div>
        )}
        getItemContent={(item) => (
          // <Text view={'secondary'} className={classes.collapseContent}>
          //   {item.answer}
          // </Text>
          <div className={classes.collapseContent}>{parse(item.answer)}</div>
        )}
      />
    </AppContentWrapper>
  );
});
