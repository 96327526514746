import { useEffect, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { FiltersKnowledgeDto } from '../../../../../generateAxios';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import {
  knowledgePageActions,
  knowledgePageSelectors,
} from '../../../../../store/reducers/knowledgePageSlice.ts';
import { selectParamByKey } from '../../../../../store/reducers/selectors.ts';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { knowledgeConfig } from '../../../../configs/knowledgeConfig.ts';

import { KnowledgeCard } from '../KnowledgeCard/KnowledgeCard.tsx';

import classes from './KnowledgeCards.module.css';

export const KnowledgeCards = () => {
  const dispatch = useAppDispatch();

  const [searchValue] = useAppSelector(selectParamByKey(knowledgeConfig.searchQueryKey));
  const filterBlocks = useAppSelector(selectParamByKey(knowledgeConfig.filterBlockQueryKey));
  const filterTypes = useAppSelector(selectParamByKey(knowledgeConfig.filterTypeQueryKey));
  const filterTools = useAppSelector(selectParamByKey(knowledgeConfig.filterToolQueryKey));
  const filterTags = useAppSelector(selectParamByKey(knowledgeConfig.filterTagsQueryKey));
  const filterComplexity = useAppSelector(
    selectParamByKey(knowledgeConfig.filterComplexityQueryKey)
  );
  const filterAuthors = useAppSelector(selectParamByKey(knowledgeConfig.filterAuthorQueryKey));
  const [sortValue] = useAppSelector(selectParamByKey(knowledgeConfig.sortQueryKey));
  const totalElements = useAppSelector(knowledgePageSelectors.totalElements);
  const knowledgeItems = useAppSelector(knowledgePageSelectors.knowledgeItems);
  const pageLoader = useAppSelector(knowledgePageSelectors.pageLoader);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const getStatisticsText = (
    displayedItems: number | undefined,
    totalElements: number | null
  ): string => {
    if (displayedItems === undefined || totalElements === null) return '';
    if (totalElements === 0) {
      return 'Ничего не найдено';
    }
    return `${displayedItems} из ${totalElements}`;
  };
  // при применении сортировки или фильтрации сбрасываем страницу на 0
  useEffect(() => {
    setPageNumber(0);

    const promise = dispatch(knowledgePageActions.getKnowledgeItems({}));
    return () => {
      promise.abort();
    };
  }, [
    searchValue,
    sortValue,
    filterBlocks,
    filterTypes,
    filterTools,
    filterComplexity,
    filterTags,
    filterAuthors,
  ]);

  // действия только на "показать еще" (со второй (index = 1) страницы)
  useEffect(() => {
    if (pageNumber !== 0) {
      dispatch(
        knowledgePageActions.getKnowledgeItems({
          pageNumber: pageNumber,
          addItems: true,
        })
      );
    }
  }, [pageNumber]);

  return (
    <AppContentWrapper className={classes.container}>
      {pageLoader && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      <div className={classes.cards}>
        {knowledgeItems?.map((el) => (
          <KnowledgeCard knowledge={el} key={el._uuid} setFirstPage={() => setPageNumber(0)} />
        ))}
      </div>
      <div className={classes.showMore}>
        {knowledgeItems && knowledgeItems.length !== totalElements && (
          <Button
            view={'ghost'}
            label={'Показать еще'}
            className={cnMixSpace({ pL: 'l', pR: 'l' })}
            onClick={() => setPageNumber((prevState) => prevState + 1)}
            loading={pageLoader}
          />
        )}
        <Text view={'secondary'}>{getStatisticsText(knowledgeItems?.length, totalElements)}</Text>
      </div>
    </AppContentWrapper>
  );
};
