import { useEffect } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { cnMixFlex } from '@consta/uikit/MixFlex';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { faqPageActions } from '../../../../../store/reducers/faqPageSlice.ts';
import { generalActions, generalSelectors } from '../../../../../store/reducers/generalSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { SearchInput } from '../../../../components/SearchInput/SearchInput.tsx';

import { faqBlockQueryKey, faqSearchQueryKey } from '../../FaqPage.tsx';

import classes from './FaqFilter.module.css';
import { ChoiceGroupWithQuery } from './components/ChoiceGroupWithQuery/ChoiceGroupWithQuery.tsx';

export const FaqFilter = () => {
  const dispatch = useAppDispatch();
  const blocks = useAppSelector(generalSelectors.blocks);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    dispatch(generalActions.getBlocks());
  }, []);

  return (
    <AppContentWrapper className={classes.container}>
      <div className={cnMixFlex({ gap: 'm' })}>
        {isAdminMode && (
          <Button
            label={'Добавить'}
            iconRight={IconAdd}
            onClick={() => dispatch(faqPageActions.setFaqEditModal(true))}
          />
        )}
        <ChoiceGroupWithQuery
          filterQueryKey={faqBlockQueryKey}
          items={blocks?.map((el) => ({ label: el.short_name, value: el._uuid })) ?? null}
        />
      </div>
      <SearchInput
        searchQueryKey={faqSearchQueryKey}
        placeholder={'Введите текст'}
        buttonLabel={'Найти'}
        wrapperClassName={classes.search}
        // showSearchButton
      />
    </AppContentWrapper>
  );
};
