import { memo } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Text } from '@consta/uikit/Text';

import classNames from 'classnames';

import { HelpdeskForShowDto } from '../../../../../../../generateAxios';

import classes from './ResourceListItem.module.css';

type Props = {
  item: HelpdeskForShowDto;
  onClickEdit: (item: HelpdeskForShowDto) => void;
  onClickDelete: (item: HelpdeskForShowDto) => void;
  isSelected: boolean;
};

export const ResourceListItem = memo(({ item, onClickEdit, onClickDelete, isSelected }: Props) => {
  return (
    <div className={classNames({ [classes.container]: true, [classes.isSelected]: isSelected })}>
      <IconDraggable size={'xs'} view={'primary'} className={classes.draggable} />
      <div className={classes.description}>
        <Text size={'s'} lineHeight={'xs'}>
          {item.name}
        </Text>
        <Text size={'xs'} view={'ghost'} lineHeight={'xs'}>
          {item.link}
        </Text>
      </div>
      <Button
        onlyIcon
        iconLeft={() => <IconEdit size={'s'} />}
        size={'s'}
        view={'clear'}
        onClick={() => onClickEdit(item)}
      />
      <Button
        onlyIcon
        iconLeft={IconTrash}
        size={'s'}
        view={'clear'}
        onClick={() => onClickDelete(item)}
      />
    </div>
  );
});
