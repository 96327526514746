import { useRef, useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconForward } from '@consta/uikit/IconForward';
import { Text } from '@consta/uikit/Text';

import { IconSearchStroked } from '@consta/icons/IconSearchStroked';

import classNames from 'classnames';
import Slider from 'react-slick';

import { type PortfolioImageDto } from '../../../../../generateAxios';
import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import imgErrorSettings from '../../../../assets/icons/svg/icn_settings_cross.svg';
import { ImageSlidermodal } from '../../../Modals/ImageSlidermodal/ImageSlidermodal.tsx';

import classes from './PortfolioImgSlider.module.css';

export const PortfolioImgSlider = ({
  images,
}: {
  images: (PortfolioImageDto | (PortfolioImageDto & { data?: File }))[];
}) => {
  const sliderRef = useRef<Slider>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState<boolean>(false);

  const portfolioLength = images?.length || 0;
  return (
    <div className={classes.container}>
      <Slider
        slidesToShow={1}
        slidesToScroll={1}
        draggable={false}
        beforeChange={(_, nextSlide) => {
          setShowFullDescription(false);
          setCurrentSlide(nextSlide);
        }}
        adaptiveHeight
        ref={sliderRef}
        infinite={false}
        arrows={false}
      >
        {images?.map((image) => {
          const descriptionRef = useRef<HTMLDivElement>(null);

          const imgSrc =
            'data' in image && image.data instanceof File
              ? URL.createObjectURL(image.data)
              : image.uuid_image
                ? `${OpenAPI.BASE}/image/${image.uuid_image}`
                : imgErrorSettings;
          return (
            <div key={image.uuid_image}>
              <div key={image.uuid_image} className={classes.img}>
                <img
                  key={image.uuid_image}
                  id={'image'}
                  src={imgSrc}
                  onError={(event) => {
                    event.currentTarget.src = imgErrorSettings;
                  }}
                  alt={''}
                />
                <Button
                  view={'ghost'}
                  iconLeft={IconSearchStroked}
                  className={classes.imgPreview}
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              </div>
              {/*<Text>{image.description}</Text>*/}
              <Text
                ref={descriptionRef}
                className={classNames(classes.imgDescription, {
                  [classes.collapsedDescription]: !showFullDescription,
                })}
                onMouseEnter={(e) => {
                  e.currentTarget.setAttribute(
                    'style',
                    descriptionRef.current &&
                      descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight
                      ? 'cursor: pointer;'
                      : ''
                  );
                }}
                onClick={() => {
                  if (
                    descriptionRef.current &&
                    descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight
                  ) {
                    setShowFullDescription(true);
                  } else {
                    setShowFullDescription(false);
                  }
                }}
              >
                {image.description}
              </Text>
            </div>
          );
        })}
      </Slider>

      <CarouselButtons
        handleNextSlide={() => sliderRef.current?.slickNext()}
        handlePrevSlide={() => sliderRef.current?.slickPrev()}
        currentSlide={currentSlide + 1}
        slidesLength={portfolioLength}
      />
      <ImageSlidermodal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        images={images || []}
        initialSlide={currentSlide}
      />
    </div>
  );
};

export type CarouselButtonsProps = {
  handleNextSlide?: () => void;
  handlePrevSlide?: () => void;
  currentSlide: number;
  slidesLength: number;
};

export const CarouselButtons = ({
  currentSlide,
  slidesLength,
  handlePrevSlide,
  handleNextSlide,
}: CarouselButtonsProps) => {
  return (
    <div className={classes.carouselButtons}>
      <Button
        onlyIcon
        iconLeft={IconBackward}
        view="ghost"
        form="round"
        size="m"
        onClick={handlePrevSlide}
        disabled={currentSlide === 1}
      />
      <Text>
        {currentSlide} из {slidesLength}
      </Text>

      <Button
        onlyIcon
        iconLeft={IconForward}
        view="ghost"
        form="round"
        size="m"
        onClick={handleNextSlide}
        disabled={currentSlide === slidesLength}
      />
    </div>
  );
};
