import { useEffect } from 'react';

import { Loader } from '@consta/uikit/Loader';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import {
  knowledgeItemPageActions,
  knowledgeItemPageSelectors,
} from '../../../store/reducers/knowledgeItemPageSlice.ts';
import { portfolioItemPageActions } from '../../../store/reducers/portfolioItemPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../components/AppContentWrapper/AppContentWrapper.tsx';
import { KnowledgeEditor } from '../../components/KnowledgeEditor/KnowledgeEditor.tsx';
import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';
import { useKnowledgeActions } from '../../hooks/useKnowledgeActions.tsx';

import classes from './KnowledgeEditPage.module.css';

export const KnowledgeEditPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const pageLoader = useAppSelector(knowledgeItemPageSelectors.pageLoader);
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const { getHrefKnowledges } = useKnowledgeActions();

  useEffect(() => {
    document.title = 'База знаний | Лаборатория данных';
    if (id !== undefined && isAdminMode && hasRoles) {
      dispatch(knowledgeItemPageActions.getKnowledgeItem(id));
    } else {
      dispatch(portfolioItemPageActions.clearState());
    }
    return () => {
      dispatch(portfolioItemPageActions.clearState());
    };
  }, [isAdminMode, id, hasRoles]);

  if (!isAdminMode)
    return (
      <AppContentWrapper>
        <Text size={'l'}>Просмотр данной страницы в режиме пользователя недоступен</Text>
      </AppContentWrapper>
    );

  return (
    <AppContentWrapper>
      <AppBreadcrumbs
        crumbs={[
          { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
          { label: 'База знаний', rout: getHrefKnowledges() },
          {
            label: `Редактирование материала`,
            rout: ``,
          },
        ]}
        classname={classes.breadcrumbs}
      />

      {pageLoader ? (
        <AppContentWrapper className={cnMixSpace({ mT: '6xl', mB: '6xl' })}>
          <Loader />
        </AppContentWrapper>
      ) : isAdminMode && !hasRoles ? (
        <SwitchToUser403 />
      ) : (
        <KnowledgeEditor />
      )}
    </AppContentWrapper>
  );
};
