import { forwardRef } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';

import classes from './Step4.module.scss';

export const Step4 = forwardRef<HTMLDivElement>((_, ref) => {
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  return (
    <AppContentWrapper className={classes.step4}>
      <Text view={'brand'} size={'l'} weight={'bold'} transform={'uppercase'} ref={ref}>
        Шаг №4
      </Text>
      <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xs', mB: 'm' })}>
        Опубликуйте кейс
      </Text>
      <Text size={'xl'} weight={'regular'}>
        {`Чтобы ваши коллеги смогли узнать о вашем решении или
           переиспользовать его — опубликуйте кейс с приложением
           в разделе Портфолио`}
      </Text>
      <div className={cnMixSpace({ mT: '2xl' })}>
        <Button
          as={'a'}
          href={relativeToAbsoluteLink(
            `${PageRoutes.DataLab_PortfolioCreatePage}${isAdminMode ? '?mode=admin' : ''}`
          )}
          view={'ghost'}
          iconRight={IconAdd}
          label={'Добавить свой кейс'}
          form={'round'}
          onClick={(event) => {
            event.preventDefault();
            navigate(
              `${PageRoutes.DataLab_PortfolioCreatePage}${isAdminMode ? '?mode=admin' : ''}`
            );
          }}
        />
      </div>
    </AppContentWrapper>
  );
});
