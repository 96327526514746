import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Tag } from '@consta/uikit/Tag';
import { Text } from '@consta/uikit/Text';

import { AnimateIconSwitcherProvider } from '@consta/icons/AnimateIconSwitcherProvider';
import { IconEyeClose } from '@consta/icons/IconEyeClose';
import { IconThumbUp } from '@consta/icons/IconThumbUp';
import { withAnimateSwitcherHOC } from '@consta/icons/withAnimateSwitcherHOC';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { PortfolioDto } from '../../../../../generateAxios';
import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks.ts';
import { portfolioPageActions } from '../../../../../store/reducers/portfolioPageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import imgErrorSettings from '../../../../assets/icons/svg/icn_settings_cross.svg';
import { PortfolioDeleteModal } from '../../../../components/Modals/PortfolioDeleteModal/PortfolioDeleteModal.tsx';
import { PortfolioUnpublishModal } from '../../../../components/Modals/PortfolioUnpublishModal/PortfolioUnpublishModal.tsx';
import { divideNumIntoCategories } from '../../../../utils/divideNumIntoCategories.ts';
import { getMimeType } from '../../../../utils/getMimeType.ts';
import { parseHtml } from '../../../../utils/parseHtml.tsx';
import { relativeToAbsoluteLink } from '../../../../utils/relativeToAbsoluteLink.ts';

import classes from './PortfolioCard.module.css';

const AddLikeAnimateIcon = withAnimateSwitcherHOC({
  startIcon: IconThumbUp,
  endIcon: IconThumbUp,
  // endDirection: -360,
  transition: 500,
});

export const PortfolioCard = ({
  portfolio,
  deletePortfolio,
  changePortfolioStatus,
}: {
  portfolio: PortfolioDto;
  deletePortfolio: (uuid: string) => Promise<void>;
  changePortfolioStatus: (uuid: string, newStatus: PortfolioDto['status']) => Promise<void>;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const [likeLoading, setLikeLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [unpublishModal, setUnpublishModal] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingPublish, setLoadingPublish] = useState<boolean>(false);

  const publicDate = !portfolio.public_date
    ? 'нет даты'
    : dayjs(portfolio.public_date).isValid()
      ? dayjs(portfolio.public_date).format('DD MMMM YYYY')
      : 'некорректная дата';

  const hrefPortfolioItem = `${PageRoutes.DataLab_PortfolioPage}/${portfolio._uuid}${isAdminMode ? '?mode=admin' : ''}`;
  const hrefPortfolioEdit = `${PageRoutes.DataLab_PortfolioEditPage.replace(':id', portfolio._uuid)}?mode=admin `;

  // const screenUuid =
  //   portfolio.images?.find((image) => image.is_main)?.uuid_image ??
  //   portfolio.images?.[0].uuid_image;

  let imgBase64src: string | undefined = undefined;
  let imgBase64 = portfolio.images?.find((image) => image.is_main)?.image;
  if (imgBase64) {
    const mimeType = getMimeType(imgBase64);
    imgBase64src = `data:${mimeType};base64,${imgBase64.replace(/\s+/g, '')}`;
  }

  const changeLikeCount = async () => {
    setLikeLoading(true);
    await dispatch(
      portfolioPageActions.setPortfolioLike({
        portfolio,
        action: !portfolio.count_like_by_user ? 'add' : 'delete',
      })
    );
    setLikeLoading(false);
  };

  const handlerDeletePortfolio = async () => {
    setLoadingDelete(true);
    await deletePortfolio(portfolio._uuid);
    setLoadingDelete(false);
    setDeleteModal(false);
  };

  const handlerChangeStatus = async (newStatus: PortfolioDto['status']) => {
    setLoadingPublish(true);
    await changePortfolioStatus(portfolio._uuid, newStatus);
    setLoadingPublish(false);
    setUnpublishModal(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.logo}>
          <img
            src={
              portfolio.tool.uuid_icon
                ? `${OpenAPI.BASE}/image/${portfolio.tool.uuid_icon}`
                : imgErrorSettings
            }
            onError={(event) => {
              event.currentTarget.src = imgErrorSettings;
            }}
            width={'48px'}
            height={'48px'}
            alt={'иконка'}
          />
        </div>
        <div className={classes.screen}>
          <img
            // src={screenUuid ? `${OpenAPI.BASE}/image/${screenUuid}` : imgErrorSettings}
            src={imgBase64src ? imgBase64src : imgErrorSettings}
            onError={(event) => {
              event.currentTarget.src = imgErrorSettings;
            }}
            width={'100%'}
            height={'100%'}
            alt={'скриншот'}
          />
        </div>
      </div>

      <div className={classes.content}>
        <div>
          <div className={classes.contentHeader}>
            <Tag mode={'info'} size={'s'} label={portfolio.block.short_name} />
            <Text view={'secondary'}>{publicDate}</Text>
          </div>
          <Text size={'xl'} weight={'semibold'} className={classes.title}>
            {portfolio.name}
          </Text>
          <Text className={classes.description}>
            {portfolio.description && parseHtml(portfolio.description)}
            {/*{portfolio.description}*/}
            {/*{portfolio.description*/}
            {/*  ?.replace(/<\/?(?!br\s*\/?)[^>]+>/gi, '')*/}
            {/*  // replace(/<\/?[^>]+(>|$)/g, '').*/}
            {/*  .replace(/&nbsp;/g, '')}*/}
          </Text>
        </div>
        <div className={classes.footer}>
          <div className={cnMixFlex({ gap: 's' })}>
            <Button
              label={'Подробнее'}
              size={'s'}
              form={'round'}
              as={'a'}
              href={relativeToAbsoluteLink(hrefPortfolioItem)}
              className={cnMixSpace({ pR: 'l', pL: 'l' })}
              onClick={(e) => {
                e.preventDefault();
                navigate(hrefPortfolioItem);
              }}
            />
            {isAdminMode && (
              <>
                {portfolio.read_only ? (
                  <Button disabled iconLeft={IconEdit} view={'ghost'} size={'s'} form={'round'} />
                ) : (
                  <Button
                    iconLeft={IconEdit}
                    as={'a'}
                    href={relativeToAbsoluteLink(hrefPortfolioEdit)}
                    view={'ghost'}
                    size={'s'}
                    form={'round'}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(hrefPortfolioEdit);
                    }}
                  />
                )}
                {portfolio.status === 'PUBLISHED' && (
                  <Button
                    disabled={portfolio.read_only}
                    iconLeft={IconEyeClose}
                    view={'ghost'}
                    size={'s'}
                    form={'round'}
                    onClick={() => setUnpublishModal(true)}
                    loading={loadingPublish}
                  />
                )}
                <Button
                  disabled={portfolio.read_only}
                  iconLeft={IconTrash}
                  iconSize={'s'}
                  view={'ghost'}
                  size={'s'}
                  form={'round'}
                  className={classes.deleteButton}
                  onClick={() => setDeleteModal(true)}
                  loading={loadingDelete}
                />
              </>
            )}
          </div>
          <AnimateIconSwitcherProvider active={!portfolio.count_like_by_user}>
            <Button
              // label={`${portfolio.count_like_all}`}
              label={divideNumIntoCategories(portfolio.count_like_all)}
              view={!!portfolio.count_like_by_user ? 'secondary' : 'clear'}
              size={'s'}
              form={'round'}
              iconRight={AddLikeAnimateIcon}
              className={classes.likeButton}
              onClick={() => changeLikeCount()}
              loading={likeLoading}
            />
          </AnimateIconSwitcherProvider>
        </div>
      </div>
      <PortfolioDeleteModal
        name={portfolio?.name}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={handlerDeletePortfolio}
        loading={loadingDelete}
      />
      <PortfolioUnpublishModal
        name={portfolio?.name}
        isOpen={unpublishModal}
        onUnpublish={() => handlerChangeStatus('MODERATION')}
        onClose={() => setUnpublishModal(false)}
        loading={loadingPublish}
      />
    </div>
  );
};
