import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { metricsPageSelectors } from '../../../../../store/reducers/metricsPageSlice.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import classes from './MetricsHero.module.scss';

export const MetricsHero = () => {
  const oneAvailableBlockName = useAppSelector(metricsPageSelectors.oneAvailableBlockName);
  return (
    <div className={classes.container}>
      <div className={classes.greyBg}></div>
      <AppContentWrapper className={classes.content}>
        <AppBreadcrumbs
          crumbs={[
            { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
            { label: 'Метрики', rout: PageRoutes.DataLab_MetricsPage },
          ]}
          classname={classes.breadcrumbs}
        />
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xl' })}>
          Редактирование метрик {oneAvailableBlockName ? oneAvailableBlockName : ''}
        </Text>
        <Text size={'xl'} className={classes.contentDescription}>
          {`Укажите метрики вашего подразделения`}
        </Text>
      </AppContentWrapper>
    </div>
  );
};
