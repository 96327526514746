/* eslint-disable @typescript-eslint/no-unused-vars */
import { FullToolDto, NsiRestService } from '../../generateAxios';

import { createAppSlice } from '../createAppSlice';

type ToolsPageState = {
  tools: FullToolDto[] | null;
  pageLoader: boolean;
};

const initialState: ToolsPageState = {
  tools: null,
  pageLoader: true,
};

const toolsPageSlice = createAppSlice({
  name: 'toolsPage',
  initialState,
  reducers: (creators) => ({
    clearState: creators.reducer(() => {
      return initialState;
    }),
    getTools: creators.asyncThunk(
      async (_: undefined) => {
        const res = await NsiRestService.getFullTool();
        return res;
      },
      {
        fulfilled: (state, action) => {
          state.tools = action.payload;
        },
        rejected: (_) => {},
        settled: (state) => {
          state.pageLoader = false;
        },
      }
    ),
  }),

  selectors: {
    pageLoader: (state) => state.pageLoader,
    tools: (state) => state.tools,
  },
});

export const toolsPageActions = toolsPageSlice.actions;
export const toolsPageSelectors = toolsPageSlice.getSelectors(toolsPageSlice.selectSlice);

export default toolsPageSlice.reducer;
