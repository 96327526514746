import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { portfolioPageSelectors } from '../../../../../store/reducers/portfolioPageSlice.ts';
import { PageRoutes } from '../../../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';

import classes from './HeroBlock.module.css';

export const HeroBlock = () => {
  const statistics = useAppSelector(portfolioPageSelectors.statistics);

  return (
    <div className={classes.container}>
      <div className={classes.greyBg}></div>
      <AppContentWrapper className={classes.content}>
        <AppBreadcrumbs
          crumbs={[
            { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
            { label: 'Портфолио', rout: PageRoutes.DataLab_PortfolioPage },
          ]}
          classname={classes.breadcrumbs}
        />
        <Text size={'3xl'} weight={'semibold'} className={cnMixSpace({ mT: '3xl' })}>
          Портфолио
        </Text>
        <Text size={'xl'} className={cnMixSpace({ mT: 'm' })}>
          {`Ознакомьтесь с кейсами, созданными вашими коллегами
           с помощью современных инструментов для
            самостоятельной аналитики`}
        </Text>
        <div className={classes.metrics}>
          <div className={classes.metric}>
            <Text size={'2xl'} view={'brand'} weight={'medium'}>
              {statistics?.totalApps ?? '-'}
            </Text>
            <Text view={'secondary'}>{statistics?.totalAppsName}</Text>
          </div>
          <div className={classes.metric}>
            <Text size={'2xl'} view={'brand'} weight={'medium'}>
              {statistics?.totalPortfolio ?? '-'}
            </Text>
            <Text view={'secondary'}>{statistics?.totalPortfolioName}</Text>
          </div>
        </div>
      </AppContentWrapper>
    </div>
  );
};
