/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockFaqDto } from '../models/BlockFaqDto';
import type { FaqDto } from '../models/FaqDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FaqRestService {
  /**
   * Список всех FAQ
   * @returns FaqDto OK
   * @throws ApiError
   */
  public static getFaq(): CancelablePromise<Array<FaqDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/faq',
    });
  }
  /**
   * Редактирование FAQ
   * @param requestBody
   * @returns FaqDto OK
   * @throws ApiError
   */
  public static updateFaq(
    requestBody: FaqDto,
  ): CancelablePromise<Array<FaqDto>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/faq',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Добавление FAQ
   * @param requestBody
   * @returns FaqDto OK
   * @throws ApiError
   */
  public static addFaq(
    requestBody: FaqDto,
  ): CancelablePromise<Array<FaqDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/faq',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Редактирование порядка FAQ
   * @param requestBody
   * @returns FaqDto OK
   * @throws ApiError
   */
  public static updateFaqsOrder(
    requestBody: Array<FaqDto>,
  ): CancelablePromise<Array<FaqDto>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/faq/all',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Добавление FAQ на главную страницу или удаление с нее
   * @param onMain
   * @param id
   * @returns FaqDto OK
   * @throws ApiError
   */
  public static addFaqToMain(
    onMain: boolean,
    id: string,
  ): CancelablePromise<FaqDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/faq/main/{id}',
      path: {
        'id': id,
      },
      query: {
        'onMain': onMain,
      },
    });
  }
  /**
   * Список FAQ для главной страницы
   * @returns FaqDto OK
   * @throws ApiError
   */
  public static getFaqsMain(): CancelablePromise<Array<FaqDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/faq/main',
    });
  }
  /**
   * Список FAQ, сгруппированный по блокам
   * @returns BlockFaqDto OK
   * @throws ApiError
   */
  public static getFaqsByBlock(): CancelablePromise<Array<BlockFaqDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/faq/blocks',
    });
  }
  /**
   * Удаление FAQ
   * @param id
   * @returns FaqDto OK
   * @throws ApiError
   */
  public static deleteFaq(
    id: string,
  ): CancelablePromise<Array<FaqDto>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/faq/{id}',
      path: {
        'id': id,
      },
    });
  }
}
