import { useState } from 'react';

import { Button } from '@consta/uikit/Button';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { AnimateIconSwitcherProvider } from '@consta/icons/AnimateIconSwitcherProvider';
import { IconThumbUp } from '@consta/icons/IconThumbUp';
import { withAnimateSwitcherHOC } from '@consta/icons/withAnimateSwitcherHOC';

import { PortfolioDto } from '../../../../generateAxios';
import { useAppDispatch } from '../../../../hooks/hooks.ts';
import { portfolioItemPageActions } from '../../../../store/reducers/portfolioItemPageSlice.ts';
import { InfoCard } from '../../../components/InfoCard/InfoCard.tsx';
import { divideNumIntoCategories } from '../../../utils/divideNumIntoCategories.ts';

const AddLikeAnimateIcon = withAnimateSwitcherHOC({
  startIcon: IconThumbUp,
  endIcon: IconThumbUp,
  // endDirection: -360,
  transition: 500,
});

export const PortfolioLikeInfoCard = ({ portfolio }: { portfolio: PortfolioDto }) => {
  const dispatch = useAppDispatch();
  const [likeLoading, setLikeLoading] = useState<boolean>(false);

  const changeLikeCount = async () => {
    setLikeLoading(true);
    await dispatch(
      portfolioItemPageActions.setPortfolioLike({
        portfolio,
        action: !portfolio.count_like_by_user ? 'add' : 'delete',
      })
    );
    setLikeLoading(false);
  };

  return (
    <InfoCard
      header={'Кейс показался вам полезным?'}
      content={'Оцените его, чтобы другие пользователи смогли быстрее ориентироваться в портфолио'}
      footer={
        <AnimateIconSwitcherProvider active={!portfolio.count_like_by_user}>
          <Button
            // label={`${portfolio.count_like_all}`}
            label={`Мне нравится (${divideNumIntoCategories(portfolio.count_like_all)})`}
            view={!!portfolio.count_like_by_user ? 'secondary' : 'ghost'}
            size={'m'}
            iconLeft={AddLikeAnimateIcon}
            className={cnMixSpace({ pL: 'm', pR: 'l' })}
            onClick={() => changeLikeCount()}
            loading={likeLoading}
          />
        </AnimateIconSwitcherProvider>
      }
    />
  );
};
