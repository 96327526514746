/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LikeByType } from '../models/LikeByType';
import type { LikeDto } from '../models/LikeDto';
import type { LikeDtoForShow } from '../models/LikeDtoForShow';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LikesRestService {
  /**
   * Получить список like
   * @returns LikeDto OK
   * @throws ApiError
   */
  public static getLike(): CancelablePromise<Array<LikeDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/like',
    });
  }
  /**
   * Добавить like
   * @param requestBody
   * @returns LikeDtoForShow OK
   * @throws ApiError
   */
  public static addLike(
    requestBody: LikeDto,
  ): CancelablePromise<LikeDtoForShow> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/like',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Получить количество like по uid объекта
   * @param id
   * @returns number OK
   * @throws ApiError
   */
  public static getCountLikeForObject(
    id: string,
  ): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/like/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Удалить like по uid ОБЪЕКТА
   * @param id
   * @returns LikeDtoForShow OK
   * @throws ApiError
   */
  public static deleteLike(
    id: string,
  ): CancelablePromise<LikeDtoForShow> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/like/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Получить количество like по типу объекта
   * @param type
   * @returns LikeByType OK
   * @throws ApiError
   */
  public static getLikeByObjectType(
    type: 'KNOWLEDGE' | 'COURSE' | 'PORTFOLIO' | 'FAQ',
  ): CancelablePromise<Array<LikeByType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/like/type',
      query: {
        'type': type,
      },
    });
  }
}
