import { Button } from '@consta/uikit/Button';
import { IconForward } from '@consta/uikit/IconForward';
import { cnMixFlex } from '@consta/uikit/MixFlex';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import { IconEye } from '@consta/icons/IconEye';

import { useFormContext } from 'react-hook-form';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { portfolioItemPageSelectors } from '../../../../../store/reducers/portfolioItemPageSlice.ts';
import { isAdminModeSelector } from '../../../../../store/reducers/selectors.ts';
import { InfoCard } from '../../../InfoCard/InfoCard.tsx';

import { PortfolioFormValues } from '../../PortfolioEditor.tsx';

/** обязательные поля формы для отслеживания  */
const fields: Array<keyof PortfolioFormValues> = [
  'name',
  'block',
  'organizations',
  // 'domains',
  'tool',
  'user',
  'description',
  'goals',
  'results',
  'images',
];
export const PortfolioEditorFormStatus = ({
  onPreview,
  onSubmit,
  hasBlockRight,
  loading = false,
}: {
  onSubmit?: () => void;
  onPreview?: () => void;
  hasBlockRight: boolean;
  loading?: boolean;
}) => {
  const methods = useFormContext<PortfolioFormValues>();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const portfolio = useAppSelector(portfolioItemPageSelectors.portfolio);

  // Используем watch, чтобы отслеживать все поля
  const watchedFields = methods.watch(fields);
  const index = watchedFields.findIndex((field) => !field);
  const totalCount = fields.length;

  const completedCount = watchedFields.filter((field) => {
    if (Array.isArray(field)) return !!field.length;
    else return !!field;
  }).length;

  /* админка создание кейса с правами - опубликовать
   * админка редактирование опубликованного с правами - сохранить
   * админка редактирование не опубликованного с правами - опубликовать
   * без прав - отправить на проверку
   * режим пользователя - отправить на проверку (не должен появится)
   * */

  let cartTitle = 'Отправьте кейс на проверку';
  let cardContent =
    'Ваша заявка будет рассмотрена администратором. После рассмотрения заявки мы опубликуем ваш кейс в разделе Портфолио.';
  let saveButtonName = 'Отправить заявку';

  if (isAdminMode && hasBlockRight) {
    if (!portfolio || portfolio.status === 'MODERATION') {
      cartTitle = 'Публикация кейса';
      cardContent = 'Кейс будет опубликован в разделе Портфолио';
      saveButtonName = 'Опубликовать';
    }

    if (portfolio && portfolio.status === 'PUBLISHED') {
      cartTitle = 'Сохраните изменения';
      cardContent =
        'После сохранения внесенные изменения будут сохранены и доступны для пользователя';
      saveButtonName = 'Сохранить';
    }
  }

  return totalCount === completedCount ? (
    <InfoCard
      header={cartTitle}
      content={cardContent}
      footer={
        <div className={cnMixFlex({ flex: 'flex', gap: 'm', justify: 'space-between' })}>
          <Button
            label={saveButtonName}
            onClick={() => {
              onSubmit?.();
            }}
            className={cnMixSpace({ pL: 'l', pR: 'l' })}
            disabled={portfolio?.read_only}
            loading={loading}
          />
          <Button
            iconRight={IconEye}
            onlyIcon
            view={'ghost'}
            onClick={() => {
              onPreview?.();
            }}
          />
        </div>
      }
      // additionalClass={classes.infoCard}
    />
  ) : (
    <InfoCard
      header={
        <Text size={'l'} weight={'semibold'}>
          {completedCount} из {totalCount}{' '}
          <Text view={'alert'} size={'l'} weight={'semibold'} as={'span'}>
            *
          </Text>{' '}
          полей заполнено
        </Text>
      }
      content={
        hasBlockRight
          ? 'Чтобы опубликовать кейс, необходимо заполнить все обязательные поля'
          : 'Чтобы отправить заявку на добавление кейса, необходимо заполнить все обязательные поля'
      }
      footer={
        <Button
          view={'ghost'}
          label={'Перейти к незаполненным'}
          iconRight={IconForward}
          onClick={() => {
            methods.trigger(fields[index]);
            methods.setFocus(fields[index]);
          }}
          width={'full'}
        />
      }
    />
  );
};
