import { ReactNode, useCallback, useEffect } from 'react';

import { Loader } from '@consta/uikit/Loader';

import { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { instance } from '../../axios/apiInstance.ts';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks.ts';
import { generalActions, generalSelectors } from '../../store/reducers/generalSlice.ts';

import { LoginModal } from '../components/LoginModal/LoginModal.tsx';
import { NotificationsBar } from '../components/NotificationsBar/NotificationsBar.tsx';

type PropsType = { children: ReactNode };

// компонента-обертка для добавления авторизации, обработки ошибок и показа ошибок запросов
export const WithAuth = (props: PropsType) => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const userDetails = useAppSelector(generalSelectors.userDetails);

  useEffect(() => {
    instance.interceptors.request.use((config) => {
      if (import.meta.env.VITE_AUTH_TYPE === 'TOKEN') {
        const getAccessToken = () => localStorage.getItem('access_token');
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
      }
      return Promise.resolve(config);
    });

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error instanceof AxiosError && error.name !== 'CanceledError') {
          dispatch(
            generalActions.addNotification({
              status: 'alert',
              message: error.response?.data?.error ?? error.message,
              responseCode: error.response?.status,
              responseUrl: error.response?.data?.path,
            })
          );
        }
        if (import.meta.env.VITE_AUTH_TYPE === 'TOKEN') {
          if (error.response?.status === 401) {
            dispatch(generalActions.setShowLoginModal(true));
            // dispatch(
            //   generalActions.addNotification({ status: 'alert', message: 'Вы не авторизованы' })
            // );
          }
        } else {
          if (error.response?.status === 401) {
            // todo перенаправить на страницу без авторизации
            // navigate(MarketPageRoutesEnum.page403);
            console.log('неожиданный ответ status === 401');
          }
        }
        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(generalActions.getUserInfo());
  }, [dispatch]);

  return (
    <>
      {!userDetails ? (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Loader />
        </div>
      ) : (
        props.children
      )}
      <AppModeControl />
      <LoginModal />
      <NotificationsBar />
    </>
  );
};

type ParamsObject = {
  [key: string]: string[];
};
// для избегания перерисовки при чтении searchParams все значения searchParams дублируются в store
// для возможности подписки на определенные query параметры
const AppModeControl = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const getParamsObject = useCallback((searchParams: URLSearchParams): ParamsObject => {
    const paramsObject: ParamsObject = {};

    for (const [key, value] of searchParams.entries()) {
      if (paramsObject[key]) {
        (paramsObject[key] as string[]).push(value);
      } else {
        paramsObject[key] = [value];
      }
    }

    return paramsObject;
  }, []);

  useEffect(() => {
    const paramsObject = getParamsObject(searchParams);
    // const params = Object.fromEntries(searchParams);
    dispatch(generalActions.setSearchParams(paramsObject));
  }, [searchParams, dispatch]);

  return null;
};
