import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { portfolioItemPageActions } from '../../../store/reducers/portfolioItemPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { PageRoutes } from '../../appMenuAndRouting/appRoutes.tsx';
import { AppBreadcrumbs } from '../../components/AppBreaedcrumbs/AppBreadcrumbs.tsx';
import { AppContentWrapper } from '../../components/AppContentWrapper/AppContentWrapper.tsx';
import { PortfolioEditor } from '../../components/PortfolioEditor/PortfolioEditor.tsx';
import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';

import classes from './PortfolioCreatePage.module.css';

export const PortfolioCreatePage = () => {
  const dispatch = useAppDispatch();
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    document.title = 'Портфолио | Лаборатория данных';
    dispatch(portfolioItemPageActions.clearState());
  }, []);

  return (
    <AppContentWrapper>
      <AppBreadcrumbs
        crumbs={[
          { label: 'Лаборатория данных', rout: PageRoutes.DataLab_MainPage },
          { label: 'Портфолио', rout: PageRoutes.DataLab_PortfolioPage },
          {
            label: `Добавление кейса`,
            rout: ``,
          },
        ]}
        classname={classes.breadcrumbs}
      />
      {isAdminMode && !hasRoles ? <SwitchToUser403 /> : <PortfolioEditor />}
    </AppContentWrapper>
  );
};
