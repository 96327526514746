import { Text } from '@consta/uikit/Text';

import { useFormContext } from 'react-hook-form';

import { KnowledgeFormValues } from '../../KnowledgeEditor.tsx';

import { KnowledgeEditorArticle } from './components/KnowledgeEditorArticle/KnowledgeEditorArticle.tsx';
import { KnowledgeEditorFiles } from './components/KnowledgeEditorFiles/KnowledgeEditorFiles.tsx';
import { KnowledgeEditorLinks } from './components/KnowledgeEditorLinks/KnowledgeEditorLinks.tsx';
import { KnowledgeEditorVideo } from './components/KnowledgeEditorVideo/KnowledgeEditorVideo.tsx';

export const KnowledgeEditorContent = () => {
  const { watch } = useFormContext<KnowledgeFormValues>();
  const type = watch('knowledge_type');
  switch (type) {
    case 'VIDEO':
      return <KnowledgeEditorVideo />;
    case 'INTERNAL':
      return <KnowledgeEditorArticle />;
    case 'FILE':
      return <KnowledgeEditorFiles />;
    case 'LINK':
      return <KnowledgeEditorLinks />;
    default:
      return <Text>не корректный тип материала</Text>;
  }
};
