import { useCallback, useState } from 'react';

import { AxiosProgressEvent } from 'axios';
import { useNavigate } from 'react-router-dom';

import { instance } from '../../axios/apiInstance.ts';
import { KnowledgeDto } from '../../generateAxios';
import { OpenAPI } from '../../generateAxios/core/OpenAPI.ts';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks.ts';
import { generalActions } from '../../store/reducers/generalSlice.ts';
import { knowledgeItemPageActions } from '../../store/reducers/knowledgeItemPageSlice.ts';
import { knowledgePageActions } from '../../store/reducers/knowledgePageSlice.ts';
import { isAdminModeSelector } from '../../store/reducers/selectors.ts';

import { PageRoutes } from '../appMenuAndRouting/appRoutes.tsx';
import { knowledgeConfig } from '../configs/knowledgeConfig.ts';

export const useKnowledgeActions = (
  knowledge?: KnowledgeDto | null,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdminMode = useAppSelector(isAdminModeSelector);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const hrefKnowledgeItem = `${PageRoutes.DataLab_KnowledgesPage}/${knowledge?._uuid}${isAdminMode ? '?mode=admin' : ''}`;
  const hrefKnowledgeEdit = `${PageRoutes.DataLab_KnowledgeEditPage.replace(':id', knowledge?._uuid ?? '')}?mode=admin`;
  const getHrefKnowledges = useCallback(
    () =>
      `${PageRoutes.DataLab_KnowledgesPage}${isAdminMode ? '?mode=admin' : ''}${localStorage.getItem(knowledgeConfig.viewLocalStorageKey) === 'table' ? `${isAdminMode ? '&' : '?'}${knowledgeConfig.viewQueryKey}=table` : ''}`,
    [isAdminMode]
  );

  const changeLikeCount = useCallback(async () => {
    knowledge &&
      (await dispatch(
        knowledgePageActions.setKnowledgeLike({
          knowledge,
          action: !knowledge.count_like_by_user ? 'add' : 'delete',
        })
      ));
  }, [knowledge, dispatch]);

  const downloadZip = useCallback(async () => {
    if (!knowledge?._uuid) return;
    try {
      // KnowledgeRestService.getZipFile(knowledge._uuid);
      const response = await instance.get<Blob>(`${OpenAPI.BASE}/files/getzip/${knowledge._uuid}`, {
        responseType: 'blob', // Указываем, что ожидаем Blob в ответе, в кодогенераторе не работает
        onDownloadProgress: onDownloadProgress,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.download = `files.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      dispatch(
        generalActions.addNotification({ status: 'warning', message: 'ошибка при загрузке файла' })
      );
    } finally {
    }
  }, [knowledge?._uuid, dispatch, onDownloadProgress]);

  const downloadFile = useCallback(async () => {
    if (!knowledge?.files) return;
    try {
      const response = await instance.get<Blob>(
        `${OpenAPI.BASE}/files/${knowledge.files![0]._uuid}`,
        {
          responseType: 'blob', // Указываем, что ожидаем Blob в ответе, в кодогенераторе не работает
          onDownloadProgress: onDownloadProgress,
        }
      );

      if (knowledge.files![0].origin_file_extension?.extension === 'pdf') {
        // открываем в новой вкладке
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        window.open(url, '_blank');
      } else {
        // загружаем
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = knowledge.files![0].origin_file_name ?? 'file';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      dispatch(
        generalActions.addNotification({ status: 'warning', message: 'ошибка при загрузке файла' })
      );
    } finally {
    }
  }, [knowledge?.files, dispatch, onDownloadProgress]);

  const openLinksModal = useCallback(() => {
    knowledge &&
      dispatch(knowledgePageActions.setLinksModal({ isOpen: true, knowledge: knowledge }));
  }, [knowledge, dispatch]);

  const openVideoModal = useCallback(() => {
    dispatch(
      knowledgePageActions.setVideoModal({
        files: knowledge?.video_files ?? [],
        isOpen: true,
      })
    );
  }, [knowledge?.video_files, dispatch]);

  const navigateToEdit = useCallback(() => {
    navigate(hrefKnowledgeEdit);
  }, [navigate]);

  const navigateToView = useCallback(() => {
    navigate(hrefKnowledgeItem);
  }, [navigate]);

  const navigateToKnowledges = useCallback(() => {
    navigate(getHrefKnowledges());
  }, [navigate, getHrefKnowledges]);

  const deleteKnowledge = useCallback(
    async (callback?: () => void) => {
      if (!knowledge?._uuid) return;
      setLoadingDelete(true);
      await dispatch(
        knowledgeItemPageActions.deleteKnowledge({
          uuid: knowledge?._uuid ?? '',
          callback: callback,
        })
      );
      setLoadingDelete(false);
    },
    [knowledge?._uuid, dispatch]
  );

  return {
    changeLikeCount,
    downloadZip,
    downloadFile,
    openLinksModal,
    openVideoModal,
    navigateToEdit,
    hrefKnowledgeEdit,
    navigateToKnowledges,
    getHrefKnowledges,
    deleteKnowledge,
    loadingDelete,
    navigateToView,
    hrefKnowledgeItem,
  };
};
