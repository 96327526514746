import { Button } from '@consta/uikit/Button';
import { IconDraggable } from '@consta/uikit/IconDraggable';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';

import classes from './AddedLink.module.css';

export const AddedLink = ({
  deleteLink,
  onChangeLink,
  link,
  title,
  index,
}: {
  title?: string;
  link?: string;
  index: number;
  deleteLink: (index: number) => void;
  onChangeLink: (index: number) => void;
}) => {
  return (
    <div className={classes.container}>
      <IconDraggable size={'s'} className={classes.draggable} />
      <div className={classes.content}>
        <Text size={'s'} lineHeight={'xs'} className={classes.linkTitle}>
          {title ?? 'нет описания'}
        </Text>
        <Text
          view={'ghost'}
          size={'xs'}
          lineHeight={'xs'}
          truncate
          className={cnMixSpace({ mT: '2xs' })}
        >
          {link ?? 'нет ссылки'}
        </Text>
      </div>
      <Button
        view={'clear'}
        onlyIcon
        iconRight={IconEdit}
        size={'s'}
        onClick={() => onChangeLink(index)}
      />
      <Button
        view={'clear'}
        onlyIcon
        iconRight={IconTrash}
        size={'s'}
        onClick={() => deleteLink(index)}
      />
    </div>
  );
};
