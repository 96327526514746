/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FilterSelectorsPortfolioDto,
  type FiltersPortfolioDto,
  LikesRestService,
  MetricRestService,
  PortfolioDto,
  PortfolioRestService,
} from '../../generateAxios';

import { createAppSlice } from '../createAppSlice';
import { AppDispatch } from '../store.ts';

import { generalActions } from './generalSlice.ts';

type PortfolioPageState = {
  portfolioItems: PortfolioDto[] | null;
  portfolioFilters: FilterSelectorsPortfolioDto | null;
  pageLoader: boolean;
  // общее количество в пагинации
  totalElements: number | null;
  currentPortfolio: PortfolioDto | null;
  // статистика для hero block
  statistics: {
    totalApps: number | null;
    totalAppsName: string;
    totalPortfolio: number | null;
    totalPortfolioName: string;
  } | null;
  showSideBar: boolean;
  // количество на модерацию
  moderationCount: number | null;
};

const initialState: PortfolioPageState = {
  currentPortfolio: null,
  portfolioItems: null,
  portfolioFilters: null,
  pageLoader: true,
  totalElements: null,
  statistics: null,
  showSideBar: false,
  moderationCount: null,
};

const portfolioPageSlice = createAppSlice({
  name: 'portfolioPage',
  initialState,
  reducers: (creators) => ({
    setCurrentPortfolio: creators.reducer<PortfolioDto | null>((state, action) => {
      state.currentPortfolio = action.payload;
    }),
    setShowSideBar: creators.reducer<boolean>((state, action) => {
      state.showSideBar = action.payload;
    }),

    clearState: creators.reducer(() => {
      return initialState;
    }),
    getPortfolioItems: creators.asyncThunk(
      async (
        {
          requestBody,
          addItems = false,
        }: {
          requestBody: FiltersPortfolioDto;
          addItems?: boolean;
          forPublication?: boolean;
        },
        { signal }
      ) => {
        const promise = PortfolioRestService.getAllByFilter(requestBody);
        signal.onabort = () => {
          promise.cancel();
        };
        const res = await promise;
        return { data: res, addItems };
      },
      {
        pending: (state) => {
          state.pageLoader = true;
        },
        fulfilled: (state, action) => {
          const { data, addItems } = action.payload;
          if (!state.portfolioItems) {
            state.portfolioItems = action.payload.data.dtos?.content ?? [];
          } else {
            state.portfolioItems = addItems
              ? [...state.portfolioItems, ...(data.dtos?.content ?? [])]
              : data.dtos?.content ?? [];
          }
          state.portfolioFilters = data.filters ?? null;
          state.totalElements = action.payload.data.dtos?.totalElements ?? null;
          state.moderationCount = action.payload.data.moderation_count ?? null;
          state.pageLoader = false;
        },
      }
    ),
    getPortfolioMetrics: creators.asyncThunk(
      async () => {
        const res = await MetricRestService.getGroupMetrics();
        const metricApp = res.find((item) => item.key === 'STORE_APPLICATION_COUNT');
        const totalApps = metricApp?.value ?? null;
        const totalAppsName = metricApp?.presentation_name ?? '-'; // приложение, приложения, приложений
        const metricPortfolio = res.find((item) => item.key === 'PORTFOLIO_COUNT');
        const totalPortfolio = metricPortfolio?.value ?? null;
        const totalPortfolioName = metricPortfolio?.presentation_name ?? '-'; // кейс, кейса, кейсов
        return { totalApps, totalPortfolio, totalAppsName, totalPortfolioName };
      },
      {
        fulfilled: (state, action) => {
          state.statistics = action.payload;
        },
      }
    ),
    setPortfolioLike: creators.asyncThunk(
      async ({ portfolio, action }: { portfolio: PortfolioDto; action: 'add' | 'delete' }) => {
        if (action === 'add') {
          const res = await LikesRestService.addLike({
            _uuid: '00000000-0000-0000-0000-000000000000',
            object_type: 'PORTFOLIO',
            uuid_object: portfolio._uuid,
            thumb_up: 1,
          });
          return res;
        } else {
          const res = await LikesRestService.deleteLike(portfolio._uuid);
          return res;
        }
      },
      {
        fulfilled: (state, action) => {
          const index = state.portfolioItems?.findIndex(
            (item) => item._uuid === action.payload.uuid_object
          );
          if (state.portfolioItems && index !== undefined) {
            state.portfolioItems[index] = {
              ...state.portfolioItems[index],
              count_like_by_user: action.payload.count_like_by_user,
              count_like_all: action.payload.count_like_all,
            };
          }
        },
      }
    ),
    deletePortfolio: creators.asyncThunk(
      async (uuid: string, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        await PortfolioRestService.deletePortfolioById(uuid);
        dispatch(
          generalActions.addNotification({
            status: 'success',
            message: 'Кейс успешно удален',
          })
        );
        return uuid;
      },
      {
        pending: (state) => {
          state.pageLoader = true;
        },
        fulfilled: (state, action) => {
          state.portfolioItems =
            state.portfolioItems?.filter((item) => item._uuid !== action.payload) || null;
          state.totalElements = state.totalElements ? state.totalElements - 1 : null;
          state.pageLoader = false;
        },
        rejected: (state) => {
          state.pageLoader = false;
        },
      }
    ),
  }),

  selectors: {
    pageLoader: (state) => state.pageLoader,
    portfolioItems: (state) => state.portfolioItems,
    totalElements: (state) => state.totalElements,
    moderationCount: (state) => state.moderationCount,
    statistics: (state) => state.statistics,
    portfolioFilters: (state) => state.portfolioFilters,
    toolsFilters: (state) => state.portfolioFilters?.tools,
    domainFilters: (state) => state.portfolioFilters?.domain,
    blockFilters: (state) => state.portfolioFilters?.blocks,
    showSideBar: (state) => state.showSideBar,
  },
});

export const portfolioPageActions = portfolioPageSlice.actions;
export const portfolioPageSelectors = portfolioPageSlice.getSelectors(
  portfolioPageSlice.selectSlice
);

export default portfolioPageSlice.reducer;
