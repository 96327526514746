import { createIcon } from '@consta/icons/Icon';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={'currentColor'}>
    <path d="M5.26829 9.7522C8.16496 9.7522 10.5132 12.1005 10.5132 14.9971C10.5132 17.8937 8.16496 20.242 5.26829 20.242C2.37162 20.242 0.0234146 17.8937 0.0234146 14.9971L0 14.2478C0 8.45447 4.69641 3.75806 10.4898 3.75806V6.75513C8.48841 6.75513 6.60681 7.53451 5.19163 8.94969C4.91923 9.22214 4.67061 9.51202 4.44635 9.8165C4.71416 9.77435 4.98863 9.7522 5.26829 9.7522ZM18.7551 9.7522C21.6517 9.7522 24 12.1005 24 14.9971C24 17.8937 21.6517 20.242 18.7551 20.242C15.8585 20.242 13.5102 17.8937 13.5102 14.9971L13.4868 14.2478C13.4868 8.45447 18.1832 3.75806 23.9766 3.75806V6.75513C21.9753 6.75513 20.0936 7.53451 18.6785 8.94969C18.406 9.22214 18.1574 9.51202 17.9331 9.8165C18.2009 9.77435 18.4754 9.7522 18.7551 9.7522Z" />
  </svg>
);

export const IconQuote = createIcon({
  l: svg,
  m: svg,
  s: svg,
  xs: svg,
  name: 'IconQuote',
});
