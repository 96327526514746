import { useEffect } from 'react';

import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { Text } from '@consta/uikit/Text';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks.ts';
import { generalActions, generalSelectors } from '../../../../store/reducers/generalSlice.ts';
import { validateURL } from '../../../utils/valudateURL.ts';

import { AppModal } from '../../AppModal/AppModal.tsx';

import classes from './LearningModal.module.css';

export const LearningModal = () => {
  const learningModal = useAppSelector(generalSelectors.learningModal);
  const helpdeskLearning = useAppSelector(generalSelectors.helpdeskLearning);
  const dispatch = useAppDispatch();
  const closeModal = () => dispatch(generalActions.setLearningModal(false));

  useEffect(() => {
    if (learningModal) {
      dispatch(generalActions.getHelpdesk('LEARNING'));
    }
  }, [learningModal]);

  return (
    <AppModal isOpen={learningModal} close={closeModal} className={classes.modal}>
      <div className={classes.description}>
        <Text size="xl" view="primary" lineHeight="xs" weight={'bold'}>
          {'Открыть страницу Обучение'}
        </Text>
        <Text size="l" view="primary" lineHeight="xs" align={'center'}>
          {'Выберите блок, в котором вы состоите'}
        </Text>
      </div>
      <div className={classes.buttonsGroup}>
        {!helpdeskLearning && (
          <div>
            <Loader />
          </div>
        )}
        {helpdeskLearning?.length === 0 && (
          <Text size={'m'} view={'secondary'} lineHeight={'xs'} align={'center'}>
            {'Нет доступных блоков'}
          </Text>
        )}
        {helpdeskLearning?.map((helpdeskLink) => {
          return (
            <Button
              key={helpdeskLink._uuid}
              view={'ghost'}
              label={helpdeskLink.block?.short_name}
              onClick={() =>
                validateURL(helpdeskLink.link) && window.open(helpdeskLink.link, '_blank')
              }
            />
          );
        })}
      </div>
    </AppModal>
  );
};
