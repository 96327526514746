import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks.ts';
import { faqPageActions } from '../../../store/reducers/faqPageSlice.ts';
import { hasSomeRoleSelector, isAdminModeSelector } from '../../../store/reducers/selectors.ts';

import { SwitchToUser403 } from '../../components/SwitchToUser403/SwitchToUser403.tsx';

import { FaqDeleteModal } from './components/FaqDeleteModal/FaqDeleteModal.tsx';
import { FaqEditModal } from './components/FaqEditModal/FaqEditModal.tsx';
import { FaqFilter } from './components/FaqFilter/FaqFilter.tsx';
import { FaqsList } from './components/FaqsList/FaqsList.tsx';
import { HeroBlock } from './components/HeroBlock/HeroBlock.tsx';

export const faqSearchQueryKey = 'search';
export const faqBlockQueryKey = 'block';

export const FaqPage = () => {
  const dispatch = useAppDispatch();
  const hasRoles = useAppSelector(hasSomeRoleSelector);
  const isAdminMode = useAppSelector(isAdminModeSelector);

  useEffect(() => {
    document.title = 'FAQ | Лаборатория данных';
    return () => {
      dispatch(faqPageActions.clearState());
    };
  }, []);
  return (
    <>
      <HeroBlock />
      {isAdminMode && !hasRoles ? (
        <SwitchToUser403 />
      ) : (
        <>
          <FaqFilter />
          <FaqsList />
          <FaqEditModal />
          <FaqDeleteModal />
        </>
      )}
    </>
  );
};
