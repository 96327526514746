import React, { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../hooks/hooks.ts';
import { selectParamCountByKeys } from '../../../../../../store/reducers/selectors.ts';
import { FilterSideBarHeader } from '../../../../../components/FilterSideBarHeader/FilterSideBarHeader.tsx';
import { portfolioConfig } from '../../../../../configs/portfolioConfig.ts';

export const PortfolioFilterSideBarHeader = () => {
  const keys = useMemo(
    () => [
      portfolioConfig.filterToolQueryKey,
      portfolioConfig.filterBlockQueryKey,
      portfolioConfig.filterDomainQueryKey,
    ],
    []
  );

  const count = useAppSelector(selectParamCountByKeys(keys));
  const [, setSearchParams] = useSearchParams();

  const resetAll = () => {
    setSearchParams((prev) => {
      keys.map((key) => prev.delete(key));
      return prev;
    });
  };

  return <FilterSideBarHeader count={count} resetAll={resetAll} />;
};
