import { Fragment } from 'react';

import { Text } from '@consta/uikit/Text';

import { useAppSelector } from '../../../../../hooks/hooks.ts';
import { mainPageSelectors } from '../../../../../store/reducers/mainPageSlice.ts';
import { AppContentWrapper } from '../../../../components/AppContentWrapper/AppContentWrapper.tsx';
import { Divider } from '../../../../components/Divider/Divider.tsx';

import classes from './Contacts.module.scss';
import { ContactsBlock } from './ContactsBlock/ContactsBlock.tsx';

export const Contacts = () => {
  const team = useAppSelector(mainPageSelectors.teamItems);

  return (
    <AppContentWrapper className={classes.container}>
      <Text size={'3xl'} weight={'semibold'}>
        Контакты
      </Text>

      {team?.map((el, index, array) => {
        return (
          <Fragment key={el.block?._uuid}>
            <ContactsBlock team={el} />
            {index !== array.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </AppContentWrapper>
  );
};
