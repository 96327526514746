import { Fragment, useCallback, useState } from 'react';

import { Attachment } from '@consta/uikit/Attach';
import { Button } from '@consta/uikit/Button';
import { Text } from '@consta/uikit/Text';

import { IconDownload } from '@consta/icons/IconDownload';
import { IconOpenInNew } from '@consta/icons/IconOpenInNew';

import { AxiosProgressEvent } from 'axios';

import { instance } from '../../../../../axios/apiInstance.ts';
import { OpenAPI } from '../../../../../generateAxios/core/OpenAPI.ts';
import { useAppDispatch } from '../../../../../hooks/hooks.ts';
import { generalActions } from '../../../../../store/reducers/generalSlice.ts';
import { useKnowledgeActions } from '../../../../hooks/useKnowledgeActions.tsx';
import { Divider } from '../../../Divider/Divider.tsx';
import { FileForShowDtoWithData } from '../../../KnowledgeEditor/KnowledgeEditor.tsx';

import { KnowledgeDtoWithFileData } from '../../KnowledgePreview.tsx';

import classes from './KnowledgeContentFiles.module.css';

export const KnowledgeContentFiles = ({ knowledge }: { knowledge: KnowledgeDtoWithFileData }) => {
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);

  const onDownloadAllProgress = useCallback((progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
      setDownloadProgress(Math.round(percentComplete));
    }
  }, []);

  const { downloadZip } = useKnowledgeActions(knowledge, onDownloadAllProgress);

  const handlerDownLoadAll = async () => {
    setDownloadProgress(1);
    await downloadZip();
    setDownloadProgress(null);
  };

  if (!knowledge.files) return null;

  return (
    <div className={classes.fileContent}>
      <div className={classes.listContainer}>
        {knowledge.files.map((file, index) => (
          <Fragment key={file._uuid}>
            <FileItem file={file} />
            {index !== knowledge.files!.length - 1 && <Divider />}
          </Fragment>
        ))}
      </div>
      <div className={classes.downloadAllWrapper}>
        {downloadProgress && <Text view={'secondary'}>{downloadProgress}%</Text>}
        <Button
          size={'s'}
          view={'ghost'}
          iconLeft={IconDownload}
          label={'Скачать все'}
          onClick={handlerDownLoadAll}
          className={classes.downloadAll}
          loading={!!downloadProgress}
        />
      </div>
    </div>
  );
};

const FileItem = ({ file }: { file: FileForShowDtoWithData }) => {
  const dispatch = useAppDispatch();
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);

  const handlerDownloadFile = async () => {
    try {
      const link = document.createElement('a');

      // скачивание только что загруженного файла
      if ('data' in file && file.data instanceof File) {
        link.href = URL.createObjectURL(file.data);
        link.download = file.data.name;
      } else {
        // скачивание файла с сервера
        setDownloadProgress(1);
        // const response = await FilesRestService.getFileById(file._uuid);
        const response = await instance.get<Blob>(`${OpenAPI.BASE}/files/${file._uuid}`, {
          responseType: 'blob', // Указываем, что ожидаем Blob в ответе, в кодогенераторе не работает
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
            const total = progressEvent.total ?? file.origin_file_size;
            if (total) {
              const percentComplete = (progressEvent.loaded / total) * 100;
              setDownloadProgress(Math.round(percentComplete));
            }
          },
        });
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.download = file.origin_file_name ?? 'file';
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      dispatch(
        generalActions.addNotification({
          status: 'warning',
          message: 'ошибка при загрузке файла',
        })
      );
    } finally {
      setDownloadProgress(null);
    }
  };

  const handlerOpenPdf = async () => {
    if (file.origin_file_extension?.extension === 'pdf') {
      try {
        if ('data' in file && file.data instanceof File) {
          const url = URL.createObjectURL(file.data);
          window.open(url, '_blank');
        } else {
          const response = await instance.get<Blob>(`${OpenAPI.BASE}/files/${file._uuid}`, {
            responseType: 'blob', // Указываем, что ожидаем Blob в ответе, в кодогенераторе не работает
            onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
              const total = progressEvent.total ?? file.origin_file_size;
              if (total) {
                const percentComplete = (progressEvent.loaded / total) * 100;
                setDownloadProgress(Math.round(percentComplete));
              }
            },
          });
          // открываем в новой вкладке
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          window.open(url, '_blank');
        }
      } catch (error) {
        dispatch(
          generalActions.addNotification({
            status: 'warning',
            message: 'ошибка при открытии файла pdf',
          })
        );
      } finally {
        setDownloadProgress(null);
      }
    }
  };

  return (
    <div className={classes.listItem}>
      <Attachment
        fileName={file.origin_file_name}
        fileExtension={file.origin_file_extension?.extension ?? ''}
        fileDescription={`${(file.origin_file_size ? file.origin_file_size / (1024 * 1024) : 0).toFixed(2)} Mb`}
        // className={classes.attachment}
        size={'m'}
        loading={downloadProgress !== null}
        loadingProgress={downloadProgress ?? undefined}
      />
      {file.origin_file_extension?.extension === 'pdf' && (
        <Button
          size={'s'}
          view={'ghost'}
          iconRight={IconOpenInNew}
          onlyIcon
          onClick={handlerOpenPdf}
        />
      )}
      <Button
        size={'s'}
        view={'ghost'}
        iconRight={IconDownload}
        onlyIcon
        onClick={handlerDownloadFile}
      />
    </div>
  );
};
