import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFormContext } from 'react-hook-form';

import {
  FilterUserDto,
  FullTeamItemDto,
  TeamItemIgnoreBlockDto,
} from '../../../../../../../generateAxios';
import { CustomUserSelect } from '../../../../../../components/CustomUserSelect/CustomUserSelect.tsx';

import classes from './EditContact.module.css';

export const EditContact = ({ contact }: { contact: TeamItemIgnoreBlockDto }) => {
  const { control, getValues } = useFormContext<FullTeamItemDto>();
  const contactIndex = (getValues('teams') ?? []).findIndex((r) => r._uuid === contact._uuid);
  if (contactIndex === -1) return null;

  return (
    <div className={classes.container}>
      <Text size={'l'} weight={'medium'}>
        {`${contact.is_new ? 'Добавление' : 'Редактирование'}  контакта `}
      </Text>
      <Controller
        name={'teams'}
        control={control}
        rules={{
          validate: {
            validateContact: (value) => {
              if (value?.[contactIndex]?.sso_id) {
                return true;
              } else {
                return 'Выберите пользователя';
              }
            },
          },
        }}
        render={({ field: { ref, ...field }, fieldState }) => {
          const originalUser: TeamItemIgnoreBlockDto | null = field.value?.[contactIndex] ?? null;
          const user: FilterUserDto | null = originalUser?.sso_id
            ? {
                avatar: originalUser.avatar,
                display_name: originalUser.display_name ?? '',
                email: originalUser.email,
                sso_id: originalUser.sso_id ?? 'не указано',
                username: originalUser.username ?? 'не указано',
              }
            : null;

          const onChangeUser = (user: FilterUserDto | null) => {
            const newValue = [...(field.value ?? [])];
            newValue[contactIndex] = {
              ...contact,
              sso_id: user?.sso_id ?? '',
              email: user?.email ?? '',
              display_name: user?.display_name ?? '',
              avatar: user?.avatar,
              username: user?.username ?? '',
              first_name: user?.first_name ?? '',
              last_name: user?.last_name ?? '',
              middle_name: user?.middle_name ?? '',
              position: user?.position ?? '',
            };
            field.onChange(newValue);
          };
          const contacts = getValues('teams')?.filter((el) => el._uuid !== contact._uuid);
          return (
            <div>
              <CustomUserSelect
                required
                label={'Сотрудник'}
                currentUser={user}
                setCurrentUser={onChangeUser}
                placeholder={'Выберите сотрудника'}
                showPhoto={false}
                getItemDisabled={(item) => contacts?.some((el) => el.username === item.username)}
                caption={fieldState.error?.message}
                status={fieldState.error ? 'alert' : undefined}
              />
            </div>
          );
        }}
      />
      <Controller
        name={'teams'}
        control={control}
        render={({ field: { ref, ...field } }) => {
          return (
            <TextField
              {...field}
              width={'full'}
              value={field.value?.[contactIndex]?.position ?? ''}
              label={'Роль'}
              placeholder={'Введите роль'}
              onChange={({ value }) => {
                const newValue = [...(field.value ?? [])];
                newValue[contactIndex] = { ...newValue[contactIndex], position: value ?? '' };
                field.onChange(newValue);
              }}
              inputRef={ref}
            />
          );
        }}
      />
    </div>
  );
};
