import { useEffect } from 'react';

import { Switch } from '@consta/uikit/Switch';

import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../../hooks/hooks.ts';
import { portfolioPageSelectors } from '../../../../../../../store/reducers/portfolioPageSlice.ts';
import { isAdminModeSelector } from '../../../../../../../store/reducers/selectors.ts';
import { portfolioConfig } from '../../../../../../configs/portfolioConfig.ts';

import classes from './SwitcherForPublication.module.css';

const queryKeyForDeleting = [
  portfolioConfig.sortQueryKey,
  portfolioConfig.filterBlockQueryKey,
  portfolioConfig.filterDomainQueryKey,
  portfolioConfig.filterToolQueryKey,
  portfolioConfig.searchQueryKey,
];

export const SwitcherForPublication = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isAdminMode = useAppSelector(isAdminModeSelector);
  const moderationCount = useAppSelector(portfolioPageSelectors.moderationCount);

  useEffect(() => {
    if (!isAdminMode) {
      setSearchParams((prev) => {
        prev.delete(portfolioConfig.statusPortfolioQueryKey);
        return prev;
      });
    }
  }, [isAdminMode]);

  return isAdminMode ? (
    <Switch
      size={'s'}
      checked={searchParams.get(portfolioConfig.statusPortfolioQueryKey) === 'MODERATION'}
      onChange={({ checked }) => {
        setSearchParams((prev) => {
          queryKeyForDeleting.map((key) => prev.delete(key));
          checked
            ? prev.set(portfolioConfig.statusPortfolioQueryKey, 'MODERATION')
            : prev.delete(portfolioConfig.statusPortfolioQueryKey);
          return prev;
        });
      }}
      label={`Заявки на публикацию${moderationCount ? ` (${moderationCount})` : ''}`}
      className={classes.switcher}
    />
  ) : null;
};
