/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MetricForCrudDto } from '../models/MetricForCrudDto';
import type { MetricForKeyDto } from '../models/MetricForKeyDto';
import type { MetricsByBlocksDto } from '../models/MetricsByBlocksDto';
import type { MetricValueActualDto } from '../models/MetricValueActualDto';
import type { MetricValueDto } from '../models/MetricValueDto';
import type { MetricValueGroupDto } from '../models/MetricValueGroupDto';
import type { SetMetricValueDto } from '../models/SetMetricValueDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MetricRestService {
  /**
   * Получение списка всех метрик
   * @returns MetricForCrudDto OK
   * @throws ApiError
   */
  public static getMetrics1(): CancelablePromise<Array<MetricForCrudDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metric',
    });
  }
  /**
   * Обновление метрики
   * @param requestBody
   * @returns MetricForCrudDto OK
   * @throws ApiError
   */
  public static updateMetric(
    requestBody: MetricForCrudDto,
  ): CancelablePromise<MetricForCrudDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/metric',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Добавление метрики
   * @param requestBody
   * @returns MetricForCrudDto OK
   * @throws ApiError
   */
  public static addMetric(
    requestBody: MetricForCrudDto,
  ): CancelablePromise<MetricForCrudDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/metric',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Получения всех значений по метрикам
   * @returns MetricValueDto OK
   * @throws ApiError
   */
  public static getMetricVale(): CancelablePromise<Array<MetricValueDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metric/value',
    });
  }
  /**
   * Занесение значения для метрики
   * @param requestBody
   * @returns MetricValueDto OK
   * @throws ApiError
   */
  public static addMetricVale(
    requestBody: SetMetricValueDto,
  ): CancelablePromise<MetricValueDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/metric/value',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Добавление описания метрики по ключу
   * @param requestBody
   * @returns string OK
   * @throws ApiError
   */
  public static addMetricDescriptionForKey(
    requestBody: MetricForKeyDto,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/metric/key',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Получение агрегированного значения по ключам
   * @returns MetricValueGroupDto OK
   * @throws ApiError
   */
  public static getGroupMetrics(): CancelablePromise<Array<MetricValueGroupDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metric/group',
    });
  }
  /**
   * Получение списка всех метрик
   * @returns MetricsByBlocksDto OK
   * @throws ApiError
   */
  public static getMetricsByBlock(): CancelablePromise<Array<MetricsByBlocksDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metric/byblocks',
    });
  }
  /**
   * Получение актуального значения по каждой метрике
   * @returns MetricValueActualDto OK
   * @throws ApiError
   */
  public static getActualMetrics(): CancelablePromise<Array<MetricValueActualDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/metric/actual',
    });
  }
  /**
   * Удаление метрики
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteMetric(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/metric/{id}',
      path: {
        'id': id,
      },
    });
  }
}
