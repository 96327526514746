import { Fragment } from 'react';

import { cnMixFlex } from '@consta/uikit/MixFlex';

import { Controller, useFormContext } from 'react-hook-form';

import { Divider } from '../../../Divider/Divider.tsx';
import { PortfolioComment } from '../../../PortfolioComment/PortfolioComment.tsx';

import { PortfolioFormValues } from '../../PortfolioEditor.tsx';

import classes from './PortfolioEditorFeedbacks.module.css';

export const PortfolioEditorFeedbacks = () => {
  const { control } = useFormContext<PortfolioFormValues>();
  return (
    <div className={classes.container}>
      <Controller
        name={'feedbacks'}
        control={control}
        render={({ field }) => {
          const deleteComment = (index: number) => {
            field.onChange(field.value?.filter((_, i) => i !== index));
          };
          return (
            <div
              className={cnMixFlex({
                flex: 'flex',
                direction: 'column',
                gap: 'l',
                align: 'stretch',
              })}
            >
              {field.value?.map((feedback, index) => {
                return (
                  <Fragment key={index}>
                    <PortfolioComment
                      feedback={feedback}
                      compactView
                      index={index}
                      deleteComment={deleteComment}
                    />
                    <Divider />
                  </Fragment>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
};
