import { cnMixSpace } from '@consta/uikit/MixSpace';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';

import { Controller, useFormContext } from 'react-hook-form';

import type { FullTeamItemDto, HelpdeskForShowDto } from '../../../../../../../generateAxios';

import classes from './EditResource.module.css';

export const EditResource = ({ resource }: { resource: HelpdeskForShowDto }) => {
  const { control, getValues } = useFormContext<FullTeamItemDto>();
  const resourceIndex = (getValues('resources') ?? []).findIndex((r) => r._uuid === resource._uuid);
  if (resourceIndex === -1) return null;

  return (
    <div className={classes.container}>
      <Text size={'l'} weight={'medium'} className={cnMixSpace({ mB: '2xs' })}>
        {`${resource.is_new ? 'Добавление' : 'Редактирование'} ресурса`}{' '}
      </Text>
      <Controller
        name={'resources'}
        control={control}
        render={({ field: { ref, ...field } }) => {
          return (
            <TextField
              {...field}
              width={'full'}
              value={field.value?.[resourceIndex]?.name ?? ''}
              label={'Название ресурса'}
              placeholder={'Введите название ресурса'}
              onChange={({ value }) => {
                const newValue = [...(field.value ?? [])];
                newValue[resourceIndex] = { ...newValue[resourceIndex], name: value ?? '' };
                field.onChange(newValue);
              }}
              inputRef={ref}
            />
          );
        }}
      />
      <Controller
        name={'resources'}
        control={control}
        rules={{
          validate: {
            validateEmail: (value) => {
              if (value?.[resourceIndex]?.link?.toLowerCase()?.match(/^https?:\/\//)) {
                return true;
              } else {
                return 'Ссылка должна начинаться с http:// или https://';
              }
            },
          },
        }}
        render={({ field: { ref, ...field }, fieldState }) => {
          return (
            <TextField
              {...field}
              required
              width={'full'}
              value={field.value?.[resourceIndex]?.link ?? ''}
              label={'Ссылка на ресурс'}
              placeholder={'Введите ссылку на ресурс'}
              onChange={({ value }) => {
                const newValue = [...(field.value ?? [])];
                newValue[resourceIndex] = { ...newValue[resourceIndex], link: value ?? '' };
                field.onChange(newValue);
              }}
              inputRef={ref}
              status={!!fieldState.error?.message ? 'alert' : undefined}
              caption={fieldState.error?.message}
            />
          );
        }}
      />
    </div>
  );
};
